import React, { useState, useEffect } from "react";
import {
  BsCalendar2Week,
  BsCalendar3,
  BsClock,
  BsClockHistory,
} from "react-icons/bs";
import { API, formatMonthDate } from "../utils/services";
import { Grid, Box, CssBaseline } from "@mui/material";
import LineChart from "../components/common/graphs/LineGraphs";
import CustomHeading from "../components/common/CustomHeading";
import axiosInstance from "../helpers/axios";
import IntervalCard from "../components/common/DashboardCard";
import { ClockLoader } from "react-spinners";

const ResourceDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [activeInterval, setActiveInterval] = useState("daily");
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const [dashboardTotals, setDashboardTotals] = useState({
    todayHours: 0,
    todayBillableHours: 0,
    todayNonBillableHours: 0,
    thisWeekHours: 0,
    thisWeekWriteoffHours: 0,
    thisWeekBillableHours: 0,
    thisWeekNonBillableHours: 0,
    lastWeekHours: 0,
    lastWeekWriteoffHours: 0,
    lastWeekBillableHours: 0,
    lastWeekNonBillableHours: 0,
    thisMonthHours: 0,
    thisMonthWriteoffHours: 0,
    thisMonthBillableHours: 0,
    thisMonthNonBillableHours: 0,
    thisYearHours: 0,
    thisYearTotalBillable: 0,
    thisYearTotalNonBillable: 0,
  });

  useEffect(() => {
    fetchDashboardData();
    handleIntervalChange("daily");
  }, []);

  // Resource Main Totals
  const fetchDashboardData = async () => {
    try {
      const response = await axiosInstance.get(
        `${API}/workHour/resourceDashboardTotals`
      );
      const data = response.data;

      setDashboardTotals({
        todayHours: data.todayHours,
        todayBillableHours: data.todayBillableHours,
        todayNonBillableHours: data.todayNonBillableHours,
        thisWeekHours: data.thisWeekHours,
        thisWeekWriteoffHours: data.thisWeekWriteoffHours,
        thisWeekBillableHours: data.thisWeekBillableHours,
        thisWeekNonBillableHours: data.thisWeekNonBillableHours,
        lastWeekHours: data.lastWeekHours,
        lastWeekWriteoffHours: data.lastWeekWriteoffHours,
        lastWeekBillableHours: data.lastWeekBillableHours,
        lastWeekNonBillableHours: data.lastWeekNonBillableHours,
        thisMonthHours: data.thisMonthHours,
        thisMonthWriteoffHours: data.thisMonthWriteoffHours,
        thisMonthBillableHours: data.thisMonthBillableHours,
        thisMonthNonBillableHours: data.thisMonthNonBillableHours,
        thisYearHours: data.thisYearTotalHours,
        thisYearTotalBillable: data.thisYearTotalBillable,
        thisYearTotalNonBillable: data.thisYearTotalNonBillable,
      });
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  // Resource Chart Totals
  const getUserWorkHours = async (interval) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${API}/workHour/resourceChartTotal`,
        {
          params: { interval },
        }
      );

      // console.log("Chart data", response.data);
      if (response.data.workHoursData) {
        setLoading(false);
      }
      return response.data.workHoursData;
    } catch (error) {
      setLoading(false);
      console.error("Error fetching user work hours:", error);
      return [];
    }
  };

  // This function generates labels for the x-axis based on the interval
  const getLabelsForInterval = (interval) => {
    switch (interval) {
      case "daily":
        return getLastNDaysLabels(7);
      case "weekly":
        return getLastNWeeksLabels(4);
      case "monthly":
        return getLastNMonthsLabels(12);
      case "yearly":
        return getLastNYearsLabels(4);
      default:
        return [];
    }
  };

  // getlabels functions
  const getLastNDaysLabels = (n) => {
    const labels = [];
    const today = new Date();

    for (let i = n - 1; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(date.getDate() - i);
      labels.push(formatMonthDate(date.toISOString().split("T")[0]));
    }
    return labels;
  };

  const getLastNWeeksLabels = (n) => {
    const labels = [];
    const today = new Date();

    for (let week = n - 1; week >= 0; week--) {
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay() - (week - 1) * 7);
      labels.push(formatMonthDate(startOfWeek.toISOString().split("T")[0]));
    }

    return labels;
  };

  const getLastNMonthsLabels = (n) => {
    const labels = [];
    const today = new Date();

    for (let month = n - 1; month >= 0; month--) {
      const firstOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() - month,
        1
      );
      labels.push(
        firstOfMonth.toLocaleDateString("default", { month: "short" })
      );
    }

    return labels;
  };

  const getLastNYearsLabels = (n) => {
    const labels = [];
    const currentYear = new Date().getFullYear();

    for (let year = n - 1; year >= 0; year--) {
      labels.push(`${currentYear - year}`);
    }

    return labels;
  };

  const handleIntervalChange = async (interval) => {
    setActiveInterval(interval);

    // Fetch data from the backend
    const workHoursData = await getUserWorkHours(interval);

    const labels = getLabelsForInterval(interval);
    const availableHoursData = [];
    const billableHoursData = [];
    const billedHoursData = [];

    // Assuming 7 hours a day, 35 hours a week, 154 hours a month, 1820 hours a year
    const availableHours = {
      daily: 7,
      weekly: 35,
      monthly: 154,
      yearly: 1820,
    };

    workHoursData.forEach(
      ({
        totalWorkHours,
        totalWriteOffHours,
        billableWorkHours,
        billedWorkHours,
      }) => {
        availableHoursData.push(availableHours[interval]);
        billableHoursData.push(billableWorkHours);
        billedHoursData.push(billedWorkHours);
      }
    );

    const newChartData = {
      labels,
      datasets: [
        {
          label: "Available Hours",
          data: availableHoursData,
          fill: true,
          borderColor: "#4bc0c0",
          backgroundColor: "rgba(75, 192, 192, 0.5)",
        },
        {
          label: "Clocked Hours",
          data: billableHoursData.reverse(),
          borderColor: "#ffcd56",
          backgroundColor: "rgba(255, 205, 86, 0.5)",
        },
        {
          label: "Billed Hours",
          data: billedHoursData.reverse(),
          borderColor: "#ff6384",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };

    setChartData(newChartData);
  };

  return (
    <>
      <CssBaseline />

      {/* Heading  */}
      <Grid container marginBottom={3}>
        <Grid item>
          <CustomHeading variant="h5" text="Dashboard" />
        </Grid>
      </Grid>

      {/* Main content grid */}
      <Grid container spacing={2}>
        {/* Time info grid - left side */}
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          container
          spacing={2}
          direction="column"
        >
          <IntervalCard
            interval="daily"
            activeInterval={activeInterval}
            handleIntervalChange={handleIntervalChange}
            icon={<BsClock />}
            title="Today's Time"
            totalHours={dashboardTotals.todayHours}
            billableHours={dashboardTotals.todayBillableHours}
            nonBillableHours={dashboardTotals.todayNonBillableHours}
          />
          <IntervalCard
            interval="weekly"
            activeInterval={activeInterval}
            handleIntervalChange={handleIntervalChange}
            icon={<BsClockHistory />}
            title="This Week's"
            totalHours={
              dashboardTotals.thisWeekHours +
              dashboardTotals.thisWeekWriteoffHours
            }
            billableHours={dashboardTotals.thisWeekBillableHours}
            nonBillableHours={dashboardTotals.thisWeekNonBillableHours}
          />
          <IntervalCard
            interval="monthly"
            activeInterval={activeInterval}
            handleIntervalChange={handleIntervalChange}
            icon={<BsCalendar2Week />}
            title="Monthly Time"
            totalHours={
              dashboardTotals.thisMonthHours +
              dashboardTotals.thisMonthWriteoffHours
            }
            billableHours={dashboardTotals.thisMonthBillableHours}
            nonBillableHours={dashboardTotals.thisMonthNonBillableHours}
          />
          <IntervalCard
            interval="yearly"
            activeInterval={activeInterval}
            handleIntervalChange={handleIntervalChange}
            icon={<BsCalendar3 />}
            title="Yearly Time"
            totalHours={dashboardTotals.thisYearHours}
            billableHours={dashboardTotals.thisYearTotalBillable}
            nonBillableHours={dashboardTotals.thisYearTotalNonBillable}
          />
        </Grid>

        {/* Chart - right side */}
        <Grid item xs={12} sm={12} md={9}>
          <Box
            width="100%"
            sx={{
              height: "100%",
              padding: "10px",
              backgroundColor: "#fff",
              borderRadius: "10px",
              boxShadow: "0px 3px 6px #00000029",
            }}
          >
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  gap: "20px",
                  // backgroundColor: "#eae4dd",
                  borderRadius: "10px",
                }}
              >
                <ClockLoader size={100} color={"#886a4d"} loading={loading} />
                <CustomHeading text="Graph Loading..." />
              </Box>
            ) : (
              <LineChart data={chartData} />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ResourceDashboard;
