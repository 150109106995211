import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Box,
  CssBaseline,
  MenuItem,
  Select,
  Typography,
  Card,
  CardContent,
  Container,
} from "@mui/material";
import LineChart from "../components/graphs/LineGraphs";
import axiosInstance from "../helpers/axios";
import Header from "../components/ui/Header";
import { format } from "date-fns";
import useResponsive from "../constants/useResponsive";
import CustomButton from "../components/ui/CustomButton";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../constants/theme";
import { PlusCircleFilled } from "@ant-design/icons";
import { API, formatMonthDate } from "../utils/services";

const ResourceDashboard = () => {
  const navigate = useNavigate();
  const todayDate = format(new Date(), "MMMM d, yyyy");
  const { isSm, isXl } = useResponsive();

  const [activeInterval, setActiveInterval] = useState("daily");
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [startEndDate, setStartEndDate] = useState({ start: "", end: "" }); // New state for start and end date
  const [dashboardTotals, setDashboardTotals] = useState({
    todayHours: 0,
    todayBillableHours: 0,
    todayNonBillableHours: 0,
    thisWeekHours: 0,
    thisWeekWriteoffHours: 0,
    thisWeekBillableHours: 0,
    thisWeekNonBillableHours: 0,
    lastWeekHours: 0,
    lastWeekWriteoffHours: 0,
    lastWeekBillableHours: 0,
    lastWeekNonBillableHours: 0,
    thisMonthHours: 0,
    thisMonthWriteoffHours: 0,
    thisMonthBillableHours: 0,
    thisMonthNonBillableHours: 0,
    thisYearHours: 0,
    thisYearTotalBillable: 0,
    thisYearTotalNonBillable: 0,
  });

  // Fetch Dashboard Data
  const fetchDashboardData = async () => {
    try {
      const response = await axiosInstance.get(
        `${API}/workHour/resourceDashboardTotals`
      );
      const data = response.data;

      setDashboardTotals({
        todayHours: data.todayHours,
        todayBillableHours: data.todayBillableHours,
        todayNonBillableHours: data.todayNonBillableHours,
        thisWeekHours: data.thisWeekHours,
        thisWeekWriteoffHours: data.thisWeekWriteoffHours,
        thisWeekBillableHours: data.thisWeekBillableHours,
        thisWeekNonBillableHours: data.thisWeekNonBillableHours,
        lastWeekHours: data.lastWeekHours,
        lastWeekWriteoffHours: data.lastWeekWriteoffHours,
        lastWeekBillableHours: data.lastWeekBillableHours,
        lastWeekNonBillableHours: data.lastWeekNonBillableHours,
        thisMonthHours: data.thisMonthHours,
        thisMonthWriteoffHours: data.thisMonthWriteoffHours,
        thisMonthBillableHours: data.thisMonthBillableHours,
        thisMonthNonBillableHours: data.thisMonthNonBillableHours,
        thisYearHours: data.thisYearTotalHours,
        thisYearTotalBillable: data.thisYearTotalBillable,
        thisYearTotalNonBillable: data.thisYearTotalNonBillable,
      });
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  // Get labels for the interval
  const getLabelsForInterval = useCallback((interval) => {
    switch (interval) {
      case "daily":
        return getLastNDaysLabels(7);
      case "weekly":
        return getLastNWeeksLabels(4);
      case "monthly":
        return getLastNMonthsLabels(12);
      case "yearly":
        return getLastNYearsLabels(4);
      default:
        return [];
    }
  }, []);

  // Get last n days labels
  const getLastNDaysLabels = (n) => {
    const labels = [];
    const today = new Date();

    for (let i = n - 1; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(date.getDate() - i);
      labels.push(formatMonthDate(date.toISOString().split("T")[0]));
    }
    return labels;
  };

  // Get last n weeks labels
  const getLastNWeeksLabels = (n) => {
    const labels = [];
    const today = new Date();

    for (let week = n - 1; week >= 0; week--) {
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay() - (week - 1) * 7);
      labels.push(formatMonthDate(startOfWeek.toISOString().split("T")[0]));
    }

    return labels;
  };

  // Get last n months labels
  const getLastNMonthsLabels = (n) => {
    const labels = [];
    const today = new Date();

    for (let month = n - 1; month >= 0; month--) {
      const firstOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() - month,
        1
      );
      labels.push(
        firstOfMonth.toLocaleDateString("default", { month: "short" })
      );
    }

    return labels;
  };

  // Get last n years labels
  const getLastNYearsLabels = (n) => {
    const labels = [];
    const currentYear = new Date().getFullYear();

    for (let year = n - 1; year >= 0; year--) {
      labels.push(`${currentYear - year}`);
    }

    return labels;
  };

  // Fetch User Work Hours
  const getUserWorkHours = async (interval) => {
    try {
      const response = await axiosInstance.get(
        `${API}/workHour/resourceChartTotal`,
        {
          params: { interval },
        }
      );

      if (response.data.workHoursData) {
      }
      return response.data.workHoursData;
    } catch (error) {
      console.error("Error fetching user work hours:", error);
      return [];
    }
  };

  // Handle interval change and update start/end dates
  const handleIntervalChange = useCallback(
    async (interval) => {
      setActiveInterval(interval);

      // Fetch data from the backend
      const workHoursData = await getUserWorkHours(interval);

      const labels = getLabelsForInterval(interval);
      const availableHoursData = [];
      const billableHoursData = [];
      const billedHoursData = [];

      // Assuming 7 hours a day, 35 hours a week, 154 hours a month, 1820 hours a year
      const availableHours = {
        daily: 7,
        weekly: 35,
        monthly: 154,
        yearly: 1820,
      };

      workHoursData.forEach(
        ({
          totalWorkHours,
          totalWriteOffHours,
          billableWorkHours,
          billedWorkHours,
        }) => {
          availableHoursData.push(availableHours[interval]);
          billableHoursData.push(billableWorkHours);
          billedHoursData.push(billedWorkHours);
        }
      );

      const newChartData = {
        labels,
        datasets: [
          {
            label: "Available Hours",
            data: availableHoursData,
            fill: true,
            borderColor: "rgba(52, 199, 89, 1)",
            backgroundColor: "rgba(52, 199, 89, 1)",
          },
          {
            label: "Clocked Hours",
            data: [...billableHoursData].reverse(), // Avoid mutating the original array
            borderColor: "rgba(45, 207, 245, 1)",
            backgroundColor: "rgba(45, 207, 245, 1)",
          },
          {
            label: "Billed Hours",
            data: [...billedHoursData].reverse(), // Avoid mutating the original array
            borderColor: "rgba(255, 221, 0, 1)",
            backgroundColor: "rgba(255, 221, 0, 1)",
          },
        ],
      };

      setChartData(newChartData);

      // Update start and end date
      setStartEndDate({
        start: labels[labels.length - 2],
        end: labels[labels.length - 1],
      });
    },
    [getLabelsForInterval]
  );

  useEffect(() => {
    fetchDashboardData();
    handleIntervalChange("daily");
  }, [handleIntervalChange]);

  return (
    <>
      <CssBaseline />
      <Header />

      <Container maxWidth={isXl ? "xl" : "md"} sx={{ my: 2 }}>
        <Grid
          container
          spacing={2}
          display="flex"
          flexDirection={isSm ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
        >
          {/* Date and Dropdown with Start and End Date  */}
          <Grid
            item
            xs={12}
            sm={12}
            display="flex"
            flexDirection={isSm ? "column" : "row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent={isSm ? "center" : "start"}
              alignItems="center"
              gap={2}
              marginBottom={isSm ? 2 : 0}
            >
              <Typography variant="h6" fontWeight="550">
                {todayDate}
              </Typography>
              <Select
                value={activeInterval}
                onChange={(e) => handleIntervalChange(e.target.value)}
                className="h-8 bg-[#F8F8F8] border-hidden"
                sx={{
                  fontWeight: "bold",
                  "& .MuiSelect-select": {
                    fontWeight: "bold",
                  },
                }}
              >
                <MenuItem value="daily">Today</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="yearly">Yearly</MenuItem>
              </Select>
              <Typography variant="body1">
                {activeInterval === "weekly"
                  ? `${startEndDate.start} - ${startEndDate.end} `
                  : startEndDate.end}
              </Typography>
            </Box>
            <Box>
              <CustomButton
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: COLORS.primary,
                  color: "white",
                  fontWeight: 600,
                  padding: "10px 15px",
                  borderRadius: "30px",
                  width: "auto",
                }}
                onClick={() => navigate("/resource/addtime")}
              >
                Add Time
                <PlusCircleFilled
                  style={{ fontSize: "20px", marginLeft: "10px" }}
                />
              </CustomButton>
            </Box>
          </Grid>

          {/* Cards for Total Hours, Billable Hours, Non-Billable Hours */}
          <Grid
            item
            container
            xs={12}
            spacing={isSm ? 2 : 4}
            justifyContent="center"
          >
            {["Total Hours", "Billable Hours", "Non-Billable Hours"].map(
              (title, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      height: 80,
                      padding: "0px 20px",
                      backgroundColor: "#F8F8F8",
                      borderRadius: "10px",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <Typography color="#8D8D8D" variant="body2">
                        {title}
                      </Typography>
                      <Typography variant="h5">
                        {title === "Total Hours"
                          ? activeInterval === "daily"
                            ? dashboardTotals.todayHours.toFixed(2)
                            : activeInterval === "weekly"
                            ? dashboardTotals.thisWeekHours.toFixed(2)
                            : activeInterval === "monthly"
                            ? dashboardTotals.thisMonthHours.toFixed(2)
                            : dashboardTotals.thisYearHours.toFixed(2)
                          : title === "Billable Hours"
                          ? activeInterval === "daily"
                            ? dashboardTotals.todayBillableHours.toFixed(2)
                            : activeInterval === "weekly"
                            ? dashboardTotals.thisWeekBillableHours.toFixed(2)
                            : activeInterval === "monthly"
                            ? dashboardTotals.thisMonthBillableHours.toFixed(2)
                            : dashboardTotals.thisYearTotalBillable.toFixed(2)
                          : activeInterval === "daily"
                          ? dashboardTotals.todayNonBillableHours.toFixed(2)
                          : activeInterval === "weekly"
                          ? dashboardTotals.thisWeekNonBillableHours.toFixed(2)
                          : activeInterval === "monthly"
                          ? dashboardTotals.thisMonthNonBillableHours.toFixed(2)
                          : dashboardTotals.thisYearTotalNonBillable.toFixed(
                              2
                            )}{" "}
                        h
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )
            )}
          </Grid>

          {/* Chart - right side */}
          <Grid item xs={12}>
            <Box
              width="100%"
              sx={{
                padding: "10px",
                backgroundColor: "#F8F8F8",
                borderRadius: "10px",
              }}
            >
              <LineChart data={chartData} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ResourceDashboard;
