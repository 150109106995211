import { employeeConstants } from "../actions/constants";

const initState = {
  employees: [],
  totalCountOfEmployees: 0,
  activeEmployeeCount: 0,
  loading: false,
  error: null,
  message: "",
};

const adminEmployeesReducer = (state = initState, action) => {
  switch (action.type) {
    case employeeConstants.GET_ALL_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case employeeConstants.GET_ALL_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: action.payload.employees,
        totalCountOfEmployees: action.payload.totalCountOfEmployees, // Update totalCountOfEmployees
        activeEmployeeCount: action.payload.activeEmployeeCount,
        loading: false,
      };
    case employeeConstants.GET_ALL_EMPLOYEES_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default adminEmployeesReducer;
