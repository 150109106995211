import React, { useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

// Register the components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ data }) => {
  // console.log("Data in line chart graph", data)
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;

      // Apply gradient to dataset
      chart.data.datasets = chart.data.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: dataset.borderColor,
      }));

      chart.update();
    }
  }, [data]); // Re-run the effect when 'data' changes

  const options = {
    responsive: true,
    elements: {
      line: {
        tension: 0.4, // Smoothes the line
        borderWidth: 3,
        borderColor: "#4bc0c0",
      },
      point: {
        radius: 5,
        borderWidth: 2,
        backgroundColor: "white",
        hoverRadius: 7,
        hoverBorderWidth: 3,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hides X-axis grid lines
        },
        ticks: {
          color: "#555", // Color of X-axis labels
          font: {
            size: 14,
          },
        },
      },
      y: {
        grid: {
          color: "#ddd", // Color of Y-axis grid lines
        },
        ticks: {
          color: "#555", // Color of Y-axis labels
          font: {
            size: 14,
          },

          // Include a dollar sign in the ticks
          callback: function (value, index, values) {
            return value + " hrs";
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          boxWidth: 20,
          padding: 20,
          color: "#333",
          font: {
            size: 12,
          },
        },
      },

      tooltip: {
        backgroundColor: "rgba(0,0,0,0.8)",
        titleFont: {
          size: 16,
        },
        bodyFont: {
          size: 14,
        },
        cornerRadius: 4,
        displayColors: false,
      },
    },
    animation: {
      duration: 1500, // General animation time
    },
    maintainAspectRatio: false,
  };

  return (
    <div style={{ height: "440px" }}>
      <Line ref={chartRef} data={data} options={options} />
    </div>
  );
};

export default LineChart;
