import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClockCircle } from "react-icons/ai";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import axiosInstance from "../helpers/axios";
import {
  API,
  billedDesignations,
  errorAlert,
  formatMonthDate,
} from "../utils/services";
import { getAllEmployeesByAdminId } from "../actions";
import CustomHeading from "../components/common/CustomHeading";
import LineChart from "../components/common/graphs/LineGraphs";
import BarChart from "../components/common/graphs/BarGraph";
import IntervalCard from "../components/common/DashboardCard";
import { Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ClockLoader } from "react-spinners";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminEmployees = useSelector((state) => state.adminEmployees);
  const { activeEmployeeCount, employees } = adminEmployees;

  const [loading, setLoading] = useState(false);
  const [activeInterval, setActiveInterval] = useState("daily");
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [dashboardTotals, setDashboardTotals] = useState({
    todayHours: 0,
    todayBillableHours: 0,
    todayNonBillableHours: 0,
    thisMonthHours: 0,
    thisMonthBillableHours: 0,
    thisMonthNonBillableHours: 0,
    thisYearHours: 0,
    thisYearBillableHours: 0,
    thisYearNonBillableHours: 0,
  });

  useEffect(() => {
    dispatch(getAllEmployeesByAdminId());
    fetchDashboardData();
    handleIntervalChangeBarGraph("daily");
    handleIntervalChange("daily");
  }, [dispatch]);

  let recentColors = []; // Store recent colors

  const getRandomColor = () => {
    let hue, saturation, lightness;
    let color;

    do {
      // Generate a random hue value between 0 and 360
      hue = Math.floor(Math.random() * 360);
      // Set saturation and lightness to a wider range of values
      saturation = 60 + Math.random() * 60; // 40% to 100%
      lightness = 25 + Math.random() * 50; // 25% to 75%
      // Create the HSL color
      color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    } while (recentColors.includes(color)); // Ensure the color is not a recent one

    // Update recent colors; keep only the last 10 colors
    recentColors = [...recentColors.slice(-9), color];

    return color;
  };

  // Admin Dashboard Totals
  const fetchDashboardData = async () => {
    try {
      const response = await axiosInstance.get(
        `${API}/workHour/adminDashboardTotals`
      );

      // console.group("Dashboard totals", response.data);
      const {
        todayHours,
        todayBillableHours,
        todayNonBillableHours,
        thisMonthHours,
        thisMonthBillableHours,
        thisMonthNonBillableHours,
        thisYearHours,
        thisYearBillableHours,
        thisYearNonBillableHours,
      } = response.data;

      setDashboardTotals({
        todayHours: todayHours,
        todayBillableHours: todayBillableHours,
        todayNonBillableHours: todayNonBillableHours,
        thisMonthHours: thisMonthHours,
        thisMonthBillableHours: thisMonthBillableHours,
        thisMonthNonBillableHours: thisMonthNonBillableHours,
        thisYearHours: thisYearHours,
        thisYearBillableHours: thisYearBillableHours,
        thisYearNonBillableHours: thisYearNonBillableHours,
      });
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  //Admin Chart 1 totals
  const getAdminWorkHourChart1 = async (interval) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${API}/workHour/getAdminWorkHoursChart1`,
        { params: { interval } }
      );
      if (response.data) {
        setLoading(false);
      }
      // console.log("Chart 1 data", response.data);
      return response.data?.workHoursData;
    } catch (error) {
      setLoading(false);
      errorAlert("Error fetching data");
      console.error("Error fetching dashboard data:", error);
    }
  };

  //Admin Chart 1 totals
  const getAdminWorkHourChart2 = async (interval) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${API}/workHour/getAdminWorkHoursChart2`,
        { params: { interval: interval } }
      );
      if (response.data) {
        setLoading(false);
      }
      // console.log("Chart 2 data", response.data);
      return response.data;
    } catch (error) {
      setLoading(false);
      errorAlert("Error fetching data");
      console.error("Error fetching dashboard data:", error);
    }
  };

  //Labels Functions
  const getLabelsForInterval = (interval) => {
    switch (interval) {
      case "daily":
        return getLastNDaysLabels(7);
      case "weekly":
        return getLastNWeeksLabels(4);
      case "monthly":
        return getLastNMonthsLabels(12);
      case "yearly":
        return getLastNYearsLabels(4);
      default:
        return [];
    }
  };

  const getLastNDaysLabels = (n) => {
    const labels = [];
    const today = new Date();
    for (let i = n - 1; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(date.getDate() - i);
      labels.push(formatMonthDate(date.toISOString().split("T")[0]));
    }
    return labels;
  };

  const getLastNWeeksLabels = (n) => {
    const labels = [];
    const today = new Date();
    for (let week = n - 1; week >= 0; week--) {
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay() - (week - 1) * 7);
      labels.push(formatMonthDate(startOfWeek.toISOString().split("T")[0]));
    }
    return labels;
  };

  const getLastNMonthsLabels = (n) => {
    const labels = [];
    const today = new Date();
    for (let month = n - 1; month >= 0; month--) {
      const firstOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() - month,
        1
      );
      labels.push(
        firstOfMonth.toLocaleDateString("default", { month: "short" })
      );
    }
    return labels;
  };

  const getLastNYearsLabels = (n) => {
    const labels = [];
    const currentYear = new Date().getFullYear();
    for (let year = n - 1; year >= 0; year--) {
      labels.push(`${currentYear - year}`);
    }
    return labels;
  };

  // Get a list of active associates and partners
  const activeAssociatesAndPartners = employees.filter(
    (emp) =>
      billedDesignations.includes(emp.designation) &&
      // (emp.designation === "Associate" || emp.designation === "Partner") &&
      emp.status === "active"
  );

  const handleIntervalChange = async (interval) => {
    setActiveInterval(interval);

    handleIntervalChangeBarGraph(interval);
    let availableHours = {};

    // Fetch data from the backend
    const workHoursData = await getAdminWorkHourChart1(interval);

    const labels = getLabelsForInterval(interval);
    const availableHoursData = [];
    const billableHoursData = [];
    const billedHoursData = [];

    if (employees.length > 0) {
      // Assuming 7 hours a day, 35 hours a week, 154 hours a month, 1820 hours a year
      availableHours = {
        daily: 7 * activeAssociatesAndPartners.length,
        weekly: 35 * activeAssociatesAndPartners.length,
        monthly: 154 * activeAssociatesAndPartners.length,
        yearly: 1820 * activeAssociatesAndPartners.length,
      };
    }

    workHoursData.length > 0 &&
      workHoursData.forEach(({ billableWorkHours, billedWorkHours }) => {
        availableHoursData.push(availableHours[interval]);
        billableHoursData.push(billableWorkHours);
        billedHoursData.push(billedWorkHours);
      });

    const newChartData = {
      labels,
      datasets: [
        {
          label: "Available Hours",
          data: availableHoursData,
          fill: true,
          borderColor: "#4bc0c0",
          backgroundColor: "rgba(75, 192, 192, 0.5)",
        },
        {
          label: "Clocked Hours",
          data: billableHoursData.reverse(),
          borderColor: "#ffcd56",
          backgroundColor: "rgba(255, 205, 86, 0.5)",
        },
        {
          label: "Billed Hours",
          data: billedHoursData.reverse(),
          borderColor: "#ff6384",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };

    setChartData(newChartData);
  };

  const handleIntervalChangeBarGraph = async (interval) => {
    setActiveInterval(interval);

    // Fetch data from the backend
    const response = await getAdminWorkHourChart2(interval);
    const workHoursData = response?.workHoursData;

    const labels = getLabelsForInterval(interval);
    let datasets = [];

    activeAssociatesAndPartners.forEach((employee) => {
      const employeeData =
        workHoursData.length > 0 &&
        workHoursData.map((entry) => {
          const data = entry.data.find((d) => d.user._id === employee._id);
          return data ? data.billableHours : 0;
        });

      const employeeColor = getRandomColor();

      datasets.push({
        label: `${employee.fullName}`,
        data: employeeData.reverse(),
        backgroundColor: employeeColor,
      });
    });

    const newChartData = {
      labels,
      datasets,
    };

    setBarChartData(newChartData);
  };

  return (
    <>
      <CssBaseline />

      {/* Heading */}
      <Grid container marginBottom={3}>
        <Grid item>
          <CustomHeading variant="h5" text="Dashboard" />
        </Grid>
      </Grid>

      {/* Cards & Chart1 */}
      <Grid container spacing={2}>
        {/* Time info grid - left side */}
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          container
          spacing={2}
          direction="column"
        >
          <Grid item>
            <Card
              onClick={() => navigate("/admin/allresources")}
              sx={{
                cursor: "pointer",
                backgroundColor: "#eae4dd",
                height: "110px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  boxShadow: 3,
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                  width: "100%",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <CustomHeading
                    fontSize="16px"
                    color="#80461b"
                    text="Active Resources"
                    align="center"
                  />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <CustomHeading fontSize="18px" text={activeEmployeeCount} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <IntervalCard
            interval="daily"
            activeInterval={activeInterval}
            handleIntervalChange={handleIntervalChange}
            icon={<AiOutlineClockCircle />}
            title="Today's Time"
            totalHours={dashboardTotals.todayHours}
            billableHours={dashboardTotals.todayBillableHours}
            nonBillableHours={dashboardTotals.todayNonBillableHours}
          />
          <IntervalCard
            interval="monthly"
            activeInterval={activeInterval}
            handleIntervalChange={handleIntervalChange}
            icon={<AiOutlineClockCircle />}
            title="Monthly Time"
            totalHours={dashboardTotals.thisMonthHours}
            billableHours={dashboardTotals.thisMonthBillableHours}
            nonBillableHours={dashboardTotals.thisMonthNonBillableHours}
          />
          <IntervalCard
            interval="yearly"
            activeInterval={activeInterval}
            handleIntervalChange={handleIntervalChange}
            icon={<AiOutlineClockCircle />}
            title="Yearly Time"
            totalHours={dashboardTotals.thisYearHours}
            billableHours={dashboardTotals.thisYearBillableHours}
            nonBillableHours={dashboardTotals.thisYearNonBillableHours}
          />
        </Grid>

        {/* Chart - right side */}
        <Grid item xs={12} sm={12} md={9}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                gap: "20px",
                // backgroundColor: "#eae4dd",
                borderRadius: "10px",
              }}
            >
              <ClockLoader size={100} color={"#886a4d"} loading={loading} />
              <CustomHeading text="Graph Loading..." />
            </Box>
          ) : (
            <LineChart data={chartData} />
          )}
        </Grid>
      </Grid>

      {/* Bar Graph */}
      <Grid item xs={12} sm={12} md={12} marginTop="20px">
        <BarChart data={barChartData} />
      </Grid>
    </>
  );
};

export default AdminDashboard;
