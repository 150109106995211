import moment from "moment";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import axios from "axios";

export const API = process.env.REACT_APP_BACKEND_URL;
// console.log("Backend URL:", API);

// export const API = `http://192.168.0.242:8000/api/v1`;
// export const API = `http://192.168.68.108:8000/api/v1`; 
// export const API = `http://192.168.43.180:8000/api/v1`;
// export const API = `http://192.168.0.113:8000/api/v1`;

export const authToken = localStorage.getItem("authToken");

//Main Date Format - 2023-06-07(year-month-day)

// 2023-06-07(year-month-day) - backend work date format
export default function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

//1st January, 2024 to 2024-01-01 yyyy-mm-dd
export function formatLetterToNormalDate(inputDateString) {
  // Split the input date string into parts
  const dateParts = inputDateString.match(
    /(\w+)\s+(\d+)(st|nd|rd|th),\s+(\d+)/
  );

  if (!dateParts) {
    // Handle invalid date format
    console.error("Invalid date format:", inputDateString);
    return "";
  }

  const month = dateParts[1];
  const day = dateParts[2];
  const year = dateParts[4];

  // Map month names to month numbers
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthNumber = monthNames.indexOf(month) + 1;

  if (monthNumber === 0) {
    // Handle invalid month name
    console.error("Invalid month name:", month);
    return "";
  }

  // Format the date components
  const formattedDate = `${year}-${String(monthNumber).padStart(
    2,
    "0"
  )}-${String(day).padStart(2, "0")}`;
  return formattedDate;
}

// 07-06-2023(day-month-year)
export function formatDatePrevious(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
}

// 8th June
export function formatMonthDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });

  // Get the day with suffix
  const dayWithSuffix = getDayWithSuffix(day);

  // Format the date
  const formattedDate = `${dayWithSuffix} ${month}`;

  return formattedDate;
}

// June 7th, 2023
export function formatLetterDate(dateString) {
  const date = moment(dateString, "YYYY-MM-DD");
  const month = date.format("MMMM");
  const day = date.date(); // Get the day as a number
  const year = date.format("YYYY");

  // Get the day with suffix
  const dayWithSuffix = getDayWithSuffix(day);

  // Format the date
  const formattedDate = `${month} ${dayWithSuffix}, ${year}`;

  return formattedDate;
}

// Reusable function to get the day with suffix
function getDayWithSuffix(day) {
  if (day === 1 || day === 21 || day === 31) {
    return `${day}st`;
  } else if (day === 2 || day === 22) {
    return `${day}nd`;
  } else if (day === 3 || day === 23) {
    return `${day}rd`;
  } else {
    return `${day}th`;
  }
}

//03/06 (month / day) - timespent report
export function formatNumberDate(dateString) {
  const date = new Date(dateString);
  const month = date.getMonth() + 1; // Get month (0-11) and add 1 to match desired format
  const day = date.getDate();

  // Pad month and day with leading zeros if necessary
  const formattedMonth = month.toString().padStart(2, "0");
  const formattedDay = day.toString().padStart(2, "0");

  // Format the date
  const formattedDate = `${formattedDay}/${formattedMonth}`;

  return formattedDate;
}

//Notification Functions
export function errorAlert(error) {
  toast.error(error, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 1500,
  });
}

export function successAlert(success) {
  toast.success(success, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 800,
  });
}

//Confirmation Alert
export const ConfirmationAlert = ({ icon, question, answer, onDeleteOff }) => {
  return (
    <div>
      <p>{question}</p>
      <Button
        variant="contained"
        color="error"
        startIcon={icon}
        onClick={onDeleteOff}
      >
        {answer}
      </Button>
    </div>
  );
};

// export const holidays = [
//   "2023-08-15",
//   "2023-09-19",
//   "2023-10-02",
//   "2023-10-24",
//   "2023-11-10",
//   "2023-11-11",
//   "2023-11-12",
//   "2023-11-13",
//   "2023-12-22",
//   // "2023-12-23",
//   // "2023-12-24",
//   "2023-12-25",
//   "2023-12-26",
//   "2023-12-27",
//   "2023-12-28",
//   "2023-12-29",
//   // "2023-12-30",
//   // "2023-12-31",
//   //2024 holidays
//   "2024-01-01",
//   "2024-01-26",
//   "2024-03-25",
//   "2024-08-15",
//   "2024-08-16",
//   "2024-09-07",
//   "2024-09-16",
//   "2024-10-02",
//   "2024-10-12",
//   "2024-10-31",
//   "2024-11-01",
//   // "2024-12-21",
//   // "2024-12-22",
//   "2024-12-23",
//   "2024-12-24",
//   "2024-12-25",
//   "2024-12-26",
//   "2024-12-27",
//   // "2024-12-28",
//   // "2024-12-29",
//   "2024-12-30",
//   "2024-12-31",
// ];

let holidays = [];

const fetchHolidays = async () => {
  try {
    const response = await axios.get(`${API}/holidays`);
    holidays = response.data.map((holiday) => formatDate(holiday.date));
    console.log("Holidays in services", holidays);
  } catch (error) {
    console.error("Error fetching holidays:", error);
  }
};

fetchHolidays();

export { holidays };

let allDesignations = [];
let billedDesignations = [];
let unBilledDesignations = [];

const fetchDesignations = async () => {
  try {
    const response = await axios.get(`${API}/designations`);
    allDesignations = response.data.data.map((d) => d.name);
    
    billedDesignations = response.data.data
      .filter((designation) => designation.billed)
      .map((d) => d.name);

    unBilledDesignations = response.data.data
      .filter((designation) => !designation.billed)
      .map((d) => d.name);

    console.log(
      "designations",
      allDesignations,
      unBilledDesignations,
      billedDesignations
    );
  } catch (error) {
    console.error("Error fetching designations:", error);
  }
};

fetchDesignations();

export { allDesignations, billedDesignations, unBilledDesignations };
