import axiosInstance from "../helpers/axios";
import { API } from "../utils/services";
import { clientConstants } from "./constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const getAllClients = (rowsPerPage, page) => {
  return async (dispatch) => {
    dispatch({ type: clientConstants.GET_ALL_CLIENT_BY_ADMIN_REQUEST });

    try {
      const { data } = await axiosInstance.get(
        `${API}/client/getAllClientByAdminId`,
        {
          params: {
            limit: rowsPerPage,
            page,
          },
        }
      );
      dispatch({
        type: clientConstants.GET_ALL_CLIENT_BY_ADMIN_SUCCESS,
        payload: {
          clients: data.clients,
          totalClients: data.totalClients,
        },
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: clientConstants.GET_ALL_CLIENT_BY_ADMIN_FAILURE,
        payload: {
          error: error.response.data.error,
        },
      });
      toast.error({
        text1: JSON.stringify(error.response.data.message),
      });
    }
  };
};

export const addClient = async (adminId, name, clientAddress) => {
  return async (dispatch) => {
    //submit login data
    dispatch({
      type: clientConstants.CREATE_NEW_CLIENT_REQUEST,
    });
    await axiosInstance
      .post(`/client/createNew`, {
        admin: adminId,
        name,
        clientAddress,
      })
      .then((response) => {
        // return response.data
        dispatch({
          type: clientConstants.CREATE_NEW_CLIENT_SUCCESS,
        });
        toast.success({
          text1: "client added successfully",
        });
      })
      .catch((error) => {
        // console.log(error?.response?.data?.message);
        dispatch({
          type: clientConstants.CREATE_NEW_CLIENT_SUCCESS,
          payload: {
            error: error.response.data.message,
          },
        });
        toast.error({
          text1: JSON.stringify(error.response.data.message),
        });
      });
  };
};
