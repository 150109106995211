import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import formatDate, {
  API,
  ConfirmationAlert,
  errorAlert,
  formatLetterDate,
  formatMonthDate,
  successAlert,
} from "../../utils/services";
import Select from "react-select";
import { BiDuplicate } from "react-icons/bi";
import { TbDiscount2Off } from "react-icons/tb";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Button,
  TablePagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  Delete,
  Discount,
  Download,
  Edit,
  HideSourceSharp,
  Search,
  SourceSharp,
  Update,
} from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import "./Timespent.css";
import CustomButton from "../../components/common/CustomButton";
import CustomHeading from "../../components/common/CustomHeading";
import CustomTooltip from "../../components/common/CustomTootltip";
import { useTSEmployees } from "../../helpers/hooks/useTSEmployees";
import { useMattersOptions } from "../../helpers/hooks/useMattersOptions";
import { useEmployeeOptions } from "../../helpers/hooks/useEmployeesOptions";
import NoDataFound from "../../components/common/NoDataFound";
import { unBilledDesignations } from "../../utils/services";

const TimeSpent = () => {
  //constants
  const auth = useSelector((state) => state.auth);

  const [filteredWorkHour, setFilteredWorkHour] = useState([]);

  const { matters, filteredMatters } = useMattersOptions();
  const { employees } = useTSEmployees(filteredWorkHour);
  const { filteredEmployees } = useEmployeeOptions();

  // console.log("filteredWorkHour", filteredWorkHour);

  const uniqueNames = employees
    .filter((item) => !unBilledDesignations.includes(item.designation))
    .map((item) => item.fullName);

  // console.log("uniqueNames", uniqueNames);

  const filteredNames = [
    ...new Set(
      filteredWorkHour && filteredWorkHour.map((item) => item?.user?.fullName)
    ),
  ];

  // console.log("filteredNames", filteredNames);

  const commonNames = uniqueNames
    .filter((name) => filteredNames.includes(name))
    .sort();

  // console.log("commonNames", commonNames);

  let sendMail = false;
  const adminId = auth.user._id;

  //states
  const [companyData, setCompanyData] = useState({
    companyName: "",
    companyEmail: "",
    companyAddress: "",
    companyLogo: null,
  });

  const [client, setClient] = useState("");
  const [matter, setMatter] = useState(""); //matter id
  const [selectedMatterName, setSelectedMatterName] = useState("");
  const [selectedLawyer, setSelectedLawyer] = useState("");
  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 2));
    return formatDate(pastDate);
  });
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );
  const [discountHours, setDiscountHours] = useState("");
  const [showComment, setShowComment] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [workHour, setWorkHour] = useState(0);
  const [workMin, setWorkMin] = useState(30);
  const [workDescription, setWorkDescription] = useState("");
  const [comments, setComments] = useState("");
  const [entryId, setEntryId] = useState("");

  //add and edit time modal states
  const [showModal, setShowModal] = useState(false);
  const [editingTime, setEditingTime] = useState(null);
  const [date, setDate] = useState(new Date());
  const [modalClient, setModalClient] = useState("");
  const [modalMatter, setModalMatter] = useState(""); //matter id
  const [modalSelectedMatterName, setModalSelectedMatterName] = useState("");
  const [modalSelectedLawyerName, setModalSelectedLawyerName] = useState("");
  const [modalSelectedLawyerId, setModalSelectedLawyerId] = useState("");
  const [addressedComments, setAddressedComments] = useState({});
  const [loading, setLoading] = useState(true);

  //pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const ope = 0;
  const needFin = false;
  const [totalCount, setTotalCount] = useState(0);

  //multiple selection entries states
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [newClient, setNewClient] = useState("");
  const [newMatter, setNewMatter] = useState(""); //matter id
  const [newSelectedMatterName, setNewSelectedMatterName] = useState("");

  //formatted date constants
  const formattedStartDate = formatLetterDate(startSelectedDate);
  const formattedEndDate = formatLetterDate(endSelectedDate);

  useEffect(() => {
    fetchAllInvoices();
    const loadData = async () => {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1500)); // Simulating API call delay
      setLoading(false);
    };
    fetchCompanyDetails();
    loadData();
  }, []);

  useEffect(() => {
    filterBetweenDates();
  }, [page, rowsPerPage]);

  //pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchCompanyDetails = async () => {
    try {
      const response = await axiosInstance.get(
        `${API}/company/details/${adminId}`
      );

      // Set other company details as well
      setCompanyData({
        ...companyData,
        companyName: response.data.companyName || "",
        companyEmail: response.data.companyEmail || "",
        companyAddress: response.data.companyAddress || "",
        companyLogo: response.data.companyLogo || "",
        companyWebsite: response.data.companyWebsite || "",
        companyAccountsEmail: response.data.companyAccountsEmail || "",
      });
    } catch (error) {
      errorAlert("Error in fetching company details!!");
    }
  };

  // filter function and Validation
  const isValidObjFieldForFilters = () => {
    if (startSelectedDate === "" || endSelectedDate === "") {
      return false;
    } else {
      return true;
    }
    // return Object.values(obj).every(value => value.trim())
  };

  const isValidFilters = () => {
    //all fields must be filled
    if (!isValidObjFieldForFilters()) return errorAlert("Enter the dates!!");

    return true;
  };

  const filterBetweenDates = async () => {
    setLoading(true);

    // Assuming your input date is in the format 'YYYY-MM-DD'
    const inputFromDate = matter
      ? matterOpenDate < lastInvoiceRaisedDate
        ? lastInvoiceRaisedDate
        : matterOpenDate
      : startSelectedDate;

    // Create a new Date object with the input fromDate
    const fromDate = new Date(inputFromDate);

    // Increment the day by 1 to get the next date
    if (matter) {
      fromDate.setDate(
        matterOpenDate < lastInvoiceRaisedDate
          ? fromDate.getDate() + 1
          : fromDate.getDate()
      );
    } else {
      fromDate.setDate(fromDate.getDate());
    }

    if (isValidFilters()) {
      const response = await axiosInstance.get(
        `${API}/workHour/filterWorkHourByUserId`,
        {
          params: {
            user: selectedLawyer,
            fromDate: fromDate.toISOString().split("T")[0], // Format the date as 'YYYY-MM-DD'
            toDate: endSelectedDate,
            matterId: matter,
            needFin,
            sendMail,
            adminId,
            role: "",
            limit: rowsPerPage,
            page: page,
          },
        }
      );

      setFilteredWorkHour(response.data.workHours);
      // setFilteredWorkHour(response.data.workHours);
      setTotalCount(response.data.totalFilteredWorkHours);
      setLoading(false);
      fetchAllInvoices();
    }
  };

  //INVOICES
  const fetchAllInvoices = async () => {
    try {
      const response = await axiosInstance.get(`${API}/invoices`, {
        params: {
          limit: 0,
          page: 0,
        },
      });
      setInvoices(response.data.invoices);
    } catch (error) {
      console.error("Error fetching invoices:", error.response.data);
    }
  };

  let lastInvoiceRaisedDate;
  if (matter !== "" && invoices.length > 0) {
    const filteredDates = invoices
      .filter(
        (invoice) =>
          invoice.matter._id === matter && invoice.status !== "cancelled"
      )
      .map((entry) => formatDate(entry?.endDate));

    if (filteredDates.length > 0) {
      lastInvoiceRaisedDate = filteredDates.reduce(
        (maxDate, currDate) => (currDate > maxDate ? currDate : maxDate),
        filteredDates[0] // Use the first date as the initial value
      );
    }
  }

  // console.log(lastInvoiceRaisedDate);

  const matterOpenDate = matters.filter((name) => name._id === matter)[0]
    ?.matterOpenDate;

  const link1Text = companyData.companyAccountsEmail;
  const link1URL = `mailto:${companyData.companyAccountsEmail}`;
  const link2Text = companyData.companyWebsite;
  const link2URL = companyData.companyWebsite;

  let currency;
  const matterCurr = matters.find((m) => m._id === matter);
  if (matterCurr) {
    currency = matterCurr.currency === "USD" ? "$" : "Rs. ";
  } else {
    currency = "";
    // console.log("Matter not found.");
  }

  const doubleUsedTableColumns = [
    "Associate Name",
    "Total Time",
    "Billed Time",
    "Hourly Rate",
    "Total Amount",
  ];

  const doubleUsedTableData = [];

  let teamTotalHours = 0;
  let teamTotalBilledHours = 0;
  let teamTotalBilledAmount = 0;
  let teamTotalUnbilledAmount = 0;
  let persontotalHours = 0;

  // Filtering the data with matter, dates and resource name
  if (commonNames.length > 0) {
    commonNames.forEach((name) => {
      let personWorkHours = 0;
      let personWorkHoursUnbilled = 0;

      if (filteredWorkHour.length > 0) {
        filteredWorkHour
          .filter(
            (result) =>
              result?.user?.fullName === name &&
              result.workDate >= startSelectedDate
          )
          .forEach((result) => {
            const workHour = parseFloat(result.workHour);
            personWorkHours += workHour;
          });

        filteredWorkHour
          .filter(
            (result) => result.workDate && result?.user?.fullName === name
          )
          .forEach((result) => {
            const workHour = parseFloat(result.workHour);
            personWorkHoursUnbilled += workHour;
          });
      }

      persontotalHours = parseFloat(personWorkHours);

      const newMatter = matters.find(
        (matter) => matter.name.trim() === selectedMatterName.trim()
      );

      let price;
      if (newMatter) {
        if (newMatter.pricePerHour) {
          price = newMatter.pricePerHour.$numberDecimal;
        } else if (newMatter.resourceSpecificPrice.length !== 0) {
          const employeeEntry = newMatter.resourceSpecificPrice.find((entry) =>
            entry.includes(name)
          );

          if (employeeEntry) {
            const [, hours] = employeeEntry.split(":");
            price = hours.trim();
          }
        } else {
          price = 0;
        }
      }

      if (price) {
        let personTotalBilledAmount = price * personWorkHours;
        let personTotalUnBilledAmount = price * personWorkHoursUnbilled;
        teamTotalHours += personWorkHours;
        teamTotalBilledHours += personWorkHours;
        teamTotalBilledAmount += personTotalBilledAmount;
        teamTotalUnbilledAmount += personTotalUnBilledAmount;

        if (persontotalHours > 0) {
          const data1 = [
            name,
            persontotalHours.toFixed(2),
            personWorkHours.toFixed(2),
            currency + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            currency +
              personTotalBilledAmount
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          ];
          doubleUsedTableData.push(data1);
        }
      }
    });
  }

  // // Add footer row
  const footerRow = [
    {
      content: "Total Hours",
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content: teamTotalHours.toFixed(2),
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content: teamTotalBilledHours.toFixed(2),
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content: "",
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content:
        currency +
        teamTotalBilledAmount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
  ];

  doubleUsedTableData.push(footerRow);

  // Table Calculations End ---------------------------------------------------------

  // Functions to Edit, Discount and Writeoff Hours of Resources
  const handleDiscountHoursChange = (entryId, value) => {
    setDiscountHours((prevState) => ({
      ...prevState,
      [entryId]: value,
    }));
  };

  const handleWriteoff = (id) => {
    toast(
      <ConfirmationAlert
        onDeleteOff={() => onWriteoffWorkHour(id)}
        question={"Are you sure to write off total hours?"}
        answer={"Yes, Write Off it"}
        icon={<TbDiscount2Off />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const handleDiscount = (id) => {
    toast(
      <ConfirmationAlert
        onDeleteOff={() => onhandleApplyDiscount(id)}
        question={"Are you sure to discount entered hours?"}
        answer={"Yes, Discount it"}
        icon={<Discount />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const handleDelete = (id) => {
    toast(
      <ConfirmationAlert
        onDeleteOff={() => onDeleteWorkHour(id)}
        question={"Are you sure to delete this entry?"}
        answer={"Yes, Delete it"}
        icon={<Delete />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onWriteoffWorkHour = async (entryId) => {
    try {
      const response = await axiosInstance.delete(
        `${API}/workhour/admin/delete/${entryId}`
      );

      if (response.data) {
        // Update the state after deletion
        setFilteredWorkHour((prevFilteredWorkHour) => {
          // Remove the deleted entry from the state
          const updatedWorkHour = prevFilteredWorkHour.filter(
            (entry) => entry._id !== entryId
          );
          return updatedWorkHour;
        });

        successAlert("WorkHour Complete WriteOff successfully!");
      } else {
        // Handle deletion failure
        errorAlert("WriteOff entry failed", "Please try again...");
      }
    } catch (error) {
      // Handle other errors
      console.error("Error writeoff entry:", error);
      errorAlert("Writeoff entry failed", "Please try again...");
    }
  };

  const onhandleApplyDiscount = async (entryId) => {
    const discountHoursForEntry = discountHours[entryId];

    if (discountHoursForEntry !== undefined) {
      try {
        const response = await axiosInstance.put(
          `${API}/workhour/admin/updateWithDiscount`,
          { entryId, updatedWorkHours: discountHoursForEntry }
        );

        // Check if the update was successful
        if (response.data) {
          // Update the state after applying discount
          setFilteredWorkHour((prevFilteredWorkHour) => {
            const updatedWorkHour = prevFilteredWorkHour.map((entry) =>
              entry._id === entryId
                ? { ...entry, workHour: response.data.workHourEntry.workHour }
                : entry
            );
            return updatedWorkHour;
          });

          successAlert(response.data.message);
        } else {
          // Handle update failure
          errorAlert("Applying discount failed", "Please try again...");
        }
      } catch (error) {
        // Handle other errors
        console.error("Error applying discount:", error);
        errorAlert("Applying discount failed", "Please try again...");
      }
    }
  };

  const onDeleteWorkHour = async (entryId) => {
    await axiosInstance
      .delete(`${API}/workhour/delete/${entryId}`)
      .then((response) => {
        // const res = getWorkHourByUserId();
        if (response.data) {
          successAlert("Entry deleted successfully");
          filterBetweenDates();
        }
      })
      .catch((error) => {
        errorAlert("Deletion of entry failed", "Please try again...");
      });
  };

  const generateTimeSpent = () => {
    const doc = new jsPDF();

    const pageWidth = doc.internal.pageSize.getWidth();
    const rightMargin = 15;
    const logoDesiredHeight = 20;
    const logoYPosition = 10;
    const logoXPosition = 13;

    function addHeader() {
      // Set the font style, size, and position of the header text
      const fontSize = 8;

      doc.setFont("helvetica", "normal");
      doc.setFontSize(fontSize);

      // Add the image to the header
      // doc.addImage(companyData.companyLogo, "PNG", 15, 10, 70, 9);
      doc.addImage(
        companyData.companyLogo,
        "PNG",
        logoXPosition,
        logoYPosition,
        0,
        logoDesiredHeight
      );

      // right header
      doc.setTextColor(46, 115, 176);

      const link2Width = doc.getTextWidth(link2Text);
      const link2X = pageWidth - link2Width - rightMargin;
      doc.textWithLink(link2Text, link2X, 19, {
        url: link2URL,
        underline: true,
      });

      // Set link color to blue and add underline
      const link1Width = doc.getTextWidth(link1Text);
      const link1X = pageWidth - link1Width - rightMargin;
      doc.textWithLink(link1Text, link1X, 22, {
        url: link1URL,
        underline: true,
      });

      doc.setTextColor(0);

      // Calculate text width and position to align it right
      const addressWidth = doc.getTextWidth(companyData.companyAddress);
      const addressX = pageWidth - addressWidth - rightMargin;
      doc.text(companyData.companyAddress, addressX, 26);

      // Reset text color to black
    }

    const Timespent = "/assets/Timespent.jpg";

    doc.addImage(Timespent, "JPEG", 0, 0, 210, 297);
    doc.addImage(
      companyData.companyLogo,
      "PNG",
      logoXPosition,
      logoYPosition,
      0,
      logoDesiredHeight
    );

    const fontSize = 20;
    doc.setFontSize(fontSize);

    //center alignment width calculations
    const clientNameLength = (210 - doc.getTextWidth(client)) / 2;
    const matterNameLength = (210 - doc.getTextWidth(selectedMatterName)) / 2;

    const dateRangeLength =
      (210 - doc.getTextWidth(`${formattedStartDate} - ${formattedEndDate}`)) /
      2;

    //client name
    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(20);
    doc.text(client, clientNameLength, 120);

    //matter name
    doc.setFontSize(20);
    doc.setFont("helvetica", "normal");
    doc.text(selectedMatterName, matterNameLength, 130);

    //horizontal line
    doc.setLineWidth(0.3);
    doc.setDrawColor(136, 106, 71);
    doc.line(40, 145, 180, 145);

    //date range
    doc.setFont("helvetica", "italic");
    doc.setTextColor(0, 0, 0); // Black color
    doc.setFontSize(15);
    doc.text(
      `${formattedStartDate} - ${formattedEndDate}`,
      dateRangeLength + 10,
      160
    );

    // ----------------2nd Page --------------------

    doc.addPage();
    addHeader();
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");

    doc.text("Dear Sir / Ma'am", 15, 40);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.text(
      `${client} - ${selectedMatterName} From Period ${formatMonthDate(
        startSelectedDate
      )} To ${formatMonthDate(endSelectedDate)}`,
      14,
      50
    );
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.text(
      "Please find enclosed our memorandum of time-spent on the given matter for the said period.",
      15,
      60
    );

    //---------------------------------Table 1 Duplicate Table Page 2----------------------------
    //Table 1 Page 2 Data
    // ----------------------------------Main Table declaration ------------------------

    const startY = 70; // Starting Y-coordinate for the table

    doc.autoTable(doubleUsedTableColumns, doubleUsedTableData, {
      startY,
      theme: "grid",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,

      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          // Header row styles
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.halign = "left"; // Align header text to the left
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.fontSize = 9;
        } else if (data.row.section === "body") {
          // Body row styles
          if (data.column.index !== 0) {
            // Right-align all columns except the first column
            data.cell.styles.halign = "right";
          }
        } else if (data.row.index === doubleUsedTableData.length - 1) {
          // Footer row align
          data.cell.styles.halign = "right"; // Align footer text to the right
          data.cell.styles.fillColor = [226, 213, 203];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        } else if (
          data.row.index === doubleUsedTableData.length - 1 &&
          data.row.section === "foot"
        ) {
          // Footer row styles
          data.cell.styles.fillColor = [226, 213, 203];
          data.cell.styles.textColor = [255, 255, 255];
        }

        // Set padding for table data cells
        if (
          data.row.section === "body" &&
          data.column.index === 0 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 1 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 2 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 3 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 4 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
      },
    });

    // Calculate the table height
    const tableHeight = doc.autoTable.previous.finalY - startY;

    doc.text(
      "Summary of payable on this matter is provided below ",
      15,
      startY + tableHeight + 10
    );

    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.text(
      "Total Amount for the above period",
      15,
      startY + tableHeight + 20
    );
    doc.text(
      currency +
        (parseFloat(ope) + parseFloat(teamTotalBilledAmount))
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      doc.internal.pageSize.getWidth() -
        doc.getTextWidth(
          currency +
            (parseFloat(ope) + parseFloat(teamTotalBilledAmount))
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        ) -
        15,
      startY + tableHeight + 20
    );
    doc.text(
      `Unbilled amount since last invoice (${
        lastInvoiceRaisedDate !== undefined
          ? formatLetterDate(lastInvoiceRaisedDate)
          : "no invoices sent yet"
      })`,
      15,
      startY + tableHeight + 28
    );

    doc.text(
      currency +
        (parseFloat(ope) + parseFloat(teamTotalUnbilledAmount))
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      doc.internal.pageSize.getWidth() -
        doc.getTextWidth(
          currency +
            (parseFloat(ope) + parseFloat(teamTotalUnbilledAmount))
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        ) -
        15,
      startY + tableHeight + 28
    );

    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");

    doc.text(
      "Please do let us know if any changes are required. Please note that this is only a statement of timespent for ",
      15,
      startY + tableHeight + 43
    );
    doc.text(
      "your reference and not an invoice. The invoice shall be raised subsequently in the manner agreed with you.",
      15,
      startY + tableHeight + 48
    );

    doc.text("Yours Sincerely,", 15, startY + tableHeight + 58);
    doc.setFont("helvetica", "bold");
    doc.text(auth.user.fullName, 15, startY + tableHeight + 63);
    doc.setFont("helvetica", "normal");

    // --------------------------------------3rd Page----------------------------------------------------
    doc.addPage();
    addHeader();

    let startY1 = 35; // Starting y-coordinate for each table

    commonNames.forEach((name, index) => {
      const personData = filteredWorkHour
        .filter(
          (result) =>
            result.user?.fullName === name &&
            result.workDate >= startSelectedDate
        )
        .sort((a, b) => new Date(a.workDate) - new Date(b.workDate)); // Sort the data by workDate in ascending order

      const billedTime = personData.reduce(
        (total, result) => total + parseFloat(result.workHour),
        0
      );

      const headerRow = [
        {
          content: name,
          colSpan: 2,
          styles: {
            cellWidth: "auto", // Set cell width to auto to allow content alignment
            halign: "left",
            fillColor: [136, 106, 71],
            textColor: [255, 255, 255],
            fontStyle: "bold", // Set the font style to bold
          },
        },
        {
          content: "Total Time",
          styles: {
            cellWidth: "auto", // Set cell width to auto to allow content alignment
            halign: "left",
            fillColor: [136, 106, 71],
            textColor: [255, 255, 255],
            fontStyle: "bold", // Set the font style to bold
          },
        },

        {
          content: "Billed Time",
          styles: {
            cellWidth: "auto", // Set cell width to auto to allow content alignment
            halign: "left",
            fillColor: [136, 106, 71],
            textColor: [255, 255, 255],
            fontStyle: "bold", // Set the font style to bold
          },
        },
      ];

      const tableRows = [headerRow]; // Initialize tableRows with the header row
      let commentsText = " ";

      // Add individual data rows for the person
      if (personData.length > 0) {
        personData.forEach((result) => {
          commentsText = result.comments || " ";

          const data = [
            formatMonthDate(result.workDate),
            showComment === true && commentsText !== " "
              ? `${result.workDescription}

-----------------------------------------------------------------------------------------------------------
||      ${commentsText}  ||`
              : result.workDescription,
            parseFloat(result.workHour).toFixed(2),
            parseFloat(result.workHour).toFixed(2),
          ];
          tableRows.push(data);
        });

        // Add footer row with total time
        const footerRow = [
          {
            content: "Total Time",
            colSpan: 2,
            styles: {
              halign: "left",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
          {
            content: billedTime.toFixed(2),
            styles: {
              halign: "right",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
          {
            content: billedTime.toFixed(2),
            styles: {
              halign: "right",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
        ];
        tableRows.push(footerRow);

        // Remove empty rows and columns from tableRows
        const filteredTableRows = tableRows.filter((row) =>
          row.some((cell) => cell !== "")
        );

        // Generate table for the current person

        doc.autoTable({
          body: filteredTableRows,
          startY: startY1,
          theme: "grid",
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.1,
          didParseCell: function (data) {
            if (data.row.index === 0 && data.row.section === "head") {
              // Header row styles
              data.cell.styles.fillColor = [136, 106, 71];
              data.cell.styles.textColor = [255, 255, 255];
              data.cell.styles.halign = "left";
              data.cell.styles.valign = "left"; // Align the text vertically at the center
              data.cell.styles.fontStyle = "bold"; // Set the font style to bold
            } else if (
              data.row.index === filteredTableRows.length - 1 &&
              data.row.section === "body"
            ) {
              // Footer row styles
              data.cell.styles.fillColor = [97, 68, 58];
              data.cell.styles.textColor = [255, 255, 255];
              // data.cell.styles.halign = "right";
              data.cell.styles.fontStyle = "bold";
            } else if (data.row.section === "body") {
              // Body row styles
              if (data.column.index !== 1) {
                // Right-align all columns except the first column
                data.cell.styles.halign = "left";
              }
            }
            // Set padding for table data cells
            if (
              data.row.section === "body" &&
              data.column.index === 0 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 3,
              };
              data.cell.styles.fontSize = 9;
              data.cell.styles.halign = "left";
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
            if (
              data.row.section === "body" &&
              data.column.index === 1 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 4,
                bottom: 3,
                left: 4,
              };
              data.cell.styles.fontSize = 9;
              data.cell.styles.fontStyle = "times";
              data.cell.styles.halign = "left";
              data.cell.styles.cellWidth = 122; // Set the width to 100 units
              data.cell.styles.textColor = [0, 0, 0];
            }

            if (data.row.section === "body" && data.column.index === 1) {
              // Check if the cell contains the separator line indicating comments
              const cellText = data.cell.text;
              const hasComments = cellText.includes(
                "-----------------------------------------------------------------------------------------------------------"
              );

              if (hasComments) {
                data.cell.styles.fillColor = [215, 230, 230];
              }
            }

            if (
              data.row.section === "body" &&
              data.column.index === 2 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 2,
              };
              data.cell.styles.halign = "right";
              data.cell.styles.fontSize = 9;
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
            if (
              data.row.section === "body" &&
              data.column.index === 3 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 2,
              };
              data.cell.styles.halign = "right";
              data.cell.styles.fontSize = 9;
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
          },
        });
        startY1 = doc.lastAutoTable.finalY + 10; // Update startY for the next table
      }
    });

    doc.save(
      `${client} - ${selectedMatterName} From Period ${formatMonthDate(
        startSelectedDate
      )} To ${formatMonthDate(endSelectedDate)}.pdf`
    );
  };

  const openModal = (time, type) => {
    setShowModal(true);

    if (type === "duplicate") {
      setEditingTime(null);
      setDate(time.workDate);
      setWorkDescription(time.workDescription);
      setModalSelectedLawyerName(time.user.fullName);
      setModalSelectedLawyerId(time.user._id);
      setModalMatter(time.matter);
      setModalSelectedMatterName(time.matter.name);
      setComments(time.comments);
      const hour = time.workHour.split(".");
      setWorkHour(hour[0]);
      setWorkMin(Math.floor((hour[1] * 6) / 10));
    } else if (type === "add") {
      setEditingTime(null);
      setDate(new Date());
      setWorkDescription("");
      setModalSelectedLawyerId("");
      setModalSelectedLawyerName("");
      setModalMatter("");
      setModalSelectedMatterName("");
      setComments("");
      setWorkHour(0);
      setWorkMin(30);
    } else {
      setEditingTime(time);
      setDate(time.workDate);
      setEntryId(time._id);
      setWorkDescription(time.workDescription);
      setModalSelectedLawyerName(time.user.fullName);
      setModalSelectedLawyerId(time.user._id);
      // setUserId(time.user._id);
      setModalMatter(time.matter);
      setModalSelectedMatterName(time.matter.name);
      setComments(time.comments);
      const hour = time.workHour.split(".");
      setWorkHour(hour[0]);
      setWorkMin(Math.floor((hour[1] * 6) / 10));
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setDate(new Date());
    setWorkDescription("");
    // setUserId("");
    setModalSelectedLawyerName("");
    setModalSelectedLawyerId("");
    setModalMatter("");
    setModalSelectedMatterName("");
    setModalClient("");
    setComments("");
    setWorkHour(0);
    setWorkMin(30);
    setEntryId("");
  };

  //Form Validation
  const isValidObjField = () => {
    if (
      date === "" ||
      modalSelectedLawyerId === "" ||
      modalMatter === "" ||
      workDescription === "" ||
      workHour === "00" ||
      workMin === "00"
    ) {
      return false;
    } else {
      return true;
    }
    // return Object.values(obj).every(value => value.trim())
  };

  const isValidForm = () => {
    //all fields must be filled
    if (!isValidObjField()) return errorAlert("All Fields are Required!");

    return true;
  };

  //Add, Duplicate and Edit Time
  const handleSubmit = async (e) => {
    e.preventDefault();

    const time = {
      admin: auth.user._id,
      user: modalSelectedLawyerId,
      workDate: date,
      matter: modalMatter,
      workDescription,
      comments,
      workHour: `${workHour}.${workMin * (10 / 6)}`,
      category: "billable",
    };

    if (isValidForm()) {
      if (editingTime) {
        const response = await axios.put(
          `${API}/workHour/update/${entryId}`,
          time
        );

        if (response.data) {
          successAlert("Time Updated Successfully!");
          setFilteredWorkHour((prevFilteredWorkHour) => {
            const updatedWorkHour = prevFilteredWorkHour.map((entry) =>
              entry._id === entryId ? response.data : entry
            );
            return updatedWorkHour;
          });
          closeModal();
          filterBetweenDates();
        }
      } else {
        try {
          const response = await axiosInstance.post(
            `${API}/workHour/createNew`,
            time
          );

          if (response.data) {
            successAlert("Great!! Time Added Successfully.");

            // Call filterBetweenDates after adding time
            setFilteredWorkHour((prevFilteredWorkHour) => {
              // Include the new entry in the updated state
              const updatedWorkHour = [...prevFilteredWorkHour, response.data];
              return updatedWorkHour;
            });

            closeModal();
            filterBetweenDates();
          }
        } catch (error) {
          console.error("Error adding time:", error);
          errorAlert("Error adding time");
        }
      }
    }
  };

  //address comment function
  const markCommentAsAddressed = (entryId) => {
    setAddressedComments((prev) => ({
      ...prev,
      [entryId]: !prev[entryId], // Toggle the addressed state
    }));
  };

  //mupltiple select entries functions
  const handleSelectEntry = (id, checked) => {
    if (checked) {
      setSelectedEntries([...selectedEntries, id]);
    } else {
      setSelectedEntries(selectedEntries.filter((entryId) => entryId !== id));
    }
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);

    // If "Select All" checkbox is checked, set all entry IDs as selected; otherwise, clear selection
    setSelectedEntries(
      isChecked
        ? filteredWorkHour
            .filter((entry) => entry.workDate >= startSelectedDate)
            .map((entry) => entry._id)
        : []
    );
  };

  const handleUpdateEntries = () => {
    // Send Axios request to backend to update selected entries with new matter code

    if (newMatter) {
      handleUpdateEntriesAlert();
    } else {
      errorAlert("Select the new matter");
    }
  };

  const handleUpdateEntriesAlert = (id) => {
    toast(
      <ConfirmationAlert
        onDeleteOff={() => onUpdateMultipleEntries(id)}
        question={"Are you sure to update matter of all selected entries?"}
        answer={"Yes, Update Matter"}
        icon={<Update />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onUpdateMultipleEntries = async (entryId) => {
    axiosInstance
      .post("/updateMatterCodeEntries", {
        entries: selectedEntries,
        newMatter,
      })
      .then((response) => {
        // Handle success response
        filterBetweenDates();
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating entries:", error);
      });
  };

  //main table data show function
  const renderRows = () => {
    return commonNames.map((employee) => {
      let totalWorkHours = 0;
      return (
        <React.Fragment key={employee}>
          {filteredWorkHour
            .filter(
              (result) =>
                result?.user?.fullName === employee &&
                result.workDate >= startSelectedDate
            )
            .map((item, index) => {
              totalWorkHours += parseFloat(item.workHour);
              return (
                <TableRow
                  key={item._id}
                  role="checkbox"
                  tabIndex={-1}
                  sx={{
                    backgroundColor: index % 2 ? "white" : "#eae4dd",
                    fontFamily: "Prata",
                  }}
                >
                  <TableCell>
                    <input
                      type="checkbox"
                      checked={selectedEntries.includes(item._id)}
                      onChange={(e) =>
                        handleSelectEntry(item._id, e.target.checked)
                      }
                    />
                  </TableCell>
                  <TableCell>{formatMonthDate(item.workDate)}</TableCell>
                  <TableCell>{item.user?.fullName}</TableCell>
                  <TableCell>{item.matter?.name}</TableCell>
                  <TableCell>
                    {item.workDescription}{" "}
                    {showComment && item.comments && (
                      <>
                        <p
                          style={{
                            marginTop: "10px",
                            padding: "5px",
                            backgroundColor: "yellow",
                            color: "red",
                            textDecoration: addressedComments[item._id]
                              ? "line-through"
                              : "none",
                          }}
                        >
                          {item?.comments ? item.comments : ""}
                        </p>

                        <Button
                          style={{
                            backgroundColor: "lightgrey",
                            color: "red",
                            fontSize: "12px",
                          }}
                          onClick={() => markCommentAsAddressed(item._id)}
                        >
                          Address Comment
                        </Button>
                      </>
                    )}
                  </TableCell>

                  <TableCell>{item.workHour}</TableCell>
                  <TableCell>
                    <input
                      type="text"
                      name="updatedWorkHours"
                      value={discountHours[item._id] || ""}
                      onChange={(e) =>
                        handleDiscountHoursChange(item._id, e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell className="table-cell">
                    <CustomTooltip text="Duplicate Entry">
                      <BiDuplicate
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal(item, "duplicate")}
                      />
                    </CustomTooltip>
                  </TableCell>
                  <TableCell>
                    <CustomTooltip text="Edit Entry">
                      <Edit
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal(item, "edit")}
                      />
                    </CustomTooltip>
                  </TableCell>
                  <TableCell>
                    <CustomTooltip text="Discount Entered Hours">
                      <Discount
                        // size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDiscount(item._id)}
                      />
                    </CustomTooltip>
                  </TableCell>
                  <TableCell>
                    <CustomTooltip text="Complete WriteOff">
                      <TbDiscount2Off
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleWriteoff(item._id)}
                      />
                    </CustomTooltip>
                  </TableCell>
                  <TableCell className="table-cell">
                    <CustomTooltip text="Delete Entry">
                      <Delete
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDelete(item._id)}
                      />
                    </CustomTooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          {totalWorkHours > 0 && (
            <React.Fragment>
              <TableRow
                style={{
                  backgroundColor: "#372a1b",
                }}
              >
                <TableCell
                  colSpan={4}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Total Work Hours of {employee}:
                </TableCell>
                <TableCell
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {totalWorkHours.toFixed(2)}
                </TableCell>
                <TableCell colSpan={7}></TableCell>
              </TableRow>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    });
  };

  const columns = [
    "Date",
    "Associate",
    "Matter",
    "Narrative",
    "Total Time",
    "DIS Hrs",
    "Duplicate",
    "Edit",
    "DIS",
    "W/O",
    "Delete",
  ];

  return (
    <>
      <ToastContainer />

      {/* Main Heading  */}
      <Grid container spacing={2} alignItems="baseline">
        <Grid item>
          <CustomHeading variant="h5" text="Timespent" />
        </Grid>
        <Grid item>
          <CustomTooltip text="Add Time">
            <CustomButton
              style={{
                borderRadius: "50%",
                minWidth: "5px",
                height: "30px",
                transition:
                  "transform 0.3s ease-in-out, background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#886a47",
                  transform: "scale(1.1)",
                },
              }}
              onClick={() => openModal(null, "add")}
            >
              <AddIcon />
            </CustomButton>
          </CustomTooltip>
        </Grid>
      </Grid>

      {/* Filters  */}
      <Paper elevation={3} sx={{ padding: 2, marginY: 2 }}>
        <Grid container spacing={1} alignItems="baseline" marginBottom="1rem">
          <Grid item xs={12} sm={7} md={2.5}>
            <Select
              options={filteredMatters}
              value={filteredMatters.find(
                (option) => option.label === `${selectedMatterName} | ${client}`
              )}
              onChange={(e) => {
                setMatter(e.value);
                setSelectedMatterName(e.label.split("|")[0]);
                setClient(e.label.split("|")[1]);
              }}
              isSearchable
              placeholder="Select Matter"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={5} md={1.5}>
            <Select
              options={filteredEmployees}
              value={filteredEmployees.find(
                (resOption) => resOption.label === `${selectedLawyer}`
              )}
              onChange={(e) => {
                setSelectedLawyer(e.value);
              }}
              isSearchable
              placeholder="Resource"
              fullWidth
            />
          </Grid>

          <Grid item xs={6} sm={6} md={1.5}>
            <input
              type="date"
              className="date"
              value={startSelectedDate}
              onChange={(e) => setStartSelectedDate(e.target.value)}
              style={{
                width: "100%",
                // padding: "0.5rem",
                boxSizing: "border-box",
              }}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={1.5}>
            <input
              type="date"
              className="date"
              value={endSelectedDate}
              onChange={(e) => setEndSelectedDate(e.target.value)}
              style={{
                width: "100%",
                boxSizing: "border-box",
              }}
            />
          </Grid>

          <Grid item xs={4} sm={4} md={1.5}>
            <CustomButton
              fontSize="13px"
              onClick={() => {
                filterBetweenDates();
              }}
              style={{ width: "100%" }}
            >
              <Search style={{ marginRight: "8px" }} />
              Search
            </CustomButton>
          </Grid>

          <Grid item xs={4} sm={4} md={2}>
            <CustomButton
              variant="outlined"
              fontSize="13px"
              style={{ width: "100%" }}
              onClick={() => {
                setShowComment(!showComment);
              }}
            >
              {showComment === false ? (
                <SourceSharp style={{ marginRight: "8px" }} />
              ) : (
                <HideSourceSharp style={{ marginRight: "8px" }} />
              )}
              {showComment === false ? "Show comments" : "Hide Comments"}
            </CustomButton>
          </Grid>

          <Grid item xs={4} sm={4} md={1.5}>
            <CustomButton
              variant="outlined"
              fontSize="13px"
              style={{ width: "100%" }}
              onClick={generateTimeSpent}
            >
              <Download style={{ marginRight: "8px" }} />
              Download
            </CustomButton>
          </Grid>
        </Grid>

        {selectedEntries.length > 0 && (
          <Grid container spacing={1} alignItems="baseline" marginTop="1rem">
            <Grid item xs={12} sm={7} md={5}>
              <Select
                options={filteredMatters}
                value={filteredMatters.find(
                  (option) =>
                    option.label === `${newSelectedMatterName} | ${newClient}`
                )}
                onChange={(e) => {
                  setNewMatter(e.value);
                  setNewSelectedMatterName(e.label.split("|")[0]);
                  setNewClient(e.label.split("|")[1]);
                }}
                isSearchable
                placeholder="Search New Matter | Client"
                fullWidth
              />
            </Grid>

            <Grid item xs={4} sm={4} md={2}>
              <CustomButton
                variant="contained"
                style={{ width: "100%" }}
                onClick={() => handleUpdateEntries()}
              >
                <Update style={{ marginRight: "8px" }} />
                Update Entries
              </CustomButton>
            </Grid>
          </Grid>
        )}
      </Paper>

      {/* Modal  */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <CustomHeading
              variant="h5"
              align="center"
              text={editingTime ? "EDIT TIME" : "ADD TIME"}
            />

            <form>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                  width: "100%",
                  marginTop: 15,
                }}
              >
                <div style={{ width: "50%" }}>
                  <label>Date *</label>
                  <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>

                <div style={{ width: "50%" }}>
                  <label>Resource *</label>
                  <Select
                    options={filteredEmployees}
                    value={filteredEmployees.find(
                      (resOption) =>
                        resOption.label === `${modalSelectedLawyerName}`
                    )}
                    onChange={(e) => {
                      setModalSelectedLawyerId(e.value);
                      setModalSelectedLawyerName(e.label);
                    }}
                    isSearchable
                    placeholder="Select Resource"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                  width: "100%",
                  marginTop: 15,
                }}
              >
                <div style={{ width: "100%" }}>
                  <label>
                    {"Selected Matter : " + modalSelectedMatterName}
                  </label>

                  <Select
                    options={filteredMatters}
                    value={filteredMatters.find(
                      (option) =>
                        option.label ===
                        `${modalSelectedMatterName} | ${modalClient}`
                    )}
                    onChange={(e) => {
                      setModalMatter(e.value);
                      setModalSelectedMatterName(e.label.split("|")[0]);
                      setModalClient(e.label.split("|")[1]);
                    }}
                    isSearchable
                    placeholder="Search Matter | Client"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                  width: "100%",
                  marginTop: 15,
                }}
              >
                <div style={{ width: "50%" }}>
                  <label>Work Hours *</label>
                  <select
                    style={{ width: "100%", padding: 10 }}
                    value={workHour}
                    name="workHour"
                    onChange={(event) => {
                      setWorkHour(event.target.value);
                    }}
                  >
                    {Array.from({ length: 19 }, (_, i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>

                <div style={{ width: "50%" }}>
                  <label>Work Minutes *</label>
                  <select
                    style={{ width: "100%", padding: 10 }}
                    value={workMin}
                    name="workMin"
                    onChange={(event) => {
                      setWorkMin(event.target.value);
                    }}
                  >
                    {Array.from({ length: 10 }, (_, i) => (
                      <option key={i * 6} value={i * 6}>
                        {i * 6}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                }}
              >
                <label>
                  Work Description *
                  <textarea
                    style={{ height: 50, width: "100%" }}
                    type="text"
                    placeholder="Enter work description"
                    value={workDescription}
                    onChange={(e) => setWorkDescription(e.target.value)}
                  />
                </label>
              </div>

              <div
                style={{
                  width: "100%",
                }}
              >
                <label>
                  Comments
                  <input
                    type="text"
                    style={{ width: "100%" }}
                    placeholder="Enter comments for the reviewer"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </label>
              </div>

              <div style={{ textAlign: "center" }}>
                <CustomButton onClick={handleSubmit} style={{ width: "30%" }}>
                  Submit
                </CustomButton>
                <CustomButton
                  variant="text"
                  color="error"
                  style={{ width: "30%" }}
                  onClick={closeModal}
                >
                  Cancel
                </CustomButton>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Main Table  */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100, 500, 1000]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <TableContainer sx={{ maxHeight: "75vh" }}>
          <Table aria-label="reusable table">
            <TableHead>
              <TableRow sx={{ height: "5px" }}>
                <TableCell colSpan={12}>
                  <div className="middle">
                    <div style={{ display: "flex", gap: "10px" }}>
                      <label>Total amount for above period :</label>
                      <p>
                        {currency}
                        {teamTotalBilledAmount
                          ?.toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <label>Total Hours for above period: </label>
                      <p>{teamTotalHours?.toFixed(2)}</p>
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <label> Total unbilled amount : </label>
                      <p>
                        {currency}
                        {teamTotalUnbilledAmount
                          ?.toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={(e) => handleSelectAll(e)}
                    />
                    Select All
                  </label>
                </TableCell>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    id="table-header"
                    style={column === "DIS Hrs" ? { width: "100px" } : {}}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredWorkHour.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={11}>
                    <NoDataFound message="Oops! No workhours found." />
                  </TableCell>
                </TableRow>
              ) : (
                renderRows()
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default TimeSpent;
