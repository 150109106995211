import axios from "axios";
import store from "../store";
import { authConstants } from "../actions/constants";
import { API } from "../utils/services";

// Retrieve token from local storage
const getToken = () => {
  return localStorage.getItem("authToken");
};

const api = API;

const axiosInstance = axios.create({
  baseURL: api,
  headers: {
    Authorization: getToken() ? `Bearer ${getToken()}` : "",
  },
});

axiosInstance.interceptors.request.use((req) => {
  // Fetch token from local storage each time a request is made
  const token = getToken();
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    console.log(error.response);

    const status = error.response.status;
    if (status && status === 500) {
      localStorage.clear();
      store.dispatch({ type: authConstants.LOGOUT_SUCCESS });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
