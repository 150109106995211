export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
};

export const employeeConstants = {
  GET_ALL_EMPLOYEES_REQUEST: "GET_ALL_EMPLOYEES_REQUEST",
  GET_ALL_EMPLOYEES_SUCCESS: "GET_ALL_EMPLOYEES_SUCCESS",
  GET_ALL_EMPLOYEES_FAILURE: "GET_ALL_EMPLOYEES_FAILURE",

  // GET_ALL_EMPLOYEES_BY_EMP_REQUEST: "GET_ALL_EMPLOYEES_BY_EMP_REQUEST",
  // GET_ALL_EMPLOYEES_BY_EMP_SUCCESS: "GET_ALL_EMPLOYEES_BY_EMP_SUCCESS",
  // GET_ALL_EMPLOYEES_BY_EMP_FAILURE: "GET_ALL_EMPLOYEES_BY_EMP_FAILURE",
};

export const matterConstants = {
  GET_ALL_MATTER_REQUEST: "GET_ALL_MATTER_REQUEST",
  GET_ALL_MATTER_SUCCESS: "GET_ALL_MATTER_SUCCESS",
  GET_ALL_MATTER_FAILURE: "GET_ALL_MATTER_FAILURE",

  ADD_NEW_MATTER_REQUEST: "ADD_NEW_MATTER_REQUEST",
  ADD_NEW_MATTER_SUCCESS: "ADD_NEW_MATTER_SUCCESS",
  ADD_NEW_MATTER_FAILURE: "ADD_NEW_MATTER_FAILURE",

  UPDATE_NEW_MATTER_REQUEST: "UPDATE_NEW_MATTER_REQUEST",
  UPDATE_NEW_MATTER_SUCCESS: "UPDATE_NEW_MATTER_SUCCESS",
  UPDATE_NEW_MATTER_FAILURE: "UPDATE_NEW_MATTER_FAILURE",
};

export const searchConstants = {
  GET_SEARCH_RESULT_REQUEST: "GET_SEARCH_RESULT_REQUEST",
  GET_SEARCH_RESULT_SUCCESS: "GET_SEARCH_RESULT_SUCCESS",
  GET_SEARCH_RESULT_FAILURE: "GET_SEARCH_RESULT_FAILURE",
};

export const timeConstants = {
  ADD_TIME_REQUEST: "ADD_TIME_REQUEST",
  ADD_TIME_SUCCESS: "ADD_TIME_SUCCESS",
  ADD_TIME_FAILURE: "ADD_TIME_FAILURE",

  //GET WORKHOURS
  GET_WORK_HOUR_REQUEST: "GET_WORK_HOUR_REQUEST",
  GET_WORK_HOUR_SUCCESS: "GET_WORK_HOUR_SUCCESS",
  GET_WORK_HOUR_FAILURE: "GET_WORK_HOUR_FAILURE",

  //GET TOTAL NUMBER OF WORKHOURS FOR TODAY, MONTH, YEAR
  GET_TOTAL_WORK_HOURS_REQUEST : "GET_TOTAL_WORK_HOURS_REQUEST",
  GET_TOTAL_WORK_HOURS_SUCCESS : "GET_TOTAL_WORK_HOURS_SUCCESS",
  GET_TOTAL_WORK_HOURS_FAILURE : "GET_TOTAL_WORK_HOURS_FAILURE",

  //OPE constants

  ADD_NEW_OPE_REQUEST: "ADD_NEW_OPE_REQUEST",
  ADD_NEW_OPE_SUCCESS: "ADD_NEW_OPE_SUCCESS",
  ADD_NEW_OPE_FAILURE: "ADD_NEW_OPE_FAILURE",

  FILTER_DATA_FOR_USER_REQUEST: "FILTER_DATA_FOR_USER_REQUEST",
  FILTER_DATA_FOR_USER_SUCCESS: "FILTER_DATA_FOR_USER_SUCCESS",
  FILTER_DATA_FOR_USER_FAILURE: "FILTER_DATA_FOR_USER_FAILURE",

  FILTER_DATA_FOR_ADMIN_REQUEST: "FILTER_DATA_FOR_ADMIN_REQUEST",
  FILTER_DATA_FOR_ADMIN_SUCCESS: "FILTER_DATA_FOR_ADMIN_SUCCESS",
  FILTER_DATA_FOR_ADMIN_FAILURE: "FILTER_DATA_FOR_ADMIN_FAILURE",

  FILTER_DATA_FOR_ADMIN_DASHBOARD_REQUEST:
    "FILTER_DATA_FOR_ADMIN_DASHBOARD_REQUEST",
  FILTER_DATA_FOR_ADMIN_DASHBOARD_SUCCESS:
    "FILTER_DATA_FOR_ADMIN_DASHBOARD_SUCCESS",
  FILTER_DATA_FOR_ADMIN_DASHBOARD_FAILURE:
    "FILTER_DATA_FOR_ADMIN_DASHBOARD_FAILURE",

  //writeoffs
  FETCH_WRITEOFFS_REQUEST_BY_ADMIN: "FETCH_WRITEOFFS_REQUEST_BY_ADMIN",
  FETCH_WRITEOFFS_SUCCESS_BY_ADMIN: "FETCH_WRITEOFFS_SUCCESS_BY_ADMIN",
  FETCH_WRITEOFFS_FAILURE_BY_ADMIN: "FETCH_WRITEOFFS_FAILURE_BY_ADMIN",

  //discount time
  UPDATE_WORKHOURS_WITH_DISCOUNT_REQUEST:
    "UPDATE_WORKHOURS_WITH_DISCOUNT_REQUEST",
  UPDATE_WORKHOURS_WITH_DISCOUNT_SUCCESS:
    "UPDATE_WORKHOURS_WITH_DISCOUNT_SUCCESS",
  UPDATE_WORKHOURS_WITH_DISCOUNT_FAILURE:
    "UPDATE_WORKHOURS_WITH_DISCOUNT_FAILURE",
};

export const clientConstants = {
  GET_ALL_CLIENT_BY_ADMIN_REQUEST: "GET_ALL_CLIENT_BY_ADMIN_REQUEST",
  GET_ALL_CLIENT_BY_ADMIN_SUCCESS: "GET_ALL_CLIENT_BY_ADMIN_SUCCESS",
  GET_ALL_CLIENT_BY_ADMIN_FAILURE: "GET_ALL_CLIENT_BY_ADMIN_FAILURE",

  CREATE_NEW_CLIENT_REQUEST: "CREATE_NEW_CLIENT_REQUEST",
  CREATE_NEW_CLIENT_SUCCESS: "CREATE_NEW_CLIENT_SUCCESS",
  CREATE_NEW_CLIENT_FAILURE: "CREATE_NEW_CLIENT_FAILURE",
};
