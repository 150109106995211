import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../helpers/axios";
import { errorAlert, successAlert } from "../../utils/services";
import { ToastContainer } from "react-toastify";
import {
  Box,
  Grid,
  TextField,
  CssBaseline,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CustomHeading from "../../components/common/CustomHeading";
import CustomButton from "../../components/common/CustomButton";

const validationSchema = Yup.object().shape({
  otp: Yup.string().required("OTP is required").min(4, "OTP must be 4 digits"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password should be at least 6 characters"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const Otp = () => {
  const [timer, setTimer] = useState(120);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : prevTimer));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Function to format seconds to MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onsubmitOtp = async (data) => {
    try {
      const response = await axiosInstance.post("/forgotPassword", {
        otp: data.otp,
        userId: location.state.user._id,
        password: data.confirmPassword,
      });

      if (response.data) {
        successAlert(`Password reset successfully.`);
        navigate("/");
      }
    } catch (error) {
      errorAlert(
        error?.response?.data?.error
          ? error.response.data.error
          : "Network error! Please try again."
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <CssBaseline />
      <Grid container>
        {/* Left Image  */}
        <Grid
          item
          md={6}
          backgroundColor="#372a1b"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          sx={{
            display: { xs: "none", md: "flex" },
          }}
        >
          <img
            // src={Comput_white_logo}
            src={`/assets/Comput_white_logo.png`}
            alt="logo"
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        </Grid>

        {/* Right Form  */}
        <Grid
          item
          md={6}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          padding={5}
          width="100%"
          gap="15px"
        >
          {/* Logo  */}
          <Box
            justifyContent="center"
            alignItems="center"
            sx={{
              display: { md: "none", xs: "flex" },
            }}
          >
            <img
              // src={Comput_brown_logo}
              src={`/assets/Comput_brown_logo.png`}
              alt="logo"
              style={{ maxWidth: "30%" }}
            />
          </Box>

          <CustomHeading text="OTP Verification" />
          <CustomHeading
            text="We have sent an OTP on your email"
            variant="h8"
            color="gray"
            fontWeight="100"
          />

          {/* Form  */}
          <Box
            component="form"
            onSubmit={handleSubmit(onsubmitOtp)}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Enter OTP"
              type="number"
              {...register("otp")}
              error={!!errors.otp}
              helperText={errors.otp?.message}
              className={`formInput ${errors.otp ? "formInputError" : ""}`}
            />

            <Typography
              style={{ fontSize: "14px", color: "#FF5733", marginLeft: "5px" }}
            >
              {`Time Left: ${formatTime(timer)}`}
            </Typography>

            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={!!errors.password}
              className="formInput"
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{ color: errors.password ? "red" : "" }}
              >
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                {...register("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                className={errors.password ? "formInputError" : ""}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "red",
                    },
                }}
              />
              {errors.password && (
                <p className="errorMessage">{errors.password?.message}</p>
              )}
            </FormControl>

            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={!!errors.confirmPassword}
              className="formInput"
            >
              <InputLabel
                htmlFor="outlined-adornment-confirm-password"
                sx={{ color: errors.confirmPassword ? "red" : "" }}
              >
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm-password"
                type={showConfirmPassword ? "text" : "password"}
                {...register("confirmPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
                className={errors.confirmPassword ? "formInputError" : ""}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "red",
                    },
                }}
              />
              {errors.confirmPassword && (
                <p className="errorMessage">
                  {errors.confirmPassword?.message}
                </p>
              )}
            </FormControl>

            <CustomButton type="submit">Submit</CustomButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Otp;
