import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../helpers/axios";
import { API } from "../utils/services";
const { timeConstants } = require("./constants");

export const fetchWriteoffsByAdminId = (
  adminId,
  fromDate,
  toDate,
  matterId,
  user
) => {
  return async (dispatch) => {
    // console.log("Request Data", adminId, fromDate, toDate, matterId, user);
    dispatch({ type: timeConstants.FETCH_WRITEOFFS_REQUEST_BY_ADMIN });
    const authToken = localStorage.getItem("authToken");
    await axios
      .get(`${API}/writeoffs/getWriteoffHoursByAdmin`, {
        params: {
          adminId,
          fromDate,
          toDate,
          matterId,
          user,
          limit: 0,
          page: 0,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(async (response) => {
        // return response.data
        // console.log("Response Data for WriteOffs Data", response);
        dispatch({
          type: timeConstants.FETCH_WRITEOFFS_SUCCESS_BY_ADMIN,
          payload: {
            writeoffHours: response.data.writeoffHours,
            totalWriteoffCount: response.data.totalCount,
          },
        });
      })
      .catch((error) => {
        // console.log(error);
        dispatch({
          type: timeConstants.FETCH_WRITEOFFS_FAILURE_BY_ADMIN,
          payload: {
            error: JSON.stringify(error.response),
          },
        });
        toast.error({
          text1: JSON.stringify(error.response),
        });
      });
  };
};
