import { Box, Card, CardContent, Grid } from "@mui/material";
import CustomHeading from "./CustomHeading";

const IntervalCard = ({
  interval,
  activeInterval,
  handleIntervalChange,
  // icon,
  title,
  totalHours,
  billableHours,
  nonBillableHours,
}) => {
  const isActive = activeInterval === interval;
  const color = isActive ? "white" : "#80461b";
  const backgroundColor = isActive ? "#886a4d" : "#eae4dd";

  return (
    <Grid item>
      <Card
        onClick={() => handleIntervalChange(interval)}
        sx={{
          cursor: "pointer",
          backgroundColor: backgroundColor,
          height: "110px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            boxShadow: 3,
          },
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
            width: "100%",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            {/* {React.cloneElement(icon, { size: 20, color: color })} */}
            <CustomHeading
              fontSize="16px"
              color={color}
              text={title}
              align="center"
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
              marginBottom: "-10px",
            }}
          >
            <CustomHeading
              fontSize="14px"
              color={color}
              text={` ${parseFloat(totalHours).toFixed(1)} hrs `}
            />
            <CustomHeading
              fontSize="14px"
              color={color}
              text={` ${parseFloat(billableHours).toFixed(1)} B`}
            />
            <CustomHeading
              fontSize="14px"
              color={color}
              text={`${parseFloat(nonBillableHours).toFixed(1)} NB`}
            />
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default IntervalCard;
