import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchWriteoffsByAdminId } from "../../actions";
import jsPDF from "jspdf";
import "jspdf-autotable";
import formatDate, {
  ConfirmationAlert,
  errorAlert,
  formatLetterDate,
  formatMonthDate,
  successAlert,
  API,
} from "../../utils/services";
import Select from "react-select";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import {
  Add,
  Delete,
  Download,
  Edit,
  Search,
  // ViewCompact,
} from "@mui/icons-material";
import { BiMoney } from "react-icons/bi";
import axiosInstance from "../../helpers/axios";
import CustomButton from "../../components/common/CustomButton";
import CustomTooltip from "../../components/common/CustomTootltip";
import CustomHeading from "../../components/common/CustomHeading";
import SkeletonRows from "../../components/common/CustomSkeletonLoading";
import { useMattersOptions } from "../../helpers/hooks/useMattersOptions";
import { useTSEmployees } from "../../helpers/hooks/useTSEmployees";
// import { useEmployeeOptions } from "../../helpers/hooks/useEmployeesOptions";
import NoDataFound from "../../components/common/NoDataFound";

const Invoice = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const { matters, filteredMatters } = useMattersOptions();
  // const { filteredEmployees } = useEmployeeOptions();

  //states
  const [client, setClient] = useState("");
  const [matter, setMatter] = useState(""); //matter id
  const [selectedMatterName, setSelectedMatterName] = useState(""); //matter name
  const [selectedLawyer, setSelectedLawyer] = useState("");
  const [startSelectedDate, setStartSelectedDate] = useState(
    formatDate(new Date())
  );
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );
  const [loading, setLoading] = useState(true);
  const [filteredWorkHour, setFilteredWorkHour] = useState([]);

  //pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [totalWorkHours, setTotalWorkHours] = useState(0);
  const [opeWorkHours, setOpeWorkHours] = useState([]);

  //modal states
  const [ope, setOPE] = useState("0");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [dollarPrice, setDollarPrice] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [filteredStatus, setFilteredStatus] = useState(null);
  const [show, setShow] = useState("Invoices");
  const [raisedDate, setRaisedDate] = useState(formatDate(new Date()));
  const [collectDate, setCollectDate] = useState("");
  const [status, setStatus] = useState("pending");
  const [invoiceId, setInvoiceId] = useState("");
  const [showModal, setShowModal] = useState(false);
  // const [invoiceFile, setInvoiceFile] = useState([]);
  const needFin = false;

  const { commonNames } = useTSEmployees(filteredWorkHour);

  //company states
  const [companyData, setCompanyData] = useState({
    companyName: "",
    companyEmail: "",
    companyAddress: "",
    companyLogo: null, // Assuming this could be a URL string or null if not available
    companyWebsite: "",
    companyAccountsEmail: "",
    companyCorrespondentBankName: "",
    companyCorrespondentSwiftCode: "",
    companyIntermediaryBankClearingCode: "",
    companyIntermediaryAccountNumber: "",
    companyBankSwiftCode: "",
    companyABAFEDNumber: "",
    companyBeneficiaryName: "",
    companyBeneficiaryAccountNumber: "",
    companyBeneficiaryBankName: "",
    companyIFSCCode: "",
    companyGSTIN: "",
    companySAC: "",
    companyPAN: "",
    companyLUT: "",
  });

  //invoice el states
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);

  //TS download states
  const [downloadWithTS, setDownloadWithTS] = useState("withTS");
  const [matterType, setMatterType] = useState("timespent");
  const [discountAmount, setDiscountAmount] = useState("");
  const [editingInvoice, setEditingInvoice] = useState(null);

  // console.log("downloadWithTS", downloadWithTS, matterType);

  //constants
  const formattedStartDate = formatLetterDate(startSelectedDate);
  const formattedEndDate = formatLetterDate(endSelectedDate);
  const adminId = auth.user._id;
  const authToken = localStorage.getItem("authToken");
  let sendMail = "false";
  let currency;
  let matterAddress = "";
  let flatFeesAmount = 0;

  const matterCurr = matters.find((m) => m._id === matter);
  // console.log("Selected Matter", matterCurr);

  if (matterCurr) {
    currency = matterCurr.currency === "USD" ? "USD " : "Rs. ";
    matterAddress =
      matterCurr.matterAddress !== "" ? matterCurr.matterAddress : "";
    flatFeesAmount = matterCurr.flatFeesAmount.$numberDecimal;
  } else {
    currency = "";
  }

  // console.log(
  //   "flatFeesAmount",
  //   flatFeesAmount,
  //   "discountAmount",
  //   discountAmount
  // );

  //Generate invoice button
  const enableDownload =
    matter &&
    invoiceNo &&
    raisedDate &&
    startSelectedDate &&
    endSelectedDate &&
    ((currency === "USD " && dollarPrice !== "") ||
      (currency === "Rs. " && dollarPrice === ""));

  //dropdowns
  const [anchorElDownload, setAnchorElDownload] = useState(null);

  const handleClick = (event, setAnchorEl) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (setAnchorEl) => {
    setAnchorEl(null);
  };

  //useEffects
  useEffect(() => {
    dispatch(
      fetchWriteoffsByAdminId(
        auth.user._id,
        startSelectedDate,
        endSelectedDate,
        matter,
        selectedLawyer
      )
    );
    const loadData = async () => {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1500)); // Simulating API call delay
      setLoading(false);
    };

    loadData();
    fetchCompanyDetails();
  }, []);

  useEffect(() => {
    dispatch(fetchAllInvoices);
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    const opeAmountCalcs = opeWorkHours.reduce(
      (total, curr) => total + parseFloat(curr.amount.$numberDecimal),
      0
    );

    setOPE(opeAmountCalcs);
  }, [opeWorkHours]);

  //pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //matter el pdf upload functions
  const handleRemoveFile = (index) => {
    const newSelectedFile = selectedFile.filter((_, i) => i !== index);
    setSelectedFile(newSelectedFile);
  };

  const handleFileUpload = async (e) => {
    // console.log(e.target.files);
    if (e.target.files.length > 0) {
      const files = e.target.files;
      const uploadPromises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("adminId", auth.user._id);
        // Adjust the form data append lines as necessary for the new endpoint requirements

        // Update the fetch URL to the new endpoint
        uploadPromises.push(
          fetch(`${API}/uploadInvoice`, {
            method: "POST",
            body: formData,
          })
        );
      }

      try {
        setUploading(true);
        const uploadResponses = await Promise.all(uploadPromises);
        const uploadData = await Promise.all(
          uploadResponses.map((res) => res.json())
        );

        uploadResponses.forEach((response) => {
          if (!response.ok) {
            console.error(
              `Error with status ${response.status} during file upload`
            );
          }
        });

        // Assume the API returns an object with a URL field for the uploaded file
        const uploadedFileUrls = uploadData.map((data) => data.url);
        setSelectedFile([...selectedFile, ...uploadedFileUrls]);
      } catch (e) {
        console.error(e);
      } finally {
        setUploading(false);
      }
    }
  };

  // filter function and Validation
  const isValidObjFieldForFilters = () => {
    if (startSelectedDate === "" || endSelectedDate === "") {
      return false;
    } else {
      return true;
    }
    // return Object.values(obj).every(value => value.trim())
  };

  const isValidFilters = () => {
    //all fields must be filled
    if (!isValidObjFieldForFilters()) return errorAlert("Enter the dates!!");

    return true;
  };

  async function filterBetweenDates() {
    // console.log("Logged----------");
    if (isValidFilters()) {
      setLoading(true);
      const response = await axiosInstance.get(
        `${API}/workHour/filterWorkHourByUserId`,
        {
          params: {
            user: selectedLawyer,
            fromDate: startSelectedDate,
            toDate: endSelectedDate,
            matterId: matter,
            needFin,
            sendMail,
            adminId,
          },
        }
      );

      const opeResponse = await axiosInstance.get(
        `${API}/workHour/filterWorkHourByUserId`,
        {
          params: {
            user: selectedLawyer,
            fromDate: startSelectedDate,
            toDate: endSelectedDate,
            matterId: matter,
            role: "OPE",
            needFin,
            sendMail,
            adminId,
          },
        }
      );
      setOpeWorkHours(opeResponse.data.workHours);
      setFilteredWorkHour(response.data.workHours);
      setTotalWorkHours(response.data.totalCount);
      setLoading(false);
    }
  }
  // console.log("FilterData workhours-------", filteredWorkHour, opeWorkHours);

  const fetchCompanyDetails = async () => {
    try {
      const response = await axiosInstance.get(
        `${API}/company/details/${adminId}`
      );

      // console.log("Company details", response.data);

      // Update to set all company details from response
      setCompanyData((prevData) => ({
        ...prevData,
        ...response.data,
      }));
    } catch (error) {
      // console.log("Error in fetching company details", error);
      // Assuming errorAlert is a function you've defined to show error messages
      errorAlert("Error in fetching company details!!");
    }
  };

  // Common Table Data Calculations Start----------------------------------

  const link1Text = companyData.companyAccountsEmail;
  const link1URL = `mailto:${companyData.companyAccountsEmail}`;
  const link2Text = companyData.companyWebsite;
  const link2URL = companyData.companyWebsite;

  const doubleUsedTableColumns = [
    "Associate Name",
    "Total Time",
    "Billed Time",
    "Hourly Rate",
    "Total Amount",
  ];

  const doubleUsedTableData = [];

  let teamTotalHours = 0;
  let teamTotalBilledHours = 0;
  let teamTotalBilledAmount = 0;
  let persontotalHours = 0;

  let amount;

  // console.log(
  //   "amount",
  //   amount,
  //   "teamTotalBilledAmount",
  //   teamTotalBilledAmount,
  //   "flatFeesAmount",
  //   flatFeesAmount
  // );

  // Filtering the data with matter, dates and resource name
  commonNames.forEach((name) => {
    let personWorkHours = 0;
    // let personWriteoffHours = 0;
    filteredWorkHour &&
      filteredWorkHour
        .filter(
          (result) => result.user?.fullName === name && result.role !== "OPE"
        )
        .forEach((result) => {
          const workHour = parseFloat(result.workHour);
          personWorkHours += workHour;
          // console.log(name, personWorkHours)
        });

    persontotalHours = parseFloat(personWorkHours);
    //  + parseFloat(personWriteoffHours);

    // console.log(name, persontotalHours)

    const newMatter = matters.find(
      (matter) => matter?.name.trim() === selectedMatterName.trim()
    );

    // console.log(newMatter);

    let price;
    if (newMatter) {
      if (newMatter.pricePerHour) {
        price = newMatter.pricePerHour.$numberDecimal;
      } else if (newMatter.resourceSpecificPrice.length !== 0) {
        const employeeEntry = newMatter.resourceSpecificPrice.find((entry) =>
          entry.includes(name)
        );
        // console.log(employeeEntry)

        if (employeeEntry) {
          const [, hours] = employeeEntry.split(":");
          price = hours.trim();
          // console.log("price", name, price);
        }
      } else {
        price = 0;
      }
    }

    if (price) {
      let personTotalBilledAmount = price * personWorkHours;
      teamTotalHours += persontotalHours;
      teamTotalBilledHours += personWorkHours;
      teamTotalBilledAmount += personTotalBilledAmount;

      const data1 = [
        name,
        persontotalHours.toFixed(2),
        personWorkHours.toFixed(2),
        currency + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        currency +
          personTotalBilledAmount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      ];

      // console.log( name,
      //   persontotalHours.toFixed(2),
      //   personWorkHours.toFixed(2),
      //   currency + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      //   currency +
      //     personTotalBilledAmount
      //       .toFixed(2)
      //       .toString()
      //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),)

      doubleUsedTableData.push(data1);
    }
  });

  amount = parseFloat(
    matterType === "timespent" ? teamTotalBilledAmount : flatFeesAmount
  );

  // Add footer row
  const footerRow = [
    {
      content: "Total Hours",
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content: teamTotalHours.toFixed(2),
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content: teamTotalBilledHours.toFixed(2),
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content: "",
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content:
        currency +
        amount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
  ];

  doubleUsedTableData.push(footerRow);

  // Table Calculations End ---------------------------------------------------------

  // All Invoices pdf generation
  const generateDomesticInvoice = async () => {
    const doc = new jsPDF();

    //this header is different from others common header
    const pageWidth = doc.internal.pageSize.getWidth();
    const rightMargin = 15; // You can adjust the right margin as needed
    const logoDesiredHeight = 20; // Set the desired height for the logo
    const logoYPosition = 10; // Set the Y position for the logo
    const logoXPosition = 13; // Set the X position for the logo

    function addHeader() {
      // Set the font style, size, and position of the header text
      const fontSize = 8;

      doc.setFont("helvetica", "normal");
      doc.setFontSize(fontSize);

      // Add the image to the header
      // doc.addImage(companyData.companyLogo, "PNG", 15, 10, 70, 9);
      doc.addImage(
        companyData.companyLogo,
        "PNG",
        logoXPosition,
        logoYPosition,
        0,
        logoDesiredHeight
      );

      // right header
      doc.setTextColor(46, 115, 176);

      const link2Width = doc.getTextWidth(link2Text);
      const link2X = pageWidth - link2Width - rightMargin;
      doc.textWithLink(link2Text, link2X, 16, {
        url: link2URL,
        underline: true,
      });

      // Set link color to blue and add underline
      const link1Width = doc.getTextWidth(link1Text);
      const link1X = pageWidth - link1Width - rightMargin;
      doc.textWithLink(link1Text, link1X, 19, {
        url: link1URL,
        underline: true,
      });

      doc.setTextColor(0);

      // Calculate text width and position to align it right
      const addressWidth = doc.getTextWidth(companyData.companyAddress);
      const addressX = pageWidth - addressWidth - rightMargin;
      doc.text(companyData.companyAddress, addressX, 23);

      // doc.setTextColor(0);
      doc.setFont("helvetica", "bold");
      const gstWidth = doc.getTextWidth(`GSTIN: ${companyData.companyGSTIN}`);
      const gstX = pageWidth - gstWidth - rightMargin;

      doc.text(`GSTIN: ${companyData.companyGSTIN}`, gstX, 27);

      const sacWidth = doc.getTextWidth(`SAC : ${companyData.companySAC}`);
      const sacX = pageWidth - sacWidth - rightMargin;
      doc.text(`SAC : ${companyData.companySAC}`, sacX, 30);
      doc.setFont("helvetica", "normal");
    }

    // Load the image
    const InvoicePage = `/assets/invoice.jpg`;
    //  const imageBase64 = await convertToBase64(imageUrl);

    doc.addImage(InvoicePage, "JPEG", 0, 0, 210, 297);
    doc.addImage(
      companyData.companyLogo,
      "PNG",
      logoXPosition,
      logoYPosition,
      0,
      logoDesiredHeight
    );

    //Header Image

    //left top width height
    // Header Text
    const fontSize = 20;
    doc.setFontSize(fontSize);

    //center alignment width calculations
    const clientNameLength = (210 - doc.getTextWidth(client)) / 2;
    const matterNameLength = (210 - doc.getTextWidth(selectedMatterName)) / 2;
    const dateRangeLength =
      (210 - doc.getTextWidth(`${formattedStartDate} - ${formattedEndDate}`)) /
      2;

    //client name
    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(20);
    doc.text(client, clientNameLength, 120);

    //matter name
    doc.setFontSize(20);
    doc.setFont("helvetica", "normal");
    doc.text(selectedMatterName, matterNameLength, 130);

    //horizontal line
    doc.setLineWidth(0.3);
    doc.setDrawColor(136, 106, 71);
    doc.line(40, 145, 180, 145);

    //date range
    doc.setFont("helvetica", "italic");
    doc.setTextColor(0, 0, 0); // Black color
    doc.text(
      `${formattedStartDate} - ${formattedEndDate}`,
      dateRangeLength,
      160
    );

    // -----------2nd Page -----------------

    doc.addPage();
    addHeader();

    // Client and Matter Address
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(client, 14, 45);
    doc.setFont("helvetica", "normal");

    let maxWidth = 100; // Maximum width of text before wrapping
    let lines = doc.splitTextToSize(matterAddress, maxWidth);
    let yPosition = 50;
    lines.forEach((line) => {
      doc.text(line, 15, yPosition);
      yPosition += 5;
    });

    // Additional Information
    doc.setFont("helvetica", "bold");
    doc.text("By Email Only", 15, yPosition + 3);
    doc.setFont("helvetica", "normal");
    doc.text(formatLetterDate(new Date()), 15, yPosition + 11);
    doc.text("Dear Sir / Ma'am", 15, yPosition + 19);
    doc.setFont("helvetica", "bold");
    doc.text(`${selectedMatterName}`, 14, yPosition + 27);
    doc.setFont("helvetica", "normal");
    doc.text(
      `Please find enclosed our invoice for legal services rendered for all the work done between ${formatLetterDate(
        startSelectedDate
      )}`,
      15,
      yPosition + 37
    );
    doc.text(
      `to ${formatLetterDate(
        endSelectedDate
      )} for ${selectedMatterName}. The time-spent reports enclosed are for your`,
      15,
      yPosition + 42
    );
    doc.text(
      "records. The payment details are mentioned therein.",
      15,
      yPosition + 47
    );
    doc.text(
      `We will be grateful if you would pay all local bank charges so that ${auth.user.fullName} receives the full invoiced amount.`,
      15,
      yPosition + 55
    );
    doc.text("We trust you find this to be in order.", 15, yPosition + 63);
    doc.text("Yours sincerely", 15, yPosition + 71);
    doc.text("Accounts Team", 15, yPosition + 79);
    doc.setFont("helvetica", "bold");
    doc.text(auth.user.fullName, 15, yPosition + 84);
    doc.setFont("helvetica", "normal");

    //------------- 3rd Page -------------------

    doc.addPage();
    addHeader();
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(
      `INVOICE FOR LEGAL SERVICES RENDERED (INVOICE NO. ${invoiceNo})`,
      15,
      40
    );
    doc.text(`- ${selectedMatterName}`, 15, 45);
    doc.setFont("helvetica", "normal");
    doc.text(
      "Fees for legal services rendered in connection with the above.",
      15,
      53
    );
    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.text("Fees", 15, 60);
    doc.setFont("helvetica", "normal");
    if (dollarPrice !== "") {
      doc.text(
        `USD ${amount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / INR ${(amount * dollarPrice)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        15,
        68
      );
    } else {
      doc.text(
        `${currency}${amount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        15,
        68
      );
    }
    doc.setFont("helvetica", "bold");
    doc.text("Disbursements", 15, 78);
    doc.setFont("helvetica", "normal");
    //I am writing Rs. directly because all our OPEs right now in rupees but may be in future we need to make it dynamic
    doc.text(
      currency === "USD "
        ? `USD ${parseFloat(ope / dollarPrice)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / INR ${parseFloat(ope)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
        : `Rs. ${parseFloat(ope)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
      15,
      86
    );
    doc.setFont("helvetica", "bold");
    doc.text("Invoice Total", 15, 96);
    doc.setFont("helvetica", "normal");

    if (dollarPrice !== "" && currency === "USD ") {
      doc.text(
        `USD ${(parseFloat(amount) + parseFloat(ope / dollarPrice))
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / INR ${(
          parseFloat(amount) * dollarPrice +
          +parseFloat(ope)
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        15,
        104
      );
    } else {
      doc.text(
        `${currency}${(parseFloat(amount) + parseFloat(ope))
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        15,
        104
      );
    }
    doc.setFont("helvetica", "italic");
    doc.text(
      "Legal services provided by a firm of Advocates are subject to Goods and Service Tax (GST) on reverse–",
      15,
      114
    );
    doc.text(
      "charge basis. The obligation to pay GST is placed upon the service recipient, if applicable and is not included",
      15,
      119
    );

    doc.text(
      "in the memorandum of fees. The entire amount of GST on the above services is required to be paid by the ",
      15,
      124
    );

    doc.text(
      "service recipient on reverse charge basis as Notification No. 13/2017 Central Tax (Rate). ",
      15,
      129
    );

    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text("Payment Details", 15, 140);
    doc.setFontSize(10);

    doc.setFont("helvetica", "bold");
    doc.text("Correspondent Bank Name: ", 15, 150);

    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyCorrespondentBankName,
      20 + doc.getTextWidth("Correspondent Bank Name:"),
      150
    );
    doc.setFont("helvetica", "bold");
    doc.text("Correspondent Swift Code: ", 15, 158);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyCorrespondentSwiftCode,
      20 + doc.getTextWidth("Correspondent Swift Code:"),
      158
    );

    doc.setFont("helvetica", "bold");
    doc.text("Intermediary Bank Clearing Code: ", 15, 166);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyIntermediaryBankClearingCode,
      20 + doc.getTextWidth("Intermediary Bank Clearing Code:"),
      166
    );

    doc.setFont("helvetica", "bold");
    doc.text("Intermediary Account Number: ", 15, 174);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyIntermediaryAccountNumber,
      20 + doc.getTextWidth("Intermediary Account Number:"),
      174
    );

    doc.setFont("helvetica", "bold");
    doc.text("Bank Swift Code: ", 15, 182);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyBankSwiftCode,
      20 + doc.getTextWidth("Bank Swift Code:"),
      182
    );

    doc.setFont("helvetica", "bold");
    doc.text("ABA FED Number: ", 15, 190);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyABAFEDNumber,
      20 + doc.getTextWidth("ABA FED Number:"),
      190
    );

    doc.setFont("helvetica", "bold");
    doc.text("Beneficiary Name: ", 15, 198);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyBeneficiaryName,
      20 + doc.getTextWidth("Beneficiary Name:"),
      198
    );

    doc.setFont("helvetica", "bold");
    doc.text("Beneficiary Account Number: ", 15, 206);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyBeneficiaryAccountNumber,
      20 + doc.getTextWidth("Beneficiary Account Number:"),
      206
    );

    doc.setFont("helvetica", "bold");
    doc.text("Beneficiary Bank: ", 15, 214);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyBeneficiaryBankName,
      20 + doc.getTextWidth("Beneficiary Bank:"),
      214
    );

    doc.setFont("helvetica", "bold");
    doc.text("IFSC Code: ", 15, 222);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyIFSCCode,
      18 + doc.getTextWidth("IFSC Code:"),
      222
    );

    doc.setFont("helvetica", "bold");
    doc.text("GSTIN: ", 15, 230);
    doc.setFont("helvetica", "normal");
    doc.text(companyData.companyGSTIN, 18 + doc.getTextWidth("GSTIN:"), 230);

    doc.setFont("helvetica", "bold");
    doc.text("SAC: ", 15, 238);
    doc.setFont("helvetica", "normal");
    doc.text(companyData.companySAC, 18 + doc.getTextWidth("SAC:"), 238);

    doc.setFont("helvetica", "bold");
    doc.text("PAN: ", 15, 246);
    doc.setFont("helvetica", "normal");
    doc.text(companyData.companyPAN, 18 + doc.getTextWidth("PAN:"), 246);

    //-------4th Page -------------
    if (downloadWithTS === "withTS") {
      doc.addPage();
      addHeader();

      doc.setFont("helvetica", "bold");
      doc.setFontSize(13);
      doc.text("Summary of Time-Spent", 15, 40);

      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");

      // ----------------------------------Table Data  Start

      const startY = 55; // Starting Y-coordinate for the table

      doc.autoTable(doubleUsedTableColumns, doubleUsedTableData, {
        startY,
        theme: "grid",
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.1,

        didParseCell: function (data) {
          if (data.row.index === 0 && data.row.section === "head") {
            // Header row styles
            data.cell.styles.fillColor = [136, 106, 71];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.halign = "left"; // Align header text to the left
            data.cell.styles.fontStyle = "bold";
            data.cell.styles.fontSize = 9;
          } else if (data.row.section === "body") {
            // Body row styles
            if (data.column.index !== 0) {
              // Right-align all columns except the first column
              data.cell.styles.halign = "right";
            }
          } else if (data.row.index === doubleUsedTableData.length - 1) {
            // Footer row align
            data.cell.styles.halign = "right"; // Align footer text to the right
            data.cell.styles.fillColor = [226, 213, 203];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.fontStyle = "bold";
          } else if (
            data.row.index === doubleUsedTableData.length - 1 &&
            data.row.section === "foot"
          ) {
            // Footer row styles
            data.cell.styles.fillColor = [226, 213, 203];
            data.cell.styles.textColor = [255, 255, 255];
          }

          // Set padding for table data cells
          if (
            data.row.section === "body" &&
            data.column.index === 0 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 1 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 2 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 3 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 4 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
        },
      });

      // -----------------------------------Table data End

      // Calculate the table height
      const tableHeight = doc.autoTable.previous.finalY - startY;

      doc.text("Time-spent accrued", 15, startY + tableHeight + 10);
      doc.text(
        currency +
          amount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        210 - doc.getTextWidth(`${currency} ${amount.toFixed(2)}`) - 15,
        startY + tableHeight + 10
      );
      doc.text(
        "Add: Out of pocket expenses and other costs, if any",
        15,
        startY + tableHeight + 18
      );

      doc.text(
        currency +
          (currency === "USD "
            ? parseFloat(ope / dollarPrice).toFixed(2)
            : parseFloat(ope).toFixed(2)),
        210 -
          doc.getTextWidth(
            currency +
              (currency === "USD "
                ? parseFloat(ope / dollarPrice).toFixed(2)
                : parseFloat(ope).toFixed(2))
          ) -
          15,
        startY + tableHeight + 18
      );

      doc.setFont("helvetica", "bold");
      doc.text("Total Amount Due", 15, startY + tableHeight + 26);
      doc.text(
        currency +
          (
            parseFloat(amount) +
            (currency === "USD "
              ? parseFloat(ope / dollarPrice)
              : parseFloat(ope))
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        210 -
          doc.getTextWidth(
            currency +
              (
                parseFloat(amount) +
                (currency === "USD "
                  ? parseFloat(ope / dollarPrice)
                  : parseFloat(ope))
              )
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          ) -
          15,
        startY + tableHeight + 26
      );

      // ----------------5th Page --------------------

      doc.addPage();
      addHeader();

      let startY1 = 35;

      //Description Table for every resource
      commonNames.forEach((name, index) => {
        const personData =
          filteredWorkHour &&
          filteredWorkHour
            .filter(
              (result) =>
                result.user?.fullName === name && result.role !== "OPE"
            )
            .sort((a, b) => new Date(a.workDate) - new Date(b.workDate)); // Sort the data by workDate in ascending order

        // Add table header row with person's name
        const headerRow = [
          {
            content: name,
            colSpan: 2,
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },
          {
            content: "Total Time",
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },

          {
            content: "Billed Time",
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },
        ];

        const tableRows = [headerRow]; // Initialize tableRows with the header row

        // Add individual data rows for the person
        personData.forEach((result) => {
          const data = [
            formatMonthDate(result.workDate),
            result.workDescription.trim(" "),
            result.workHour,
            result.workHour,
          ];
          tableRows.push(data);
        });

        // Calculate total time
        const totalTime = personData.reduce(
          (total, result) => total + parseFloat(result.workHour),
          0
        );

        // Add footer row with total time
        const footerRow = [
          {
            content: "Total Time",
            colSpan: 2,
            styles: {
              halign: "left",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
          {
            content: totalTime.toFixed(2),
            styles: {
              halign: "right",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
          {
            content: totalTime.toFixed(2),
            styles: {
              halign: "right",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
        ];
        tableRows.push(footerRow);

        // Remove empty rows and columns from tableRows
        const filteredTableRows = tableRows.filter((row) =>
          row.some((cell) => cell !== "")
        );

        // Generate table for the current person
        doc.autoTable({
          // columns: tableColumn,
          body: filteredTableRows,
          startY: startY1,
          theme: "grid",
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.1,
          didParseCell: function (data) {
            if (data.row.index === 0 && data.row.section === "head") {
              // Header row styles
              data.cell.styles.fillColor = [136, 106, 71];
              data.cell.styles.textColor = [255, 255, 255];
              data.cell.styles.halign = "left";
              data.cell.styles.valign = "left"; // Align the text vertically at the center
              data.cell.styles.fontStyle = "bold"; // Set the font style to bold
            } else if (
              data.row.index === filteredTableRows.length - 1 &&
              data.row.section === "body"
            ) {
              // Footer row styles
              data.cell.styles.fillColor = [97, 68, 58];
              data.cell.styles.textColor = [255, 255, 255];
              // data.cell.styles.halign = "right";
              data.cell.styles.fontStyle = "bold";
            } else if (data.row.section === "body") {
              // Body row styles
              if (data.column.index !== 1) {
                // Right-align all columns except the first column
                data.cell.styles.halign = "left";
              }
            }

            // Set padding for table data cells
            if (
              data.row.section === "body" &&
              data.column.index === 0 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 3,
              };
              data.cell.styles.fontSize = 9;
              data.cell.styles.halign = "left";
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
            if (
              data.row.section === "body" &&
              data.column.index === 1 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 4,
                bottom: 3,
                left: 4,
              };
              data.cell.styles.fontSize = 9;
              data.cell.styles.fontStyle = "times";
              data.cell.styles.halign = "left";
              data.cell.styles.cellWidth = 122; // Set the width to 100 units
              data.cell.styles.textColor = [0, 0, 0];
            }
            if (
              data.row.section === "body" &&
              data.column.index === 2 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 2,
              };
              data.cell.styles.halign = "right";
              data.cell.styles.fontSize = 9;
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
            if (
              data.row.section === "body" &&
              data.column.index === 3 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 2,
              };
              data.cell.styles.halign = "right";
              data.cell.styles.fontSize = 9;
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
          },
        });

        startY1 = doc.lastAutoTable.finalY + 10; // Update startY for the next table
      });
    }

    doc.save(`${invoiceNo.slice(-3)}_${selectedMatterName}.pdf`);
  };

  const generateOffshoreInvoice = () => {
    const doc = new jsPDF();

    //this header is different from others common header

    const pageWidth = doc.internal.pageSize.getWidth();
    const rightMargin = 15; // You can adjust the right margin as needed
    const logoDesiredHeight = 20; // Set the desired height for the logo
    const logoYPosition = 10; // Set the Y position for the logo
    const logoXPosition = 13; // Set the X position for the logo

    function addHeader() {
      // Set the font style, size, and position of the header text
      const fontSize = 8;

      doc.setFont("helvetica", "normal");
      doc.setFontSize(fontSize);

      // Add the image to the header
      // doc.addImage(companyData.companyLogo, "PNG", 15, 10, 70, 9);
      doc.addImage(
        companyData.companyLogo,
        "PNG",
        logoXPosition,
        logoYPosition,
        0,
        logoDesiredHeight
      );

      // right header
      doc.setTextColor(46, 115, 176);

      const link2Width = doc.getTextWidth(link2Text);
      const link2X = pageWidth - link2Width - rightMargin;
      doc.textWithLink(link2Text, link2X, 16, {
        url: link2URL,
        underline: true,
      });

      // Set link color to blue and add underline
      const link1Width = doc.getTextWidth(link1Text);
      const link1X = pageWidth - link1Width - rightMargin;
      doc.textWithLink(link1Text, link1X, 19, {
        url: link1URL,
        underline: true,
      });

      doc.setTextColor(0);

      // Calculate text width and position to align it right
      const addressWidth = doc.getTextWidth(companyData.companyAddress);
      const addressX = pageWidth - addressWidth - rightMargin;
      doc.text(companyData.companyAddress, addressX, 23);

      // doc.setTextColor(0);
      doc.setFont("helvetica", "bold");
      const gstWidth = doc.getTextWidth(`GSTIN: ${companyData.companyGSTIN}`);
      const gstX = pageWidth - gstWidth - rightMargin;

      doc.text(`GSTIN: ${companyData.companyGSTIN}`, gstX, 27);

      const sacWidth = doc.getTextWidth(`SAC : ${companyData.companySAC}`);
      const sacX = pageWidth - sacWidth - rightMargin;
      doc.text(`SAC : ${companyData.companySAC}`, sacX, 30);
      doc.setFont("helvetica", "normal");
    }

    const InvoicePage = `/assets/invoice.jpg`;

    doc.addImage(InvoicePage, "JPEG", 0, 0, 210, 297);
    doc.addImage(
      companyData.companyLogo,
      "PNG",
      logoXPosition,
      logoYPosition,
      0,
      logoDesiredHeight
    );

    //Header Image
    //left top width height

    const fontSize = 20;
    doc.setFontSize(fontSize);

    //center alignment width calculations
    const clientNameLength = (210 - doc.getTextWidth(client)) / 2;
    const matterNameLength = (210 - doc.getTextWidth(selectedMatterName)) / 2;
    const dateRangeLength =
      (210 - doc.getTextWidth(`${formattedStartDate} - ${formattedEndDate}`)) /
      2;

    //client name
    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(20);
    doc.text(client, clientNameLength, 120);

    //matter name
    doc.setFontSize(20);
    doc.setFont("helvetica", "normal");
    doc.text(selectedMatterName, matterNameLength, 130);

    //horizontal line
    doc.setLineWidth(0.3);
    doc.setDrawColor(136, 106, 71);
    doc.line(40, 145, 180, 145);

    //date range
    doc.setFont("helvetica", "italic");
    doc.setTextColor(0, 0, 0); // Black color
    doc.text(
      `${formattedStartDate} - ${formattedEndDate}`,
      dateRangeLength,
      160
    );

    // -----------2nd Page -----------------

    doc.addPage();
    addHeader();

    // Client and Matter Address
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(client, 14, 45);
    doc.setFont("helvetica", "normal");

    let maxWidth = 100; // Maximum width of text before wrapping
    let lines = doc.splitTextToSize(matterAddress, maxWidth);
    let yPosition = 50;
    lines.forEach((line) => {
      doc.text(line, 15, yPosition);
      yPosition += 5;
    });

    // Additional Information
    doc.setFont("helvetica", "bold");
    doc.text("By Email Only", 15, yPosition + 3);
    doc.setFont("helvetica", "normal");
    doc.text(formatLetterDate(new Date()), 15, yPosition + 11);
    doc.text("Dear Sir / Ma'am", 15, yPosition + 19);
    doc.setFont("helvetica", "bold");
    doc.text(`${selectedMatterName}`, 14, yPosition + 27);
    doc.setFont("helvetica", "normal");
    doc.text(
      `Please find enclosed our invoice for legal services rendered for all the work done between ${formatLetterDate(
        startSelectedDate
      )}`,
      15,
      yPosition + 37
    );
    doc.text(
      `to ${formatLetterDate(
        endSelectedDate
      )} for ${selectedMatterName}. The time-spent reports enclosed are for your`,
      15,
      yPosition + 42
    );
    doc.text(
      "records. The payment details are mentioned therein.",
      15,
      yPosition + 47
    );
    doc.text(
      `We will be grateful if you would pay all local bank charges so that ${auth.user.fullName} receives the full invoiced amount.`,
      15,
      yPosition + 55
    );
    doc.text("We trust you find this to be in order.", 15, yPosition + 63);
    doc.text("Yours sincerely", 15, yPosition + 71);
    doc.text("Accounts Team", 15, yPosition + 79);
    doc.setFont("helvetica", "bold");
    doc.text(auth.user.fullName, 15, yPosition + 84);
    doc.setFont("helvetica", "normal");

    //------------- 3rd Page -------------------

    doc.addPage();
    addHeader();
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(
      `INVOICE FOR LEGAL SERVICES RENDERED (INVOICE NO. ${invoiceNo})`,
      15,
      40
    );
    doc.text(`- ${selectedMatterName}`, 15, 45);
    doc.setFont("helvetica", "normal");
    doc.text(
      "Fees for legal services rendered in connection with the above.",
      15,
      58
    );
    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.text("Fees", 15, 66);
    doc.setFont("helvetica", "normal");
    doc.text(
      `${currency}${amount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
      15,
      74
    );
    doc.setFont("helvetica", "bold");
    doc.text("Disbursements", 15, 82);
    doc.setFont("helvetica", "normal");
    doc.text(
      `USD ${parseFloat(ope / dollarPrice)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
      15,
      90
    );
    doc.setFont("helvetica", "bold");
    doc.text("Invoice Total", 15, 98);
    doc.setFont("helvetica", "normal");
    doc.text(
      `USD ${(parseFloat(amount) + parseFloat(ope / dollarPrice))
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `,
      15,
      106
    );
    doc.setFont("helvetica", "italic");
    doc.text(
      "The Export Service Operations are Under Bond or Letter Of Undertaking Without Payment Of IGST ",
      15,
      120
    );
    doc.text(`– Reference no. ${companyData.companyLUT}`, 15, 125);

    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text("Payment Details", 15, 140);
    doc.setFontSize(10);

    doc.setFont("helvetica", "bold");
    doc.text("Correspondent Bank Name: ", 15, 150);

    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyCorrespondentBankName,
      20 + doc.getTextWidth("Correspondent Bank Name:"),
      150
    );
    doc.setFont("helvetica", "bold");
    doc.text("Correspondent Swift Code: ", 15, 158);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyCorrespondentSwiftCode,
      20 + doc.getTextWidth("Correspondent Swift Code:"),
      158
    );

    doc.setFont("helvetica", "bold");
    doc.text("Intermediary Bank Clearing Code: ", 15, 166);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyIntermediaryBankClearingCode,
      20 + doc.getTextWidth("Intermediary Bank Clearing Code:"),
      166
    );

    doc.setFont("helvetica", "bold");
    doc.text("Intermediary Account Number: ", 15, 174);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyIntermediaryAccountNumber,
      20 + doc.getTextWidth("Intermediary Account Number:"),
      174
    );

    doc.setFont("helvetica", "bold");
    doc.text("Bank Swift Code: ", 15, 182);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyBankSwiftCode,
      20 + doc.getTextWidth("Bank Swift Code:"),
      182
    );

    doc.setFont("helvetica", "bold");
    doc.text("ABA FED Number: ", 15, 190);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyABAFEDNumber,
      20 + doc.getTextWidth("ABA FED Number:"),
      190
    );

    doc.setFont("helvetica", "bold");
    doc.text("Beneficiary Name: ", 15, 198);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyBeneficiaryName,
      20 + doc.getTextWidth("Beneficiary Name:"),
      198
    );

    doc.setFont("helvetica", "bold");
    doc.text("Beneficiary Account Number: ", 15, 206);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyBeneficiaryAccountNumber,
      20 + doc.getTextWidth("Beneficiary Account Number:"),
      206
    );

    doc.setFont("helvetica", "bold");
    doc.text("Beneficiary Bank: ", 15, 214);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyBeneficiaryBankName,
      20 + doc.getTextWidth("Beneficiary Bank:"),
      214
    );

    doc.setFont("helvetica", "bold");
    doc.text("IFSC Code: ", 15, 222);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyIFSCCode,
      18 + doc.getTextWidth("IFSC Code:"),
      222
    );

    doc.setFont("helvetica", "bold");
    doc.text("GSTIN: ", 15, 230);
    doc.setFont("helvetica", "normal");
    doc.text(companyData.companyGSTIN, 18 + doc.getTextWidth("GSTIN:"), 230);

    doc.setFont("helvetica", "bold");
    doc.text("SAC: ", 15, 238);
    doc.setFont("helvetica", "normal");
    doc.text(companyData.companySAC, 18 + doc.getTextWidth("SAC:"), 238);

    doc.setFont("helvetica", "bold");
    doc.text("PAN: ", 15, 246);
    doc.setFont("helvetica", "normal");
    doc.text(companyData.companyPAN, 18 + doc.getTextWidth("PAN:"), 246);

    //-------4th Page -------------

    if (downloadWithTS === "withTS") {
      doc.addPage();
      addHeader();

      doc.setFont("helvetica", "bold");
      doc.setFontSize(13);
      doc.text("Summary of Time-Spent", 15, 40);

      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");

      // ----------------------------------Table Data  Start

      doc.setFont("helvetica", "bold");
      doc.setFontSize(13);
      doc.text("Summary of Time-Spent", 15, 40);

      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");

      const startY = 55; // Starting Y-coordinate for the table

      doc.autoTable(doubleUsedTableColumns, doubleUsedTableData, {
        startY,
        theme: "grid",
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.1,

        didParseCell: function (data) {
          if (data.row.index === 0 && data.row.section === "head") {
            // Header row styles
            data.cell.styles.fillColor = [136, 106, 71];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.halign = "left"; // Align header text to the left
            data.cell.styles.fontStyle = "bold";
            data.cell.styles.fontSize = 9;
          } else if (data.row.section === "body") {
            // Body row styles
            if (data.column.index !== 0) {
              // Right-align all columns except the first column
              data.cell.styles.halign = "right";
            }
          } else if (data.row.index === doubleUsedTableData.length - 1) {
            // Footer row align
            data.cell.styles.halign = "right"; // Align footer text to the right
            data.cell.styles.fillColor = [226, 213, 203];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.fontStyle = "bold";
          } else if (
            data.row.index === doubleUsedTableData.length - 1 &&
            data.row.section === "foot"
          ) {
            // Footer row styles
            data.cell.styles.fillColor = [226, 213, 203];
            data.cell.styles.textColor = [255, 255, 255];
          }

          // Set padding for table data cells
          if (
            data.row.section === "body" &&
            data.column.index === 0 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 1 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 2 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 3 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 4 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
        },
      });

      // -----------------------------------Table data End

      // Calculate the table height
      const tableHeight = doc.autoTable.previous.finalY - startY;

      doc.text("Time-spent accrued", 15, startY + tableHeight + 10);
      doc.text(
        currency +
          parseFloat(amount)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        210 -
          doc.getTextWidth(
            currency +
              parseFloat(amount)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          ) -
          15,
        startY + tableHeight + 10
      );
      doc.text(
        "Add: Out of pocket expenses and other costs, if any",
        15,
        startY + tableHeight + 18
      );
      doc.text(
        currency + parseFloat(ope / dollarPrice).toFixed(2),
        210 -
          doc.getTextWidth(
            currency + parseFloat(ope / dollarPrice).toFixed(2)
          ) -
          15,
        startY + tableHeight + 18
      );

      doc.setFont("helvetica", "bold");
      doc.text("Total Amount Due", 15, startY + tableHeight + 26);
      doc.text(
        currency +
          (parseFloat(amount) + parseFloat(ope / dollarPrice))
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        210 -
          doc.getTextWidth(
            currency +
              (parseFloat(amount) + parseFloat(ope / dollarPrice))
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          ) -
          15,
        startY + tableHeight + 26
      );

      // ----------------5th Page --------------------

      doc.addPage();
      addHeader();

      let startY1 = 35;

      //Description Table for every resource
      commonNames.forEach((name, index) => {
        const personData =
          filteredWorkHour &&
          filteredWorkHour
            .filter(
              (result) =>
                result.user?.fullName === name && result.role !== "OPE"
            )
            .sort((a, b) => new Date(a.workDate) - new Date(b.workDate)); // Sort the data by workDate in ascending order

        // Add table header row with person's name
        const headerRow = [
          {
            content: name,
            colSpan: 2,
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },
          {
            content: "Total Time",
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },

          {
            content: "Billed Time",
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },
        ];

        const tableRows = [headerRow]; // Initialize tableRows with the header row

        // Add individual data rows for the person
        personData.forEach((result) => {
          const data = [
            formatMonthDate(result.workDate),
            result.workDescription,
            result.workHour,
            result.workHour,
          ];
          tableRows.push(data);
        });

        // Calculate total time
        const totalTime = personData.reduce(
          (total, result) => total + parseFloat(result.workHour),
          0
        );

        // Add footer row with total time
        const footerRow = [
          {
            content: "Total Time",
            colSpan: 2,
            styles: {
              halign: "left",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
          {
            content: totalTime.toFixed(2),
            styles: {
              halign: "right",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
          {
            content: totalTime.toFixed(2),
            styles: {
              halign: "right",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
        ];
        tableRows.push(footerRow);

        // Remove empty rows and columns from tableRows
        const filteredTableRows = tableRows.filter((row) =>
          row.some((cell) => cell !== "")
        );

        // Generate table for the current person
        doc.autoTable({
          // columns: tableColumn,
          body: filteredTableRows,
          startY: startY1,
          theme: "grid",
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.1,
          didParseCell: function (data) {
            if (data.row.index === 0 && data.row.section === "head") {
              // Header row styles
              data.cell.styles.fillColor = [136, 106, 71];
              data.cell.styles.textColor = [255, 255, 255];
              data.cell.styles.halign = "left";
              data.cell.styles.valign = "left"; // Align the text vertically at the center
              data.cell.styles.fontStyle = "bold"; // Set the font style to bold
            } else if (
              data.row.index === filteredTableRows.length - 1 &&
              data.row.section === "body"
            ) {
              // Footer row styles
              data.cell.styles.fillColor = [97, 68, 58];
              data.cell.styles.textColor = [255, 255, 255];
              // data.cell.styles.halign = "right";
              data.cell.styles.fontStyle = "bold";
            } else if (data.row.section === "body") {
              // Body row styles
              if (data.column.index !== 1) {
                // Right-align all columns except the first column
                data.cell.styles.halign = "left";
              }
            }

            // Set padding for table data cells
            if (
              data.row.section === "body" &&
              data.column.index === 0 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 3,
              };
              data.cell.styles.fontSize = 9;
              data.cell.styles.halign = "left";
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
            if (
              data.row.section === "body" &&
              data.column.index === 1 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 4,
                bottom: 3,
                left: 4,
              };
              data.cell.styles.fontSize = 9;
              data.cell.styles.fontStyle = "times";
              data.cell.styles.halign = "left";
              data.cell.styles.cellWidth = 122; // Set the width to 100 units
              data.cell.styles.textColor = [0, 0, 0];
            }
            if (
              data.row.section === "body" &&
              data.column.index === 2 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 2,
              };
              data.cell.styles.halign = "right";
              data.cell.styles.fontSize = 9;
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
            if (
              data.row.section === "body" &&
              data.column.index === 3 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 2,
              };
              data.cell.styles.halign = "right";
              data.cell.styles.fontSize = 9;
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
          },
        });

        startY1 = doc.lastAutoTable.finalY + 10; // Update startY for the next table
      });
    }

    doc.save(`${invoiceNo.slice(-3)}_${selectedMatterName}.pdf`);
  };

  const generateInvoicesReport = () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });

    const invoiceTableColumns = [
      "Raised Date",
      "Collection Date",
      "Invoice No.",
      "Invoice Period",
      "Matter",
      "Amount",
      "Status",
    ];

    // Separate invoices into pending and collected
    // Assuming startSelectedDate and endSelectedDate are either valid dates or undefined/null.
    const filterInvoices = (
      status,
      invoices,
      startSelectedDate,
      endSelectedDate
    ) => {
      return invoices.filter((invoice) => {
        const statusMatch = invoice.status === status;
        const dateRangeMatch =
          !startSelectedDate ||
          !endSelectedDate ||
          (invoice.startDate >= startSelectedDate &&
            invoice.endDate <= endSelectedDate);

        return statusMatch && dateRangeMatch;
      });
    };

    const pendingInvoices = filterInvoices(
      "pending",
      invoices,
      startSelectedDate,
      endSelectedDate
    );
    const collectedInvoices = filterInvoices(
      "collected",
      invoices,
      startSelectedDate,
      endSelectedDate
    );

    // Function to format invoice data into table rows
    const formatInvoiceData = (invoiceList) => {
      return invoiceList.map((invoice) => [
        formatMonthDate(invoice.invoiceDate),
        invoice.collectionDate
          ? formatMonthDate(invoice.collectionDate)
          : "Not yet collected",
        invoice.invoiceNumber,
        `${formatMonthDate(invoice.startDate)} - ${formatMonthDate(
          invoice.endDate
        )}`,
        invoice.matter.name,
        invoice.amount.$numberDecimal,
        invoice.status,
      ]);
    };

    // Function to calculate total amount
    const calculateTotalAmount = (invoiceList) => {
      return invoiceList.reduce(
        (total, invoice) => parseFloat(invoice.amount.$numberDecimal) + total,
        0
      );
    };

    // Formatting data for pending and collected invoices
    const pendingInvoiceData = formatInvoiceData(pendingInvoices);
    const collectedInvoiceData = formatInvoiceData(collectedInvoices);

    // Adding footer row for total amount
    const pendingInvoiceFooter = [
      { content: "Total Amount", colSpan: 5, styles: { halign: "right" } },
      calculateTotalAmount(pendingInvoices),
      "",
    ];

    const collectedInvoiceFooter = [
      { content: "Total Amount", colSpan: 5, styles: { halign: "right" } },
      calculateTotalAmount(collectedInvoices),
      "",
    ];

    pendingInvoiceData.push(pendingInvoiceFooter);
    collectedInvoiceData.push(collectedInvoiceFooter);

    // Document Title
    doc.setFontSize(25);
    doc.setFont("helvetica", "bold");
    doc.text("Invoices Report", 120, 20);

    // Date Range Title
    doc.setFont("helvetica", "underline");
    doc.setFontSize(15);
    if (startSelectedDate !== "") {
      doc.text(
        `${formattedStartDate}` +
          (startSelectedDate !== endSelectedDate
            ? ` - ${formattedEndDate}`
            : ""),
        (297 -
          doc.getTextWidth(
            `${formattedStartDate}` +
              (startSelectedDate !== endSelectedDate
                ? ` - ${formattedEndDate}`
                : "")
          )) /
          2,
        30
      );
    }

    // Adding Pending Invoices Table
    doc.setFontSize(13);
    doc.text("Pending Invoices", 14, 40);
    doc.autoTable(invoiceTableColumns, pendingInvoiceData, {
      startY: 45,
      theme: "grid",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      didParseCell: (data) => customizeCellStyles(data),
    });

    // Adding Collected Invoices Table
    let currentY = doc.lastAutoTable.finalY + 10; // Get the Y position after the first table
    doc.setFontSize(13);
    doc.text("Collected Invoices", 14, currentY);
    doc.autoTable(invoiceTableColumns, collectedInvoiceData, {
      startY: currentY + 5,
      theme: "grid",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      didParseCell: (data) => customizeCellStyles(data),
    });

    doc.save("Invoices.pdf");
  };

  // Custom function to adjust cell styles
  function customizeCellStyles(data) {
    if (data.row.index === 0 && data.row.section === "head") {
      data.cell.styles.fillColor = [136, 106, 71];
      data.cell.styles.textColor = [255, 255, 255];
    }
    if (
      data.row.index === data.table.body.length - 1 &&
      data.row.section === "body"
    ) {
      data.cell.styles.fillColor = [97, 68, 58];
      data.cell.styles.textColor = [255, 255, 255];
      data.cell.styles.fontStyle = "bold";
    }
    if (data.row.section === "body" && data.column.index === 5) {
      data.cell.styles.halign = "right";
      data.cell.styles.cellWidth = 35;
    }
  }

  //CRUD opeartions start here ----------------------------

  //GET
  const fetchAllInvoices = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`${API}/invoices`, {
        params: {
          limit: rowsPerPage,
          page,
        },
      });

      if (response.data) {
        setInvoices(response.data.invoices);
        setTotalInvoices(response.data.totalInvoices);
        setLoading(false);
        // console.log("Invoices data------", invoices);
      }
    } catch (error) {
      setLoading(false);
      errorAlert("Error getting invoices");
      console.error("Error fetching invoices:", error.response.data);
    }
  };

  //POST - Add invoice
  const handleSubmit = async (event) => {
    const totalAmount = amount * (dollarPrice !== "" ? dollarPrice : 1);

    try {
      // Send the invoiceData to the backend API
      const invoiceData = {
        admin: auth.user._id,
        matter,
        invoiceNumber: invoiceNo,
        invoiceDate: raisedDate,
        collectionDate: collectDate,
        startDate: startSelectedDate,
        endDate: endSelectedDate,
        amount: totalAmount.toFixed(2),
        discountAmount: parseFloat(discountAmount || 0).toFixed(2),
        status: status,
      };

      if (invoiceData.invoiceDate === "" || invoiceData.invoiceNumber === "") {
        errorAlert("Enter both raised invoice date and invoice number");
      } else {
        const response = await axiosInstance.post(
          `${API}/invoices`,
          invoiceData
        );
        if (response.data) {
          successAlert("Invoice created successfully!");
          fetchAllInvoices();
          closeModal();
          // console.log("Invoice created:", response.data);
        }
      }
    } catch (error) {
      // Handle errors, show error messages, etc.
      console.error("Error creating invoice:", error.response.data);
      errorAlert("Error creating invoice", error.response.data);
    }
  };

  //PUT
  const openModal = (invoice) => {
    if (invoice) {
      console.log("Editing invoice data", invoice);
      setEditingInvoice(invoice);
      // console.log("Invoice Edit Data", invoice);
      setInvoiceId(invoice._id);
      setRaisedDate(formatDate(invoice.invoiceDate));
      setCollectDate(
        invoice.collectionDate !== null
          ? formatDate(invoice.collectionDate)
          : ""
      );
      setInvoiceNo(invoice.invoiceNumber);
      setStatus(invoice.status);
      // setInvoiceFile(invoice.invoiceFile);
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditingInvoice(null);
    setInvoiceId("");
    setRaisedDate(formatDate(new Date()));
    setCollectDate("");
    setInvoiceNo("");
    setStatus("pending");
    setDollarPrice("");
    setDiscountAmount("");
    setDownloadWithTS("withTS");
    setMatterType("timespent");
  };

  const handleEditInvoice = async (e) => {
    e.preventDefault();

    const formData = {
      admin: auth.user._id,
      invoiceNumber: invoiceNo,
      invoiceDate: raisedDate,
      collectionDate: collectDate,
      status: status,
      invoiceFile: selectedFile,
    };

    console.log("formdata", formData);

    try {
      // Update existing leave
      const response = await axiosInstance.put(
        `${API}/invoices/${invoiceId}`,
        formData
      );
      if (response.data) {
        console.log(response.data);
        successAlert("Invoice Update Successfully!");
        fetchAllInvoices();
      }
      closeModal();
    } catch (error) {
      console.error("Error:", error);
      errorAlert("Error in updating invoice", error);
    }
  };

  //Filter for UI
  const handleFilterChange = (status) => {
    setFilteredStatus(status);
  };

  const filteredInvoices = filteredStatus
    ? invoices.filter((invoice) => invoice.status === filteredStatus)
    : invoices;

  //DELETE
  const handleInvoice = (id) => {
    // console.log(id);
    toast(
      <ConfirmationAlert
        onDeleteOff={() => onDeleteInvoice(id)}
        question={"Are you sure to delete this invoice?"}
        answer={"Yes, Delete it"}
        icon={<Delete />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onDeleteInvoice = async (invoiceId) => {
    await axios
      .delete(`${API}/invoices/${invoiceId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data) {
          fetchAllInvoices();
          successAlert("Invoice Deleted Successfully!");
        }
      })
      .catch((error) => {
        // console.log(error);
        errorAlert("Deletion of invoice failed", "Please try again...");
      });
  };

  const columns = [
    "Raised Date",
    "Collection Date",
    "Invoice No",
    "Invoice Period",
    "Matter",
    "Total Amount",
    "Discount",
    "Status",
    "Upload Invoice",
    "Edit",
    "Delete",
  ];

  //CRUD Operations End here---------------------------

  return (
    <>
      <ToastContainer />

      <Grid container spacing={2} alignItems="baseline">
        <Grid item>
          <CustomHeading variant="h5" text="Invoices" />
        </Grid>
        <Grid item>
          <CustomTooltip text="Add Invoice">
            <CustomButton
              style={{
                borderRadius: "50%",
                minWidth: "5px",
                height: "30px",
                transition:
                  "transform 0.3s ease-in-out, background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#886a47",
                  transform: "scale(1.1)",
                },
              }}
              onClick={() => openModal(null, "add")}
            >
              <Add />
            </CustomButton>
          </CustomTooltip>
        </Grid>
      </Grid>

      {/* Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h4
              style={{
                width: "100%",
                textAlign: "center",
                padding: 5,
                color: "#886a47",
                fontWeight: 700,
                borderRadius: 10,
              }}
            >
              {editingInvoice ? "Edit" : "Add"} Invoice
            </h4>
            <form>
              {/* 1st Line Inputs  */}
              {!editingInvoice && (
                <Grid
                  container
                  spacing={2}
                  alignItems="baseline"
                  marginBottom="1rem"
                >
                  <Grid item xs={12} sm={7} md={6}>
                    <label> Select Matter * </label>
                    <Select
                      options={filteredMatters}
                      value={filteredMatters.find(
                        (option) =>
                          option.label === `${selectedMatterName} | ${client}`
                      )}
                      onChange={(e) => {
                        setMatter(e.value);
                        setSelectedMatterName(e.label.split("|")[0]);
                        setClient(e.label.split("|")[1]);
                      }}
                      isSearchable
                      placeholder="Select Matter | Client"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={3}>
                    <label> Start Date * </label>
                    <input
                      type="date"
                      className="date"
                      value={startSelectedDate}
                      onChange={(e) => setStartSelectedDate(e.target.value)}
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={3}>
                    <label> End Date * </label>
                    <input
                      type="date"
                      className="date"
                      value={endSelectedDate}
                      onChange={(e) => setEndSelectedDate(e.target.value)}
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    />
                  </Grid>
                </Grid>
              )}

              {/* 2nd Line Inputs  */}
              {!editingInvoice && (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6} sm={6} md={6}>
                    {currency === "USD " && (
                      <input
                        type="number"
                        placeholder="Dollar Price *"
                        value={dollarPrice}
                        onChange={(e) => setDollarPrice(e.target.value)}
                        required
                        disabled={currency === "Rs. " || matter === ""}
                      />
                    )}
                  </Grid>

                  <Grid item xs={6} sm={5} md={currency === "USD " ? 6 : 12}>
                    <CustomButton variant="text" style={{ width: "100%" }}>
                      <BiMoney
                        size={20}
                        style={{ marginRight: "8px", color: "#886a48" }}
                      />
                      <label>
                        Total Amount : {currency}
                        {parseFloat(amount)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      </label>
                    </CustomButton>
                  </Grid>
                </Grid>
              )}

              {/* 3rd Line Inputs  */}
              {!editingInvoice && (
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6}>
                    <input
                      type="number"
                      placeholder="Discount Amount (optional)"
                      value={discountAmount}
                      onChange={(e) => setDiscountAmount(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={6} sm={5} md={6}>
                    <CustomTooltip text="Search Time Spent">
                      <CustomButton
                        variant="outlined"
                        style={{ width: "100%" }}
                        onClick={() => filterBetweenDates()}
                      >
                        <Search style={{ marginRight: "8px" }} />
                        Calculate Total Amount
                      </CustomButton>
                    </CustomTooltip>
                  </Grid>
                </Grid>
              )}

              {/* 4th Line inputs  */}
              <Grid container spacing={2}>
                <Grid item xs={2} sm={2} md={6}>
                  <label>
                    Raised Date *
                    <input
                      style={{ width: "100%" }}
                      type="date"
                      className="date"
                      value={raisedDate}
                      onChange={(e) => setRaisedDate(e.target.value)}
                    />
                  </label>
                </Grid>

                <Grid item xs={2} sm={2} md={6}>
                  <label>
                    Invoice No *
                    <input
                      type="text"
                      // placeholder="Invoice Number *"
                      value={invoiceNo}
                      onChange={(e) => setInvoiceNo(e.target.value)}
                      required
                    />
                  </label>
                </Grid>
              </Grid>

              {/* 5th Line Inputs  */}
              {!editingInvoice && (
                <Grid container spacing={2}>
                  <Grid item xs={2} sm={2} md={6}>
                    <label>
                      Invoice Type *
                      <select
                        value={matterType}
                        onChange={(e) => setMatterType(e.target.value)}
                        style={{ height: "37px" }}
                        required
                      >
                        <option value="timespent">TimeSpent</option>
                        <option value="flatfees">Flat Fees</option>
                      </select>
                    </label>
                  </Grid>

                  <Grid item xs={3} sm={3} md={6}>
                    <label>
                      Invoice Format *
                      <select
                        value={downloadWithTS}
                        onChange={(e) => setDownloadWithTS(e.target.value)}
                        style={{ height: "37px" }}
                        required
                      >
                        <option value="withTS">Download with TimeSpent</option>
                        <option value="withoutTS">
                          Download without Timespent
                        </option>
                      </select>
                    </label>
                  </Grid>
                </Grid>
              )}

              {/* Editing Inputs  */}
              {editingInvoice && (
                <Grid container spacing={2}>
                  <Grid item xs={2} sm={2} md={6}>
                    <label>
                      Collection Date:
                      <input
                        type="date"
                        value={collectDate}
                        onChange={(e) => setCollectDate(e.target.value)}
                      />
                    </label>
                  </Grid>

                  <Grid item xs={2} sm={2} md={6}>
                    <label>
                      Status :
                      <select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        style={{ height: "30px" }}
                      >
                        <option value="pending">Pending</option>
                        <option value="collected">Collected</option>
                        <option value="cancelled">Cancelled</option>
                      </select>
                    </label>
                  </Grid>

                  <Grid item xs={2} sm={2} md={12}>
                    <label>
                      Invoice :
                      <input
                        type="file"
                        accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
                        onChange={handleFileUpload}
                        disabled={uploading}
                      />
                      {selectedFile.map((file, index) => (
                        <div key={index}>
                          <a
                            href={file}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Open File
                          </a>
                          <button
                            type="button"
                            onClick={() => handleRemoveFile(index)}
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                    </label>
                  </Grid>
                </Grid>
              )}

              {/* Submit Buttons  */}
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                {editingInvoice && (
                  <Grid item xs={3} sm={3} md={6}>
                    <CustomButton
                      type="submit"
                      style={{ width: "100%", height: "35px" }}
                      onClick={handleEditInvoice}
                    >
                      Submit
                    </CustomButton>
                  </Grid>
                )}

                {!editingInvoice && (
                  <Grid item xs={3} sm={3} md={6}>
                    <CustomTooltip
                      text={
                        enableDownload
                          ? ""
                          : "Enter all required fields to generate invoice"
                      }
                    >
                      <CustomButton
                        style={{ width: "100%" }}
                        onClick={(event) => {
                          handleClick(event, setAnchorElDownload);
                        }}
                        disabled={!enableDownload}
                      >
                        <Download style={{ marginRight: "8px" }} />
                        Create and Download Invoice
                      </CustomButton>
                    </CustomTooltip>
                    <Menu
                      anchorEl={anchorElDownload}
                      open={Boolean(anchorElDownload)}
                      onClose={() => handleClose(setAnchorElDownload)}
                    >
                      <MenuItem
                        onClick={() => {
                          generateDomesticInvoice();
                          handleSubmit();
                        }}
                      >
                        Domestic Invoice
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          generateOffshoreInvoice();
                          handleSubmit();
                        }}
                      >
                        Offshore Invoice
                      </MenuItem>
                      <MenuItem onClick={() => generateInvoicesReport()}>
                        All Invoices
                      </MenuItem>
                    </Menu>
                  </Grid>
                )}

                <Grid item xs={3} sm={3} md={6}>
                  <CustomButton
                    variant="text"
                    style={{ width: "50%" }}
                    onClick={closeModal}
                  >
                    Cancel
                  </CustomButton>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      )}

      {/* Filters  */}
      {/* <Paper elevation={3} sx={{ padding: 2, marginY: 2 }}>
        <Grid container spacing={2} alignItems="baseline" marginBottom="1rem">
          <Grid item xs={12} sm={7} md={3}>
            <Select
              options={filteredMatters}
              value={filteredMatters.find(
                (option) => option.label === `${selectedMatterName} | ${client}`
              )}
              onChange={(e) => {
                setMatter(e.value);
                setSelectedMatterName(e.label.split("|")[0]);
                setClient(e.label.split("|")[1]);
              }}
              isSearchable
              placeholder="Select Matter *"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={5} md={2}>
            <Select
              options={filteredEmployees}
              value={filteredEmployees.find(
                (resOption) => resOption.label === `${selectedLawyer}`
              )}
              onChange={(e) => {
                setSelectedLawyer(e.value);
              }}
              isSearchable
              placeholder="Resource"
              fullWidth
            />
          </Grid>

          <Grid item xs={6} sm={6} md={2}>
            <input
              type="date"
              className="date"
              value={startSelectedDate}
              onChange={(e) => setStartSelectedDate(e.target.value)}
              style={{
                width: "100%",
                // padding: "0.5rem",
                boxSizing: "border-box",
              }}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={2}>
            <input
              type="date"
              className="date"
              value={endSelectedDate}
              onChange={(e) => setEndSelectedDate(e.target.value)}
              style={{
                width: "100%",
                boxSizing: "border-box",
              }}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <input
              type="text"
              placeholder="Invoice Number *"
              value={invoiceNo}
              onChange={(e) => setInvoiceNo(e.target.value)}
              required
            />
          </Grid>
        </Grid>

        
        <Grid container spacing={2} alignItems="center" marginBottom="1rem">
          <Grid item xs={6} sm={6} md={3}>
            <input
              type="number"
              placeholder="Enter Dollar Price *"
              value={dollarPrice}
              onChange={(e) => setDollarPrice(e.target.value)}
              required
              disabled={currency === "Rs. " || matter === ""}
            />
          </Grid>

          <Grid item xs={2} sm={2} md={2}>
            <select
              value={matterType}
              onChange={(e) => setMatterType(e.target.value)}
              style={{ height: "37px" }}
            >
              <option value="timespent">TimeSpent</option>
              <option value="flatfees">Flat Fees</option>
            </select>
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            display="flex"
            gap="20px"
            alignItems="baseline"
          >
            <div style={{ width: "40%", textAlign: "end" }}>Raised Date * </div>
            <div style={{ width: "60%" }}>
              <input
                style={{ width: "100%" }}
                type="date"
                className="date"
                value={raisedDate}
                onChange={(e) => setRaisedDate(e.target.value)}
              />
            </div>
          </Grid>

          <Grid item xs={3} sm={3} md={3}>
            <select
              value={downloadWithTS}
              onChange={(e) => setDownloadWithTS(e.target.value)}
              style={{ height: "37px" }}
            >
              <option value="withTS">Download with TimeSpent</option>
              <option value="withoutTS">Download without Timespent</option>
            </select>
          </Grid>
        </Grid>

        
        <Grid container spacing={2}>
          <Grid item xs={6} sm={5} md={3}>
            <CustomButton variant="text" style={{ width: "100%" }}>
              <BiMoney size={20} style={{ marginRight: "8px" }} />
              <b>
                Total Amount : {currency}
                {parseFloat(amount)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              </b>
            </CustomButton>
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <input
              type="number"
              placeholder="Enter Discount Amount"
              value={discountAmount}
              onChange={(e) => setDiscountAmount(e.target.value)}
            />
          </Grid>

          <Grid item xs={6} sm={5} md={1.5}>
            <CustomTooltip text="Search Time Spent">
              <CustomButton
                style={{ width: "100%" }}
                onClick={() => {
                  filterBetweenDates();
                  setShow("TimeEntry");
                }}
              >
                <Search style={{ marginRight: "8px" }} />
                TimeSpent
              </CustomButton>
            </CustomTooltip>
          </Grid>

          <Grid item xs={6} sm={5} md={1.5}>
            <CustomTooltip text="Search Invoices">
              <CustomButton
                style={{ width: "100%" }}
                onClick={(event) => {
                  setShow("Invoices");
                }}
              >
                <Search style={{ marginRight: "8px" }} />
               
                Invoices
              </CustomButton>
            </CustomTooltip>
          </Grid>

          <Grid item xs={6} sm={5} md={3}>
            <CustomTooltip
              text={
                enableDownload
                  ? ""
                  : "Enter all required fields to generate invoice"
              }
            >
              <CustomButton
                style={{ width: "100%" }}
                onClick={(event) => handleClick(event, setAnchorElDownload)}
                disabled={!enableDownload}
              >
                <Download style={{ marginRight: "8px" }} />
                Create and Download Invoice
              </CustomButton>
            </CustomTooltip>
            <Menu
              anchorEl={anchorElDownload}
              open={Boolean(anchorElDownload)}
              onClose={() => handleClose(setAnchorElDownload)}
            >
              <MenuItem
                onClick={() => {
                  generateDomesticInvoice();
                  handleSubmit();
                }}
              >
                Domestic Invoice
              </MenuItem>
              <MenuItem
                onClick={() => {
                  generateOffshoreInvoice();
                  handleSubmit();
                }}
              >
                Offshore Invoice
              </MenuItem>
              <MenuItem onClick={() => generateInvoicesReport()}>
                All Invoices
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Paper> */}

      {/* Main Table  */}
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "10px" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100, 500]}
          component="div"
          count={show === "TimeEntry" ? totalWorkHours : totalInvoices}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {/* {show === "Invoices" && ( */}
        <div style={{ margin: "0 0 1rem 1rem" }}>
          <b>Filter By Status: </b>
          <select
            value={filteredStatus || ""}
            onChange={(e) => handleFilterChange(e.target.value || null)}
            style={{ width: "150px", height: "35px", marginLeft: "10px" }}
          >
            <option value="">All</option>
            <option value="pending">Pending</option>
            <option value="collected">Collected</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>
        {/* )} */}

        <TableContainer sx={{ maxHeight: "59vh" }}>
          <Table aria-label="reusable table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell key={index} id="table-header">
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {/* <TableHead>
              <TableRow>
                 <TableCell
                  colSpan={13}
                  style={{ fontWeight: "bold", textAlign: "center" }}
                >
                  {show === "TimeEntry" ? "TIMESPENT" : "INVOICES"}
                </TableCell> 
              </TableRow>
              {/* {show === "TimeEntry" ? (
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Associate</TableCell>
                  <TableCell>Matter</TableCell>
                  <TableCell>Narrative</TableCell>
                  <TableCell>Total Time</TableCell>
                </TableRow>
              ) : ( 
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>
                  Raised Date
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Collection Date
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Invoice No</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Invoice Period
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Matter</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Total Amount
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Discount</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Upload Invoice
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Edit</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Delete</TableCell>
              </TableRow>
            </TableHead> */}
            {loading ? (
              <>
                <SkeletonRows rows={6} colSpan={13} />
              </>
            ) : // :
            // show === "TimeEntry" ? (
            //   filteredWorkHour.length === 0 ? (
            //     <TableRow>
            //       <TableCell colSpan={13}>
            //         <NoDataFound message="Oops! No workhours found." />
            //       </TableCell>
            //     </TableRow>
            //   )
            // : (
            //   filteredWorkHour
            //     .sort((a, b) => new Date(a.workDate) - new Date(b.workDate))
            //     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            //     .map((item, index) => (
            //       <TableRow
            //         key={index}
            //         role="checkbox"
            //         tabIndex={-1}
            //         sx={{
            //           backgroundColor: index % 2 ? "white" : "#eae4dd",
            //           fontFamily: "Prata",
            //         }}
            //       >
            //         <TableCell>{formatMonthDate(item.workDate)}</TableCell>
            //         <TableCell>{item.user?.fullName}</TableCell>
            //         <TableCell>{item.matter?.name}</TableCell>
            //         <TableCell>{item.workDescription}</TableCell>
            //         <TableCell>{item.workHour}</TableCell>
            //       </TableRow>
            //     ))
            // )
            // )
            filteredInvoices.length === 0 ? (
              <TableRow>
                <TableCell colSpan={13}>
                  <NoDataFound message="Oops! No Invoices Found." />
                </TableCell>
              </TableRow>
            ) : (
              filteredInvoices
                .sort(
                  (a, b) => new Date(b.invoiceDate) - new Date(a.invoiceDate)
                )
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((invoice, index) => (
                  <TableRow
                    key={index}
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      backgroundColor: index % 2 ? "white" : "#eae4dd",
                      fontFamily: "Prata",
                    }}
                  >
                    <TableCell>
                      {formatMonthDate(invoice.invoiceDate)}
                    </TableCell>
                    <TableCell>
                      {invoice?.collectionDate
                        ? formatMonthDate(invoice.collectionDate)
                        : "Not yet collected"}
                    </TableCell>
                    <TableCell>{invoice.invoiceNumber}</TableCell>
                    <TableCell>
                      {formatMonthDate(invoice.startDate)} -{" "}
                      {formatMonthDate(invoice.endDate)}
                    </TableCell>
                    <TableCell>{invoice.matter?.name}</TableCell>
                    <TableCell>
                      {parseFloat(invoice.amount.$numberDecimal)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </TableCell>
                    <TableCell>
                      {parseFloat(invoice.discountAmount.$numberDecimal)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </TableCell>
                    <TableCell>{invoice.status}</TableCell>
                    <TableCell>
                      {invoice?.invoiceFile?.length > 0 && (
                        <a
                          href={invoice.invoiceFile}
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <IconButton style={{ color: "black" }}>
                            <Download />
                          </IconButton>
                        </a>
                      )}
                    </TableCell>
                    <TableCell className="table-cell">
                      <Edit
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal(invoice)}
                      />
                    </TableCell>
                    <TableCell className="table-cell">
                      <Delete
                        style={{ cursor: "pointer" }}
                        onClick={() => handleInvoice(invoice._id)}
                      />
                    </TableCell>
                  </TableRow>
                ))
            )}

            {/* {show === "Invoices" && ( */}
            <TableRow
              sx={{
                fontFamily: "Prata",
                backgroundColor: "#372a1b",
                color: "white",
              }}
            >
              <TableCell colSpan={5} style={{ textAlign: "center" }}>
                <b>Total Amount For All Invoices </b>
              </TableCell>
              <TableCell>
                <b>
                  {filteredInvoices
                    .reduce(
                      (total, invoice) =>
                        parseFloat(invoice.amount.$numberDecimal) + total,
                      0
                    )
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </b>
              </TableCell>
              <TableCell colSpan={5}>
                <b>
                  {filteredInvoices
                    .reduce(
                      (total, invoice) =>
                        parseFloat(invoice.discountAmount.$numberDecimal) +
                        total,
                      0
                    )
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </b>
              </TableCell>
            </TableRow>
            {/* )} */}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default Invoice;
