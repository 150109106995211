import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { API, authToken, errorAlert, successAlert } from "../../utils/services";
import {
  Grid,
  TextField,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import CustomHeading from "../../components/common/CustomHeading";
import CustomButton from "../../components/common/CustomButton";
import { UploadFile } from "@mui/icons-material";
import AddHolidays from "../../components/common/AddHolidays";
import AddDesignations from "../../components/common/AddDesignations";

const AddNewCompany = () => {
  const [editMode, setEditMode] = useState(false);
  const [companyData, setCompanyData] = useState({
    companyName: "",
    companyEmail: "",
    companyAddress: "",
    companyLogo: null,
    companyWebsite: "",
    companyAccountsEmail: "",
    companyCorrespondentBankName: "",
    companyCorrespondentSwiftCode: "",
    companyIntermediaryBankClearingCode: "",
    companyIntermediaryAccountNumber: "",
    companyBankSwiftCode: "",
    companyABAFEDNumber: "",
    companyBeneficiaryName: "",
    companyBeneficiaryAccountNumber: "",
    companyBeneficiaryBankName: "",
    companyIFSCCode: "",
    companyGSTIN: "",
    companySAC: "",
    companyPAN: "",
    companyLUT: "",
  });

  const auth = useSelector((state) => state.auth);
  const adminId = auth.user._id;
  const [uploading, setUploading] = useState(false);

  const handleRemoveImage = () => {
    setCompanyData({
      ...companyData,
      companyLogo: null,
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("adminId", auth.user._id);

      try {
        setUploading(true);

        const response = await fetch(`${API}/uploadFile`, {
          method: "POST",
          body: formData,
        });
        const data = await response.json();

        if (response.ok) {
          setCompanyData({
            ...companyData,
            companyLogo: data.url,
          });
        } else {
          console.error(`Failed to upload image: ${data.message}`);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setUploading(false);
      }
    }
  };

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await axios.get(`${API}/company/details/${adminId}`, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        setCompanyData((prevState) => ({ ...prevState, ...response.data }));
      } catch (error) {
        console.error("Failed to fetch company details:", error);
      }
    };

    fetchCompanyDetails();
  }, [adminId]);

  const handleChange = (e) => {
    setCompanyData({ ...companyData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await axios.post(
        `${API}/company/details/${adminId}`,
        companyData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (result) {
        successAlert("Company details updated successfully!");
      }
      setEditMode(false);
    } catch (error) {
      console.error("Error updating company details:", error);
      errorAlert("Failed to update company details.");
    }
  };

  return (
    <div>
      {editMode ? (
        <>
          <CustomHeading variant="h5" text="Update Company Details" />

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Company Name"
                  name="companyName"
                  value={companyData.companyName}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Company Email"
                  name="companyEmail"
                  type="email"
                  value={companyData.companyEmail}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Company Accounts Email"
                  name="companyAccountsEmail"
                  type="email"
                  value={companyData.companyAccountsEmail}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Company Website"
                  name="companyWebsite"
                  value={companyData.companyWebsite}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Company Address"
                  name="companyAddress"
                  value={companyData.companyAddress}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Correspondent Bank Name"
                  name="companyCorrespondentBankName"
                  value={companyData.companyCorrespondentBankName}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Correspondent Swift Code"
                  name="companyCorrespondentSwiftCode"
                  value={companyData.companyCorrespondentSwiftCode}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Intermediary Bank Clearing Code"
                  name="companyIntermediaryBankClearingCode"
                  value={companyData.companyIntermediaryBankClearingCode}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Intermediary Account Number"
                  name="companyIntermediaryAccountNumber"
                  value={companyData.companyIntermediaryAccountNumber}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Bank Swift Code"
                  name="companyBankSwiftCode"
                  value={companyData.companyBankSwiftCode}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="ABA/FED Number"
                  name="companyABAFEDNumber"
                  value={companyData.companyABAFEDNumber}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Beneficiary Name"
                  name="companyBeneficiaryName"
                  value={companyData.companyBeneficiaryName}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Beneficiary Account Number"
                  name="companyBeneficiaryAccountNumber"
                  value={companyData.companyBeneficiaryAccountNumber}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Beneficiary Bank Name"
                  name="companyBeneficiaryBankName"
                  value={companyData.companyBeneficiaryBankName}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="IFSC Code"
                  name="companyIFSCCode"
                  value={companyData.companyIFSCCode}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="GSTIN"
                  name="companyGSTIN"
                  value={companyData.companyGSTIN}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="SAC"
                  name="companySAC"
                  value={companyData.companySAC}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="PAN"
                  name="companyPAN"
                  value={companyData.companyPAN}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Letter of Undertaking Number"
                  name="companyLUT"
                  value={companyData.companyLUT}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>

              {/* buttons  */}
              <Grid item xs={12}>
                <input
                  type="file"
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                  id="companyLogo"
                />
                <label htmlFor="companyLogo">
                  <CustomButton
                    variant="contained"
                    component="span"
                    disabled={uploading}
                  >
                    <UploadFile style={{ marginRight: "8px" }} />
                    Upload Logo
                  </CustomButton>
                </label>
              </Grid>
              {companyData.companyLogo && (
                <Grid item xs={12}>
                  <Card>
                    <CardMedia
                      component="img"
                      alt="Company Logo"
                      height="140"
                      image={companyData.companyLogo}
                    />
                  </Card>
                  <CustomButton
                    variant="outlined"
                    style={{ width: "20%", marginTop: "10px" }}
                    onClick={handleRemoveImage}
                  >
                    Remove Logo
                  </CustomButton>
                </Grid>
              )}
              <Grid item xs={12}>
                <CustomButton type="submit" variant="contained" color="primary">
                  Save
                </CustomButton>
                <CustomButton
                  variant="outlined"
                  onClick={() => setEditMode(false)}
                  style={{ marginLeft: "10px", width: "30%" }}
                >
                  Cancel
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </>
      ) : (
        <Card>
          <CardContent>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <CustomHeading align="center" text="Company Details" />
              </Grid>
              {companyData.companyLogo && (
                <Grid item xs={12}>
                  <CardMedia
                    component="img"
                    alt="Company Logo"
                    height="140"
                    image={companyData.companyLogo}
                    title="Company Logo"
                    style={{ objectFit: "contain" }}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Name:</Typography>
                <Typography>{companyData.companyName}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Email:</Typography>
                <Typography>{companyData.companyEmail}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Accounts Email:</Typography>
                <Typography>{companyData.companyAccountsEmail}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Website:</Typography>
                <Typography>{companyData.companyWebsite}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Address:</Typography>
                <Typography>{companyData.companyAddress}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Correspondent Bank Name:</Typography>
                <Typography>
                  {companyData.companyCorrespondentBankName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Correspondent Swift Code:</Typography>
                <Typography>
                  {companyData.companyCorrespondentSwiftCode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  Intermediary Bank Clearing Code:
                </Typography>
                <Typography>
                  {companyData.companyIntermediaryBankClearingCode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  Intermediary Account Number:
                </Typography>
                <Typography>
                  {companyData.companyIntermediaryAccountNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Bank Swift Code:</Typography>
                <Typography>{companyData.companyBankSwiftCode}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">ABA/FED Number:</Typography>
                <Typography>{companyData.companyABAFEDNumber}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Beneficiary Name:</Typography>
                <Typography>{companyData.companyBeneficiaryName}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  Beneficiary Account Number:
                </Typography>
                <Typography>
                  {companyData.companyBeneficiaryAccountNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Beneficiary Bank Name:</Typography>
                <Typography>
                  {companyData.companyBeneficiaryBankName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">IFSC Code:</Typography>
                <Typography>{companyData.companyIFSCCode}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">GSTIN:</Typography>
                <Typography>{companyData.companyGSTIN}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">SAC:</Typography>
                <Typography>{companyData.companySAC}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">PAN:</Typography>
                <Typography>{companyData.companyPAN}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">LUT:</Typography>
                <Typography>{companyData.companyLUT}</Typography>
              </Grid>
              <Grid item xs={12} md={12} display="flex" justifyContent="center">
                <CustomButton
                  style={{ width: "20%" }}
                  onClick={() => setEditMode(true)}
                >
                  Edit
                </CustomButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      <div style={{ marginTop: "20px" }}>
        <AddHolidays />
      </div>

      <div style={{ marginTop: "20px" }}>
        <AddDesignations />
      </div>
    </div>
  );
};

export default AddNewCompany;
