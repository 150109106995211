import React, { useCallback, useEffect, useState } from "react";
import formatDate, {
  errorAlert,
  formatMonthDate,
  successAlert,
  API,
} from "../../utils/services";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  Button,
  Tooltip,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axiosInstance from "../../helpers/axios";
import CustomHeading from "../../components/common/CustomHeading";
import SkeletonRows from "../../components/common/CustomSkeletonLoading";
import CustomButton from "../../components/common/CustomButton";
import NoDataFound from "../../components/common/NoDataFound";

const ResourceLeave = () => {
  const auth = useSelector((state) => state.auth);
  const theme = useTheme();

  //breakpoints
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [leaves, setLeaves] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [totalLeavesCount, setTotalLeavesCount] = useState(0);

  //modal states
  const [modalStartDate, setModalStartDate] = useState(formatDate(new Date()));
  const [modalEndDate, setModalEndDate] = useState(formatDate(new Date()));
  const [modalLeaveDescription, setModalLeaveDescription] = useState("");
  const [editingLeave, setEditingLeave] = useState(null);
  const [leaveType, setLeaveType] = useState("");

  //pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  //get leaves
  const fetchAllLeaves = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`${API}/leaves/my-leaves`, {
        params: {
          limit: rowsPerPage,
          page,
        },
      });
      setLeaves(response.data.leavesWithDays);
      setTotalLeavesCount(response.data.totalLeavesCount);
      setLoading(false);
      // console.log("Resource Leaves data------", response.data.leavesWithDays);
    } catch (error) {
      setLoading(false);

      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      // console.error("Error fetching leaves:", errorMessage);
      errorAlert(errorMessage);
      setLeaves([]);
    }
  }, [rowsPerPage, page]);

  useEffect(() => {
    fetchAllLeaves();
  }, [fetchAllLeaves, page, rowsPerPage]);

  //pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Modal functions
  const openModal = (leave) => {
    if (leave) {
      setEditingLeave(leave);
      setModalStartDate(leave.leaveStartDate);
      setModalEndDate(leave.leaveEndDate);
      setModalLeaveDescription(leave.leaveDescription);
      // console.log("leave data", leave, editingLeave)
    } else {
      setEditingLeave(null);
      setModalStartDate(formatDate(new Date()));
      setModalEndDate(formatDate(new Date()));
      setModalLeaveDescription("");
    }
    setShowModal(true);
  };

  //modal form validation
  const modalIsValidForm = () => {
    if (modalStartDate === "" || modalEndDate === "") {
      errorAlert("Start Date and End Date are required!!");
      return false;
    } else if (!(modalStartDate <= modalEndDate)) {
      errorAlert("Select Correct Dates!!");
      return false;
    } else {
      return true;
    }
  };

  // console.log(auth.user.fullName);

  //add and update leave function
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (modalIsValidForm()) {
      const formData = {
        admin: auth.user.company,
        user: auth.user._id,
        fullName: auth.user.fullName,
        leaveStartDate: modalStartDate,
        leaveEndDate: modalEndDate,
        leaveDescription: modalLeaveDescription,
        leaveType: leaveType,
        leaveStatus: "pending",
        paidLeave: "yes",
      };

      // console.log(formData);

      try {
        if (editingLeave) {
          // Update existing leave
          const response = await axiosInstance.put(
            `${API}/leaves/${editingLeave._id}`,
            formData
          );
          if (response.data) {
            successAlert(response.data.message);
            setShowModal(false);
            fetchAllLeaves();
          }
        } else {
          // Add new leave
          const response = await axiosInstance.post(`${API}/leaves`, formData);
          if (response.data) {
            successAlert(response.data.message);
            setShowModal(false);
            fetchAllLeaves();
          }
        }
      } catch (error) {
        errorAlert(
          error.response.data.message ||
            (editingLeave
              ? "Error updating leave!! Try again..."
              : "Error creating leave!! Try again...")
        );
      }
    }
  };

  const userJson = localStorage.getItem("user");
  const user = JSON.parse(userJson);
  // console.log(user.totalLeaves);

  return (
    <>
      <ToastContainer />
      {/* Main Heading  */}
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <CustomHeading variant="h5" text="Leaves" />
        </Grid>
        <Grid item>
          <Tooltip title={"Add Time"}>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#886a47",
                borderRadius: "50%",
                width: "35px",
                height: "35px",
                minWidth: "35px",
                marginBottom: "10px",
                transition:
                  "transform 0.3s ease-in-out, background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#886a47",
                  transform: "scale(1.1)",
                },
              }}
              onClick={() => openModal(null)}
            >
              <AddIcon />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>

      {/* Add / Edit Modal  */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <CustomHeading
              align="center"
              text={editingLeave ? "Edit Leave" : "Add Leave"}
            />
            <form onSubmit={handleSubmit}>
              <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                marginTop={1}
                marginBottom={1}
              >
                <Grid item sm={12} md={6} width="100%">
                  <label>Start Date*</label>
                  <input
                    type="date"
                    value={modalStartDate}
                    onChange={(e) => setModalStartDate(e.target.value)}
                  />
                </Grid>
                <Grid item sm={12} md={6} width="100%">
                  <label>End Date* </label>
                  <input
                    type="date"
                    value={modalEndDate}
                    onChange={(e) => setModalEndDate(e.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                marginBottom={1}
              >
                <Grid item sm={12} md={12} width="100%">
                  <select
                    style={{ width: "100%" }}
                    value={leaveType}
                    name="leaveType"
                    onChange={(event) => setLeaveType(event.target.value)}
                  >
                    <option value="">Select Leave Type</option>
                    <option value="leave">Leave</option>
                    <option value="workFromHome">Work From Home</option>
                  </select>
                </Grid>
              </Grid>

              <textarea
                style={{ width: "100%", padding: "5px" }}
                type="text"
                placeholder="Leave description"
                value={modalLeaveDescription}
                onChange={(e) => setModalLeaveDescription(e.target.value)}
              />

              <Box
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <CustomButton type="submit">Submit</CustomButton>
                <CustomButton
                  variant="text"
                  style={{ width: "20%" }}
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </CustomButton>
              </Box>
            </form>
          </div>
        </div>
      )}

      {/* Total Table  */}
      <Paper
        sx={{
          width: isMediumScreen ? "76vw" : "100%",
          overflow: "hidden",
          marginTop: 3,
        }}
      >
        <TableContainer>
          <Table aria-label="reusable table">
            <TableHead>
              {/* columns row  */}
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Assigned</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Used</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Available</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={1}>Leaves</TableCell>
                <TableCell>{user.totalLeaves}</TableCell>
                <TableCell>
                  {leaves
                    .filter((entry) => entry.leaveType !== "workFromHome")
                    .reduce(
                      (total, curr) => total + parseFloat(curr.leaveDays),
                      0
                    )}
                </TableCell>
                <TableCell>
                  {user.totalLeaves -
                    leaves
                      .filter((entry) => entry.leaveType !== "workFromHome")
                      .reduce(
                        (total, curr) => total + parseFloat(curr.leaveDays),
                        0
                      )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={1}>Work From Home</TableCell>
                <TableCell>{user.totalWorkFromHome}</TableCell>
                <TableCell>
                  {leaves
                    .filter((entry) => entry.leaveType === "workFromHome")
                    .reduce(
                      (total, curr) => total + parseFloat(curr.leaveDays),
                      0
                    )}
                </TableCell>
                <TableCell>
                  {user.totalWorkFromHome -
                    leaves
                      .filter((entry) => entry.leaveType === "workFromHome")
                      .reduce(
                        (total, curr) => total + parseFloat(curr.leaveDays),
                        0
                      )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Leave Entries  */}
      <Paper
        sx={{
          width: isMediumScreen ? "76vw" : "100%",
          overflow: "hidden",
          marginTop: 3,
        }}
      >
        <TableContainer sx={{ maxHeight: "75vh" }}>
          <Table aria-label="reusable table">
            <TableHead>
              {/* columns row  */}
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Date</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Leave Type</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Days</TableCell>
                {!isMediumScreen && (
                  <TableCell
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Narration
                  </TableCell>
                )}
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Paid Leave</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <SkeletonRows rows={6} colSpan={11} />
              ) : leaves.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={11}>
                    <NoDataFound message="Oops! No Leaves Found." />
                  </TableCell>
                </TableRow>
              ) : (
                leaves.map((leave, index) => (
                  <TableRow
                    key={leave._id}
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      backgroundColor: index % 2 ? "white" : "#eae4dd",
                      fontFamily: "Prata",
                    }}
                  >
                    <TableCell>
                      {leave.leaveStartDate === leave.leaveEndDate
                        ? formatMonthDate(leave.leaveStartDate)
                        : `${formatMonthDate(leave.leaveStartDate)} -
                        ${formatMonthDate(leave.leaveEndDate)}`}
                    </TableCell>
                    <TableCell>
                      {leave.leaveType === "workFromHome"
                        ? "Work From Home"
                        : leave.leaveType === "quarterDay"
                        ? "Quarter Day"
                        : leave.leaveType === "halfDay"
                        ? "Half Day"
                        : "Leave"}
                    </TableCell>
                    <TableCell>{leave.leaveDays}</TableCell>
                    {!isMediumScreen && (
                      <TableCell className="table-cell">
                        {leave.leaveDescription}
                      </TableCell>
                    )}

                    <TableCell
                      style={{
                        color:
                          leave.leaveStatus === "approved"
                            ? "green"
                            : leave.leaveStatus === "pending"
                            ? "orange"
                            : "red",
                        fontWeight: "bold",
                        padding: "10px",
                      }}
                    >
                      {leave.leaveStatus === "pending"
                        ? "Pending"
                        : leave.leaveStatus === "approved"
                        ? "Approved"
                        : "Rejected"}
                    </TableCell>
                    <TableCell>
                      {leave.paidLeave === "yes" && "Paid"}
                      {leave.paidLeave === "no" && "Not Paid"}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalLeavesCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default ResourceLeave;

//TODO : User - Apply leave - Mail to HR
//Status for leave request
//Admin - Resource wise table +  Leave entries table
//
