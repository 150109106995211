import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Select from "react-select";
import { addTime } from "../../actions/index.js";
import { filterTimeByUserIdForAdmin } from "../../actions/admin.time.action.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import formatDate, {
  API,
  errorAlert,
  formatLetterDate,
  formatMonthDate,
  successAlert,
} from "../../utils/services.js";
import { BiDuplicate } from "react-icons/bi";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Download, Search } from "@mui/icons-material";
import CustomButton from "../../components/common/CustomButton.js";
import CustomHeading from "../../components/common/CustomHeading.js";
import { useMattersOptions } from "../../helpers/hooks/useMattersOptions.js";
import { useEmployeeOptions } from "../../helpers/hooks/useEmployeesOptions.js";
import SkeletonRows from "../../components/common/CustomSkeletonLoading.js";
import axiosInstance from "../../helpers/axios.js";
import { useTSEmployees } from "../../helpers/hooks/useTSEmployees.js";
import NoDataFound from "../../components/common/NoDataFound.js";

const ExportExcelSheet = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { matters, filteredMatters } = useMattersOptions();
  const { filteredEmployees } = useEmployeeOptions();
  const auth = useSelector((state) => state.auth);
  const adminTime = useSelector((state) => state.adminTime);
  const writeoff = useSelector((state) => state.writeoff);

  const { writeOffHours } = writeoff;
  const { filterLoading, filteredWorkHour, totalFilteredWorkHours } = adminTime;
  const { commonNames } = useTSEmployees(filteredWorkHour);
  const needFin = false;
  const adminId = auth?.user?.company;
  let sendMail = "false";

  //states
  const [client, setClient] = useState("");
  const [matter, setMatter] = useState(""); //matter id
  const [selectedMatterName, setSelectedMatterName] = useState(""); //matter name
  const [selectedLawyer, setSelectedLawyer] = useState("");
  const [startSelectedDate, setStartSelectedDate] = useState(
    formatDate(new Date())
  );
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );

  //pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showModal, setShowModal] = useState(false);

  //modal states
  const [date, setDate] = useState(formatDate(new Date()));
  const [modalMatter, setModalMatter] = useState("");
  const [modalClient, setModalClient] = useState("");
  const [modalSelectedMatterName, setModalSelectedMatterName] = useState("");
  const [workDescription, setWorkDescription] = useState("");
  const [comments, setComments] = useState("");
  const [workHour, setWorkHour] = useState("0");
  const [workMin, setWorkMin] = useState("30");
  const [companyData, setCompanyData] = useState({
    companyName: "",
    companyEmail: "",
    companyAddress: "",
    companyLogo: null,
  });

  const formattedStartDate = formatLetterDate(startSelectedDate);
  const formattedEndDate = formatLetterDate(endSelectedDate);

  //breakpoints
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const fetchCompanyDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `${API}/company/details/${adminId}`
      );

      // Set other company details as well
      setCompanyData({
        companyName: response.data.companyName || "",
        companyEmail: response.data.companyEmail || "",
        companyAddress: response.data.companyAddress || "",
        companyLogo: response.data.companyLogo || "",
        companyWebsite: response.data.companyWebsite || "",
        companyAccountsEmail: response.data.companyAccountsEmail || "",
      });
    } catch (error) {
      errorAlert("Error in fetching company details!!");
    }
  }, [adminId]);

  const filterBetweenDates = useCallback(() => {
    const role = "";
    dispatch(
      filterTimeByUserIdForAdmin(
        selectedLawyer,
        startSelectedDate,
        endSelectedDate,
        matter,
        needFin,
        sendMail,
        adminId,
        rowsPerPage,
        page,
        role
      )
    );
  }, [
    dispatch,
    selectedLawyer,
    startSelectedDate,
    endSelectedDate,
    matter,
    needFin,
    sendMail,
    adminId,
    rowsPerPage,
    page,
  ]);

  useEffect(() => {
    filterBetweenDates();
    fetchCompanyDetails();
  }, [dispatch, fetchCompanyDetails, filterBetweenDates]);

  //pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    // Ensure company data is defined
    const link1Text = companyData.companyAccountsEmail || "";
    const link1URL = `mailto:${companyData.companyAccountsEmail || ""}`;
    const link2Text = companyData.companyWebsite || "";
    const link2URL = companyData.companyWebsite || "";

    let currency;
    const matterCurr = matters.find((m) => m._id === matter);
    if (matterCurr) {
      currency = matterCurr.currency === "USD" ? "$" : "Rs. ";
    } else {
      currency = "No";
    }

    // Header fixed for every new page
    const pageWidth = doc.internal.pageSize.getWidth();
    const rightMargin = 15; // You can adjust the right margin as needed
    const logoDesiredHeight = 20; // Set the desired height for the logo
    const logoYPosition = 10; // Set the Y position for the logo
    const logoXPosition = 13; // Set the X position for the logo

    function addHeader() {
      // Set the font style, size, and position of the header text
      const fontSize = 8;

      doc.setFont("helvetica", "normal");
      doc.setFontSize(fontSize);

      // Add the image to the header
      if (companyData.companyLogo) {
        doc.addImage(
          companyData.companyLogo,
          "PNG",
          logoXPosition,
          logoYPosition,
          0,
          logoDesiredHeight
        );
      }

      // right header
      doc.setTextColor(46, 115, 176);

      if (link2Text) {
        const link2Width = doc.getTextWidth(link2Text);
        const link2X = pageWidth - link2Width - rightMargin;
        doc.textWithLink(link2Text, link2X, 19, {
          url: link2URL,
          underline: true,
        });
      }

      if (link1Text) {
        const link1Width = doc.getTextWidth(link1Text);
        const link1X = pageWidth - link1Width - rightMargin;
        doc.textWithLink(link1Text, link1X, 22, {
          url: link1URL,
          underline: true,
        });
      }

      doc.setTextColor(0);

      if (companyData.companyAddress) {
        const addressWidth = doc.getTextWidth(companyData.companyAddress);
        const addressX = pageWidth - addressWidth - rightMargin;
        doc.text(companyData.companyAddress, addressX, 26);
      }
    }

    const Timespent = `/assets/Timespent.jpg`;

    doc.addImage(Timespent, "JPEG", 0, 0, 210, 297);
    if (companyData.companyLogo) {
      doc.addImage(
        companyData.companyLogo,
        "PNG",
        logoXPosition,
        logoYPosition,
        0,
        logoDesiredHeight
      );
    }

    const fontSize = 20;
    doc.setFontSize(fontSize);

    // Ensure client and selectedMatterName are defined
    const clientName = client || "";
    const matterName = selectedMatterName || "";

    // Center alignment width calculations
    const clientNameLength = (210 - doc.getTextWidth(clientName)) / 2;
    const matterNameLength = (210 - doc.getTextWidth(matterName)) / 2;

    const dateRangeLength =
      (210 - doc.getTextWidth(`${formattedStartDate} - ${formattedEndDate}`)) /
      2;

    // Client name
    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(20);
    doc.text(clientName, clientNameLength, 120);

    // Matter name
    doc.setFontSize(20);
    doc.setFont("helvetica", "normal");
    doc.text(matterName, matterNameLength, 130);

    // Horizontal line
    doc.setLineWidth(0.3);
    doc.setDrawColor(136, 106, 71);
    doc.line(40, 145, 180, 145);

    // Date range
    doc.setFont("helvetica", "italic");
    doc.setTextColor(0, 0, 0); // Black color
    doc.setFontSize(15);
    doc.text(
      `${formattedStartDate} - ${formattedEndDate}`,
      dateRangeLength + 10,
      160
    );

    // ----------------2nd Page --------------------

    doc.addPage();
    addHeader();
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");

    doc.text("Dear Sir / Ma'am", 15, 40);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.text(
      `${clientName} - ${matterName} From Period ${formatMonthDate(
        startSelectedDate
      )} To ${formatMonthDate(endSelectedDate)}`,
      14,
      50
    );
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.text(
      "Please find enclosed our memorandum of time-spent on the given matter for the said period.",
      15,
      60
    );

    // Table 1 Page 2 Data
    const doubleUsedTableColumns = [
      "Associate Name",
      "Total Time",
      "Billed Time",
      "Hourly Rate",
      "Total Amount",
    ];

    const doubleUsedTableData = [];

    let teamTotalHours = 0;
    let teamTotalBilledHours = 0;
    let teamTotalBilledAmount = 0;
    let persontotalHours = 0;

    commonNames.forEach((name) => {
      let personWorkHours = 0;
      let personWriteoffHours = 0;
      filteredWorkHour &&
        filteredWorkHour
          .filter(
            (result) => result.user?.fullName === name && result.role !== "OPE"
          )
          .forEach((result) => {
            const workHour = parseFloat(result.workHour);
            personWorkHours += workHour;
          });

      if (writeOffHours) {
        writeOffHours
          .filter(
            (result) =>
              result.user?.fullName === name &&
              result.matter === matter &&
              result.workDate >= startSelectedDate &&
              result.workDate <= endSelectedDate
          )
          .forEach((result) => {
            const workHour = parseFloat(result.workHour);
            personWriteoffHours += workHour;
          });
      }

      persontotalHours =
        parseFloat(personWorkHours) + parseFloat(personWriteoffHours);

      const newMatter = matters.find(
        (matter) => matter?.name.trim() === selectedMatterName.trim()
      );

      let price;
      if (newMatter) {
        if (newMatter.pricePerHour) {
          price = newMatter.pricePerHour.$numberDecimal;
        } else if (newMatter.resourceSpecificPrice.length !== 0) {
          const employeeEntry = newMatter.resourceSpecificPrice.find((entry) =>
            entry.includes(name)
          );

          if (employeeEntry) {
            const [, hours] = employeeEntry.split(":");
            price = hours.trim();
          }
        } else {
          price = 0;
        }
      }

      if (price) {
        let personTotalBilledAmount = price * personWorkHours;
        teamTotalHours += persontotalHours;
        teamTotalBilledHours += personWorkHours;
        teamTotalBilledAmount += personTotalBilledAmount;

        const data1 = [
          name,
          persontotalHours.toFixed(2),
          personWorkHours.toFixed(2),
          currency + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          currency +
            personTotalBilledAmount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        ];
        doubleUsedTableData.push(data1);
      }
    });

    // Add footer row
    const footerRow = [
      {
        content: "Total Hours",
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      {
        content: teamTotalHours.toFixed(2),
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      {
        content: teamTotalBilledHours.toFixed(2),
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      {
        content: "",
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      {
        content:
          currency +
          teamTotalBilledAmount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
    ];

    doubleUsedTableData.push(footerRow);

    // Main Table declaration
    const startY = 70; // Starting Y-coordinate for the table

    doc.autoTable(doubleUsedTableColumns, doubleUsedTableData, {
      startY,
      theme: "grid",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,

      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          // Header row styles
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.halign = "left"; // Align header text to the left
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.fontSize = 9;
        } else if (data.row.section === "body") {
          // Body row styles
          if (data.column.index !== 0) {
            // Right-align all columns except the first column
            data.cell.styles.halign = "right";
          }
        } else if (data.row.index === doubleUsedTableData.length - 1) {
          // Footer row align
          data.cell.styles.halign = "right"; // Align footer text to the right
          data.cell.styles.fillColor = [226, 213, 203];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        } else if (
          data.row.index === doubleUsedTableData.length - 1 &&
          data.row.section === "foot"
        ) {
          // Footer row styles
          data.cell.styles.fillColor = [226, 213, 203];
          data.cell.styles.textColor = [255, 255, 255];
        }

        // Set padding for table data cells
        if (
          data.row.section === "body" &&
          data.column.index === 0 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 1 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 2 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 3 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 4 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
      },
    });

    // Calculate the table height
    const tableHeight = doc.autoTable.previous.finalY - startY;

    // OPE logic
    let ope = 0;
    ope += filteredWorkHour[0]?.amount?.$numberDecimal;
    const opeAmount = ope ? ope : 0;

    doc.text(
      "Summary of payable on this matter is provided below ",
      15,
      startY + tableHeight + 10
    );
    doc.text("Time-spent accured", 15, startY + tableHeight + 18);
    doc.text(
      currency +
        teamTotalBilledAmount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      210 -
        doc.getTextWidth(`${currency} ${teamTotalBilledAmount.toFixed(2)}`) -
        15,
      startY + tableHeight + 18
    );
    doc.text("Less: Discount on time spent", 15, startY + tableHeight + 26);
    doc.text(
      currency + "0.00",
      210 - doc.getTextWidth(currency + "0.00") - 15,
      startY + tableHeight + 26
    );
    doc.text(
      "Less: % Additional discount on time spent",
      15,
      startY + tableHeight + 33
    );
    doc.text(
      "0.00",
      210 - doc.getTextWidth("0.00") - 15,
      startY + tableHeight + 33
    );
    doc.text(
      "Add: Out of pocket expenses and other costs, if any",
      15,
      startY + tableHeight + 41
    );
    doc.text(
      currency + opeAmount,
      210 - doc.getTextWidth(`${currency} ${opeAmount}`) - 15,
      startY + tableHeight + 41
    );
    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.text(
      "Total Amount for the above period",
      15,
      startY + tableHeight + 49
    );
    doc.text(
      currency +
        teamTotalBilledAmount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      doc.internal.pageSize.getWidth() -
        doc.getTextWidth(`${currency} ${teamTotalBilledAmount.toFixed(2)}`) -
        15,
      startY + tableHeight + 49
    );

    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");

    doc.text(
      "Please do let us know if any changes are required. Please note that this is only a statement of timespent for ",
      15,
      startY + tableHeight + 67
    );
    doc.text(
      "your reference and not an invoice. The invoice shall be raised subsequently in the manner agreed with you.",
      15,
      startY + tableHeight + 72
    );

    doc.text("Yours Sincerely,", 15, startY + tableHeight + 82);
    doc.setFont("helvetica", "bold");
    doc.text("Resolut Partners", 15, startY + tableHeight + 87);
    doc.setFont("helvetica", "normal");

    // 3rd Page
    doc.addPage();
    addHeader();

    let startY1 = 35;

    commonNames.forEach((name, index) => {
      const personData =
        filteredWorkHour &&
        filteredWorkHour
          .filter((result) => result.user?.fullName === name)
          .sort((a, b) => new Date(a.workDate) - new Date(b.workDate)); // Sort the data by workDate in ascending order

      // Add table header row with person's name
      const headerRow = [
        {
          content: name,
          colSpan: 2,
          styles: {
            cellWidth: "auto", // Set cell width to auto to allow content alignment
            halign: "left",
            fillColor: [136, 106, 71],
            textColor: [255, 255, 255],
            fontStyle: "bold", // Set the font style to bold
          },
        },
        {
          content: "Total Time",
          styles: {
            cellWidth: "auto", // Set cell width to auto to allow content alignment
            halign: "left",
            fillColor: [136, 106, 71],
            textColor: [255, 255, 255],
            fontStyle: "bold", // Set the font style to bold
          },
        },
        {
          content: "Billed Time",
          styles: {
            cellWidth: "auto", // Set cell width to auto to allow content alignment
            halign: "left",
            fillColor: [136, 106, 71],
            textColor: [255, 255, 255],
            fontStyle: "bold", // Set the font style to bold
          },
        },
      ];

      const tableRows = [headerRow]; // Initialize tableRows with the header row

      // Add individual data rows for the person
      personData.forEach((result) => {
        const data = [
          formatMonthDate(result.workDate),
          result.workDescription.trim(" "),
          result.workHour,
          result.workHour,
        ];
        tableRows.push(data);
      });

      // Calculate total time
      const totalTime = personData.reduce(
        (total, result) => total + parseFloat(result.workHour),
        0
      );

      // Add footer row with total time
      const footerRow = [
        {
          content: "Total Time",
          colSpan: 2,
          styles: {
            halign: "left",
            fillColor: [97, 68, 58],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        },
        {
          content: totalTime.toFixed(2),
          styles: {
            halign: "right",
            fillColor: [97, 68, 58],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        },
        {
          content: totalTime.toFixed(2),
          styles: {
            halign: "right",
            fillColor: [97, 68, 58],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        },
      ];
      tableRows.push(footerRow);

      // Remove empty rows and columns from tableRows
      const filteredTableRows = tableRows.filter((row) =>
        row.some((cell) => cell !== "")
      );

      // Generate table for the current person
      doc.autoTable({
        body: filteredTableRows,
        startY: startY1,
        theme: "grid",
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.1,
        didParseCell: function (data) {
          if (data.row.index === 0 && data.row.section === "head") {
            // Header row styles
            data.cell.styles.fillColor = [136, 106, 71];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.halign = "left";
            data.cell.styles.valign = "left"; // Align the text vertically at the center
            data.cell.styles.fontStyle = "bold"; // Set the font style to bold
          } else if (
            data.row.index === filteredTableRows.length - 1 &&
            data.row.section === "body"
          ) {
            // Footer row styles
            data.cell.styles.fillColor = [97, 68, 58];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.fontStyle = "bold";
          } else if (data.row.section === "body") {
            // Body row styles
            if (data.column.index !== 1) {
              // Right-align all columns except the first column
              data.cell.styles.halign = "left";
            }
          }

          // Set padding for table data cells
          if (
            data.row.section === "body" &&
            data.column.index === 0 &&
            data.row.index !== 0 &&
            data.row.index !== tableRows.length - 1
          ) {
            data.cell.styles.cellPadding = {
              top: 3,
              right: 2,
              bottom: 3,
              left: 3,
            };
            data.cell.styles.fontSize = 9;
            data.cell.styles.halign = "left";
            data.cell.styles.cellWidth = 20;
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 1 &&
            data.row.index !== 0 &&
            data.row.index !== tableRows.length - 1
          ) {
            data.cell.styles.cellPadding = {
              top: 3,
              right: 4,
              bottom: 3,
              left: 4,
            };
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "times";
            data.cell.styles.halign = "left";
            data.cell.styles.cellWidth = 122; // Set the width to 100 units
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 2 &&
            data.row.index !== 0 &&
            data.row.index !== tableRows.length - 1
          ) {
            data.cell.styles.cellPadding = {
              top: 3,
              right: 2,
              bottom: 3,
              left: 2,
            };
            data.cell.styles.halign = "right";
            data.cell.styles.fontSize = 9;
            data.cell.styles.cellWidth = 20;
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 3 &&
            data.row.index !== 0 &&
            data.row.index !== tableRows.length - 1
          ) {
            data.cell.styles.cellPadding = {
              top: 3,
              right: 2,
              bottom: 3,
              left: 2,
            };
            data.cell.styles.halign = "right";
            data.cell.styles.fontSize = 9;
            data.cell.styles.cellWidth = 20;
            data.cell.styles.textColor = [0, 0, 0];
          }
        },
      });

      startY1 = doc.lastAutoTable.finalY + 10; // Update startY for the next table
    });

    doc.save(
      `${clientName} - ${matterName} From Period ${formatMonthDate(
        startSelectedDate
      )} To ${formatMonthDate(endSelectedDate)}.pdf`
    );
  };

  // const generatePDF = () => {
  //   const doc = new jsPDF();

  //   const link1Text = companyData.companyAccountsEmail;
  //   const link1URL = `mailto:${companyData.companyAccountsEmail}`;
  //   const link2Text = companyData.companyWebsite;
  //   const link2URL = companyData.companyWebsite;

  //   let currency;
  //   const matterCurr = matters.find((m) => m._id === matter);
  //   if (matterCurr) {
  //     currency = matterCurr.currency === "USD" ? "$" : "Rs. ";
  //   } else {
  //     currency = "No";
  //   }

  //   //Header fixed for every new page

  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   const rightMargin = 15; // You can adjust the right margin as needed
  //   const logoDesiredHeight = 20; // Set the desired height for the logo
  //   const logoYPosition = 10; // Set the Y position for the logo
  //   const logoXPosition = 13; // Set the X position for the logo

  //   function addHeader() {
  //     // Set the font style, size, and position of the header text
  //     const fontSize = 8;

  //     doc.setFont("helvetica", "normal");
  //     doc.setFontSize(fontSize);

  //     // Add the image to the header
  //     // doc.addImage(companyData.companyLogo, "PNG", 15, 10, 70, 9);
  //     doc.addImage(
  //       companyData.companyLogo,
  //       "PNG",
  //       logoXPosition,
  //       logoYPosition,
  //       0,
  //       logoDesiredHeight
  //     );

  //     // right header
  //     doc.setTextColor(46, 115, 176);

  //     const link2Width = doc.getTextWidth(link2Text);
  //     const link2X = pageWidth - link2Width - rightMargin;
  //     doc.textWithLink(link2Text, link2X, 19, {
  //       url: link2URL,
  //       underline: true,
  //     });

  //     // Set link color to blue and add underline
  //     const link1Width = doc.getTextWidth(link1Text);
  //     const link1X = pageWidth - link1Width - rightMargin;
  //     doc.textWithLink(link1Text, link1X, 22, {
  //       url: link1URL,
  //       underline: true,
  //     });

  //     doc.setTextColor(0);

  //     // Calculate text width and position to align it right
  //     const addressWidth = doc.getTextWidth(companyData.companyAddress);
  //     const addressX = pageWidth - addressWidth - rightMargin;
  //     doc.text(companyData.companyAddress, addressX, 26);

  //     // Reset text color to black
  //   }

  //   const Timespent = `/assets/Timespent.jpg`;

  //   doc.addImage(Timespent, "JPEG", 0, 0, 210, 297);
  //   doc.addImage(
  //     companyData.companyLogo,
  //     "PNG",
  //     logoXPosition,
  //     logoYPosition,
  //     0,
  //     logoDesiredHeight
  //   );

  //   const fontSize = 20;
  //   doc.setFontSize(fontSize);

  //   //center alignment width calculations
  //   const clientNameLength = (210 - doc.getTextWidth(client)) / 2;
  //   const matterNameLength = (210 - doc.getTextWidth(selectedMatterName)) / 2;

  //   const dateRangeLength =
  //     (210 - doc.getTextWidth(`${formattedStartDate} - ${formattedEndDate}`)) /
  //     2;

  //   //client name
  //   doc.setFont("helvetica", "bold");
  //   doc.setTextColor(136, 106, 71);
  //   doc.setFontSize(20);
  //   doc.text(client, clientNameLength, 120);

  //   //matter name
  //   doc.setFontSize(20);
  //   doc.setFont("helvetica", "normal");
  //   doc.text(selectedMatterName, matterNameLength, 130);

  //   //horizontal line
  //   doc.setLineWidth(0.3);
  //   doc.setDrawColor(136, 106, 71);
  //   doc.line(40, 145, 180, 145);

  //   //date range
  //   doc.setFont("helvetica", "italic");
  //   doc.setTextColor(0, 0, 0); // Black color
  //   doc.setFontSize(15);
  //   doc.text(
  //     `${formattedStartDate} - ${formattedEndDate}`,
  //     dateRangeLength + 10,
  //     160
  //   );

  //   // ----------------2nd Page --------------------

  //   doc.addPage();
  //   addHeader();
  //   doc.setFontSize(10);
  //   doc.setFont("helvetica", "normal");

  //   doc.text("Dear Sir / Ma'am", 15, 40);
  //   doc.setFont("helvetica", "bold");
  //   doc.setTextColor(136, 106, 71);
  //   doc.text(
  //     `${client} - ${selectedMatterName} From Period ${formatMonthDate(
  //       startSelectedDate
  //     )} To ${formatMonthDate(endSelectedDate)}`,
  //     14,
  //     50
  //   );
  //   doc.setTextColor(0, 0, 0);
  //   doc.setFont("helvetica", "normal");
  //   doc.text(
  //     "Please find enclosed our memorandum of time-spent on the given matter for the said period.",
  //     15,
  //     60
  //   );

  //   //---------------------------------Table 1 Duplicate Table Page 2----------------------------

  //   //Table 1 Page 2 Data

  //   const doubleUsedTableColumns = [
  //     "Associate Name",
  //     "Total Time",
  //     "Billed Time",
  //     "Hourly Rate",
  //     "Total Amount",
  //   ];

  //   const doubleUsedTableData = [];

  //   let teamTotalHours = 0;
  //   let teamTotalBilledHours = 0;
  //   let teamTotalBilledAmount = 0;
  //   let persontotalHours = 0;

  //   commonNames.forEach((name) => {
  //     let personWorkHours = 0;
  //     let personWriteoffHours = 0;
  //     filteredWorkHour &&
  //       filteredWorkHour
  //         .filter(
  //           (result) => result.user?.fullName === name && result.role !== "OPE"
  //         )
  //         .forEach((result) => {
  //           const workHour = parseFloat(result.workHour);
  //           personWorkHours += workHour;
  //         });

  //     if (writeOffHours) {
  //       writeOffHours
  //         .filter(
  //           (result) =>
  //             result.user?.fullName === name &&
  //             result.matter === matter &&
  //             result.workDate >= startSelectedDate && // Add start date filter
  //             result.workDate <= endSelectedDate // Add end date filter
  //         )
  //         .forEach((result) => {
  //           const workHour = parseFloat(result.workHour);
  //           personWriteoffHours += workHour;
  //         });
  //     }

  //     persontotalHours =
  //       parseFloat(personWorkHours) + parseFloat(personWriteoffHours);

  //     const newMatter = matters.find(
  //       (matter) => matter?.name.trim() === selectedMatterName.trim()
  //     );

  //     let price;
  //     if (newMatter) {
  //       if (newMatter.pricePerHour) {
  //         price = newMatter.pricePerHour.$numberDecimal;
  //       } else if (newMatter.resourceSpecificPrice.length !== 0) {
  //         const employeeEntry = newMatter.resourceSpecificPrice.find((entry) =>
  //           entry.includes(name)
  //         );

  //         if (employeeEntry) {
  //           const [, hours] = employeeEntry.split(":");
  //           price = hours.trim();
  //         }
  //       } else {
  //         price = 0;
  //       }
  //     }

  //     if (price) {
  //       let personTotalBilledAmount = price * personWorkHours;
  //       teamTotalHours += persontotalHours;
  //       teamTotalBilledHours += personWorkHours;
  //       teamTotalBilledAmount += personTotalBilledAmount;

  //       const data1 = [
  //         name,
  //         persontotalHours.toFixed(2),
  //         personWorkHours.toFixed(2),
  //         currency + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  //         currency +
  //           personTotalBilledAmount
  //             .toFixed(2)
  //             .toString()
  //             .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  //       ];
  //       doubleUsedTableData.push(data1);
  //     }
  //   });

  //   // Add footer row
  //   const footerRow = [
  //     {
  //       content: "Total Hours",
  //       styles: {
  //         fillColor: [97, 68, 58],
  //         textColor: [255, 255, 255],
  //         fontStyle: "bold",
  //       },
  //     },
  //     {
  //       content: teamTotalHours.toFixed(2),
  //       styles: {
  //         fillColor: [97, 68, 58],
  //         textColor: [255, 255, 255],
  //         fontStyle: "bold",
  //       },
  //     },
  //     {
  //       content: teamTotalBilledHours.toFixed(2),
  //       styles: {
  //         fillColor: [97, 68, 58],
  //         textColor: [255, 255, 255],
  //         fontStyle: "bold",
  //       },
  //     },
  //     {
  //       content: "",
  //       styles: {
  //         fillColor: [97, 68, 58],
  //         textColor: [255, 255, 255],
  //         fontStyle: "bold",
  //       },
  //     },
  //     {
  //       content:
  //         currency +
  //         teamTotalBilledAmount
  //           .toFixed(2)
  //           .toString()
  //           .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  //       styles: {
  //         fillColor: [97, 68, 58],
  //         textColor: [255, 255, 255],
  //         fontStyle: "bold",
  //       },
  //     },
  //   ];

  //   doubleUsedTableData.push(footerRow);

  //   // ----------------------------------Main Table declaration ------------------------
  //   const startY = 70; // Starting Y-coordinate for the table

  //   doc.autoTable(doubleUsedTableColumns, doubleUsedTableData, {
  //     startY,
  //     theme: "grid",
  //     tableLineColor: [0, 0, 0],
  //     tableLineWidth: 0.1,

  //     didParseCell: function (data) {
  //       if (data.row.index === 0 && data.row.section === "head") {
  //         // Header row styles
  //         data.cell.styles.fillColor = [136, 106, 71];
  //         data.cell.styles.textColor = [255, 255, 255];
  //         data.cell.styles.halign = "left"; // Align header text to the left
  //         data.cell.styles.fontStyle = "bold";
  //         data.cell.styles.fontSize = 9;
  //       } else if (data.row.section === "body") {
  //         // Body row styles
  //         if (data.column.index !== 0) {
  //           // Right-align all columns except the first column
  //           data.cell.styles.halign = "right";
  //         }
  //       } else if (data.row.index === doubleUsedTableData.length - 1) {
  //         // Footer row align
  //         data.cell.styles.halign = "right"; // Align footer text to the right
  //         data.cell.styles.fillColor = [226, 213, 203];
  //         data.cell.styles.textColor = [255, 255, 255];
  //         data.cell.styles.fontStyle = "bold";
  //       } else if (
  //         data.row.index === doubleUsedTableData.length - 1 &&
  //         data.row.section === "foot"
  //       ) {
  //         // Footer row styles
  //         data.cell.styles.fillColor = [226, 213, 203];
  //         data.cell.styles.textColor = [255, 255, 255];
  //       }

  //       // Set padding for table data cells
  //       if (
  //         data.row.section === "body" &&
  //         data.column.index === 0 &&
  //         data.row.index !== -1 &&
  //         data.row.index !== doubleUsedTableData.length - 1
  //       ) {
  //         data.cell.styles.fontSize = 9;
  //         data.cell.styles.fontStyle = "arial";
  //         data.cell.styles.textColor = [0, 0, 0];
  //       }
  //       if (
  //         data.row.section === "body" &&
  //         data.column.index === 1 &&
  //         data.row.index !== -1 &&
  //         data.row.index !== doubleUsedTableData.length - 1
  //       ) {
  //         data.cell.styles.fontSize = 9;
  //         data.cell.styles.fontStyle = "arial";
  //         data.cell.styles.textColor = [0, 0, 0];
  //       }
  //       if (
  //         data.row.section === "body" &&
  //         data.column.index === 2 &&
  //         data.row.index !== -1 &&
  //         data.row.index !== doubleUsedTableData.length - 1
  //       ) {
  //         data.cell.styles.fontSize = 9;
  //         data.cell.styles.fontStyle = "arial";
  //         data.cell.styles.textColor = [0, 0, 0];
  //       }
  //       if (
  //         data.row.section === "body" &&
  //         data.column.index === 3 &&
  //         data.row.index !== -1 &&
  //         data.row.index !== doubleUsedTableData.length - 1
  //       ) {
  //         data.cell.styles.fontSize = 9;
  //         data.cell.styles.fontStyle = "arial";
  //         data.cell.styles.textColor = [0, 0, 0];
  //       }
  //       if (
  //         data.row.section === "body" &&
  //         data.column.index === 4 &&
  //         data.row.index !== -1 &&
  //         data.row.index !== doubleUsedTableData.length - 1
  //       ) {
  //         data.cell.styles.fontSize = 9;
  //         data.cell.styles.fontStyle = "arial";
  //         data.cell.styles.textColor = [0, 0, 0];
  //       }
  //     },
  //   });

  //   // Calculate the table height
  //   const tableHeight = doc.autoTable.previous.finalY - startY;

  //   //ope logic is like for now it is showing me only one entry of ope so i directly set accordign to that
  //   let ope = 0;
  //   ope += filteredWorkHour[0]?.amount?.$numberDecimal;
  //   const opeAmount = ope ? ope : 0;

  //   doc.text(
  //     "Summary of payable on this matter is provided below ",
  //     15,
  //     startY + tableHeight + 10
  //   );
  //   doc.text("Time-spent accured", 15, startY + tableHeight + 18);
  //   // doc.textAlign("right");
  //   doc.text(
  //     currency +
  //       teamTotalBilledAmount
  //         .toFixed(2)
  //         .toString()
  //         .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  //     210 -
  //       doc.getTextWidth(`${currency} ${teamTotalBilledAmount.toFixed(2)}`) -
  //       15,
  //     startY + tableHeight + 18
  //   );
  //   doc.text("Less: Discount on time spent", 15, startY + tableHeight + 26);
  //   doc.text(
  //     currency + "0.00",
  //     210 - doc.getTextWidth(currency + "0.00") - 15,
  //     startY + tableHeight + 26
  //   );
  //   doc.text(
  //     "Less: % Additional discount on time spent",
  //     15,
  //     startY + tableHeight + 33
  //   );
  //   doc.text(
  //     "0.00",
  //     210 - doc.getTextWidth("0.00") - 15,
  //     startY + tableHeight + 33
  //   );
  //   doc.text(
  //     "Add: Out of pocket expenses and other costs, if any",
  //     15,
  //     startY + tableHeight + 41
  //   );
  //   doc.text(
  //     currency + opeAmount,
  //     210 - doc.getTextWidth(`${currency} ${opeAmount}`) - 15,
  //     startY + tableHeight + 41
  //   );
  //   doc.setFont("helvetica", "bold");
  //   doc.setTextColor(136, 106, 71);
  //   doc.text(
  //     "Total Amount for the above period",
  //     15,
  //     startY + tableHeight + 49
  //   );
  //   doc.text(
  //     currency +
  //       teamTotalBilledAmount
  //         .toFixed(2)
  //         .toString()
  //         .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  //     doc.internal.pageSize.getWidth() -
  //       doc.getTextWidth(`${currency} ${teamTotalBilledAmount.toFixed(2)}`) -
  //       15,
  //     startY + tableHeight + 49
  //   );

  //   doc.setTextColor(0, 0, 0);
  //   doc.setFont("helvetica", "normal");

  //   doc.text(
  //     "Please do let us know if any changes are required. Please note that this is only a statement of timespent for ",
  //     15,
  //     startY + tableHeight + 67
  //   );
  //   doc.text(
  //     "your reference and not an invoice. The invoice shall be raised subsequently in the manner agreed with you.",
  //     15,
  //     startY + tableHeight + 72
  //   );

  //   doc.text("Yours Sincerely,", 15, startY + tableHeight + 82);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("Resolut Partners", 15, startY + tableHeight + 87);
  //   doc.setFont("helvetica", "normal");

  //   // -------------------------3rd Page------------------------
  //   doc.addPage();
  //   addHeader();

  //   // Assume you have the TTF font file in the 'fontFile' variable
  //   // const customFont = {
  //   //   fontFamily: "TitilliumWebLight",
  //   //   fontStyle: "normal",
  //   //   fontFile:
  //   //     "../../../../../assets/fonts/Titillium_Web/TitilliumWeb-Light.ttf.ttf", // Replace with the actual TTF font file
  //   // };

  //   // // Convert the font file to a data URI
  //   // const fontDataUri =
  //   //   "data:font/truetype;base64," + btoa(customFont.fontFile);

  //   // doc.addFont(fontDataUri, customFont.fontFamily, customFont.fontStyle);
  //   // doc.setFont(customFont.fontFamily);

  //   let startY1 = 35;

  //   commonNames.forEach((name, index) => {
  //     const personData =
  //       filteredWorkHour &&
  //       filteredWorkHour
  //         .filter((result) => result.user?.fullName === name)
  //         .sort((a, b) => new Date(a.workDate) - new Date(b.workDate)); // Sort the data by workDate in ascending order

  //     // Add table header row with person's name
  //     const headerRow = [
  //       {
  //         content: name,
  //         colSpan: 2,
  //         styles: {
  //           cellWidth: "auto", // Set cell width to auto to allow content alignment
  //           halign: "left",
  //           fillColor: [136, 106, 71],
  //           textColor: [255, 255, 255],
  //           fontStyle: "bold", // Set the font style to bold
  //         },
  //       },
  //       {
  //         content: "Total Time",
  //         styles: {
  //           cellWidth: "auto", // Set cell width to auto to allow content alignment
  //           halign: "left",
  //           fillColor: [136, 106, 71],
  //           textColor: [255, 255, 255],
  //           fontStyle: "bold", // Set the font style to bold
  //         },
  //       },

  //       {
  //         content: "Billed Time",
  //         styles: {
  //           cellWidth: "auto", // Set cell width to auto to allow content alignment
  //           halign: "left",
  //           fillColor: [136, 106, 71],
  //           textColor: [255, 255, 255],
  //           fontStyle: "bold", // Set the font style to bold
  //         },
  //       },
  //     ];

  //     const tableRows = [headerRow]; // Initialize tableRows with the header row

  //     // Add individual data rows for the person
  //     personData.forEach((result) => {
  //       const data = [
  //         formatMonthDate(result.workDate),
  //         result.workDescription.trim(" "),
  //         result.workHour,
  //         result.workHour,
  //       ];
  //       tableRows.push(data);
  //     });

  //     // Calculate total time
  //     const totalTime = personData.reduce(
  //       (total, result) => total + parseFloat(result.workHour),
  //       0
  //     );

  //     // Add footer row with total time
  //     const footerRow = [
  //       {
  //         content: "Total Time",
  //         colSpan: 2,
  //         styles: {
  //           halign: "left",
  //           fillColor: [97, 68, 58],
  //           textColor: [255, 255, 255],
  //           fontStyle: "bold",
  //         },
  //       },
  //       {
  //         content: totalTime.toFixed(2),
  //         styles: {
  //           halign: "right",
  //           fillColor: [97, 68, 58],
  //           textColor: [255, 255, 255],
  //           fontStyle: "bold",
  //         },
  //       },
  //       {
  //         content: totalTime.toFixed(2),
  //         styles: {
  //           halign: "right",
  //           fillColor: [97, 68, 58],
  //           textColor: [255, 255, 255],
  //           fontStyle: "bold",
  //         },
  //       },
  //     ];
  //     tableRows.push(footerRow);

  //     // Remove empty rows and columns from tableRows
  //     const filteredTableRows = tableRows.filter((row) =>
  //       row.some((cell) => cell !== "")
  //     );

  //     // Generate table for the current person
  //     doc.autoTable({
  //       body: filteredTableRows,
  //       startY: startY1,
  //       theme: "grid",
  //       tableLineColor: [0, 0, 0],
  //       tableLineWidth: 0.1,
  //       didParseCell: function (data) {
  //         if (data.row.index === 0 && data.row.section === "head") {
  //           // Header row styles
  //           data.cell.styles.fillColor = [136, 106, 71];
  //           data.cell.styles.textColor = [255, 255, 255];
  //           data.cell.styles.halign = "left";
  //           data.cell.styles.valign = "left"; // Align the text vertically at the center
  //           data.cell.styles.fontStyle = "bold"; // Set the font style to bold
  //         } else if (
  //           data.row.index === filteredTableRows.length - 1 &&
  //           data.row.section === "body"
  //         ) {
  //           // Footer row styles
  //           data.cell.styles.fillColor = [97, 68, 58];
  //           data.cell.styles.textColor = [255, 255, 255];
  //           // data.cell.styles.halign = "right";
  //           data.cell.styles.fontStyle = "bold";
  //         } else if (data.row.section === "body") {
  //           // Body row styles
  //           if (data.column.index !== 1) {
  //             // Right-align all columns except the first column
  //             data.cell.styles.halign = "left";
  //           }
  //         }

  //         // Set padding for table data cells
  //         if (
  //           data.row.section === "body" &&
  //           data.column.index === 0 &&
  //           data.row.index !== 0 &&
  //           data.row.index !== tableRows.length - 1
  //         ) {
  //           data.cell.styles.cellPadding = {
  //             top: 3,
  //             right: 2,
  //             bottom: 3,
  //             left: 3,
  //           };
  //           data.cell.styles.fontSize = 9;
  //           // data.cell.styles.fontStyle = customFont.fontStyle;
  //           data.cell.styles.halign = "left";
  //           data.cell.styles.cellWidth = 20;
  //           data.cell.styles.textColor = [0, 0, 0];
  //         }
  //         if (
  //           data.row.section === "body" &&
  //           data.column.index === 1 &&
  //           data.row.index !== 0 &&
  //           data.row.index !== tableRows.length - 1
  //         ) {
  //           data.cell.styles.cellPadding = {
  //             top: 3,
  //             right: 4,
  //             bottom: 3,
  //             left: 4,
  //           };
  //           data.cell.styles.fontSize = 9;
  //           data.cell.styles.fontStyle = "times";
  //           data.cell.styles.halign = "left";
  //           data.cell.styles.cellWidth = 122; // Set the width to 100 units
  //           data.cell.styles.textColor = [0, 0, 0];
  //         }
  //         if (
  //           data.row.section === "body" &&
  //           data.column.index === 2 &&
  //           data.row.index !== 0 &&
  //           data.row.index !== tableRows.length - 1
  //         ) {
  //           data.cell.styles.cellPadding = {
  //             top: 3,
  //             right: 2,
  //             bottom: 3,
  //             left: 2,
  //           };
  //           data.cell.styles.halign = "right";
  //           data.cell.styles.fontSize = 9;
  //           // data.cell.styles.fontStyle = customFont.fontStyle;
  //           data.cell.styles.cellWidth = 20;
  //           data.cell.styles.textColor = [0, 0, 0];
  //         }
  //         if (
  //           data.row.section === "body" &&
  //           data.column.index === 3 &&
  //           data.row.index !== 0 &&
  //           data.row.index !== tableRows.length - 1
  //         ) {
  //           data.cell.styles.cellPadding = {
  //             top: 3,
  //             right: 2,
  //             bottom: 3,
  //             left: 2,
  //           };
  //           data.cell.styles.halign = "right";
  //           data.cell.styles.fontSize = 9;
  //           // data.cell.styles.fontStyle = customFont.fontStyle;
  //           data.cell.styles.cellWidth = 20;
  //           data.cell.styles.textColor = [0, 0, 0];
  //         }
  //       },
  //     });

  //     startY1 = doc.lastAutoTable.finalY + 10; // Update startY for the next table
  //   });

  //   doc.save(
  //     `${client} - ${selectedMatterName} From Period ${formatMonthDate(
  //       startSelectedDate
  //     )} To ${formatMonthDate(endSelectedDate)}.pdf`
  //   );
  // };

  const openModal = (time) => {
    setShowModal(true);

    setDate(time.workDate);
    setWorkDescription(time.workDescription);
    setModalMatter(time.matter);
    setModalSelectedMatterName(time.matter.name);
    setComments(time.comments);
    const hour = time.workHour.split(".");
    setWorkHour(hour[0]);
    setWorkMin(Math.floor((hour[1] * 6) / 10));
  };

  const closeModal = () => {
    setShowModal(false);

    setDate(new Date());
    setWorkDescription("");
    setModalMatter("");
    setModalSelectedMatterName("");
    setModalClient("");
    setComments("");
    setWorkHour(0);
    setWorkMin(30);
  };

  //Form Validation
  const isValidObjField = () => {
    if (
      date === "" ||
      modalMatter === "" ||
      workDescription === "" ||
      workHour === "00" ||
      workMin === "00"
    ) {
      return false;
    } else {
      return true;
    }
    // return Object.values(obj).every(value => value.trim())
  };

  const isValidForm = () => {
    //all fields must be filled
    if (!isValidObjField()) return errorAlert("All Fields are Required!");

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValidForm()) {
      //submit login data
      const time = {
        admin: adminId,
        user: auth.user._id,
        workDate: date,
        matter: modalMatter,
        workDescription,
        comments,
        workHour: `${workHour}.${workMin * (10 / 6)}`,
        category: "billable",
      };
      dispatch(await addTime(auth, time));
      successAlert("Great !! Time Added Successfully");
      closeModal();
    }
  };

  return (
    <>
      <ToastContainer />

      {/* Modal  */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h4
              style={{
                width: "100%",
                // backgroundColor: "#886a47",
                textAlign: "center",
                padding: 5,
                color: "#886a47",
                fontWeight: 700,
                borderRadius: 10,
              }}
            >
              DUPLICATE TIME
            </h4>
            <form>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                  width: "100%",
                  marginTop: 30,
                }}
              >
                <div style={{ width: "50%" }}>
                  <label>Date</label>
                  <div style={{ width: "100%" }}>
                    <input
                      type="date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                </div>

                <div style={{ width: "50%" }}>
                  <label>
                    {" "}
                    {"Selected Matter : " + modalSelectedMatterName}
                  </label>

                  <div style={{ width: "100%" }}>
                    <Select
                      options={filteredMatters}
                      value={filteredMatters.find(
                        (option) =>
                          option.label ===
                          `${modalSelectedMatterName} | ${modalClient}`
                      )}
                      onChange={(e) => {
                        setModalMatter(e.value);
                        setModalSelectedMatterName(e.label.split("|")[0]);
                        setModalClient(e.label.split("|")[1]);
                      }}
                      isSearchable
                      placeholder="Search Matter | Client"
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: 20,
                  marginTop: 15,
                  width: "100%",
                }}
              >
                <div style={{ width: "50%" }}>
                  <label>Work Hours</label>
                  <div style={{ width: "100%" }}>
                    <select
                      style={{ width: "100%", padding: 10 }}
                      value={workHour}
                      name="workHour"
                      onChange={(event) => {
                        setWorkHour(event.target.value);
                        // console.log(event.target.value);
                      }}
                    >
                      {Array.from({ length: 19 }, (_, i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div style={{ width: "50%", gap: 15 }}>
                  <label>Work Minutes</label>
                  <div style={{ width: "100%" }}>
                    <select
                      style={{ width: "100%", padding: 10 }}
                      value={workMin}
                      name="workMin"
                      onChange={(event) => {
                        // console.log(event.target.value);
                        setWorkMin(event.target.value);
                      }}
                    >
                      {Array.from({ length: 10 }, (_, i) => (
                        <option key={i * 6} value={i * 6}>
                          {i * 6}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <label>
                Work Description
                <textarea
                  style={{ height: 100, width: "100%" }}
                  type="text"
                  placeholder="Enter work description"
                  value={workDescription}
                  onChange={(e) => setWorkDescription(e.target.value)}
                />
              </label>
              <div>
                <label>
                  Comments
                  <input
                    style={{ width: "100%", height: 50 }}
                    type="text"
                    placeholder="Enter comments for the reviewer"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </label>
              </div>

              <div style={{ textAlign: "center" }}>
                <CustomButton onClick={handleSubmit} style={{ width: "30%" }}>
                  Submit
                </CustomButton>
                <CustomButton
                  variant="text"
                  style={{ width: "30%" }}
                  onClick={closeModal}
                >
                  Cancel
                </CustomButton>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Main Heading  */}
      <Grid container>
        <Grid item>
          <CustomHeading variant="h5" text="Timespent" />
        </Grid>
      </Grid>

      {/* Filters  */}
      <Paper
        elevation={3}
        sx={{ padding: 2, marginY: 2, width: isMediumScreen ? "78vw" : "100%" }}
      >
        <Grid container spacing={1} alignItems="baseline" marginBottom="1rem">
          <Grid item xs={12} sm={7} md={3}>
            <Select
              options={filteredMatters}
              value={filteredMatters.find(
                (option) => option.label === `${selectedMatterName} | ${client}`
              )}
              onChange={(e) => {
                setMatter(e.value);
                setSelectedMatterName(e.label.split("|")[0]);
                setClient(e.label.split("|")[1]);
              }}
              isSearchable
              placeholder="Search Matter | Client"
            />
          </Grid>

          <Grid item xs={12} sm={5} md={2}>
            <Select
              options={filteredEmployees}
              value={filteredEmployees.find(
                (resOption) => resOption.label === `${selectedLawyer}`
              )}
              onChange={(e) => {
                setSelectedLawyer(e.value);
                // console.log(e.label);
              }}
              isSearchable
              placeholder="Resource"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <input
              type="date"
              className="date"
              value={startSelectedDate}
              onChange={(e) => setStartSelectedDate(e.target.value)}
              style={{
                width: "100%",
                boxSizing: "border-box",
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <input
              type="date"
              className="date"
              value={endSelectedDate}
              onChange={(e) => setEndSelectedDate(e.target.value)}
              style={{
                width: "100%",
                boxSizing: "border-box",
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={1.5}>
            <CustomButton
              style={{ width: "100%", height: "40px", fontWeight: "300" }}
              onClick={filterBetweenDates}
            >
              Search <Search style={{ marginLeft: "8px" }} />
            </CustomButton>
          </Grid>

          <Grid item xs={12} sm={6} md={1.5}>
            <CustomButton
              style={{ width: "100%", height: "40px", fontWeight: "300" }}
              onClick={generatePDF}
            >
              Download
              <Download style={{ marginLeft: "8px" }} />
            </CustomButton>
          </Grid>
        </Grid>
      </Paper>

      {/* Main Table  */}
      <Paper
        sx={{ width: isMediumScreen ? "78vw" : "100%", overflow: "hidden" }}
      >
        <TableContainer sx={{ maxHeight: "75vh" }}>
          <Table aria-label="reusable table">
            <TableHead>
              {/* columns row  */}
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Date</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Associate</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Matter</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Narrative</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Total Time</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Duplicate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterLoading ? (
                <>
                  <SkeletonRows rows={4} colSpan={11} />
                </>
              ) : filteredWorkHour.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={11}>
                    <NoDataFound />
                  </TableCell>
                </TableRow>
              ) : (
                filteredWorkHour.map((item, index) => (
                  <TableRow
                    key={item._id}
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      backgroundColor: index % 2 ? "white" : "#eae4dd",
                      fontFamily: "Prata",
                    }}
                  >
                    <TableCell>{formatMonthDate(item.workDate)}</TableCell>
                    <TableCell>{item.user?.fullName}</TableCell>
                    <TableCell>{item.matter?.name}</TableCell>
                    <TableCell>{item.workDescription}</TableCell>
                    <TableCell>{item.workHour}</TableCell>

                    <TableCell className="table-cell">
                      <BiDuplicate
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal(item, "edit")}
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100, 500, 1000]}
          component="div"
          count={totalFilteredWorkHours}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default ExportExcelSheet;
