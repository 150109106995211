import axios from "axios";
import { timeConstants } from "./constants";
import { API } from "../utils/services";

export const updateWorkHoursWithDiscount = (entryId, discountHours) => {
  return async (dispatch) => {
    try {
      const authToken = localStorage.getItem("authToken");
      dispatch({ type: timeConstants.UPDATE_WORKHOURS_WITH_DISCOUNT_REQUEST });

      const response = await axios.put(
        `${API}/workhour/admin/updateWithDiscount`,
        { entryId, updatedWorkHours: discountHours }, // Pass discountHours as 'updatedWorkHours'
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      dispatch({
        type: timeConstants.UPDATE_WORKHOURS_WITH_DISCOUNT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error("Error in updating work hours with discount", error);
      dispatch({
        type: timeConstants.UPDATE_WORKHOURS_WITH_DISCOUNT_FAILURE,
        payload: error.message,
      });
    }
  };
};



// new code which i will change to 


// timeActions.js
// import axios from "axios";
// import { timeConstants } from "./constants";
// import { API } from "../utils/services";

// export const applyDiscount = (entryId, discountHours) => {
//   return async (dispatch) => {
//     try {
//       const authToken = localStorage.getItem("authToken");
//       dispatch({ type: timeConstants.APPLY_DISCOUNT_REQUEST });

//       const response = await axios.put(
//         `${API}/workhour/admin/updateWithDiscount`,
//         { entryId, updatedWorkHours: discountHours },
//         {
//           headers: {
//             Authorization: `Bearer ${authToken}`,
//           },
//         }
//       );

//       if (response.data) {
//         dispatch({
//           type: timeConstants.APPLY_DISCOUNT_SUCCESS,
//           payload: {
//             entryId,
//             workHour: response.data.workHourEntry.workHour,
//             message: response.data.message,
//           },
//         });
//       } else {
//         dispatch({
//           type: timeConstants.APPLY_DISCOUNT_FAILURE,
//           payload: "Applying discount failed",
//         });
//       }
//     } catch (error) {
//       dispatch({
//         type: timeConstants.APPLY_DISCOUNT_FAILURE,
//         payload: error.message,
//       });
//     }
//   };
// };





// // timeReducer.js
// import { timeConstants } from "./constants";

// const initialState = {
//   // Define your initial state properties here
// };

// const timeReducer = (state = initialState, action) => {
//   switch (action.type) {
//     // ... other cases

//     case timeConstants.APPLY_DISCOUNT_REQUEST:
//       return {
//         ...state,
//         // Update state properties for request
//       };
//     case timeConstants.APPLY_DISCOUNT_SUCCESS:
//       return {
//         ...state,
//         filteredWorkHour: state.filteredWorkHour.map((entry) =>
//           entry._id === action.payload.entryId
//             ? { ...entry, workHour: action.payload.workHour }
//             : entry
//         ),
//         // Add other state updates if needed
//       };
//     case timeConstants.APPLY_DISCOUNT_FAILURE:
//       return {
//         ...state,
//         // Update state properties for failure
//       };
//     default:
//       return state;
//   }
// };

// export default timeReducer;
