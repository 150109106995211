import { matterConstants } from "./constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API } from "../utils/services";

export const addNewMatter = (form) => {
  return async (dispatch) => {
    const authToken = localStorage.getItem("authToken");
    dispatch({ type: matterConstants.ADD_NEW_MATTER_REQUEST });
    try {
      const response = await fetch(`/matter/createNew`, {
        method: "POST",
        body: form,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        const responseData = await response.json();
        dispatch({
          type: matterConstants.ADD_NEW_MATTER_FAILURE,
          payload: {
            error: responseData.message || "Matter name should not be same",
          },
        });
        toast.error({
          text1: responseData.message || "Matter name should not be same",
        });
        return;
      }

      const responseData = await response.json();
      // console.log(responseData.message);
      dispatch({
        type: matterConstants.ADD_NEW_MATTER_SUCCESS,
      });
      toast.success({
        text1: responseData.message,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };
};

export const updateMatter = (form, authToken, matterId) => {
  // console.log("Frontend Action Creator:", form, authToken, matterId);
  return async (dispatch) => {
    dispatch({ type: matterConstants.UPDATE_NEW_MATTER_REQUEST });
    try {
      const response = await fetch(`${API}/matter/updateMatter/${matterId}`, {
        method: "PUT",
        body: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json", // Set content type to JSON
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        dispatch({
          type: matterConstants.UPDATE_NEW_MATTER_FAILURE,
          payload: { error: errorResponse.message },
        });
        // console.log(errorResponse.message)
        toast.error({
          text1: errorResponse.message,
        });
        return;
      }

      const responseData = await response.json();
      dispatch({
        type: matterConstants.UPDATE_NEW_MATTER_SUCCESS,
        payload: responseData,
      });
      toast.success({
        text1: responseData.message,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };
};
