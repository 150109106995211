import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { RiDeleteBin6Line } from "react-icons/ri";
// import { CiEdit } from "react-icons/ci";
import { RxDotFilled } from "react-icons/rx";
import formatDate, {
  errorAlert,
  formatMonthDate,
  successAlert,
  API,
  ConfirmationAlert,
  holidays,
} from "../../utils/services.js";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format } from "date-fns";
import {
  // Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import Select from "react-select";
import { BiDuplicate } from "react-icons/bi";
import "./Calendar.css";
import axiosInstance from "../../helpers/axios.js";
import CustomHeading from "../../components/common/CustomHeading.js";
import CustomButton from "../../components/common/CustomButton.js";
import SkeletonRows from "../../components/common/CustomSkeletonLoading.js";
import { useMattersOptions } from "../../helpers/hooks/useMattersOptions.js";
// import { ClockLoader } from "react-spinners";
import NoDataFound from "../../components/common/NoDataFound.js";

const getMonthStartEndDates = (date) => {
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return { startDate, endDate };
};

const getWeekendDates = (startDate, endDate) => {
  const weekendDates = [];
  const currentDate = new Date(startDate);
  const formatDateInReverse = (date) => {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  while (currentDate <= endDate) {
    const day = currentDate.getDay();
    if (day === 0 || day === 6) {
      weekendDates.push(formatDateInReverse(currentDate));
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return weekendDates;
};

const calculateTotals = (searchResult, writeOffHours, selectedDate) => {
  let total = 0;
  let totalBillable = 0;
  let totalWriteoff = 0;

  const matterGroups = {};

  // console.log("Edit data", searchResult, writeOffHours, selectedDate);

  searchResult.forEach((item) => {
    const workHour = parseFloat(item.workHour) || 0;
    total += workHour;

    if (
      item.matter?.pricePerHour?.$numberDecimal !== "0" &&
      item.matter?.resourceSpecificPrice?.[0] !== ""
    ) {
      totalBillable += workHour;
    }

    totalWriteoff = writeOffHours
      .filter((item) => item.workDate === selectedDate)
      .reduce((total, item) => total + parseFloat(item.workHour), 0);

    const matterName = item.matter?.name || "Uncategorized";
    if (!matterGroups[matterName]) {
      matterGroups[matterName] = {
        entries: [],
        total: 0,
      };
    }
    matterGroups[matterName].entries.push(item);
    matterGroups[matterName].total += workHour;
  });

  return {
    total: parseFloat(total).toFixed(2),
    totalBillable: parseFloat(totalBillable).toFixed(2),
    totalWriteoff: parseFloat(totalWriteoff).toFixed(2),
    matterGroups,
  };
};

const MyCalendar = () => {
  const { filteredMatters } = useMattersOptions();
  const auth = useSelector((state) => state.auth);

  // states
  const [searchResult, setSearchResult] = useState([]);
  const [searchWriteoffResult, setSearchWriteoffResult] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [leaves, setLeaves] = useState([]);
  const [writeOffHours, setWriteOffHours] = useState([]);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userWorkHour, setUserWorkHour] = useState([]);
  const [editingTime, setEditingTime] = useState(null);
  const [date, setDate] = useState(new Date());
  const [modalMatter, setModalMatter] = useState("");
  const [modalClient, setModalClient] = useState("");
  const [modalSelectedMatterName, setModalSelectedMatterName] = useState("");
  const [workDescription, setWorkDescription] = useState("");
  const [comments, setComments] = useState("");
  const [workHour, setWorkHour] = useState("0");
  const [workMin, setWorkMin] = useState("30");
  const [entryId, setEntryId] = useState("");
  const [loading, setLoading] = useState(true);
  const [editingLeave, setEditingLeave] = useState(null);
  const [leaveStartDate, setLeaveStartDate] = useState("");
  const [leaveEndDate, setLeaveEndDate] = useState("");
  const [leaveDescription, setLeaveDescription] = useState("");
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [lastSentTSDate, setLastSentTSDate] = useState(null);

  const totalWorkHour = useMemo(
    () => userWorkHour.concat(writeOffHours || ""),
    [userWorkHour, writeOffHours]
  );

  const { startDate, endDate } = useMemo(
    () => getMonthStartEndDates(activeStartDate),
    [activeStartDate]
  );

  const formattedStartDate = useMemo(() => formatDate(startDate), [startDate]);
  const formattedEndDate = useMemo(() => formatDate(endDate), [endDate]);

  const weekendDates = useMemo(
    () => getWeekendDates(startDate, endDate),
    [startDate, endDate]
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const workHoursResponse = await axiosInstance.get(
        `${API}/workHour/getWorkHourByUserId`,
        {
          params: {
            fromDate: formattedStartDate,
            toDate: formattedEndDate,
            limit: 0,
            page: 0,
          },
        }
      );
      const writeOffResponse = await axiosInstance.get(
        `${API}/writeoffs/getWriteoffHoursByResource`,
        {
          params: {
            fromDate: formattedStartDate,
            toDate: formattedEndDate,
            limit: 0,
            page: 0,
          },
        }
      );
      const leavesResponse = await axiosInstance.get(
        `${API}/leaves/my-leaves`,
        {
          params: {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            limit: 0,
            page: 0,
          },
        }
      );

      setUserWorkHour(workHoursResponse.data.workHours);
      setWriteOffHours(writeOffResponse.data.writeoffHours);
      setLeaves(leavesResponse.data.leavesWithDays);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
      errorAlert(error.response.data.error);
    }
  }, [formattedStartDate, formattedEndDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearch = useCallback(
    (text) => {
      if (!text) {
        setSearchResult([]);
        return;
      }

      const searchedData = userWorkHour.filter((query) =>
        query.workDate.includes(text)
      );

      const searchedData2 = writeOffHours.filter((query) =>
        query.workDate.includes(text)
      );

      setSearchResult(searchedData);
      setSearchWriteoffResult(searchedData2);
    },
    [userWorkHour, writeOffHours]
  );

  useEffect(() => {
    handleSearch(selectedDate);
  }, [userWorkHour, selectedDate, handleSearch]);

  // useEffect(() => {
  //   handleSearch(selectedDate);
  // }, [userWorkHour, selectedDate]);

  useEffect(() => {
    setSelectedDate(formatDate(new Date()));
  }, []);

  const { total, totalBillable, totalWriteoff, matterGroups } = calculateTotals(
    searchResult,
    writeOffHours,
    selectedDate
  );

  const todayLeaves = leaves.filter(
    (leave) =>
      leave.leaveStartDate <= selectedDate && selectedDate <= leave.leaveEndDate
  );

  const handleDateClick = (date) => {
    setSelectedDate(formatDate(date));
  };

  // const handleSearch = (text) => {
  //   if (!text) {
  //     setSearchResult([]);
  //     return;
  //   }

  //   const searchedData = userWorkHour.filter((query) =>
  //     query.workDate.includes(text)
  //   );

  //   const searchedData2 = writeOffHours.filter((query) =>
  //     query.workDate.includes(text)
  //   );

  //   setSearchResult(searchedData);
  //   setSearchWriteoffResult(searchedData2);
  // };

  const getMarkedDates = (appointments, leaves, holidays, weekendDates) => {
    const markedDates = {};
    const totalWorkHoursPerDate = appointments.reduce((result, entry) => {
      const { workDate, workHour } = entry;
      const parsedWorkHour = parseFloat(workHour);
      if (parsedWorkHour >= 0) {
        if (result[workDate]) {
          result[workDate] += parsedWorkHour;
        } else {
          result[workDate] = parsedWorkHour;
        }
      }
      return result;
    }, {});

    Object.keys(totalWorkHoursPerDate).forEach((date) => {
      let formattedDate;
      try {
        formattedDate = formatDate(date);
      } catch (error) {
        console.error("date error", error);
        return;
      }

      const workHours = totalWorkHoursPerDate[date].toFixed(2);
      let dotColor;
      if (workHours >= 9) {
        dotColor = "green";
      } else if (workHours >= 3.5) {
        dotColor = "orange";
      } else if (workHours > 0) {
        dotColor = "red";
      }

      markedDates[formattedDate] = { marked: true, dotColor };
    });

    weekendDates.forEach((weekendDate) => {
      const formattedWeekendDate = format(new Date(weekendDate), "yyyy-MM-dd");
      if (totalWorkHoursPerDate[formattedWeekendDate]) {
        markedDates[formattedWeekendDate] = {
          marked: true,
          dotColor: "green",
        };
      } else {
        markedDates[formattedWeekendDate] = {
          marked: true,
          dotColor: "white",
        };
      }
    });

    leaves.forEach((leave) => {
      const startDate = new Date(leave.leaveStartDate);
      const endDate = new Date(leave.leaveEndDate);
      while (startDate <= endDate) {
        const formattedDate = format(startDate, "yyyy-MM-dd");
        markedDates[formattedDate] = { marked: true, dotColor: "green" };
        startDate.setDate(startDate.getDate() + 1);
      }
    });

    holidays.forEach((holiday) => {
      markedDates[holiday] = { marked: true, dotColor: "skyblue" };
    });

    return markedDates;
  };

  const markedDates = useMemo(
    () => getMarkedDates(totalWorkHour, leaves, holidays, weekendDates),
    [totalWorkHour, leaves, holidays, weekendDates]
  );

  const renderDot = useCallback(
    ({ date, view }) => {
      if (view === "month") {
        const utcDate = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        );
        const dateString = utcDate.toISOString().split("T")[0];
        const isFutureDate = date > new Date();
        const isLeaveApplied = leaves.some(
          (leave) =>
            leave.leaveStartDate <= dateString &&
            dateString <= leave.leaveEndDate
        );
        const isFutureHoliday =
          holidays.some((holiday) => new Date(holiday) > new Date()) &&
          holidays.includes(dateString);

        let dotColor;

        if (isLeaveApplied) {
          dotColor = "green";
        } else if (isFutureDate && !isFutureHoliday) {
          dotColor = "white";
        } else {
          const dotInfo = markedDates[dateString];
          dotColor = dotInfo?.dotColor || "red";
        }

        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {<RxDotFilled size={25} style={{ color: dotColor }} />}
          </div>
        );
      }

      return null;
    },
    [leaves, holidays, markedDates]
  );

  const handleDelete = (id) => {
    toast(
      <ConfirmationAlert
        onDeleteOff={() => onDeleteWorkHour(id)}
        question={"Are you sure to delete this time entry?"}
        answer={"Yes, Delete it"}
        icon={<Delete />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  // const handleDeleteLeave = (id) => {
  //   toast(
  //     <ConfirmationAlert
  //       onDeleteOff={() => onDeleteLeave(id)}
  //       question={"Are you sure to delete this leave?"}
  //       answer={"Yes, Delete it"}
  //       icon={<Delete />}
  //     />,
  //     {
  //       position: toast.POSITION.TOP_CENTER,
  //       closeButton: true,
  //       draggable: false,
  //     }
  //   );
  // };

  const onDeleteWorkHour = async (entryId) => {
    try {
      const response = await axiosInstance.delete(
        `${API}/workhour/delete/${entryId}`
      );
      if (response.data) {
        successAlert("Item deleted successfully!");
        setUserWorkHour((prevFilteredWorkHour) => {
          const updatedWorkHour = prevFilteredWorkHour.filter(
            (entry) => entry._id !== entryId
          );
          return updatedWorkHour;
        });
        fetchData();
      }
    } catch (error) {
      errorAlert("Deletion of entry failed", "Please try again...");
    }
  };

  // const onDeleteLeave = async (leaveId) => {
  //   try {
  //     const response = await axiosInstance.delete(`${API}/leaves/${leaveId}`);
  //     if (response.data) {
  //       successAlert("Leave deleted successfully!");
  //       fetchData();
  //     }
  //   } catch (error) {
  //     errorAlert("Deletion of leave failed", "Please try again...");
  //   }
  // };

  const openEditModal = (time, type) => {
    setShowEditModal(true);
    if (type === "duplicate") {
      setEditingTime(null);
      setDate(time.workDate);
      setWorkDescription(time.workDescription);
      setModalMatter(time.matter);
      setModalSelectedMatterName(time.matter.name);
      setComments(time.comments);
      const hour = time.workHour.split(".");
      setWorkHour(hour[0]);
      setWorkMin(Math.floor((hour[1] * 6) / 10));
    } else if (type === "add") {
      setEditingTime(null);
      setDate(selectedDate);
      setWorkDescription("");
      setModalMatter("");
      setModalSelectedMatterName("");
      setComments("");
      setWorkHour(0);
      setWorkMin(30);
    } else {
      setEditingTime(time);
      setDate(time.workDate);
      setEntryId(time._id);
      setWorkDescription(time.workDescription);
      setModalMatter(time.matter);
      setModalSelectedMatterName(time.matter.name);
      setComments(time.comments);
      const hour = time.workHour.split(".");
      setWorkHour(hour[0]);
      setWorkMin(Math.floor((hour[1] * 6) / 10));
    }
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setDate(new Date());
    setWorkDescription("");
    setModalMatter("");
    setModalSelectedMatterName("");
    setModalClient("");
    setComments("");
    setWorkHour(0);
    setWorkMin(30);
    setEntryId("");
  };

  const openLeaveModal = (leave, type) => {
    if (type === "edit") {
      setEditingLeave(leave);
      setLeaveStartDate(leave.leaveStartDate);
      setLeaveEndDate(leave.leaveEndDate);
      setLeaveDescription(leave.leaveDescription);
    } else {
      setEditingLeave(null);
      setLeaveStartDate("");
      setLeaveEndDate("");
      setLeaveDescription("");
    }
    setShowLeaveModal(true);
  };

  const closeLeaveModal = () => {
    setShowLeaveModal(false);
    setEditingLeave(null);
  };

  const isValidObjField = () => {
    return !(
      date === "" ||
      modalMatter === "" ||
      workDescription === "" ||
      workHour === "00" ||
      workMin === "00"
    );
  };

  const isValidForm = () => {
    if (!isValidObjField()) return errorAlert("All Fields are Required!");
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const time = {
      admin: auth.user.company,
      user: auth.user._id,
      workDate: date,
      matter: modalMatter,
      workDescription,
      comments,
      workHour: `${workHour}.${workMin * (10 / 6)}`,
      category: "billable",
    };

    if (isValidForm()) {
      if (editingTime) {
        try {
          const response = await axiosInstance.put(
            `${API}/workHour/update/${entryId}`,
            time
          );
          if (response.data) {
            successAlert("Time Updated Successfully!");
            setUserWorkHour((prevUserWorkHour) => {
              const updatedWorkHour = prevUserWorkHour.map((entry) =>
                entry._id === entryId ? response.data : entry
              );
              return updatedWorkHour;
            });
            closeEditModal();
            fetchData();
          }
        } catch (error) {
          console.error("Error updating time:", error);
          errorAlert("Error updating time");
        }
      } else {
        try {
          const response = await axiosInstance.post(
            `${API}/workHour/createNew`,
            time
          );
          if (response.data) {
            successAlert("Great !! Time Added Successfully!");
            closeEditModal();
            fetchData();
          }
        } catch (error) {
          console.error("Error adding time:", error);
          errorAlert("Error adding time");
        }
      }
    }
  };

  const handleSubmitLeave = async (e) => {
    e.preventDefault();

    const formData = {
      admin: auth.user.company,
      user: auth.user._id,
      leaveStartDate: leaveStartDate,
      leaveEndDate: leaveEndDate,
      leaveDescription: leaveDescription,
    };

    try {
      if (editingLeave) {
        const response = await axiosInstance.put(
          `${API}/leaves/${editingLeave._id}`,
          formData
        );
        if (response.data) {
          successAlert("Leave updated successfully!");
          closeLeaveModal();
          fetchData();
        }
      } else {
        const response = await axiosInstance.post(`${API}/leaves`, formData);
        if (response.data) {
          successAlert("Leave added successfully!");
          closeLeaveModal();
          fetchData();
        }
      }
    } catch (error) {
      console.error("Error:", error);
      errorAlert("Error : Something went wrong", error);
    }
  };

  const tileClassName = ({ date }) => {
    if (date.getDay() === 0 || date.getDay() === 6) {
      return "react-calendar__tile--weekend";
    }
    return "";
  };

  const handleMatterChange = async (option) => {
    try {
      // console.log("Matter id", option);
      const response = await axiosInstance.get(
        `${API}/matter/getMatterByMatterId/${option}`
      );
      const matterDetails = response.data;
      // console.log("Matter details", matterDetails);
      setLastSentTSDate(response.data.matterDetails.lastSentTSDate);

      // console.log("lastSentTSDate", response.data.matterDetails.lastSentTSDate);
    } catch (error) {
      console.error("Error fetching matter details:", error);
    }
  };

  return (
    <>
      <ToastContainer />

      {showLeaveModal && (
        <div className="modal">
          <div className="modal-content">
            <h4
              style={{
                width: "100%",
                textAlign: "center",
                padding: 5,
                color: "#886a47",
                fontWeight: 700,
                borderRadius: 10,
              }}
            >
              {editingLeave ? "Edit Leave" : "Add Leave"}
            </h4>
            <form onSubmit={handleSubmitLeave}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                  width: "100%",
                  marginTop: 15,
                }}
              >
                <div style={{ width: "50%" }}>
                  <label>Start Date:</label>
                  <input
                    type="date"
                    value={leaveStartDate}
                    onChange={(e) => setLeaveStartDate(e.target.value)}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <label>End Date: </label>
                  <input
                    type="date"
                    value={leaveEndDate}
                    onChange={(e) => setLeaveEndDate(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label>
                  Leave Description:
                  <input
                    type="text"
                    placeholder="Enter leave description"
                    value={leaveDescription}
                    onChange={(e) => setLeaveDescription(e.target.value)}
                  />
                </label>
              </div>

              <div style={{ textAlign: "center" }}>
                <CustomButton type="submit">Submit</CustomButton>
                <CustomButton
                  variant="text"
                  style={{ width: "20%" }}
                  onClick={closeLeaveModal}
                >
                  Cancel
                </CustomButton>
              </div>
            </form>
          </div>
        </div>
      )}

      {showEditModal && (
        <div className="modal">
          <div className="modal-content">
            <h4
              style={{
                width: "100%",
                textAlign: "center",
                padding: 5,
                color: "#886a47",
                fontWeight: 700,
                borderRadius: 10,
              }}
            >
              {editingTime ? "EDIT TIME" : "ADD TIME"}
            </h4>
            <form>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                  width: "100%",
                  marginTop: 15,
                }}
              >
                <div style={{ width: "50%" }}>
                  <label>Date</label>
                  <input
                    type="date"
                    value={date}
                    min={lastSentTSDate || ""}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>

                <div style={{ width: "50%" }}>
                  <label>Selected Matter : {modalSelectedMatterName}</label>
                  <Select
                    options={filteredMatters}
                    value={filteredMatters.find(
                      (option) =>
                        option.label ===
                        `${modalSelectedMatterName} | ${modalClient}`
                    )}
                    onChange={(e) => {
                      setModalMatter(e.value);
                      setModalSelectedMatterName(e.label.split("|")[0]);
                      setModalClient(e.label.split("|")[1]);
                      handleMatterChange(e.value)
                    }}
                    isSearchable
                    placeholder="Search Matter | Client"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: 20,
                  marginTop: 15,
                  width: "100%",
                }}
              >
                <div style={{ width: "50%" }}>
                  <label>Work Hours</label>

                  <select
                    style={{ width: "100%", padding: 10 }}
                    value={workHour}
                    name="workHour"
                    onChange={(event) => {
                      setWorkHour(event.target.value);
                    }}
                  >
                    {Array.from({ length: 19 }, (_, i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>

                <div style={{ width: "50%", gap: 15 }}>
                  <label>Work Minutes</label>

                  <select
                    style={{ width: "100%", padding: 10 }}
                    value={workMin}
                    name="workMin"
                    onChange={(event) => {
                      setWorkMin(event.target.value);
                    }}
                  >
                    {Array.from({ length: 10 }, (_, i) => (
                      <option key={i * 6} value={i * 6}>
                        {i * 6}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <label>
                Work Description
                <textarea
                  style={{ height: 80, width: "100%" }}
                  type="text"
                  placeholder="Enter work description"
                  value={workDescription}
                  onChange={(e) => setWorkDescription(e.target.value)}
                />
              </label>
              <label>
                Comments
                <input
                  style={{ width: "100%", height: 50 }}
                  type="text"
                  placeholder="Enter comments for the reviewer"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
              </label>

              <div style={{ textAlign: "center" }}>
                <CustomButton onClick={handleSubmit}>Submit</CustomButton>
                <CustomButton
                  variant="text"
                  style={{ width: "20%" }}
                  onClick={closeEditModal}
                >
                  Cancel
                </CustomButton>
              </div>
            </form>
          </div>
        </div>
      )}

      <CustomHeading text={"Explore Your Calendar"} />

      <Grid container spacing={2} padding={1}>
        <Grid item xs={12} sm={12} md={3.5} xl={2.6}>
          <Grid item xs={12} sm={12} md={12}>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                gap: 2,
                flexDirection: "row",
                marginBottom: 2,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 5,
                padding: "1rem 1rem",
              }}
            >
              <div>
                <p style={{ fontSize: 15 }}>{"🔴 < 3.5 hours"}</p>
                <p style={{ fontSize: 15 }}>{"🟠 >= 3.5 hours"}</p>
              </div>
              <div>
                <p style={{ fontSize: 15, display: "flex" }}>
                  <div
                    style={{
                      backgroundColor: "green",
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      margin: "3px 10px 0 1px",
                    }}
                  ></div>
                  <div>{">= 9 hrs/Leave"}</div>
                </p>
                <p style={{ fontSize: 15, display: "flex" }}>
                  <div
                    style={{
                      backgroundColor: "skyblue",
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      margin: "3px 10px 0 1px",
                    }}
                  ></div>
                  <div>= Holiday</div>
                </p>
              </div>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            display="flex"
            justifyContent="center"
          >
            {/* {loading ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  gap: "20px",
                  marginTop: "50px",
                  // backgroundColor: "#eae4dd",
                  borderRadius: "10px",
                }}
              >
                <ClockLoader size={100} color={"#886a4d"} loading={loading} />
                <CustomHeading fontSize="16px" text="Calendar Loading..." />
              </Box>
            ) : ( */}
            <Calendar
              onClickDay={handleDateClick}
              onActiveStartDateChange={({ activeStartDate }) => {
                setActiveStartDate(activeStartDate);
              }}
              tileContent={renderDot}
              tileClassName={tileClassName}
            />
            {/* )} */}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={8.5} xl={9.4}>
          <Paper
            className="entries-container"
            elevation={5}
            style={{ borderRadius: 20 }}
          >
            <div id="entries-heading-data-container">
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={4} sm={4} md={4}>
                  <CustomButton
                    style={{
                      backgroundColor: todayLeaves.length !== 0 && "green",
                      fontSize: "0.7rem",
                      fontWeight: 600,
                      width: "100%",
                    }}
                    onClick={() =>
                      todayLeaves.length === 0
                        ? openLeaveModal(null, "add")
                        : undefined
                    }
                  >
                    {todayLeaves.length === 0
                      ? "Request Leave"
                      : "Leave Applied!!"}
                  </CustomButton>
                </Grid>

                <Grid item xs={4} sm={4} md={4} textAlign="center">
                  <CustomHeading
                    text={`${
                      userWorkHour[0]?.workDate
                        ? formatMonthDate(selectedDate)
                        : "All"
                    } Entries`}
                    variant="h8"
                  />
                </Grid>

                <Grid item xs={4} sm={4} md={4}>
                  <CustomButton
                    style={{
                      fontSize: "0.7rem",
                      fontWeight: 600,
                      width: "100%",
                    }}
                    onClick={() => openEditModal(null, "add")}
                  >
                    Add Time
                  </CustomButton>
                </Grid>
              </Grid>

              {parseFloat(total + totalWriteoff) !== 0 && (
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={6} md={2.5}>
                    <div id="button-design">
                      <b>
                        Total : {parseFloat(total + totalWriteoff).toFixed(1)}{" "}
                        hrs
                      </b>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div id="button-design">
                      <b>
                        Billable : {parseFloat(totalBillable).toFixed(1)} hrs
                      </b>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3.5}>
                    <div id="button-design">
                      <b>
                        Non-Billable :{" "}
                        {parseFloat(total - totalBillable).toFixed(1)} hrs
                      </b>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div id="button-design">
                      <b>
                        Discount : {parseFloat(totalWriteoff).toFixed(1)} hrs
                      </b>
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>

            {searchResult.length === 0 && (
              <NoDataFound message="Oops!!....No WorkHours found." />
            )}

            {searchResult?.length !== 0 && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-cell">
                        <b>Matter</b>
                      </TableCell>
                      <TableCell className="table-cell">
                        <b>Narration</b>
                      </TableCell>
                      <TableCell>
                        <b>WorkHours</b>
                      </TableCell>
                      <TableCell>
                        <b>Duplicate</b>
                      </TableCell>
                      <TableCell>
                        <b>Edit</b>
                      </TableCell>
                      <TableCell>
                        <b>Delete</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {loading ? (
                      <SkeletonRows rows={5} colSpan={11} />
                    ) : (
                      Object.keys(matterGroups).map((matterName) => (
                        <React.Fragment key={matterName}>
                          {matterGroups[matterName].entries.map((entry) => (
                            <TableRow key={entry._id}>
                              <TableCell className="table-cell">
                                {entry.matter?.name}
                              </TableCell>
                              <TableCell className="table-cell">
                                {entry.workDescription}
                              </TableCell>
                              <TableCell className="table-cell">
                                {entry.workHour
                                  ? entry.workHour
                                  : entry.amount?.$numberDecimal}
                              </TableCell>
                              <TableCell className="table-cell">
                                <BiDuplicate
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    openEditModal(entry, "duplicate")
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <Edit
                                  style={{ cursor: "pointer" }}
                                  onClick={() => openEditModal(entry, "edit")}
                                />
                              </TableCell>
                              <TableCell>
                                <Delete
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleDelete(entry._id)}
                                />
                              </TableCell>
                            </TableRow>
                          ))}

                          <TableRow>
                            <TableCell colSpan={2}>
                              <strong>Total for {matterName}</strong>
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              <strong>
                                {parseFloat(
                                  matterGroups[matterName].total
                                ).toFixed(2)}
                              </strong>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            {searchWriteoffResult.length === 0 && (
              <>
                <hr />
                <CustomHeading
                  align="center"
                  color="green"
                  text="No Discounted Hours!"
                />
              </>
            )}

            {searchWriteoffResult.length !== 0 && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <CustomHeading align="center" text="Discounted Hours" />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="table-cell">
                        <b>Matter</b>
                      </TableCell>
                      <TableCell className="table-cell">
                        <b>Narration</b>
                      </TableCell>
                      <TableCell className="table-cell">
                        <b>WorkHours</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchWriteoffResult.map((entry) => (
                      <TableRow key={entry._id}>
                        <TableCell className="table-cell">
                          {entry.matter?.name}
                        </TableCell>
                        <TableCell className="table-cell">
                          {entry.workDescription}
                        </TableCell>
                        <TableCell className="table-cell">
                          {entry.workHour
                            ? entry.workHour
                            : entry.amount?.$numberDecimal}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            {todayLeaves.length !== 0 && <hr />}

            {todayLeaves.length !== 0 && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <CustomHeading align="center" text="Leave Details" />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Leave Type</b>
                      </TableCell>
                      <TableCell>
                        <b>Leave Days</b>
                      </TableCell>
                      <TableCell>
                        <b>Leave Status</b>
                      </TableCell>
                      <TableCell className="table-cell">
                        <b>Narration</b>
                      </TableCell>
                      {/* <TableCell>
                        <b>Edit</b>
                      </TableCell>
                      <TableCell>
                        <b>Delete</b>
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {todayLeaves.map((leave) => (
                      <TableRow key={leave._id}>
                        <TableCell>
                          {" "}
                          {leave.leaveType === "workFromHome"
                            ? "Work From Home"
                            : leave.leaveType === "quarterDay"
                            ? "Quarter Day"
                            : leave.leaveType === "halfDay"
                            ? "Half Day"
                            : "Leave"}
                        </TableCell>
                        <TableCell>{leave.leaveDays}</TableCell>
                        <TableCell
                          style={{
                            color:
                              leave.leaveStatus === "approved"
                                ? "green"
                                : leave.leaveStatus === "pending"
                                ? "orange"
                                : "red",
                            fontWeight: "bold",
                            padding: "10px",
                          }}
                        >
                          {leave.leaveStatus === "pending"
                            ? "Pending"
                            : leave.leaveStatus === "approved"
                            ? "Approved"
                            : "Rejected"}
                        </TableCell>
                        <TableCell className="table-cell">
                          {leave.leaveDescription}
                        </TableCell>
                        {/* <TableCell>
                          <CiEdit
                            style={{ cursor: "pointer" }}
                            onClick={() => openLeaveModal(leave, "edit")}
                          />
                        </TableCell>
                        <TableCell>
                          <RiDeleteBin6Line
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteLeave(leave._id)}
                          />
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default MyCalendar;
