import { matterConstants } from "../actions/constants";

const initState = {
  matters: [],
  totalMatters: 0,
  matterLength: 0,
  loading: false,
  error: null,
  message: "",
};

const matterReducer = (state = initState, action) => {
  switch (action.type) {
    case matterConstants.GET_ALL_MATTER_REQUEST:
    case matterConstants.UPDATE_NEW_MATTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case matterConstants.GET_ALL_MATTER_SUCCESS:
      return {
        ...state,
        matters: action.payload.matters,
        matterLength: action.payload.matters.length,
        totalMatters: action.payload.totalMatters,
        loading: false,
      };
    case matterConstants.GET_ALL_MATTER_FAILURE:
    case matterConstants.UPDATE_NEW_MATTER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case matterConstants.UPDATE_NEW_MATTER_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        loading: false,
      };
    default:
      return state;
  }
};

export default matterReducer;
