import React, { useEffect, useState } from "react";
import formatDate, {
  ConfirmationAlert,
  errorAlert,
  formatLetterDate,
  formatMonthDate,
  successAlert,
  API,
} from "../../utils/services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Delete, Download, Search } from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import CustomButton from "../../components/common/CustomButton";
import CustomHeading from "../../components/common/CustomHeading";
import CustomTooltip from "../../components/common/CustomTootltip";
import SkeletonRows from "../../components/common/CustomSkeletonLoading";
import NoDataFound from "../../components/common/NoDataFound";

const Opex = () => {
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setLoading(false);
    };

    loadData();
    fetchAllOpexes();
  }, []);

  const [opexes, setOpexes] = useState([]);
  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 30));
    return formatDate(pastDate);
  });
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );
  const [showModal, setShowModal] = useState(false);
  const [editingOpex, setEditingOpex] = useState(null);
  const [date, setDate] = useState("");
  const [amount, setAmount] = useState(0);
  const [opexDescription, setOpexDescription] = useState("");
  const [expenseType, setExpenseType] = useState("recurring");
  const [expType, setExpType] = useState("");

  //pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchAllOpexes();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Modal for add and update leave

  const openModal = (opex) => {
    if (opex) {
      setEditingOpex(opex);
      setDate(opex.date);
      setAmount(opex.amount);
      setOpexDescription(opex.opexDescription);
      setExpenseType(opex.expenseType);
    } else {
      setEditingOpex(null);
      setDate(new Date());
      setAmount("");
      setOpexDescription("");
      setExpenseType("recurring");
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  //Get all opexes

  const fetchAllOpexes = async () => {
    setLoading(true);
    try {
      // console.log(startSelectedDate, endSelectedDate, expType);
      const response = await axiosInstance.get(`${API}/opex/filter`, {
        params: {
          startDate: startSelectedDate,
          endDate: endSelectedDate,
          expType,
          limit: rowsPerPage,
          page,
        },
      });

      // console.log("Opexes data", response.data);

      setOpexes(response.data.opexes);
      setTotalCount(response.data.totalCount);
      setLoading(false);
      // console.log("Opexes data------", response.data);
    } catch (error) {
      setLoading(false);
      errorAlert("Error fetching opexes");
      console.error("Error fetching opexes:", error);
    }
  };

  //Update and Create

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      adminId: auth.user._id,
      date: date,
      amount: amount,
      opexDescription: opexDescription,
      expenseType: expenseType,
    };

    // console.log("formData", formData);

    try {
      // console.log("editingOpex", editingOpex);
      if (editingOpex) {
        // Update existing leave
        const response = await axiosInstance.put(
          `${API}/opex/update/${editingOpex._id}`,
          formData
        );
        if (response.data) {
          successAlert("Opex Update Successfully!");
          fetchAllOpexes();
        }
      } else {
        // Add new leave
        const response = await axiosInstance.post(`${API}/opex/add`, formData);
        if (response.data) {
          successAlert("Opex Added Successfully!");
          fetchAllOpexes();
        }
      }
      // closeModal();
    } catch (error) {
      console.error("Error:", error);
      errorAlert("Error : Something went wrong", error);
    }
  };

  //delete functions

  const handleDeleteOpex = (id) => {
    // console.log(id);

    toast(
      <ConfirmationAlert
        onDeleteOff={() => onDeleteOpex(id)}
        question={"Are you sure to delete this opex ?"}
        answer={"Yes, Delete it"}
        icon={<Delete />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onDeleteOpex = async (opexId) => {
    await axiosInstance
      .delete(`${API}/opex/${opexId}`)
      .then((response) => {
        // console.log(response.data);
        const res = fetchAllOpexes();
        if (res) {
          successAlert("Opex deleted successfully!");
        }
      })
      .catch((error) => {
        // console.log(error);
        errorAlert("Deletion of opex failed", "Please try again...");
      });
  };

  const formattedStartDate = formatLetterDate(startSelectedDate);
  const formattedEndDate = formatLetterDate(endSelectedDate);

  const generateOpexReport = () => {
    const doc = new jsPDF();

    const tableColumns = ["Date", "Amount", "Description", "Expense Type"];
    const tableData = [];

    if (opexes.length > 0) {
      opexes.forEach((opex, index) => {
        const rowData = [
          opex.date,
          parseFloat(opex.amount)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          opex.opexDescription,
          opex.expenseType,
        ];
        tableData.push(rowData);
      });
    }

    // Sort the tableData array by the "Expense Type" column
    tableData.sort((a, b) => {
      const expenseTypeA = a[3]; // "Expense Type" column index is 3
      const expenseTypeB = b[3];
      return expenseTypeA.localeCompare(expenseTypeB); // Compare expense types as strings
    });

    const footerRow1 = [
      "Total Recurring",
      opexes
        .filter((opex) => opex.expenseType === "recurring")
        .reduce((total, opex) => parseFloat(opex.amount) + total, 0)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ];

    tableData.push(footerRow1);

    const footerRow2 = [
      "Total Non-Recurring",
      opexes
        .filter((opex) => opex.expenseType === "nonRecurring")
        .reduce((total, opex) => parseFloat(opex.amount) + total, 0)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ];

    tableData.push(footerRow2);

    const footerRow = [
      "Total Expenses",
      opexes
        .reduce((total, opex) => parseFloat(opex.amount) + total, 0)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ];

    tableData.push(footerRow);

    //First Page
    doc.setFontSize(25);
    doc.setFont("helvetica", "bold");
    doc.text("OPEX Report", 80, 20);

    doc.setFont("helvetica", "underline");
    doc.setFontSize(15);
    if (startSelectedDate !== "") {
      doc.text(
        `${formattedStartDate}` +
          (startSelectedDate !== endSelectedDate
            ? ` - ${formattedEndDate}`
            : ""),
        (210 -
          doc.getTextWidth(
            `${formattedStartDate}` +
              (startSelectedDate !== endSelectedDate
                ? ` - ${formattedEndDate}`
                : "")
          )) /
          2,
        30
      );
    }

    doc.autoTable(tableColumns, tableData, {
      startY: 40,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
        if (
          data.row.index === data.table.body.length - 2 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
        if (
          data.row.index === data.table.body.length - 3 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
        if (data.row.section === "body" && data.column.index === 1) {
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 35; // Set the width to 50 units
        }
      },
    });

    doc.save("OPEX_Report.pdf");
  };

  return (
    <div>
      <ToastContainer />

      {/* Main Heading  */}
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <CustomHeading variant="h5" text="Opex" />
        </Grid>
        <Grid item>
          <CustomTooltip text="Add">
            <CustomButton
              style={{
                borderRadius: "50%",
                minWidth: "5px",
                height: "30px",
                transition:
                  "transform 0.3s ease-in-out, background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#886a47",
                  transform: "scale(1.1)",
                },
              }}
              onClick={() => openModal(null)}
            >
              <AddIcon />
            </CustomButton>
          </CustomTooltip>
        </Grid>
      </Grid>

      {/* Search Bar  */}
      <Paper elevation={3} sx={{ padding: 2, marginY: 2 }}>
        <Grid container spacing={2} alignItems="baseline" marginBottom="1rem">
          <Grid item xs={12} sm={7} md={4}>
            <div>
              <select
                value={expType}
                name="expType"
                onChange={(e) => setExpType(e.target.value)}
                style={{ width: "100%", height: "40px" }}
              >
                <option value="">Select Expense Type</option>
                <option value="recurring">Recurring</option>
                <option value="nonRecurring">Non-Recurring</option>
              </select>
            </div>
          </Grid>

          <Grid item xs={6} sm={6} md={2}>
            <input
              type="date"
              className="date"
              value={startSelectedDate}
              onChange={(e) => setStartSelectedDate(e.target.value)}
              style={{
                width: "100%",
                // padding: "0.5rem",
                boxSizing: "border-box",
              }}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={2}>
            <input
              type="date"
              className="date"
              value={endSelectedDate}
              onChange={(e) => setEndSelectedDate(e.target.value)}
              style={{
                width: "100%",
                boxSizing: "border-box",
              }}
            />
          </Grid>

          <Grid item xs={4} sm={4} md={2}>
            <CustomButton
              fontSize="13px"
              style={{ width: "100%" }}
              onClick={() => {
                fetchAllOpexes();
              }}
            >
              <Search style={{ marginRight: "8px" }} />
              Search
            </CustomButton>
          </Grid>

          <Grid item xs={4} sm={4} md={2}>
            <CustomButton
              variant="outlined"
              fontSize="13px"
              style={{ width: "100%" }}
              onClick={generateOpexReport}
            >
              <Download style={{ marginRight: "8px" }} />
              Download
            </CustomButton>
          </Grid>
        </Grid>
      </Paper>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <CustomHeading
              variant="h5"
              align="center"
              text={editingOpex ? "Edit Opex" : "Add Opex"}
            />
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                  width: "100%",
                  marginTop: 15,
                }}
              >
                <div style={{ width: "50%" }}>
                  <label>Date * </label>
                  <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <label>Amount *</label>
                  <input
                    type="number"
                    value={amount}
                    placeholder="Enter amount"
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              </div>

              <label>Expense Type *</label>
              <select
                value={expenseType}
                name="expenseType"
                onChange={(e) => setExpenseType(e.target.value)}
                style={{ width: "100%", padding: 7, marginBottom: 10 }}
              >
                <option value="">Select Expense Type</option>
                <option value="recurring">Recurring</option>
                <option value="nonRecurring">Non-Recurring</option>
              </select>

              <textarea
                placeholder="Opex description"
                value={opexDescription}
                onChange={(e) => setOpexDescription(e.target.value)}
                style={{ width: "100%" }}
              />

              <div style={{ textAlign: "center" }}>
                <CustomButton type="submit" style={{ width: "30%" }}>
                  Submit
                </CustomButton>
                <CustomButton
                  variant="text"
                  style={{ width: "30%" }}
                  onClick={closeModal}
                >
                  Cancel
                </CustomButton>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Main Table  */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100, 500, 1000]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <TableContainer sx={{ maxHeight: "75vh" }}>
          <Table aria-label="reusable table">
            <TableHead>
              {/* columns row  */}
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Date</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Amount</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Description
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Expense Type
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Edit
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <>
                  <SkeletonRows rows={6} colSpan={11} />
                </>
              ) : opexes.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={11}>
                    <NoDataFound message="Oops! No Opex Found." />
                  </TableCell>
                </TableRow>
              ) : (
                opexes.map((opex, index) => (
                  <TableRow
                    key={opex._id}
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      backgroundColor: index % 2 ? "white" : "#eae4dd",
                      fontFamily: "Prata",
                    }}
                  >
                    <TableCell>{formatMonthDate(opex.date)}</TableCell>
                    <TableCell>
                      {parseFloat(opex.amount)
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </TableCell>
                    <TableCell>{opex.opexDescription}</TableCell>
                    <TableCell>{opex.expenseType}</TableCell>
                    <TableCell className="table-cell">
                      <CiEdit
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal(opex)}
                      />
                    </TableCell>
                    <TableCell className="table-cell">
                      <RiDeleteBin6Line
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteOpex(opex._id)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
            <TableRow>
              <TableCell>
                <b>Total</b>
              </TableCell>
              <TableCell>
                <b>
                  {opexes
                    .reduce((total, opex) => parseFloat(opex.amount) + total, 0)
                    .toFixed(1)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </b>
              </TableCell>
              <TableCell colSpan={4}></TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default Opex;
