import React from "react";
import { Typography } from "@mui/material";

const CustomHeading = ({
  text,
  fontSize,
  variant = "h5",
  fontFamily = "Poppins",
  align = "left",
  fontWeight = "bold",
  marginBottom="10px",
  color="#80461b"
}) => {
  return (
    <Typography
      variant={variant}
      style={{ fontFamily, fontWeight, fontSize }}
      align={align}
      marginBottom={marginBottom}
      color={color}
    >
      {text}
    </Typography>
  );
};

export default CustomHeading;
