import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  Box,
  CssBaseline,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TablePagination,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getWorkHourByUserId } from "../../actions";
import CustomHeading from "../../components/common/CustomHeading";
import CustomButton from "../../components/common/CustomButton";
import formatDate, {
  API,
  ConfirmationAlert,
  errorAlert,
  formatMonthDate,
  successAlert,
} from "../../utils/services";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ChevronLeft, ChevronRight, Delete } from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import SkeletonRows from "../../components/common/CustomSkeletonLoading";
import { useMediaQuery, useTheme } from "@mui/material";
import { useMattersOptions } from "../../helpers/hooks/useMattersOptions";
import NoDataFound from "../../components/common/NoDataFound";

const validationSchema = Yup.object().shape({
  selectedDate: Yup.string().required("Date is required"),
  matter: Yup.string().required("Matter is required"),
  opeDescription: Yup.string().required("Description is required"),
  amount: Yup.number().required("Amount is required"),
  currency: Yup.string().required("Currency is required"),
});

const ResourceOPE = () => {
  const dispatch = useDispatch();
  const { filteredMatters } = useMattersOptions();
  const theme = useTheme();

  const auth = useSelector((state) => state.auth);
  const time = useSelector((state) => state.time);
  const { userWorkHour, workHourLoading, totalCount } = time;
  const role = "OPE";

  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [editingOPE, setEditingOPE] = useState(null);
  const [isTableFullWidth, setIsTableFullWidth] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [startSelectedDate, setStartSelectedDate] = useState("");
  const [endSelectedDate, setEndSelectedDate] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(
      getWorkHourByUserId(
        startSelectedDate,
        endSelectedDate,
        searchQuery,
        role,
        page,
        rowsPerPage
      )
    );
  }, [
    dispatch,
    startSelectedDate,
    endSelectedDate,
    searchQuery,
    role,
    page,
    rowsPerPage,
  ]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      selectedDate: formatDate(new Date()),
      matter: "",
      client: "",
      selectedMatterName: "",
      opeDescription: "",
      amount: "",
      currency: "INR",
    },
  });

  useEffect(() => {
    const entry = editingOPE;
    if (entry) {
      setValue("selectedDate", formatDate(new Date(entry.workDate)));
      setValue("matter", entry.matter._id);
      setValue("selectedMatterName", entry.matter.name);
      setValue("opeDescription", entry.workDescription);
      setValue("amount", entry.amount.$numberDecimal);
      setValue("currency", entry.currency);
    }
  }, [editingOPE, setValue]);

  const onSubmit = async (data) => {
    // console.log("Submitting form with data:", data); // Debug log

    if (
      data.selectedDate &&
      data.matter &&
      data.opeDescription &&
      data.amount &&
      data.currency
    ) {
      const opeData = {
        admin: auth?.user?.company,
        user: auth?.user?._id,
        workDate: data.selectedDate,
        matter: data.matter,
        workDescription: data.opeDescription,
        amount: data.amount,
        currency: data.currency,
        category: "billable",
        status: "pending",
        opeBills: selectedImages,
      };

      try {
        // console.log("Onsubmit called with OPE data:", opeData); // Debug log

        let response;
        if (editingOPE) {
          response = await axiosInstance.put(
            `${API}/ope/update/${editingOPE._id}`,
            opeData
          );
        } else {
          response = await axiosInstance.post(`${API}/OPE/createNew`, opeData);
        }

        if (response.data) {
          successAlert(
            editingOPE ? "OPE Updated Successfully!" : "OPE Added Successfully!"
          );
          handleSearch();
          setEditingOPE(null);
          reset();
          setSelectedImages([]);
        }
      } catch (error) {
        console.error("Error:", error);
        errorAlert("Something went wrong", error);
      }
    } else {
      errorAlert("All Fields are Required!");
    }
  };

  const handleDelete = (id) => {
    toast(
      <ConfirmationAlert
        onDeleteOff={() => onDeleteOPE(id)}
        question={"Are you sure to delete this entry?"}
        answer={"Yes, Delete it"}
        icon={<Delete />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onDeleteOPE = async (opeId) => {
    await axiosInstance
      .delete(`${API}/workhour/delete/${opeId}`)
      .then((response) => {
        if (response.data) {
          successAlert("Successfully Deleted");
          handleSearch();
        }
      })
      .catch((error) => {
        errorAlert("Deletion of entry failed", "Please try again...");
      });
  };

  const handleEdit = (entry) => {
    if (isTableFullWidth) {
      setIsTableFullWidth((prev) => !prev);
    }
    setEditingOPE(entry);
  };

  const toggleFullWidth = () => {
    setIsTableFullWidth((prev) => !prev);
  };

  const handleFileUpload = async (e) => {
    if (e.target.files.length > 0) {
      const files = e.target.files;
      const uploadPromises = [];

      // console.log("File Upload called!!"); // Debug log

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("adminId", auth.user.company);
        formData.append("employeeName", auth.user.fullName);
        formData.append("employeeId", auth.user._id);

        uploadPromises.push(
          fetch(`${API}/uploadOpe`, {
            method: "POST",
            body: formData,
          })
        );
      }

      try {
        setUploading(true);
        const uploadResponses = await Promise.all(uploadPromises);
        const uploadData = await Promise.all(
          uploadResponses.map((res) => res.json())
        );
        const uploadedFileUrls = uploadData.map((data) => data.url);
        // console.log("Uploaded urls", uploadedFileUrls); // Debug log
        setSelectedImages([...selectedImages, ...uploadedFileUrls]);

        // Reset the file input field
        e.target.value = "";
      } catch (e) {
        console.error(e);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleRemoveImage = async (event, index) => {
    // console.log("handleRemoveImage called"); // Debug log

    const updatedSelectedImages = [...selectedImages];
    const imageToRemove = updatedSelectedImages[index];

    // console.log("Attempting to delete image at index:", index);
    // console.log("Image URL:", imageToRemove);

    try {
      // Send a request to the backend to delete the image
      const result = await axiosInstance.delete(`${API}/deleteOpeBill`, {
        data: { filePath: imageToRemove },
      });

      // console.log("Delete response:", result); // Debug log

      // Remove the image from the state only if deletion is successful
      updatedSelectedImages.splice(index, 1);
      setSelectedImages(updatedSelectedImages);

      // console.log("Image deleted successfully from DigitalOcean Spaces.");
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("Failed to delete image. Please try again.");
    }
  };

  const handleSearch = () => {
    dispatch(
      getWorkHourByUserId(
        startSelectedDate,
        endSelectedDate,
        searchQuery,
        role,
        page,
        rowsPerPage
      )
    );
  };

  return (
    <>
      <ToastContainer />
      <CssBaseline />

      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        {!isTableFullWidth && (
          <Grid item xs={12} sm={12} md={5}>
            <CustomHeading text={editingOPE ? "Edit OPE" : "Add OPE"} />

            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <Grid container columnSpacing={2} marginTop={3} marginBottom={1}>
                <Grid item xs={12} sm={6} md={6}>
                  <label>Date *</label>
                  <input
                    type="date"
                    className={`formInput ${
                      errors.selectedDate ? `formInputError` : ""
                    }`}
                    {...register("selectedDate")}
                  />
                  {errors.selectedDate && (
                    <p className="errorMessage">
                      {errors.selectedDate.message}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <label>Matter *</label>
                  <Controller
                    name="matter"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={filteredMatters}
                        value={filteredMatters.find(
                          (option) => option.value === field.value
                        )}
                        onChange={(option) => {
                          setValue("matter", option.value);
                          setValue(
                            "selectedMatterName",
                            option.label.split("|")[0]
                          );
                          setValue("client", option.label.split("|")[1]);
                          trigger("matter");
                        }}
                        isSearchable
                        placeholder="Search Matter"
                        className={` ${errors.matter ? "formInputError" : ""}`}
                        styles={{
                          control: (base) => ({
                            ...base,
                            borderColor: errors.matter
                              ? "red"
                              : base.borderColor,
                          }),
                        }}
                      />
                    )}
                  />
                  {errors.matter && (
                    <p className="errorMessage">{errors.matter.message}</p>
                  )}
                </Grid>
              </Grid>

              <Grid container columnSpacing={2} marginBottom={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <label>Amount *</label>
                  <input
                    type="number"
                    className={`formInput ${
                      errors.amount ? `formInputError` : ""
                    }`}
                    {...register("amount")}
                  />
                  {errors.amount && (
                    <p className="errorMessage">{errors.amount.message}</p>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <label>Currency *</label>
                  <select
                    className={`formInput ${
                      errors.currency ? `formInputError` : ""
                    }`}
                    {...register("currency")}
                  >
                    <option value="USD">USD</option>
                    <option value="INR">INR</option>
                  </select>
                  {errors.currency && (
                    <p className="errorMessage">{errors.currency.message}</p>
                  )}
                </Grid>
              </Grid>

              <Grid container marginBottom={2} flexDirection="column">
                <label>Description *</label>
                <textarea
                  className={`formInput ${
                    errors.opeDescription ? "formInputError" : ""
                  }`}
                  style={{ height: "120px" }}
                  {...register("opeDescription")}
                />
                {errors.opeDescription && (
                  <p className="errorMessage">
                    {errors.opeDescription.message}
                  </p>
                )}
              </Grid>

              <Grid container marginBottom={2} flexDirection="column">
                <label>Upload Bills</label>
                <input
                  type="file"
                  accept="image/jpeg, image/png, image/webp, image/heif, image/htm"
                  onChange={handleFileUpload}
                  disabled={uploading}
                  multiple
                />
                {selectedImages.map((file, index) => (
                  <div key={index}>
                    <img
                      src={file}
                      alt={index + 1}
                      style={{ maxWidth: "100px", height: "100px" }}
                    />
                    <button
                      type="button"
                      onClick={(event) => handleRemoveImage(event, index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                {uploading && <p>Uploading...</p>}
              </Grid>

              <Grid
                container
                flexDirection="row"
                justifyContent="center"
                alignItems="baseline"
                gap={2}
              >
                <CustomButton type="submit" style={{ width: "50%" }}>
                  {editingOPE ? "Update" : "Submit"}
                </CustomButton>
                <CustomButton
                  variant="text"
                  style={{ width: "30%" }}
                  onClick={() => {
                    reset();
                    setEditingOPE(null);
                    setSelectedImages([]);
                  }}
                >
                  Reset
                </CustomButton>
              </Grid>
            </Box>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sm={12}
          md={isTableFullWidth ? 12 : 7}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Paper sx={{ width: isMediumScreen ? "80vw" : "100%" }}>
            <Grid container spacing={1} alignItems="baseline" padding="10px">
              <Grid item xs={12} sm={12} md={4}>
                <Select
                  options={filteredMatters}
                  value={filteredMatters.find(
                    (option) => option.value === searchQuery
                  )}
                  onChange={(option) => setSearchQuery(option.value)}
                  isSearchable
                  placeholder="Select Matter"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <input
                  type="date"
                  className="date"
                  value={startSelectedDate}
                  onChange={(e) => setStartSelectedDate(e.target.value)}
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <input
                  type="date"
                  className="date"
                  value={endSelectedDate}
                  onChange={(e) => setEndSelectedDate(e.target.value)}
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                />
              </Grid>
            </Grid>

            <TableContainer sx={{ maxHeight: "67vh" }}>
              <Table aria-label="reusable table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={13}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <IconButton onClick={toggleFullWidth}>
                          {isTableFullWidth ? (
                            <ChevronRight />
                          ) : (
                            <ChevronLeft />
                          )}
                        </IconButton>
                        <Box flex="1" textAlign="center">
                          <CustomHeading
                            variant="subtitle1"
                            text="Recent Entries"
                          />
                        </Box>
                        <Box width="48px" />
                      </Box>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell id="tableHeadings">WorkDate</TableCell>
                    <TableCell id="tableHeadings">Matter</TableCell>
                    <TableCell id="tableHeadings">Description</TableCell>
                    <TableCell id="tableHeadings">Amount</TableCell>
                    <TableCell id="tableHeadings">Status</TableCell>
                    {isTableFullWidth && (
                      <TableCell id="tableHeadings">Bills</TableCell>
                    )}
                    <TableCell id="tableHeadings">Edit</TableCell>
                    <TableCell id="tableHeadings">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {workHourLoading ? (
                    <SkeletonRows rows={6} colSpan={11} />
                  ) : userWorkHour.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={11}>
                        <NoDataFound message="Oops! No Ope Found" />
                      </TableCell>
                    </TableRow>
                  ) : (
                    userWorkHour.length > 0 &&
                    userWorkHour.map((time, index) => (
                      <TableRow
                        key={time._id}
                        role="checkbox"
                        tabIndex={-1}
                        sx={{
                          backgroundColor: index % 2 ? "white" : "#eae4dd",
                        }}
                      >
                        <TableCell>{formatMonthDate(time.workDate)}</TableCell>
                        <TableCell>{time?.matter?.name}</TableCell>
                        <TableCell>
                          {time?.workDescription?.slice(0, 50)}
                        </TableCell>
                        <TableCell>{time.amount?.$numberDecimal}</TableCell>
                        <TableCell>{time.status}</TableCell>
                        {isTableFullWidth && (
                          <TableCell>
                            {time.opeBills.map((file, index) => (
                              <div key={index}>
                                <img
                                  src={file}
                                  alt={index + 1}
                                  style={{
                                    maxWidth: "100px",
                                    height: "100px",
                                  }}
                                />
                              </div>
                            ))}
                          </TableCell>
                        )}
                        <TableCell>
                          <CiEdit
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEdit(time)}
                          />
                        </TableCell>
                        <TableCell>
                          <RiDeleteBin6Line
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDelete(time._id)}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ResourceOPE;
