import React, { useEffect, useState } from "react";
import { AiOutlineRollback } from "react-icons/ai";
import formatDate, {
  API,
  ConfirmationAlert,
  authToken,
  errorAlert,
  formatLetterDate,
  formatMonthDate,
  successAlert,
} from "../../utils/services";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import jsPDF from "jspdf";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
} from "@mui/material";
import { Delete, Download, Edit, Search, Undo } from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import CustomHeading from "../../components/common/CustomHeading";
import CustomButton from "../../components/common/CustomButton";
import SkeletonRows from "../../components/common/CustomSkeletonLoading";
import { useMattersOptions } from "../../helpers/hooks/useMattersOptions";
import { useEmployeeOptions } from "../../helpers/hooks/useEmployeesOptions";
import { useTSEmployees } from "../../helpers/hooks/useTSEmployees";
import NoDataFound from "../../components/common/NoDataFound";

const WriteOff = () => {
  const { matters, filteredMatters } = useMattersOptions();
  const { commonNames } = useTSEmployees();
  const { filteredEmployees } = useEmployeeOptions();

  const auth = useSelector((state) => state.auth);
  const adminId = auth.user._id;

  const [selectedMatterName, setSelectedMatterName] = useState("");
  const [matter, setMatter] = useState("");
  const [client, setClient] = useState("");
  const [selectedLawyer, setSelectedLawyer] = useState("");
  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 30));
    return formatDate(pastDate);
  });
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );

  const formattedStartDate = formatLetterDate(startSelectedDate);
  const formattedEndDate = formatLetterDate(endSelectedDate);

  //modal states
  const [showModal, setShowModal] = useState(false);
  const [entryId, setEntryId] = useState("");
  const [date, setDate] = useState(new Date());
  const [workDescription, setWorkDescription] = useState("");
  const [modalClient, setModalClient] = useState("");
  const [comments, setComments] = useState("");
  const [workHour, setWorkHour] = useState(0);
  const [workMin, setWorkMin] = useState(30);
  const [modalMatter, setModalMatter] = useState(""); //matter id
  const [modalSelectedMatterName, setModalSelectedMatterName] = useState("");
  const [modalSelectedLawyerName, setModalSelectedLawyerName] = useState("");
  const [modalSelectedLawyerId, setModalSelectedLawyerId] = useState("");
  const [writeOffHours, setWriteOffHours] = useState([]);

  //paginated states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const [companyData, setCompanyData] = useState({
    companyName: "",
    companyEmail: "",
    companyAddress: "",
    companyLogo: null,
  });

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1500)); // Simulating API call delay
      setLoading(false);
    };
    fetchCompanyDetails();
    filterBetweenDates();
    loadData();
  }, []);

  useEffect(() => {
    filterBetweenDates();
  }, [page, rowsPerPage]);

  //pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // console.log("WriteoffHours", writeOffHours);

  const fetchCompanyDetails = async () => {
    try {
      const response = await axios.get(`${API}/company/details/${adminId}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      // console.log("Company details", response.data);

      // Set other company details as well
      setCompanyData({
        ...companyData,
        companyName: response.data.companyName || "",
        companyEmail: response.data.companyEmail || "",
        companyAddress: response.data.companyAddress || "",
        companyLogo: response.data.companyLogo || "",
        companyWebsite: response.data.companyWebsite || "",
        companyAccountsEmail: response.data.companyAccountsEmail || "",
      });
    } catch (error) {
      // console.log("Error in fetching company details", error);
      errorAlert("Error in fetching company details!!");
    }
  };

  const handleRollback = (id) => {
    toast(
      <ConfirmationAlert
        onDeleteOff={() => onRollbackWorkhour(id)}
        question={"Are you sure to rollback this workHour?"}
        answer={"Yes, Rollback it"}
        icon={<AiOutlineRollback />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onRollbackWorkhour = async (entryId) => {
    await axiosInstance
      .put(`${API}/workhour/admin/rollback/${entryId}`)
      .then((response) => {
        // console.log("Response From Backend for rollback", response.data);
        // Assuming `writeoffHours` is accessible within this function
        if (writeOffHours.length > 0) {
          successAlert("WorkHour Rolled Back successfully!");
          filterBetweenDates();
        }
      })
      .catch((error) => {
        // console.log(error);
        errorAlert("Rollback of entry failed", "Please try again...");
      });
  };

  //Delete Functions

  const handleDeleteWriteOff = (id) => {
    // console.log(id);

    toast(
      <ConfirmationAlert
        onDeleteOff={() => onDeleteWriteOff(id)}
        question={"Are you sure to delete this writeoff entry?"}
        answer={"Yes, Delete it"}
        icon={<Delete />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onDeleteWriteOff = async (writeoffId) => {
    await axiosInstance
      .delete(`${API}/writeoff/delete/${writeoffId}`)
      .then((response) => {
        // console.log("Writeoff entry Data", response.data);
        // const res = writeOffHours;
        if (response.data) {
          successAlert("Writeoff deleted successfully!");
          filterBetweenDates();
        }
      })
      .catch((error) => {
        // console.log(error);
        errorAlert("Deletion of writeoff entry failed", "Please try again...");
      });
  };

  //Update Functions

  const doubleUsedTableColumns = [
    "Associate Name",
    "Discounted Time",
    "Hourly Rate",
    "Total Amount",
  ];

  const doubleUsedTableData = [];

  let currency;
  const matterCurr = matters.find((m) => m._id === matter);
  if (matterCurr) {
    currency = matterCurr.currency === "USD" ? "$" : "Rs. ";
    // console.log("Currency: ", currency);
    // Use the currency value as needed
  } else {
    currency = "";
    // console.log("Matter not found.");
  }

  let teamTotalHours = 0;
  let teamTotalBilledHours = 0;
  let teamTotalBilledAmount = 0;
  let persontotalHours = 0;

  if (commonNames.length > 0) {
    commonNames.forEach((name) => {
      let personWriteoffHours = 0;
      let personWorkHours = 0;

      if (writeOffHours) {
        writeOffHours
          .filter(
            (result) =>
              result.user?.fullName === name &&
              result.matter._id === matter &&
              result.workDate >= startSelectedDate && // Add start date filter
              result.workDate <= endSelectedDate // Add end date filter
          )
          .forEach((result) => {
            const workHour = parseFloat(result.workHour);
            personWriteoffHours += workHour;
          });
      }

      const newMatter = matters.find(
        (matter) => matter.name.trim() === selectedMatterName.trim()
      );

      let price;
      if (newMatter) {
        if (newMatter.pricePerHour) {
          price = newMatter.pricePerHour.$numberDecimal;
        } else if (newMatter.resourceSpecificPrice.length !== 0) {
          const employeeEntry = newMatter.resourceSpecificPrice.find((entry) =>
            entry.includes(name)
          );
          // console.log(employeeEntry)

          if (employeeEntry) {
            const [, hours] = employeeEntry.split(":");
            price = hours.trim();
            // console.log("price", name, price);
          }
        } else {
          price = 0;
        }
      }

      if (price) {
        let personTotalBilledAmount = price * personWriteoffHours;
        teamTotalHours += personWriteoffHours;
        teamTotalBilledHours += personWriteoffHours;
        teamTotalBilledAmount += personTotalBilledAmount;

        const data1 = [
          name,
          personWriteoffHours.toFixed(2),
          currency + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          currency +
            personTotalBilledAmount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        ];

        doubleUsedTableData.push(data1);
      }
    });
  }

  // // Add footer row
  const footerRow = [
    {
      content: "Total Hours",
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content: teamTotalBilledHours.toFixed(2),
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content: "",
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content:
        currency +
        teamTotalBilledAmount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
  ];

  doubleUsedTableData.push(footerRow);

  const generateWriteoff = () => {
    const doc = new jsPDF();

    // const link1Text = "accounts@resolutpartners.com";
    // const link1URL = "mailto:accounts@resolutpartners.com";
    // const link2Text = "www.resolutpartners.com";
    // const link2URL = "https://www.resolutpartners.com";

    const link1Text = companyData.companyAccountsEmail;
    const link1URL = `mailto:${companyData.companyAccountsEmail}`;
    const link2Text = companyData.companyWebsite;
    const link2URL = companyData.companyWebsite;

    //Header fixed for every new page
    function addHeader() {
      // Set the font style, size, and position of the header text
      const fontSize = 8;
      const pageWidth = doc.internal.pageSize.getWidth();
      const rightMargin = 15; // You can adjust the right margin as needed
      const logoDesiredHeight = 20; // Set the desired height for the logo
      const logoYPosition = 10; // Set the Y position for the logo
      const logoXPosition = 13; // Set the X position for the logo

      doc.setFont("helvetica", "normal");
      doc.setFontSize(fontSize);

      // Add the image to the header
      // doc.addImage(companyData.companyLogo, "PNG", 15, 10, 70, 9);
      doc.addImage(
        companyData.companyLogo,
        "PNG",
        logoXPosition,
        logoYPosition,
        0,
        logoDesiredHeight
      );

      // right header
      doc.setTextColor(46, 115, 176);

      const link2Width = doc.getTextWidth(link2Text);
      const link2X = pageWidth - link2Width - rightMargin;
      doc.textWithLink(link2Text, link2X, 19, {
        url: link2URL,
        underline: true,
      });

      // Set link color to blue and add underline
      const link1Width = doc.getTextWidth(link1Text);
      const link1X = pageWidth - link1Width - rightMargin;
      doc.textWithLink(link1Text, link1X, 22, {
        url: link1URL,
        underline: true,
      });

      doc.setTextColor(0);

      // Calculate text width and position to align it right
      const addressWidth = doc.getTextWidth(companyData.companyAddress);
      const addressX = pageWidth - addressWidth - rightMargin;
      doc.text(companyData.companyAddress, addressX, 26);

      // Reset text color to black
    }

    // function addHeader() {
    //   // Set the font style, size, and position of the header text
    //   const fontSize = 8;

    //   doc.setFont("helvetica", "normal");
    //   doc.setFontSize(fontSize);

    //   // Add the image to the header
    //   doc.addImage(Logo, "PNG", 15, 10, 70, 9);

    //   // Add the non-link text to the header
    //   doc.text("717, Tower B, One BKC,", 165, 13);
    //   doc.text("Bandra Kurla Complex, Mumbai - 51.", 150, 16);

    //   // Add the first link to the header
    //   doc.setTextColor(46, 115, 176); // Set link color to blue
    //   doc.textWithLink(link1Text, 158, 19, { url: link1URL, underline: true });
    //   doc.textWithLink(link2Text, 165, 22, { url: link2URL, underline: true });
    //   doc.setTextColor(0);
    // }

    // -------------1st Logo Page-----------------------
    //210 * 297 Page Width and Height
    //Header Image

    const Timespent = `/assets/Timespent.jpg`;

    doc.addImage(Timespent, "JPEG", 0, 0, 210, 297);

    const fontSize = 20;
    doc.setFontSize(fontSize);

    //center alignment width calculations
    const clientNameLength = (210 - doc.getTextWidth(client)) / 2;
    const matterNameLength = (210 - doc.getTextWidth(selectedMatterName)) / 2;

    const dateRangeLength =
      (210 - doc.getTextWidth(`${formattedStartDate} - ${formattedEndDate}`)) /
      2;

    //client name
    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(20);
    doc.text(client, clientNameLength, 120);

    //matter name
    doc.setFontSize(20);
    doc.setFont("helvetica", "normal");
    doc.text(selectedMatterName, matterNameLength, 130);

    //horizontal line
    doc.setLineWidth(0.3);
    doc.setDrawColor(136, 106, 71);
    doc.line(40, 145, 175, 145);

    //date range
    doc.setFont("helvetica", "italic");
    doc.setTextColor(0, 0, 0); // Black color
    doc.setFontSize(15);
    doc.text(
      `${formattedStartDate} - ${formattedEndDate}`,
      dateRangeLength + 10,
      160
    );
    doc.text(
      `(Discounted Time)`,
      (210 - doc.getTextWidth(`(Discounted Time)`)) / 2,
      170
    );

    // ----------------2nd Page --------------------

    doc.addPage();
    addHeader();
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");

    doc.text("Dear Sir / Ma'am", 15, 40);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.text(
      `${client} - ${selectedMatterName} From Period ${formatMonthDate(
        startSelectedDate
      )} To ${formatMonthDate(endSelectedDate)}`,
      14,
      50
    );
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.text(
      "Please find enclosed our memorandum of discounted time-spent on the given matter for the said period.",
      15,
      60
    );

    //---------------------------------Table Page 2----------------------------

    const startY = 70; // Starting Y-coordinate for the table

    doc.autoTable(doubleUsedTableColumns, doubleUsedTableData, {
      startY,
      theme: "grid",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,

      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          // Header row styles
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.halign = "left"; // Align header text to the left
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.fontSize = 9;
        } else if (data.row.section === "body") {
          // Body row styles
          if (data.column.index !== 0) {
            // Right-align all columns except the first column
            data.cell.styles.halign = "right";
          }
        } else if (data.row.index === doubleUsedTableData.length - 1) {
          // Footer row align
          data.cell.styles.halign = "right"; // Align footer text to the right
          data.cell.styles.fillColor = [226, 213, 203];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        } else if (
          data.row.index === doubleUsedTableData.length - 1 &&
          data.row.section === "foot"
        ) {
          // Footer row styles
          data.cell.styles.fillColor = [226, 213, 203];
          data.cell.styles.textColor = [255, 255, 255];
        }

        // Set padding for table data cells
        if (
          data.row.section === "body" &&
          data.column.index === 0 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 1 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 2 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 3 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 4 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
      },
    });

    // Calculate the table height
    const tableHeight = doc.autoTable.previous.finalY - startY;

    doc.text(
      "Summary of discount on this matter is provided below ",
      15,
      startY + tableHeight + 10
    );
    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.text(
      "Total Amount for the above period",
      15,
      startY + tableHeight + 20
    );
    doc.text(
      currency +
        teamTotalBilledAmount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      doc.internal.pageSize.getWidth() -
        doc.getTextWidth(
          currency +
            teamTotalBilledAmount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        ) -
        15,
      startY + tableHeight + 20
    );

    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");

    doc.text("Yours Sincerely,", 15, startY + tableHeight + 30);
    doc.setFont("helvetica", "bold");
    doc.text(auth.user.fullName, 15, startY + tableHeight + 35);
    doc.setFont("helvetica", "normal");

    // --------------------------------------3rd Page----------------------------------------------------
    doc.addPage();
    addHeader();

    let startY1 = 35;

    commonNames.forEach((name, index) => {
      const writeOffEntries = writeOffHours.filter(
        (result) => result.user?.fullName === name
      );

      const personWriteOffHours = writeOffEntries.reduce(
        (total, result) => total + parseFloat(result.workHour),
        0
      );

      const headerRow = [
        {
          content: name,
          colSpan: 2,
          styles: {
            cellWidth: "auto", // Set cell width to auto to allow content alignment
            halign: "left",
            fillColor: [136, 106, 71],
            textColor: [255, 255, 255],
            fontStyle: "bold", // Set the font style to bold
          },
        },
        {
          content: "Discounted Time",
          styles: {
            cellWidth: "auto", // Set cell width to auto to allow content alignment
            halign: "left",
            fillColor: [136, 106, 71],
            textColor: [255, 255, 255],
            fontStyle: "bold", // Set the font style to bold
          },
        },
      ];

      const tableRows = [headerRow];

      // Add individual data rows for the person
      writeOffEntries.forEach((result) => {
        const data = [
          formatMonthDate(result.workDate),
          result.workDescription.trim(" "),
          parseFloat(result.workHour).toFixed(2),
        ];
        tableRows.push(data);
      });

      const footerRow = [
        {
          content: "Total Time",
          colSpan: 2,
          styles: {
            halign: "left",
            fillColor: [97, 68, 58],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        },
        {
          content: personWriteOffHours.toFixed(2),
          styles: {
            halign: "right",
            fillColor: [97, 68, 58],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        },
      ];

      tableRows.push(footerRow);

      const filteredTableRows = tableRows.filter((row) =>
        row.some((cell) => cell !== "")
      );

      doc.autoTable({
        body: filteredTableRows,
        startY: startY1,
        theme: "grid",
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.1,
        didParseCell: function (data) {
          if (data.row.index === 0 && data.row.section === "head") {
            // Header row styles
            data.cell.styles.fillColor = [136, 106, 71];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.halign = "left";
            data.cell.styles.valign = "left"; // Align the text vertically at the center
            data.cell.styles.fontStyle = "bold"; // Set the font style to bold
          } else if (
            data.row.index === filteredTableRows.length - 1 &&
            data.row.section === "body"
          ) {
            // Footer row styles
            data.cell.styles.fillColor = [97, 68, 58];
            data.cell.styles.textColor = [255, 255, 255];
            // data.cell.styles.halign = "right";
            data.cell.styles.fontStyle = "bold";
          } else if (data.row.section === "body") {
            // Body row styles
            if (data.column.index !== 1) {
              // Right-align all columns except the first column
              data.cell.styles.halign = "left";
            }
          }
          // Set padding for table data cells
          if (
            data.row.section === "body" &&
            data.column.index === 0 &&
            data.row.index !== 0 &&
            data.row.index !== tableRows.length - 1
          ) {
            data.cell.styles.cellPadding = {
              top: 3,
              right: 2,
              bottom: 3,
              left: 3,
            };
            data.cell.styles.fontSize = 9;
            data.cell.styles.halign = "left";
            data.cell.styles.cellWidth = 25;
            data.cell.styles.textColor = [0, 0, 0];
          }

          if (
            data.row.section === "body" &&
            data.column.index === 1 &&
            data.row.index !== 0 &&
            data.row.index !== tableRows.length - 1
          ) {
            data.cell.styles.cellPadding = {
              top: 3,
              right: 4,
              bottom: 3,
              left: 4,
            };
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "times";
            data.cell.styles.halign = "left";
            data.cell.styles.cellWidth = 130; // Set the width to 100 units
            data.cell.styles.textColor = [0, 0, 0];
          }

          if (
            data.row.section === "body" &&
            data.column.index === 2 &&
            data.row.index !== 0 &&
            data.row.index !== tableRows.length - 1
          ) {
            data.cell.styles.cellPadding = {
              top: 3,
              right: 2,
              bottom: 3,
              left: 2,
            };
            data.cell.styles.halign = "right";
            data.cell.styles.fontSize = 9;
            data.cell.styles.cellWidth = 27;
            data.cell.styles.textColor = [0, 0, 0];
          }
        },
      });

      startY1 = doc.lastAutoTable.finalY + 10; // Update startY for the next table
    });

    doc.save(
      `${client} - ${selectedMatterName} From Period ${formatMonthDate(
        startSelectedDate
      )} To ${formatMonthDate(endSelectedDate)}.pdf`
    );
  };

  async function filterBetweenDates() {
    // console.log("ENtered filter between dates");
    const adminId = auth.user._id;

    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API}/writeoffs/getWriteoffHoursByAdmin`,
        {
          params: {
            adminId,
            user: selectedLawyer,
            fromDate: startSelectedDate,
            toDate: endSelectedDate,
            matterId: matter,
            limit: rowsPerPage,
            page,
          },
        }
      );
      // console.log("FilterData", response);
      setWriteOffHours(response.data.writeoffHours);
      setTotalCount(response.data.totalCount);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      errorAlert(error.response.data.error);
    }
  }

  //Modal for add time and will before edit time

  const openModal = (time) => {
    // console.log("time:", time);

    setShowModal(true);
    setEntryId(time._id);
    setWorkDescription(time.workDescription);
    setDate(time.workDate);
    setModalSelectedLawyerId(time.user._id);
    setModalSelectedLawyerName(time.user.fullName);
    setModalMatter(time.matter);
    setModalSelectedMatterName(time.matter.name);
    setComments(time.comments);
    const hour = time.workHour.split(".");
    setWorkHour(hour[0]);
    setWorkMin(Math.floor((hour[1] * 6) / 10));
  };

  const closeModal = () => {
    setShowModal(false);
    setEntryId("");
    setDate(new Date());
    setWorkDescription("");
    setModalSelectedLawyerName("");
    setModalSelectedLawyerId("");
    setModalMatter("");
    setModalSelectedMatterName("");
    setModalClient("");
    setWorkHour(0);
    setWorkMin(30);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const time = {
      user: modalSelectedLawyerId,
      workDate: date,
      matter: modalMatter,
      workDescription,
      comments,
      workHour: `${workHour}.${workMin * (10 / 6)}`,
    };

    // console.log("Put request Data", entryId, time);

    try {
      const response = await axiosInstance.put(
        `${API}/writeoff/update/${entryId}`,
        time
      );

      // console.log("Writeoff updated successfully:", response.data);

      if (response.data) {
        successAlert("Writeoff updated successfully!");
        setWriteOffHours((prevFilteredWorkHour) => {
          // Include the new entry in the updated state
          const updatedWorkHour = [...prevFilteredWorkHour, response.data];
          return updatedWorkHour;
        });

        closeModal();
        filterBetweenDates();
      }
    } catch (error) {
      console.error("Error updating writeoff:", error);
      errorAlert("Error Updating Write Off Entry");
    }

    closeModal();
  };

  const columns = [
    "WorkDate",
    "Resource",
    "Matter",
    "Narration",
    "WorkHours",
    "Edit",
    "Delete",
    "Rollback",
  ];

  return (
    <div>
      <ToastContainer />

      {/* Main Heading  */}
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <CustomHeading variant="h5" text="Write Off" />
        </Grid>
      </Grid>

      {/* Filters  */}
      <Paper elevation={3} sx={{ padding: 2, marginY: 2 }}>
        <Grid container spacing={1} alignItems="baseline" marginBottom="1rem">
          <Grid item xs={12} sm={7} md={3}>
            <Select
              options={filteredMatters}
              value={filteredMatters.find(
                (option) => option.label === `${selectedMatterName} | ${client}`
              )}
              onChange={(e) => {
                setMatter(e.value);
                setSelectedMatterName(e.label.split("|")[0]);
                setClient(e.label.split("|")[1]);
              }}
              isSearchable
              placeholder="Select Matter"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={5} md={2}>
            <Select
              options={filteredEmployees}
              value={filteredEmployees.find(
                (resOption) => resOption.label === `${selectedLawyer}`
              )}
              onChange={(e) => {
                setSelectedLawyer(e.value);
              }}
              isSearchable
              placeholder="Resource"
              fullWidth
            />
          </Grid>

          <Grid item xs={6} sm={6} md={2}>
            <input
              type="date"
              className="date"
              value={startSelectedDate}
              onChange={(e) => setStartSelectedDate(e.target.value)}
              style={{
                width: "100%",
                // padding: "0.5rem",
                boxSizing: "border-box",
              }}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={2}>
            <input
              type="date"
              className="date"
              value={endSelectedDate}
              onChange={(e) => setEndSelectedDate(e.target.value)}
              style={{
                width: "100%",
                boxSizing: "border-box",
              }}
            />
          </Grid>

          <Grid item xs={4} sm={4} md={1.5}>
            <CustomButton
              style={{ width: "100%" }}
              onClick={() => {
                filterBetweenDates();
              }}
            >
              <Search style={{ marginRight: "8px" }} />
              Search
            </CustomButton>
          </Grid>

          <Grid item xs={4} sm={4} md={1.5}>
            <CustomButton
              variant="outlined"
              style={{ width: "100%" }}
              onClick={() => {
                generateWriteoff();
              }}
              id="btn-search"
            >
              <Download style={{ marginRight: "8px" }} />
              Download
            </CustomButton>
          </Grid>
        </Grid>
      </Paper>

      {/* Modal  */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <CustomHeading variant="h5" align="center" text="Edit Write Off" />
            <form>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                  width: "100%",
                  marginTop: 15,
                }}
              >
                <div style={{ width: "50%" }}>
                  <label>Date *</label>
                  <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>

                <div style={{ width: "50%" }}>
                  <label>Resource *</label>
                  <Select
                    options={filteredEmployees}
                    value={filteredEmployees.find(
                      (resOption) =>
                        resOption.label === `${modalSelectedLawyerName}`
                    )}
                    onChange={(e) => {
                      setModalSelectedLawyerId(e.value);
                      setModalSelectedLawyerName(e.label);
                      // console.log(e.label);
                    }}
                    isSearchable
                    placeholder="Select Resource"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                  width: "100%",
                  marginTop: 15,
                }}
              >
                <div style={{ width: "100%" }}>
                  <label>
                    {"Selected Matter : " + modalSelectedMatterName}
                  </label>
                  <Select
                    options={filteredMatters}
                    value={filteredMatters.find(
                      (option) =>
                        option.label ===
                        `${modalSelectedMatterName} | ${modalClient}`
                    )}
                    onChange={(e) => {
                      setModalMatter(e.value);
                      setModalSelectedMatterName(e.label.split("|")[0]);
                      setModalClient(e.label.split("|")[1]);
                    }}
                    isSearchable
                    placeholder="Search Matter | Client *"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                  width: "100%",
                  marginTop: 15,
                }}
              >
                <div style={{ width: "50%" }}>
                  <label>Work Hours *</label>
                  <select
                    style={{ width: "100%", padding: 10 }}
                    value={workHour}
                    name="workHour"
                    onChange={(event) => {
                      setWorkHour(event.target.value);
                    }}
                  >
                    {Array.from({ length: 19 }, (_, i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>

                <div style={{ width: "50%" }}>
                  <label>Work Minutes</label>

                  <select
                    style={{ width: "100%", padding: 10 }}
                    value={workMin}
                    name="workMin"
                    onChange={(event) => {
                      setWorkMin(event.target.value);
                    }}
                  >
                    {Array.from({ length: 10 }, (_, i) => (
                      <option key={i * 6} value={i * 6}>
                        {i * 6}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <label>
                Work Description
                <textarea
                  style={{ height: 50, width: "100%" }}
                  type="text"
                  placeholder="Enter work description"
                  value={workDescription}
                  onChange={(e) => setWorkDescription(e.target.value)}
                />
              </label>
              <div
                style={{
                  width: "100%",
                }}
              >
                <label>
                  Comments
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Enter comments for the reviewer"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </label>
              </div>

              <div style={{ textAlign: "center" }}>
                <CustomButton onClick={handleSubmit} style={{ width: "30%" }}>
                  Submit
                </CustomButton>
                <CustomButton
                  variant="text"
                  style={{ width: "30%" }}
                  onClick={closeModal}
                >
                  Cancel
                </CustomButton>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Main Table  */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <TableContainer sx={{ maxHeight: "75vh" }}>
          <Table aria-label="reusable table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell key={index} id="table-header">
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <>
                  <SkeletonRows rows={6} colSpan={11} />
                </>
              ) : writeOffHours.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={11}>
                    <NoDataFound message="Oops! No writeoffs found!" />
                  </TableCell>
                </TableRow>
              ) : (
                writeOffHours.map((time, index) => (
                  <TableRow
                    key={time._id}
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      backgroundColor: index % 2 ? "white" : "#eae4dd",
                      fontFamily: "Prata",
                    }}
                  >
                    <TableCell>{formatMonthDate(time.workDate)}</TableCell>
                    <TableCell>{time?.user?.fullName}</TableCell>
                    <TableCell>{time.matter.name}</TableCell>
                    <TableCell>{time.workDescription}</TableCell>
                    <TableCell>
                      {time.workHour ? time.workHour + " hrs" : "0"}
                    </TableCell>
                    <TableCell className="table-cell">
                      <Edit
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal(time)}
                      />
                    </TableCell>
                    <TableCell className="table-cell">
                      <Delete
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteWriteOff(time._id)}
                      />
                    </TableCell>
                    <TableCell className="table-cell">
                      <Undo
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRollback(time._id)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default WriteOff;
