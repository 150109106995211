import React from "react";
import { Tooltip as MuiTooltip } from "@mui/material"; // Import the Tooltip component from MUI

const CustomTooltip = ({ text, children }) => {
  return (
    <MuiTooltip title={text} arrow>
      <span>{children}</span>
    </MuiTooltip>
  );
};

export default CustomTooltip;
