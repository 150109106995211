import { useState, useEffect } from "react";
import axiosInstance from "../axios";
import { API, unBilledDesignations } from "../../utils/services";

export const useTSEmployees = (filteredWorkHour) => {
  const [employees, setEmployees] = useState([]);
  const [commonNames, setCommonNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        setLoading(true);
        const rowsPerPage = 0;
        const page = 0;

        const res = await axiosInstance.get(`${API}/employee/getAll`, {
          params: {
            limit: rowsPerPage,
            page,
          },
        });

        const { employees } = res.data;

        //all -> active + inActive + billed + unbilled -> all employees
        setEmployees(employees);

        const uniqueNames = employees
          .filter((item) => !unBilledDesignations.includes(item.designation))
          // .filter(
          //   (item) =>
          //     item.designation !== "Intern" && item.designation !== "Paralegal"
          // )
          .map((item) => item.fullName);

        const filteredNames = [
          ...new Set(
            filteredWorkHour &&
              filteredWorkHour.map((item) => item.user?.fullName)
          ),
        ];

        const commonNames = uniqueNames.filter((name) =>
          filteredNames.includes(name)
        );

        // active + inActive + billed -> TS show
        setCommonNames(commonNames);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, [filteredWorkHour]);

  return { employees, commonNames, loading, error };
};
