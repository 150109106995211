import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
      barPercentage: 0.75,
      categoryPercentage: 0.5,
      maxBarThickness: 100, // You can adjust this value as needed
    },
    y: {
      beginAtZero: true,
      grid: {
        drawBorder: false,
        color: "rgba(0, 0, 0, 0.1)",
      },
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        boxWidth: 15,
        boxHeight: 15,
        padding: 20,
        font: {
          size: 12,
        },
      },
    },
  },
};
const BarChart = ({ data }) => {
  return (
    <div
      style={{
        height: "400px",
        width: "100%", // This will make sure the graph takes the full container width
        margin: "0 auto",
        padding: "20px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        backgroundColor: "white",
      }}
    >
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
