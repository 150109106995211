import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { filterTimeByUserIdForAdmin } from "../../actions/admin.time.action";
import jsPDF from "jspdf";
import "jspdf-autotable";
import formatDate, {
  formatMonthDate,
  API,
  holidays,
  unBilledDesignations,
} from "../../utils/services";
import Select from "react-select";
import { fetchWriteoffsByAdminId } from "../../actions";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Menu,
  MenuItem,
} from "@mui/material";
import { Download, Mail, Search } from "@mui/icons-material";
import CustomButton from "../../components/common/CustomButton";
import CustomHeading from "../../components/common/CustomHeading";
import SkeletonRows from "../../components/common/CustomSkeletonLoading";
import { useMattersOptions } from "../../helpers/hooks/useMattersOptions";
import { useEmployeeOptions } from "../../helpers/hooks/useEmployeesOptions";
import axiosInstance from "../../helpers/axios";
import NoDataFound from "../../components/common/NoDataFound";

const PerformanceReport = () => {
  const dispatch = useDispatch();

  const [filteredWorkHour, setFilteredWorkHour] = useState([]);
  const { matters, filteredMatters } = useMattersOptions();
  const { employeesData, filteredEmployees } = useEmployeeOptions();

  //redux states
  const auth = useSelector((state) => state.auth);
  const writeoff = useSelector((state) => state.writeoff);
  const { writeOffHours } = writeoff;

  //useStates
  const [client, setClient] = useState("");
  const [matterId, setMatterId] = useState(""); //matter id
  const [selectedMatterName, setSelectedMatterName] = useState(""); //matter name
  const [selectedLawyer, setSelectedLawyer] = useState("");
  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 6));
    return formatDate(pastDate);
  });
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );
  const [showResourceData, setShowResourceData] = useState(true);
  const [showMatterData, setShowMatterData] = useState(false);
  const [leaves, setLeaves] = useState([]);
  const [needFin, setNeedFin] = useState(false);
  // Loading state for the table
  const [loading, setLoading] = useState(true);

  const [sortedData, setSortedData] = useState([]);
  const [totalTeamTime, setTotalTeamTime] = useState(0);
  const [totalTeamBillableTime, setTotalTeamBillableTime] = useState(0);
  const [totalNonBillableTime, setTotalNonBillableTime] = useState(0);
  const [totalDays, setTotalDays] = useState(0);

  const [anchorElSearch, setAnchorElSearch] = useState(null);
  const [anchorElDownload, setAnchorElDownload] = useState(null);
  // const [anchorElSendMail, setAnchorElSendMail] = useState(null);

  const handleClick = (event, setAnchorEl) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (setAnchorEl) => {
    setAnchorEl(null);
  };

  const limit = 0;
  const page = 0;

  //dates variables
  const formattedStartDate = formatMonthDate(startSelectedDate);
  const formattedEndDate = formatMonthDate(endSelectedDate);

  //varirables for matterwise data
  const adminId = auth.user._id;
  let allMatterTotalBillableAmount = 0;
  let allMatterTotalBilledAmount = 0;
  let teamTotalDiscount = 0;
  let totalHoursClocked = 0;
  let leaveDays;
  let finalDays;

  //useEffects
  useEffect(() => {
    const loadData = async () => {
      setLoading(false);
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1500)); // Simulating API call delay
    };
    filterBetweenDates();
    filterAndSortData();
    loadData();
  }, [dispatch]);

  useEffect(() => {
    // Only call filterAndSortData if the necessary data has been loaded
    if (writeOffHours && leaves && filteredWorkHour) {
      // Assuming you have states to track these
      filterAndSortData();
    }
  }, [writeOffHours, leaves, filteredWorkHour]);

  let sendMail;

  const totalTeamAverageBillableHourPerDay =
    totalDays !== 0 ? parseFloat(totalTeamTime / totalDays).toFixed(1) : 0;

  const totalTeamAverageBilledHourPerDay =
    totalDays !== 0
      ? parseFloat(totalTeamBillableTime / totalDays).toFixed(1)
      : 0;

  // console.log("Employees", employeesData)

  const uniqueNames =
    employeesData.length > 0
      ? employeesData
          .filter((item) => !unBilledDesignations.includes(item.designation))
          .map((item) => item?.fullName)
      : [];

  const filteredNames =
    filteredWorkHour && filteredWorkHour.length > 0
      ? [
          ...new Set(
            filteredWorkHour &&
              filteredWorkHour.map((item) => item.user?.fullName)
          ),
        ]
      : [];

  const commonNames = uniqueNames.filter((name) =>
    filteredNames.includes(name)
  );

  // console.log(uniqueNames, commonNames, filteredWorkHour)

  //sort the matters to show data in clientwise format
  const sortedMatters = matters
    .filter(
      (item) =>
        (item.pricePerHour?.$numberDecimal !== "0" ||
          item.resourceSpecificPrice?.length > 0) &&
        item.status === "enable"
    )
    .sort((a, b) => {
      const clientA = a.client.name.toLowerCase();
      const clientB = b.client.name.toLowerCase();

      if (clientA < clientB) {
        return -1; // a comes before b
      }
      if (clientA > clientB) {
        return 1; // a comes after b
      }
      return 0; // a and b are equal
    });

  //Matterwise sorted data filteration
  const matterwiseSortedData = sortedMatters
    .map((matter) => {
      let currency;

      if (matter) {
        currency = matter.currency === "USD" ? "$" : "Rs. ";
        // console.log("Currency: ", currency);
        // Use the currency value as needed
      } else {
        currency = "";
        // console.log("Matter not found.");
      }

      let matterTotalBilledAmount = 0;
      let persontotalHours = 0;
      let teamTotalHours = 0;
      let teamTotalBilledHours = 0;
      let teamTotalBilledAmount = 0;
      let teamTotalBillableAmount = 0;

      commonNames.forEach((name) => {
        let personWorkHours = 0;
        let personWriteoffHours = 0;

        filteredWorkHour &&
          filteredWorkHour
            .filter(
              (result) =>
                result.user?.fullName === name &&
                result.role !== "OPE" &&
                result.matter?.name === matter?.name &&
                result.workDate >= startSelectedDate && // Add start date filter
                result.workDate <= endSelectedDate // Add end date filter
            )
            .forEach((result) => {
              const workHour = parseFloat(result.workHour);
              personWorkHours += workHour;
            });

        if (writeOffHours) {
          writeOffHours
            .filter(
              (result) =>
                result.user?.fullName === name &&
                result.matter._id === matter._id &&
                result.workDate >= startSelectedDate &&
                result.workDate <= endSelectedDate
            )
            .forEach((result) => {
              const workHour = parseFloat(result.workHour);
              personWriteoffHours += workHour;
            });
        }

        persontotalHours =
          parseFloat(personWorkHours) + parseFloat(personWriteoffHours);

        const newMatter = matters.find(
          (matterItem) => matterItem?.name.trim() === matter?.name.trim()
        );

        let price;
        if (newMatter) {
          if (newMatter.pricePerHour) {
            price = newMatter.pricePerHour.$numberDecimal;
          } else if (newMatter.resourceSpecificPrice.length !== 0) {
            const employeeEntry = newMatter.resourceSpecificPrice.find(
              (entry) => entry.includes(name)
            );

            if (employeeEntry) {
              const [, hours] = employeeEntry.split(":");
              price = hours.trim();
            }
          } else {
            price = 0;
          }
        }

        // console.log("Price ---------", price, persontotalHours);

        if (price) {
          const personTotalBilledAmount =
            matter.currency === "USD"
              ? price * personWorkHours
              : (price * personWorkHours) / 80;
          const personTotalBillableAmount =
            matter.currency === "USD"
              ? price * (personWorkHours + personWriteoffHours)
              : (price * (personWorkHours + personWriteoffHours)) / 80;
          teamTotalHours += persontotalHours;
          teamTotalBilledHours += personWorkHours;
          teamTotalBillableAmount += personTotalBillableAmount;
          teamTotalBilledAmount += personTotalBilledAmount;
        }
      });

      // Calculate teamTotalHours only if it's greater than 0
      if (teamTotalHours > 0) {
        allMatterTotalBillableAmount += teamTotalBillableAmount;
        allMatterTotalBilledAmount += teamTotalBilledAmount;
        teamTotalDiscount = teamTotalBillableAmount - teamTotalBilledAmount;
        totalHoursClocked += teamTotalHours;

        // Return the mapped object only if teamTotalHours is greater than 0
        return {
          matter,
          teamTotalBillableAmount,
          teamTotalBilledAmount,
          teamTotalHours,
        };
      }

      // If teamTotalHours is 0 or less, return null (or an empty object, as needed)
      return null;
    })
    .filter((mappedObject) => mappedObject !== null); // Remove null values from the result

  const generateMatterWisePR = () => {
    const doc = new jsPDF({
      orientation: "landscape", // Set the orientation to landscape
    });

    const tableColumns = [
      "Client",
      "Matter",
      "Actual Time Clocked",
      "Time Billed",
      "Discount",
      "Discount %",
      "Contribution To Total",
      "Hours Clocked",
      "ERPH",
    ];
    const tableData = [];

    matterwiseSortedData.forEach((rowData, index) => {
      const {
        matter,
        teamTotalBillableAmount,
        teamTotalBilledAmount,
        teamTotalHours,
      } = rowData;

      const tableDataArray = [
        matter?.client?.name,
        matter?.name,
        teamTotalBillableAmount
          .toFixed(1)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        teamTotalBilledAmount
          .toFixed(1)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        (teamTotalBillableAmount - teamTotalBilledAmount)
          .toFixed(1)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        ((
          ((teamTotalBillableAmount - teamTotalBilledAmount) /
            teamTotalBillableAmount) *
          100
        ).toFixed(1) !== "NaN"
          ? (
              ((teamTotalBillableAmount - teamTotalBilledAmount) /
                teamTotalBillableAmount) *
              100
            ).toFixed(1)
          : (0).toFixed(1)) + "%",
        ((teamTotalBilledAmount * 100) / allMatterTotalBilledAmount).toFixed(
          1
        ) + "%",
        teamTotalHours.toFixed(1),
        (teamTotalBilledAmount / teamTotalHours).toFixed(1) !== "NaN"
          ? (teamTotalBilledAmount / teamTotalHours).toFixed(1)
          : "0.00",
      ];

      tableData.push(tableDataArray);
    });

    const footerRow = [
      {
        content: "Total Amount  (billed in USD)",
        colSpan: 2,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      {
        content: allMatterTotalBillableAmount
          .toFixed(1)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // colSpan: 3,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      {
        content: allMatterTotalBilledAmount
          .toFixed(1)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // colSpan: 3,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
    ];

    const footerRow1 = [
      {
        content: "Total Amount (billed in Rs.)",
        colSpan: 2,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      (allMatterTotalBillableAmount * 80)
        .toFixed(1)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      (allMatterTotalBilledAmount * 80)
        .toFixed(1)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      teamTotalDiscount
        .toFixed(1)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      "",
      "",
      totalHoursClocked.toFixed(1),
    ];

    tableData.push(footerRow);
    tableData.push(footerRow1);

    //First Page
    doc.setFontSize(25);
    doc.setFont("helvetica", "bold");
    doc.text(
      "Matterwise Performance Report",
      (297 - doc.getTextWidth("Matterwise Performance Report")) / 2,
      20
    );
    doc.setFont("helvetica", "underline");
    doc.setFontSize(15);
    doc.text(
      `${formattedStartDate}` +
        (startSelectedDate !== endSelectedDate ? ` - ${formattedEndDate}` : ""),
      (297 -
        doc.getTextWidth(
          `${formattedStartDate}` +
            (startSelectedDate !== endSelectedDate
              ? ` - ${formattedEndDate}`
              : "")
        )) /
        2,
      30
    );

    doc.autoTable(tableColumns, tableData, {
      startY: 40,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
        if (data.row.section === "body") {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
        }
        if (
          data.row.section === "body" &&
          data.column.index !== 0 &&
          data.column.index !== 1
        ) {
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 27;
        }
        if (data.column.index === 1 && data.column.index === 0) {
          data.cell.styles.cellWidth = 80;
        }
      },
    });

    doc.save("Matterwise_Performance_Report.pdf");
  };

  //sortedData for resourcewise performance report
  const filterAndSortData = () => {
    let totalTeamTime = 0;
    let totalTeamBillableTime = 0;
    let totalNonBillableTime = 0;
    let totalDays = 0; // Assuming this is what you meant by "total final days"

    // console.log("commonNames", commonNames);

    const data = commonNames
      .map((name) => {
        const personWorkHours =
          filteredWorkHour &&
          filteredWorkHour.filter((item) => item.user?.fullName === name);

        //writeoff entries data filteration
        const personWriteoffEntries = writeOffHours.filter(
          (result) =>
            result.user?.fullName === name &&
            result.workDate >= startSelectedDate &&
            result.workDate <= endSelectedDate
        );

        const personWriteoffHours =
          personWriteoffEntries.reduce(
            (total, result) => total + parseFloat(result.workHour),
            0
          ) || 0.0;

        //billable and non billable entries data filteration
        const billableEntries = personWorkHours.filter(
          (item) =>
            item.matter?.pricePerHour?.$numberDecimal !== "0" ||
            item.matter?.resourceSpecificPrice?.length > 0
        );

        const personBillableTime =
          billableEntries.reduce(
            (total, item) => total + parseFloat(item.workHour),
            0
          ) || 0.0;

        const nonBillableEntries = personWorkHours.filter(
          (item) => item.matter?.pricePerHour?.$numberDecimal === "0"
        );

        const personNonBillableTime =
          nonBillableEntries.reduce(
            (total, item) => total + parseFloat(item.workHour),
            0
          ) || 0.0;

        //Calculating total leave days with holidays
        leaveDays = leaves
          .filter(
            (leave) =>
              leave.user.fullName === name &&
              leave.leaveStartDate >= startSelectedDate &&
              leave.leaveEndDate <= endSelectedDate
          )
          .reduce((total, item) => (total += parseFloat(item.leaveDays)), 0);

        // console.log("Leave Days", name, leaveDays);

        const holidaysWithinLeavePeriod = holidays.filter((holiday) => {
          return holiday >= startSelectedDate && holiday <= endSelectedDate;
        });

        const holidayDays = holidaysWithinLeavePeriod.length;
        const totalLeaveDaysWithHolidays = leaveDays + holidayDays;

        // console.log("totalLeaveDaysWithHolidays", name, leaveDays, "hd",holidayDays, totalLeaveDaysWithHolidays)

        let totalDaysAfterJoining = 0;

        const joiningDate =
          employeesData.filter((employee) => employee.fullName === name)[0]
            .joiningDate !== ""
            ? employeesData.filter((employee) => employee.fullName === name)[0]
                .joiningDate
            : "";
        // console.log("Joining date", name, joiningDate);

        if (startSelectedDate < joiningDate) {
          let currentDate = new Date(joiningDate); // Use joiningDate as the starting point
          while (currentDate <= new Date(endSelectedDate)) {
            const dayOfWeek = currentDate.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
              // Increment totalDaysAfterJoining only on weekdays (Monday to Friday)
              totalDaysAfterJoining++;
            }
            currentDate.setDate(currentDate.getDate() + 1);
          }
        } else {
          let currentDate = new Date(startSelectedDate); // Use joiningDate as the starting point
          while (currentDate <= new Date(endSelectedDate)) {
            const dayOfWeek = currentDate.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
              // Increment totalDaysAfterJoining only on weekdays (Monday to Friday)
              // console.log(dayOfWeek)
              totalDaysAfterJoining++;
            }
            currentDate.setDate(currentDate.getDate() + 1);
          }
        }

        finalDays = totalDaysAfterJoining - totalLeaveDaysWithHolidays;

        const averageBillableHoursPerDay =
          parseFloat(
            (personBillableTime + personWriteoffHours) / finalDays
          ).toFixed(1) !== "Infinity"
            ? parseFloat(
                (personBillableTime + personWriteoffHours) / finalDays
              ).toFixed(1)
            : "0.0";

        const averageBilledHoursPerDay =
          parseFloat(personBillableTime / finalDays).toFixed(1) !== "Infinity"
            ? parseFloat(personBillableTime / finalDays).toFixed(1)
            : "0.0";

        // Aggregating data
        if (personBillableTime > 0) {
          totalTeamTime += personBillableTime + personWriteoffHours;
          totalTeamBillableTime += personBillableTime;
          totalNonBillableTime += personNonBillableTime;
          totalDays += finalDays;
          // Return your mapped object
          return {
            finalDays,
            name,
            averageBillableHoursPerDay,
            averageBilledHoursPerDay,
            personBillableTime,
            personWriteoffHours,
            personNonBillableTime,
          };
        }
        return null; // For filtering out later
      })
      .filter((item) => item !== null) // Remove any nulls
      .sort(
        (a, b) => b.averageBillableHoursPerDay - a.averageBillableHoursPerDay
      );

    // Now set the sorted data
    setSortedData(data);

    // Additionally, update state for your aggregated values so they can be used outside
    setTotalTeamTime(totalTeamTime);
    setTotalTeamBillableTime(totalTeamBillableTime);
    setTotalNonBillableTime(totalNonBillableTime);
    setTotalDays(totalDays); // You'll need to manage state for these

    // console.log("sorted data", data, totalTeamTime);

    // If you need to return these from the function (e.g., if not using state), you could also do that:
    return {
      sortedData: data,
      totalTeamTime,
      totalTeamBillableTime,
      totalNonBillableTime,
      totalDays,
    };
  };

  const generateDailyPR = () => {
    const doc = new jsPDF({
      orientation: "landscape", // Set the orientation to landscape
    });

    const tableColumns = [
      "Resource",
      "Period / Day",
      "Working days",
      "Available Billable Hours",
      "Billable Hours Utilized",
      "Average Billable Hours/Day",
      "Available Non-Billable Hours",
      "Non-Billable Hours Utilized",
    ];

    const tableData = [];

    let totalBillableTime = 0;
    let totalNonBilledTime = 0;
    // let totalBillableTimeInWeek = 0;
    // let totalNonBilledTimeInWeek = 0;
    // let totalBillableTimeInMonth = 0;
    // let totalNonBilledTimeInMonth = 0;

    sortedData.forEach((rowData, index) => {
      const {
        finalDays,
        // finalDaysInMonth,
        // finalDaysInWeek,
        name,
        averageBillableHoursPerDay,
        // averageBillableHoursPerDayInMonth,
        // averageBillableHoursPerDayInWeek,
        personBillableTime,
        // personBillableTimeInMonth,
        // personBillableTimeInWeek,
        personWriteoffHours,
        // personWriteoffHoursInMonth,
        // personWriteoffHoursInWeek,
        personNonBillableTime,
        // personNonBillableTimeInMonth,
        // personNonBillableTimeInWeek,
      } = rowData;

      const employeeDataArray1 = [
        name,
        `${formattedStartDate}` +
          (startSelectedDate !== endSelectedDate
            ? `- ${formattedEndDate}`
            : ""),
        finalDays,
        7 * finalDays,
        parseFloat(personBillableTime + personWriteoffHours).toFixed(1),
        averageBillableHoursPerDay,
        2 * finalDays,
        parseFloat(personNonBillableTime).toFixed(1),
      ];

      // const employeeDataArray2 = [
      //   "",
      //   `${formatMonthDate(
      //     formattedLastSaturdayPriorToLastFriday
      //   )} - ${formatMonthDate(formattedLastFriday)}`,
      //   finalDaysInWeek,
      //   7 * finalDaysInWeek,
      //   parseFloat(
      //     personBillableTimeInWeek + personWriteoffHoursInWeek
      //   ).toFixed(1),
      //   averageBillableHoursPerDayInWeek,
      //   2 * finalDaysInWeek,
      //   parseFloat(personNonBillableTimeInWeek).toFixed(1),
      // ];

      // const employeeDataArray3 = [
      //   "",
      //   `${formatMonthDate(formattedFirstDayOfMOnth)} - ${formatMonthDate(
      //     endSelectedDate
      //   )}`,
      //   finalDaysInMonth,
      //   7 * finalDaysInMonth,
      //   parseFloat(
      //     personBillableTimeInMonth + personWriteoffHoursInMonth
      //   ).toFixed(1),
      //   averageBillableHoursPerDayInMonth,
      //   2 * finalDaysInMonth,
      //   parseFloat(personNonBillableTimeInMonth).toFixed(1),
      // ];

      // Push the three arrays for the same employee
      tableData.push(employeeDataArray1);
      // tableData.push(employeeDataArray2);
      // tableData.push(employeeDataArray3);

      totalBillableTime +=
        parseFloat(personBillableTime) + parseFloat(personWriteoffHours);

      totalNonBilledTime += parseFloat(personNonBillableTime);

      // totalBillableTimeInWeek +=
      //   parseFloat(personBillableTimeInWeek) +
      //   parseFloat(personWriteoffHoursInWeek);

      // totalNonBilledTimeInWeek += parseFloat(personNonBillableTimeInWeek);

      // totalBillableTimeInMonth +=
      //   parseFloat(personBillableTimeInMonth) +
      //   parseFloat(personWriteoffHoursInMonth);

      // totalNonBilledTimeInMonth += parseFloat(personNonBillableTimeInMonth);
    });

    const footerRow1 = [
      "Total",
      "",
      "",
      commonNames.length * 7 * finalDays,
      totalBillableTime.toFixed(1),
      "",
      commonNames.length * 2 * finalDays,
      totalNonBilledTime.toFixed(1),
    ];

    // const footerRow2 = [
    //   "Weekly Total",
    //   "",
    //   "",
    //   commonNames.length * 7 * finalDaysInWeek,
    //   totalBillableTimeInWeek.toFixed(1),
    //   "",
    //   commonNames.length * 2 * finalDaysInWeek,
    //   totalNonBilledTimeInWeek.toFixed(1),
    // ];

    // const footerRow3 = [
    //   "Monthly Total",
    //   "",
    //   "",
    //   commonNames.length * 7 * finalDaysInMonth,
    //   totalBillableTimeInMonth.toFixed(1),
    //   "",
    //   commonNames.length * 2 * finalDaysInMonth,
    //   totalNonBilledTimeInMonth.toFixed(1),
    // ];

    tableData.push(footerRow1);
    // tableData.push(footerRow2);
    // tableData.push(footerRow3);

    //First Page
    doc.setFontSize(25);
    doc.setFont("helvetica", "bold");
    doc.text(
      "Time Spent Report",
      (297 - doc.getTextWidth("Time Spent Report")) / 2,
      20
    );

    doc.setFont("helvetica", "underline");
    doc.setFontSize(15);
    doc.text(
      `${formattedStartDate}` +
        (startSelectedDate !== endSelectedDate ? ` - ${formattedEndDate}` : ""),
      (297 -
        doc.getTextWidth(
          `${formattedStartDate}` +
            (startSelectedDate !== endSelectedDate
              ? ` - ${formattedEndDate}`
              : "")
        )) /
        2,
      30
    );

    doc.autoTable(tableColumns, tableData, {
      startY: 40,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }

        if (data.row.section === "body") {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 30;
        }

        if (data.column.index === 0) {
          data.cell.styles.halign = "left";
          data.cell.styles.cellWidth = 38.8;
        }
        if (data.column.index === 1) {
          data.cell.styles.halign = "left";
          data.cell.styles.cellWidth = 50;
        }
      },
    });

    doc.save("Resource_Performance_Daily_Report.pdf");
  };

  const generateWeeklyPR = () => {
    const doc = new jsPDF({
      orientation: "landscape", // Set the orientation to landscape
    });

    const tableColumns = [
      "Resource",
      "No. of days worked",
      "Avail Billable Hours",
      "Billable Hours Utilized",
      "Actual Billed",
      "Discounting",
      "Avg Billable hrs/day",
      "Avg Billed hrs/day",
      "Avail Non-Billable Hours",
      "Non-Billable Hours Utilized",
      "Missed Billing Potential(7 hrs/day)",
    ];
    const tableData = [];

    let totalBillableHours = 0;
    let totalBilledTime = 0;
    let totalNonBilledTime = 0;
    const finalData = [];

    sortedData.forEach((rowDataWeek, index) => {
      const {
        finalDays,
        name,
        personBillableTime,
        personWriteoffHours,
        personNonBillableTime,
      } = rowDataWeek;

      const tableDataArray = [
        name,
        finalDays,
        7 * finalDays,
        parseFloat(personBillableTime + personWriteoffHours).toFixed(1),
        parseFloat(personBillableTime).toFixed(1),
        ((
          (1 -
            personBillableTime / (personBillableTime + personWriteoffHours)) *
          100
        ).toFixed(1) !== "NaN"
          ? (
              (1 -
                personBillableTime /
                  (personBillableTime + personWriteoffHours)) *
              100
            ).toFixed(1)
          : 0.0) + "%",
        parseFloat(
          (personBillableTime + personWriteoffHours) / finalDays
        ).toFixed(1),
        parseFloat(personBillableTime / finalDays).toFixed(1),
        2 * finalDays,
        personNonBillableTime.toFixed(1),
        parseFloat((1 - personBillableTime / (7 * finalDays)) * 100).toFixed(
          2
        ) + "%",
      ];

      totalBillableHours += parseFloat(
        personBillableTime + personWriteoffHours
      );

      totalBilledTime += parseFloat(personBillableTime);
      totalNonBilledTime += parseFloat(personNonBillableTime.toFixed(1));

      const rowData = {
        data: tableDataArray,
        value: parseFloat(
          (personBillableTime + personWriteoffHours) / finalDays
        ).toFixed(1),
      };

      finalData.push(rowData);
    });

    finalData.sort((a, b) => b.value - a.value);

    finalData.forEach((rowData) => {
      tableData.push(rowData.data);
    });

    const footerRow = [
      "Total Hours",
      totalDays,
      7 * totalDays,
      totalTeamTime
        .toFixed(1)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      // totalBillableHours.toFixed(1),
      totalTeamBillableTime
        .toFixed(1)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      // totalBilledTime.toFixed(1),
      parseFloat((1 - totalBilledTime / totalBillableHours) * 100).toFixed(1) +
        "%",
      parseFloat(totalTeamAverageBillableHourPerDay)
        .toFixed(1)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      parseFloat(totalTeamAverageBilledHourPerDay)
        .toFixed(1)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      2 * totalDays,
      totalNonBillableTime
        .toFixed(1)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      "",
    ];

    tableData.push(footerRow);

    //First Page
    doc.setFontSize(25);
    doc.setFont("helvetica", "bold");
    doc.text(
      "Time Spent Report",
      (297 - doc.getTextWidth("Time Spent Report")) / 2,
      20
    );
    doc.setFont("helvetica", "underline");
    doc.setFontSize(15);
    doc.text(
      `${formattedStartDate}` +
        (startSelectedDate !== endSelectedDate ? ` - ${formattedEndDate}` : ""),
      (297 -
        doc.getTextWidth(
          `${formattedStartDate}` +
            (startSelectedDate !== endSelectedDate
              ? ` - ${formattedEndDate}`
              : "")
        )) /
        2,
      30
    );

    doc.autoTable(tableColumns, tableData, {
      startY: 40,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (data.row.section === "body") {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 23.5; // Set the width to 50 units
        }
        if (data.column.index === 0) {
          data.cell.styles.halign = "left";
          data.cell.styles.cellWidth = 33.7; // Set the width to 50 units
        }
      },
    });

    doc.save("Resource_Performance_Report.pdf");
  };

  const generateResourceWiseAmountReport = () => {
    const doc = new jsPDF();

    const tableColumns = [
      // "Period / Day",
      "Resource Name",
      "Matter Names",
      "Billed Hours",
      "Total Amount",
      "Total Amount (All Matters)",
    ];

    const tableData = [];

    const sortedData = commonNames.map((name, index) => {
      const personWorkHours =
        filteredWorkHour &&
        filteredWorkHour.filter(
          (item) =>
            item.user?.fullName === name &&
            item.role !== "OPE" &&
            item.workDate >= startSelectedDate &&
            item.workDate <= endSelectedDate
        );

      const billableEntries = personWorkHours.filter(
        (item) =>
          item.matter?.pricePerHour?.$numberDecimal !== "0" ||
          item.matter?.resourceSpecificPrice?.length > 0
      );
      // console.log("billableHours", name, billableEntries);

      const matterNames = [
        ...new Set(
          billableEntries
            .filter((entry) => entry.matter?.name)
            .map((entry) => entry.matter.name)
        ),
      ];

      // console.log("MatterNames", name, matterNames);

      const matterTimeAndAmounts = matterNames.map((matterName) => {
        const filteredEntries = billableEntries.filter(
          (entry) => entry.matter?.name === matterName
        );

        const totalTime = filteredEntries.reduce(
          (total, entry) => total + parseFloat(entry.workHour),
          0
        );

        const totalAmount = filteredEntries.reduce((total, entry) => {
          let price;

          if (entry.matter.pricePerHour) {
            price = parseFloat(entry.matter.pricePerHour.$numberDecimal);
          } else if (entry.matter.resourceSpecificPrice) {
            const resourcePriceEntry = entry.matter.resourceSpecificPrice.find(
              (data) => {
                const [resourceName, resourcePrice] = data
                  .split(":")
                  .map((item) => item.trim());
                return resourceName === name;
              }
            );
            if (resourcePriceEntry) {
              const [, resourcePrice] = resourcePriceEntry
                .split(":")
                .map((item) => item.trim());
              price = parseFloat(resourcePrice);
            }
          }

          if (typeof price === "number") {
            total +=
              price *
              entry.workHour *
              (entry.matter.currency === "USD" ? 80 : 1);
          }

          return total;
        }, 0);

        return { totalAmount, totalTime, matterName }; // Return an object containing both values
      });

      // console.log(
      //   startSelectedDate,
      //   endSelectedDate,
      //   name,
      //   matterNames,
      //   matterTimeAndAmounts
      // );

      return {
        startSelectedDate,
        endSelectedDate,
        name,
        matterNames,
        matterTimeAndAmounts,
      };
    });

    // Sort the data based on the total amount of all matters
    sortedData.sort((a, b) => {
      const totalAmountA = a.matterTimeAndAmounts.reduce(
        (total, entry) => total + entry.totalAmount,
        0
      );
      const totalAmountB = b.matterTimeAndAmounts.reduce(
        (total, entry) => total + entry.totalAmount,
        0
      );
      return totalAmountB - totalAmountA;
    });

    sortedData.forEach((data) => {
      let isFirstRowForEmployee = true;
      let totalAmountForEmployee = 0;

      data.matterTimeAndAmounts.forEach((entry) => {
        if (isFirstRowForEmployee) {
          tableData.push([data.name, "", "", "", ""]);
          isFirstRowForEmployee = false;
        }

        tableData.push([
          "",
          entry.matterName,
          entry.totalTime.toFixed(1),
          entry.totalAmount
            .toFixed(1)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          "",
        ]);

        totalAmountForEmployee += entry.totalAmount;
      });

      // Add summary row for the employee's total amount
      tableData.push([
        {
          content: "Total Amount for " + data.name,
          styles: { fillColor: [207, 207, 207] },
          colSpan: 4,
        },
        {
          content: totalAmountForEmployee
            .toFixed(1)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          styles: { fillColor: [207, 207, 207] },
        },
      ]);
    });

    // Calculate the total amount across all employees
    const totalAmountAllEmployees = sortedData.reduce((total, data) => {
      data.matterTimeAndAmounts.forEach((entry) => {
        total += entry.totalAmount;
      });
      return total;
    }, 0);

    // Add summary row for the total amount across all employees
    tableData.push([
      {
        content: "Total Amount (All Employees)",
        styles: { fillColor: [207, 207, 207] },
        colSpan: 4,
      },
      {
        content: totalAmountAllEmployees
          .toFixed(1)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        styles: { fillColor: [207, 207, 207] },
      },
    ]);

    //First Page
    doc.setFontSize(25);
    doc.setFont("helvetica", "bold");
    doc.text("Resource Performance Report", 40, 20);

    doc.setFont("helvetica", "underline");
    doc.setFontSize(15);
    doc.text(
      `${formattedStartDate}` +
        (startSelectedDate !== endSelectedDate ? ` - ${formattedEndDate}` : ""),
      (210 -
        doc.getTextWidth(
          `${formattedStartDate}` +
            (startSelectedDate !== endSelectedDate
              ? ` - ${formattedEndDate}`
              : "")
        )) /
        2,
      30
    );

    doc.autoTable(tableColumns, tableData, {
      startY: 40,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
      },
    });

    doc.save("Resource_Performance_Report.pdf");
  };

  async function filterBetweenDates() {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${API}/workHour/filterWorkHourByUserId`,
        {
          params: {
            user: selectedLawyer,
            fromDate: startSelectedDate,
            toDate: endSelectedDate,
            matterId,
            adminId,
            needFin,
            sendMail,
            role: "",
            limit: 0,
            page: 0,
          },
        }
      );
      // console.log("WorkHours Data", response.data);
      if (response.data) {
        setFilteredWorkHour(response.data.workHours);
      }

      dispatch(
        fetchWriteoffsByAdminId(
          adminId,
          startSelectedDate,
          endSelectedDate,
          matterId,
          selectedLawyer
        )
      );
      fetchAllLeaves();
      setLoading(false);
      filterAndSortData();
    } catch (error) {
      // Handle the error here
      console.error("Error occurred:", error);
      // Optionally, you can set an error state or display an error message to the user
    }
  }

  const fetchAllLeaves = async () => {
    try {
      const response = await axiosInstance.get(`${API}/leaves`, {
        params: {
          adminId: auth.user._id,
          userId: selectedLawyer,
          startSelectedDate,
          endSelectedDate,
          limit: 0,
          page,
        },
      });

      setLeaves(
        response.data.leavesWithDays.filter(
          (leave) => leave.leaveType === "leave"
        )
      );
      // console.log("Leaves data in performance report------", leaves);
    } catch (error) {
      console.error("Error fetching leaves:", error);
    }
  };

  const columns = [
    "Resource",
    "Period/Day",
    "No. of days worked",
    "Available Billable Hours",
    "Billable Hours Utilized",
    "Actual Billed",
    "Discounting",
    "Average Billable hrs/day",
    "Average Billed hrs/day",
    "Available Non-Billable",
    "Non-Billable Hours Utilized",
    "Missed Billing Potential (7 hrs/day)",
  ];

  const columns2 = [
    "Client",
    "Matter",
    "Actual Time Clocked",
    "Time Billed",
    "Discount",
    "Discount %",
    "Contribution To Total",
    "Hours Clocked",
    "ERPH",
  ];

  return (
    <>
      {/* Heading */}
      <Grid container marginBottom={3}>
        <Grid item>
          <CustomHeading variant="h5" text="Performance" />
        </Grid>
      </Grid>

      {/* Filters  */}
      <Paper elevation={3} sx={{ padding: 2, marginY: 2 }}>
        <Grid container spacing={1} alignItems="baseline" marginBottom="1rem">
          <Grid item xs={12} sm={7} md={2.5}>
            <Select
              options={filteredMatters}
              value={filteredMatters.find(
                (option) => option.label === `${selectedMatterName} | ${client}`
              )}
              onChange={(e) => {
                setMatterId(e.value);
                setSelectedMatterName(e.label.split("|")[0]);
                setClient(e.label.split("|")[1]);
              }}
              isSearchable
              placeholder="Select Matter"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={5} md={1.5}>
            <Select
              options={filteredEmployees}
              value={filteredEmployees.find(
                (resOption) => resOption.label === `${selectedLawyer}`
              )}
              onChange={(e) => {
                setSelectedLawyer(e.value);
              }}
              isSearchable
              placeholder="Resource"
              fullWidth
            />
          </Grid>

          <Grid item xs={6} sm={6} md={1.5}>
            <input
              type="date"
              className="date"
              value={startSelectedDate}
              onChange={(e) => setStartSelectedDate(e.target.value)}
              style={{
                width: "100%",
                // padding: "0.5rem",
                boxSizing: "border-box",
              }}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={1.5}>
            <input
              type="date"
              className="date"
              value={endSelectedDate}
              onChange={(e) => setEndSelectedDate(e.target.value)}
              style={{
                width: "100%",
                // padding: "0.5rem",
                boxSizing: "border-box",
              }}
            />
          </Grid>

          <Grid item xs={12} sm={7} md={1.5}>
            <CustomButton
              variant="contained"
              fontSize="13px"
              style={{ width: "100%" }}
              onClick={(event) => handleClick(event, setAnchorElSearch)}
            >
              <Search style={{ marginRight: "8px" }} />
              Search
            </CustomButton>
            <Menu
              anchorEl={anchorElSearch}
              open={Boolean(anchorElSearch)}
              onClose={() => handleClose(setAnchorElSearch)}
            >
              <MenuItem
                onClick={() => {
                  filterBetweenDates();
                  setShowMatterData(false);
                  setShowResourceData(true);
                  handleClose(setAnchorElSearch);
                }}
              >
                Resourcewise
              </MenuItem>
              <MenuItem
                onClick={() => {
                  filterBetweenDates();
                  setShowMatterData(true);
                  setShowResourceData(false);
                  handleClose(setAnchorElSearch);
                }}
              >
                Matterwise
              </MenuItem>
            </Menu>
          </Grid>

          <Grid item xs={12} sm={7} md={1.5}>
            <CustomButton
              variant="outlined"
              fontSize="13px"
              style={{ width: "100%" }}
              onClick={() => {
                // handleClose(setAnchorElSendMail);
                if (startSelectedDate !== "" && endSelectedDate !== "") {
                  dispatch(
                    filterTimeByUserIdForAdmin(
                      selectedLawyer,
                      startSelectedDate,
                      endSelectedDate,
                      matterId,
                      needFin,
                      (sendMail = "true"),
                      adminId,
                      limit,
                      page
                    )
                  );
                  dispatch(
                    fetchWriteoffsByAdminId(
                      adminId,
                      startSelectedDate,
                      endSelectedDate,
                      matterId,
                      selectedLawyer
                    )
                  );
                  fetchAllLeaves();
                } else {
                  alert("Select the dates!");
                }
              }}
            >
              <Mail style={{ marginRight: "8px" }} />
              Send Mail
            </CustomButton>
          </Grid>

          <Grid item xs={12} sm={7} md={2}>
            <CustomButton
              variant="outlined"
              fontSize="13px"
              style={{ width: "100%" }}
              onClick={(event) => handleClick(event, setAnchorElDownload)}
            >
              <Download style={{ marginRight: "8px" }} />
              Download PR
            </CustomButton>
            <Menu
              anchorEl={anchorElDownload}
              open={Boolean(anchorElDownload)}
              onClose={() => handleClose(setAnchorElDownload)}
            >
              <MenuItem onClick={generateDailyPR}>Daily</MenuItem>
              <MenuItem onClick={generateWeeklyPR}>Weekly/Monthly</MenuItem>
              <MenuItem onClick={generateMatterWisePR}>Matterwise</MenuItem>
              <MenuItem onClick={generateResourceWiseAmountReport}>
                Compensation
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Paper>

      {/* Main Table  */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "75vh" }}>
          <Table aria-label="reusable table">
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={13}
                  style={{ fontWeight: "bold", textAlign: "center" }}
                >
                  <CustomHeading
                    fontSize="16px"
                    align="center"
                    text={
                      showResourceData
                        ? "Resources Timespent Data"
                        : "Matters Timespent Data"
                    }
                  />
                </TableCell>
              </TableRow>
              {showResourceData ? (
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell key={index} id="table-header">
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              ) : (
                <TableRow>
                  {columns2.map((column, index) => (
                    <TableCell key={index} id="table-header">
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableHead>

            {loading ? (
              <>
                <SkeletonRows rows={6} colSpan={12} />
              </>
            ) : filteredWorkHour.length === 0 ? (
              <TableRow>
                <TableCell colSpan={13}>
                  <NoDataFound message="Oops! No workhours found." />
                </TableCell>
              </TableRow>
            ) : showResourceData ? (
              sortedData.map((rowData, index) => {
                const {
                  finalDays,
                  name,
                  averageBillableHoursPerDay,
                  averageBilledHoursPerDay,
                  personBillableTime,
                  personWriteoffHours,
                  personNonBillableTime,
                } = rowData;

                return (
                  <TableRow
                    key={index}
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      backgroundColor: index % 2 ? "white" : "#eae4dd",
                      fontFamily: "Prata",
                    }}
                  >
                    <TableCell>{name}</TableCell>
                    <TableCell>
                      {formatMonthDate(startSelectedDate)}
                      {startSelectedDate !== endSelectedDate &&
                        ` - ${formatMonthDate(endSelectedDate)}`}
                    </TableCell>
                    <TableCell>{finalDays ? finalDays : 0}</TableCell>
                    <TableCell>{7 * finalDays}</TableCell>
                    <TableCell>
                      {parseFloat(
                        personBillableTime + personWriteoffHours
                      ).toFixed(1)}
                    </TableCell>
                    <TableCell>
                      {parseFloat(personBillableTime).toFixed(1)}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {((
                        (1 -
                          personBillableTime /
                            parseFloat(
                              personBillableTime + personWriteoffHours
                            )) *
                        100
                      ).toFixed(1) !== "NaN"
                        ? (
                            (1 -
                              personBillableTime /
                                parseFloat(
                                  personBillableTime + personWriteoffHours
                                )) *
                            100
                          ).toFixed(1)
                        : "0.0") + "%"}
                    </TableCell>
                    <TableCell>{averageBillableHoursPerDay}</TableCell>
                    <TableCell>{averageBilledHoursPerDay}</TableCell>
                    <TableCell>{2 * finalDays}</TableCell>
                    <TableCell>{personNonBillableTime.toFixed(1)}</TableCell>
                    <TableCell>
                      {parseFloat(
                        (1 - personBillableTime / (7 * finalDays)) * 100
                      ).toFixed(1) !== "-Infinity"
                        ? parseFloat(
                            (1 - personBillableTime / (7 * finalDays)) * 100
                          ).toFixed(1)
                        : "0.0"}
                      %
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              matterwiseSortedData.map((rowData, index) => {
                const {
                  matter,
                  teamTotalBillableAmount,
                  teamTotalBilledAmount,
                  teamTotalHours,
                } = rowData;

                return (
                  <>
                    <TableRow
                      key={index}
                      role="checkbox"
                      tabIndex={-1}
                      sx={{
                        backgroundColor: index % 2 ? "white" : "#eae4dd",
                        fontFamily: "Prata",
                      }}
                    >
                      <TableCell>{matter.client.name}</TableCell>
                      <TableCell>{matter?.name}</TableCell>
                      <TableCell>
                        {teamTotalBillableAmount
                          .toFixed(1)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell>
                        {teamTotalBilledAmount
                          .toFixed(1)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell>
                        {(teamTotalBillableAmount - teamTotalBilledAmount)
                          .toFixed(1)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell>
                        {((
                          ((teamTotalBillableAmount - teamTotalBilledAmount) /
                            teamTotalBillableAmount) *
                          100
                        ).toFixed(1) !== "NaN"
                          ? (
                              ((teamTotalBillableAmount -
                                teamTotalBilledAmount) /
                                teamTotalBillableAmount) *
                              100
                            ).toFixed(1)
                          : (0).toFixed(1)) + "%"}
                      </TableCell>
                      <TableCell>
                        {((
                          (teamTotalBilledAmount * 100) /
                          allMatterTotalBilledAmount
                        ).toFixed(1) !== "NaN"
                          ? (
                              (teamTotalBilledAmount * 100) /
                              allMatterTotalBilledAmount
                            ).toFixed(1)
                          : (0).toFixed(1)) + "%"}
                      </TableCell>
                      <TableCell>{teamTotalHours.toFixed(1)}</TableCell>
                      <TableCell>
                        {(teamTotalBilledAmount / teamTotalHours).toFixed(1) !==
                        "NaN"
                          ? (teamTotalBilledAmount / teamTotalHours).toFixed(1)
                          : "0.00"}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })
            )}

            {showMatterData && (
              <>
                <TableRow
                  sx={{
                    fontFamily: "Prata",
                    backgroundColor: "#372a1b",
                    color: "white",
                  }}
                >
                  <TableCell colSpan={2}>
                    <b>Total Amount (in Dollars)</b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {allMatterTotalBillableAmount
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {allMatterTotalBilledAmount
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {teamTotalDiscount
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <b>{totalHoursClocked.toFixed(1)}</b>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    backgroundColor: "#886a47",
                    color: "white",
                    fontFamily: "Prata",
                  }}
                >
                  <TableCell colSpan={2}>
                    <b>Total Amount (in Rupees)</b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {(allMatterTotalBillableAmount * 80)
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {(allMatterTotalBilledAmount * 80)
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>{" "}
                  </TableCell>
                  <TableCell colSpan={5}></TableCell>
                </TableRow>
              </>
            )}

            {showResourceData && totalDays !== 0 && (
              <>
                <TableRow
                  sx={{
                    fontFamily: "Prata",
                    backgroundColor: "#372a1b",
                    color: "white",
                  }}
                >
                  <TableCell colSpan={2}>
                    <b>Total Team Performance</b>
                  </TableCell>
                  <TableCell>
                    <b>{totalDays}</b>
                  </TableCell>
                  <TableCell>
                    <b>{7 * totalDays}</b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {totalTeamTime
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {totalTeamBillableTime
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </TableCell>
                  <TableCell>
                    {parseFloat(
                      (1 - totalTeamTime / totalTeamBillableTime) * 100
                    ).toFixed(1)}{" "}
                    %
                  </TableCell>
                  <TableCell>
                    <b>
                      {parseFloat(totalTeamAverageBillableHourPerDay)
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {parseFloat(totalTeamAverageBilledHourPerDay)
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>{2 * totalDays}</b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {totalNonBillableTime
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default PerformanceReport;
