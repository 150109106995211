import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllClients,
  getAllEmployeesByAdminId,
  getAllMatters,
} from "../../actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import formatDate, {
  API,
  billedDesignations,
  errorAlert,
  successAlert,
} from "../../utils/services";
import { Grid, Paper } from "@mui/material";
import CustomButton from "../../components/common/CustomButton";
import CustomHeading from "../../components/common/CustomHeading";
import axiosInstance from "../../helpers/axios";
import "./AddMatter.css";

const AddMatter = ({ onClose }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const adminEmployees = useSelector((state) => state.adminEmployees);
  const adminClient = useSelector((state) => state.adminClient);

  const { clientList } = adminClient;
  const { employees } = adminEmployees;

  //useStates
  const [radioValue, setRadioValue] = useState("Price/hour");
  const [resourceSpecificPrices, setResourceSpecificPrices] = useState({});
  const [designationPrices, setDesignationPrices] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    client: "",
    currency: "",
    pricePerHour: "",
    resourceSpecificPrice: "",
    matterOpenDate: formatDate(new Date()),
    matterAddress: "",
    engagementLetter: [],
    flatFeesAmount: "",
  });

  //matter el states
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);

  const clientNames = clientList.map((client) => ({
    value: client._id,
    label: `${client.name}`,
  }));

  useEffect(() => {
    dispatch(getAllClients());
    dispatch(getAllEmployeesByAdminId());
  }, []);

  const isValidObjField = () => {
    if (
      formData.client === "" ||
      formData.name === "" ||
      formData.currency === "" ||
      formData.matterOpenDate === ""
    ) {
      return false;
    } else {
      return true;
    }
    // return Object.values(obj).every(value => value.trim())
  };

  const isValidPriceField = () => {
    if (true) {
      return true;
    } else {
      return false;
    }
    // return Object.values(obj).every(value => value.trim())
  };

  const isValidForm = () => {
    //all fields must be filled
    if (!isValidObjField()) return errorAlert("All Fields are Required!");
    if (!isValidPriceField()) return errorAlert("Enter any one price field");
    return true;
  };

  //input change function
  const handleOnChangeText = (value, name, type, index) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleDesignationPriceChange = (designation, price) => {
    setDesignationPrices((prevPrices) => ({
      ...prevPrices,
      [designation]: price,
    }));
  };

  //TODO : change the designations to dynamic
  //function to update the price for each resource according to designation wise
  const applyDesignationPrices = () => {
    const newResourceSpecificPrices = employees
      .filter(
        (emp) =>
          emp.designation !== "Intern" &&
          emp.designation !== "Paralegal" &&
          emp.status !== "inActive"
      )
      .map((employee) => {
        const price = designationPrices[employee.designation] || 0;
        return `${employee.fullName}: ${price}`;
      });

    // Here, you'd set this new array to your state or wherever you're storing `resourceSpecificPrice`
    setResourceSpecificPrices(newResourceSpecificPrices);
  };

  //matter el pdf upload functions
  const handleRemoveFile = (index) => {
    const newSelectedFile = selectedFile.filter((_, i) => i !== index);
    setSelectedFile(newSelectedFile);
  };

  const handleFileUpload = async (e) => {
    // console.log(e.target.files);
    if (e.target.files.length > 0) {
      const files = e.target.files;
      const uploadPromises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("adminId", auth.user._id);
        // Adjust the form data append lines as necessary for the new endpoint requirements

        // Update the fetch URL to the new endpoint
        uploadPromises.push(
          fetch(`${API}/uploadMatterEL`, {
            method: "POST",
            body: formData,
          })
        );
      }

      try {
        setUploading(true);
        const uploadResponses = await Promise.all(uploadPromises);
        const uploadData = await Promise.all(
          uploadResponses.map((res) => res.json())
        );

        uploadResponses.forEach((response) => {
          if (!response.ok) {
            console.error(
              `Error with status ${response.status} during file upload`
            );
          }
        });

        // Assume the API returns an object with a URL field for the uploaded file
        const uploadedFileUrls = uploadData.map((data) => data.url);
        setSelectedFile([...selectedFile, ...uploadedFileUrls]);
      } catch (e) {
        console.error(e);
      } finally {
        setUploading(false);
      }
    }
  };

  const onAddMatter = async (event) => {
    event.preventDefault();
    applyDesignationPrices();

    if (isValidForm()) {
      const form = new FormData();
      form.append("admin", auth.user._id);
      form.append("name", formData.name);
      form.append("client", formData.client);
      form.append("currency", formData.currency);
      form.append("pricePerHour", formData.pricePerHour);
      form.append("matterOpenDate", formData.matterOpenDate);
      form.append("engagementLetter", selectedFile);
      form.append("matterAddress", formData.matterAddress);
      form.append("flatFeesAmount", formData.flatFeesAmount || 0);

      if (radioValue === "ResourceSpecific") {
        form.append(
          "resourceSpecificPrice",
          JSON.stringify(resourceSpecificPrices)
        );
      }

      try {
        // console.log("Matter data", form);
        const response = await axiosInstance.post(
          `${API}/matter/createNew`,
          form
        );

        // console.log("Matter added", response.data);

        if (response.data) {
          setFormData({
            name: "",
            client: "",
            currency: "",
            pricePerHour: "",
            resourceSpecificPrice: "",
            matterOpenDate: "",
            engagementLetter: [],
            matterAddress: "",
            flatFeesAmount: "",
          });
          setResourceSpecificPrices("");
          successAlert("Matter Added Successfully!");
          dispatch(getAllMatters());
          onClose();
        }
      } catch (error) {
        errorAlert(error);
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <Paper
        elevation={3}
        sx={{ width: "100%", overflow: "hidden", marginBottom: 10, padding: 5 }}
      >
        <CustomHeading variant="h5" align="center" text="Add Matter" />

        <Form id="addTime-resource-form">
          <Grid container gap={3} justifyContent="center" marginBottom={3}>
            <Grid xs={12} sm={12} md={5.8}>
              <Select
                controlId="formBasicClientDetails"
                name="client"
                options={clientNames}
                value={clientNames.find(
                  (option) => option.value === formData.client
                )} // Set to the selected option value
                onChange={(e) => {
                  // console.log(e.value);
                  handleOnChangeText(e.value, "client");
                }}
                isSearchable
                placeholder="Select Client *"
              />
            </Grid>

            <Grid xs={12} sm={12} md={5.8}>
              <Form.Group
                // className="mb-3 form-input"
                controlId="formBasicMattgdhsbjerDetails"
              >
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Matter name *"
                  value={formData.name}
                  onChange={(event) => {
                    // console.log("Matter name",event.target.value)
                    handleOnChangeText(event.target.value, "name");
                  }}
                />
              </Form.Group>
            </Grid>
          </Grid>

          <Grid container gap={3} justifyContent="center" marginBottom={3}>
            <Grid xs={12} sm={5.8} md={5.8}>
              <input
                type="file"
                accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
                onChange={handleFileUpload}
                disabled={uploading}
              />

              {selectedFile.map((file, index) => (
                <div key={index}>
                  <a href={file} target="_blank" rel="noopener noreferrer">
                    Open File
                  </a>
                  <button type="button" onClick={() => handleRemoveFile(index)}>
                    Remove
                  </button>
                </div>
              ))}
            </Grid>

            <Grid xs={12} sm={5.8} md={5.8}>
              <Form.Group
                // className="mb-3 form-input"
                controlId="formBasicCurrency"
              >
                <Form.Select
                  value={formData.currency}
                  name="currency"
                  onChange={(event) => {
                    handleOnChangeText(event.target.value, "currency");
                    // console.log(event.target.value, "currency")
                  }}
                >
                  <option value="">Select Currency *</option>
                  <option value="INR">INR</option>
                  <option value="USD">USD</option>
                </Form.Select>
              </Form.Group>
            </Grid>
          </Grid>

          <Grid container gap={3} justifyContent="center" marginBottom={3}>
            <Grid xs={12} sm={5.8} md={5.8}>
              <CustomButton
                variant="outlined"
                style={{ width: "100%" }}
                onClick={() => setRadioValue("Price/hour")}
              >
                Price/Hour
              </CustomButton>
            </Grid>
            <Grid xs={12} sm={5.8} md={5.8}>
              <CustomButton
                variant="outlined"
                style={{ width: "100%" }}
                onClick={() => {
                  setRadioValue("ResourceSpecific");
                }}
              >
                Resource Specific
              </CustomButton>
            </Grid>
          </Grid>

          <Grid container gap={3} justifyContent="center" marginBottom={3}>
            <Grid xs={12} sm={12} md={12}>
              {radioValue === "Price/hour" && (
                <Form.Group
                  // className="mb-3 mt-3 form-input"
                  controlId="formBasicPricePerHour"
                >
                  <Form.Control
                    type="number"
                    name="pricePerHour"
                    placeholder="Price Per Hour *"
                    value={formData.pricePerHour}
                    onChange={(event) => {
                      // console.log(event.target.value, "pricePerHour");
                      handleOnChangeText(event.target.value, "pricePerHour");
                    }}
                  />
                </Form.Group>
              )}

              {radioValue === "ResourceSpecific" && (
                <Grid>
                  <Form.Group controlId="formBasicResourceSpecific">
                    {billedDesignations.map((designation) => (
                      <div key={designation} style={{ marginBottom: "10px" }}>
                        <label>{designation}: </label>
                        <input
                          type="number"
                          placeholder="Resource price *"
                          onChange={(event) =>
                            handleDesignationPriceChange(
                              designation,
                              event.target.value
                            )
                          }
                        />
                      </div>
                    ))}
                  </Form.Group>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid container gap={3} marginBottom={3}>
            <Grid xs={12} sm={5.8} md={5.8}>
              <Form.Group controlId="formBasicMatterType">
                <Form.Select
                  value={formData.matterType}
                  name="matterType"
                  onChange={(event) => {
                    handleOnChangeText(event.target.value, "matterType");
                    // console.log(event.target.value, "matterType");
                  }}
                >
                  <option value="">Select Matter Type *</option>
                  <option value="timespent">Time Spent</option>
                  <option value="flatfees">Flat Fees</option>
                </Form.Select>
              </Form.Group>
            </Grid>
            {formData.matterType === "flatfees" && (
              <Grid xs={12} sm={5.8} md={5.8}>
                <Form.Group controlId="formBasicPricePerHour">
                  <Form.Control
                    type="number"
                    name="flatFeesAmount"
                    placeholder="Flat Fees Amount (INR) *"
                    value={formData.flatFeesAmount}
                    onChange={(event) => {
                      // console.log(event.target.value, "flatFeesAmount");
                      handleOnChangeText(event.target.value, "flatFeesAmount");
                    }}
                  />
                </Form.Group>
              </Grid>
            )}
          </Grid>

          <Grid container gap={3} justifyContent="center" marginBottom={3}>
            <Grid xs={12} sm={5.8} md={5.8}>
              <Form.Group
                // className="mb-3 form-input"
                controlId="formBasicPricePerHour"
              >
                <Form.Label>Matter Open Date *</Form.Label>
                <Form.Control
                  type="date"
                  name="matterOpenDate"
                  placeholder="Enter Matter Open Date"
                  value={formData.matterOpenDate}
                  onChange={(event) => {
                    // console.log(event.target.value, "matterOpenDate");
                    handleOnChangeText(event.target.value, "matterOpenDate");
                  }}
                />
              </Form.Group>
            </Grid>

            <Grid xs={12} sm={5.8} md={5.8}>
              <Form.Group controlId="formBasicPricePerHour">
                <Form.Label>Matter Address</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  name="matterAddress"
                  placeholder="Matter Address"
                  value={formData.matterAddress}
                  onChange={(event) => {
                    // console.log(event.target.value, "matterAddress");
                    handleOnChangeText(event.target.value, "matterAddress");
                  }}
                />
              </Form.Group>
            </Grid>
          </Grid>

          <Grid container gap={3} justifyContent="center" alignItems="center">
            <CustomButton
              type="submit"
              onClick={onAddMatter}
              style={{ width: "20%", height: "35px" }}
            >
              Submit
            </CustomButton>
            <CustomButton
              variant="text"
              style={{ width: "20%" }}
              onClick={onClose}
            >
              Cancel
            </CustomButton>
          </Grid>
        </Form>
      </Paper>
    </>
  );
};

export default AddMatter;