import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllEmployees, getAllEmployeesByAdminId } from "../../actions";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { allDesignations, API, ConfirmationAlert } from "../../utils/services";
import ReusableTable from "../../components/common/DataTable";
import { errorAlert } from "../../utils/services";
import { successAlert } from "../../utils/services";
import { Delete } from "@mui/icons-material";
import CustomButton from "../../components/common/CustomButton";
import CustomHeading from "../../components/common/CustomHeading";
import axiosInstance from "../../helpers/axios";

const ShowResources = () => {
  const dispatch = useDispatch();
  const adminEmployees = useSelector((state) => state.adminEmployees);
  const { employees, loading, totalCountOfEmployees } = adminEmployees;
  const auth = useSelector((state) => state.auth);

  //STATES
  const [showModal, setShowModal] = useState(false);
  const [editingResource, setEditingResource] = useState(null);

  const [userInfo, setUserInfo] = useState({
    fullName: "",
    email: "",
    designation: "",
    compensation: "",
    joiningDate: "",
    password: "",
    passwordRepeat: "",
    status: "active",
    totalLeaves: "",
    totalWorkFromHome: "",
    loading: false,
  });

  const {
    fullName,
    email,
    designation,
    compensation,
    joiningDate,
    password,
    passwordRepeat,
    status,
    totalLeaves,
    totalWorkFromHome,
  } = userInfo;

  //pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  //useEffects
  useEffect(() => {
    dispatch(getAllEmployeesByAdminId(rowsPerPage, page));
  }, [dispatch, page, rowsPerPage]);

  //pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    { id: "fullName", label: "Name", minWidth: 130 },
    { id: "email", label: "Email", minWidth: 50 },
    { id: "designation", label: "Designation", minWidth: 120 },
    { id: "compensation", label: "Compensation", minWidth: 130 },
    { id: "joiningDate", label: "Joining Date", minWidth: 130 },
    { id: "totalLeaves", label: "Assigned Leaves", minWidth: 30 },
    { id: "totalWorkFromHome", label: "Assigned WFHs", minWidth: 30 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "edit", label: "Edit", minWidth: 70 },
    { id: "delete", label: "Delete", minWidth: 70 },
  ];

  const rows = employees.map((employee) => ({
    id: employee._id,
    fullName: employee.fullName,
    email: employee.email,
    designation: employee.designation,
    compensation: employee.compensation,
    joiningDate: employee.joiningDate,
    totalLeaves: employee.totalLeaves,
    totalWorkFromHome: employee.totalWorkFromHome,
    status: employee.status,
  }));

  //FUNCTIONS TO DELETE RESOURCE

  const handleDeleteResource = (id) => {
    // console.log(id);
    toast(
      <ConfirmationAlert
        onDeleteOff={() => onDeleteResource(id)}
        question={"Are you sure to delete this resource?"}
        answer={"Yes, Delete it"}
        icon={<Delete />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onDeleteResource = async (deleteId) => {
    try {
      // console.log("resourceIdToDelete", deleteId);

      const response = await axios.delete(`${API}/employee/delete/${deleteId}`);

      if (response.data) {
        successAlert("Resource Deleted Successfully!");
        dispatch(getAllEmployeesByAdminId(rowsPerPage, page));
      }
    } catch (error) {
      // console.log(error);
      errorAlert("Deletion of resource failed", "Please try again...");
    }
  };

  //FUNCTIONS FOR OPEN AND CLOSE THE MODAL

  const openModal = (entryData) => {
    // console.log("User data", entryData);
    if (entryData) {
      setEditingResource(entryData);
      // console.log("Edit data", editingResource);
      setUserInfo({
        fullName: entryData.fullName || "",
        email: entryData.email || "",
        designation: entryData.designation || "",
        joiningDate: entryData.joiningDate || "",
        status: entryData.status || "",
        compensation: entryData.compensation || "",
        totalLeaves: entryData.totalLeaves || "",
        totalWorkFromHome: entryData.totalWorkFromHome || "",
      });
      // setUserInfo({ ...user, loading: true });
    } else {
      setEditingResource(null);
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setUserInfo({
      ...userInfo,
      fullName: "",
      email: "",
      designation: "",
      compensation: "",
      joiningDate: "",
      totalLeaves: "",
      totalWorkFromHome: "",
      password: "",
      passwordRepeat: "",
      loading: false,
    });
  };

  //FUNCTIONS TO CHECK THE FORM DATA VALIDATION

  const isValidObjField = () => {
    if (
      fullName === "" ||
      email === "" ||
      designation === "" ||
      compensation === "" ||
      joiningDate === "" ||
      password === "" ||
      passwordRepeat === "" ||
      totalLeaves === "" ||
      totalWorkFromHome === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isValidEmail = (value) => {
    const regx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regx.test(value);
  };

  const isValidForm = () => {
    // All fields must be filled
    if (!isValidObjField()) {
      errorAlert("All Fields are Required!");
      return false;
    }
    // Name field with 3 or more characters
    if (!fullName.trim() || fullName.length < 3) {
      errorAlert("Name should be 3 characters long!");
      return false;
    }
    // Only valid email is required
    if (!isValidEmail(email)) {
      errorAlert("Enter a valid email!");
      return false;
    }
    // Password must be 6 characters
    if (!editingResource) {
      if (!password.trim() || password.length < 6) {
        errorAlert("Password should be 6 characters long!");
        return false;
      }
      // Password and repeatPassword must be the same
      if (password !== passwordRepeat) {
        errorAlert("Password does not match!");
        return false;
      }
    }

    if (compensation === "") {
      errorAlert("Enter Compensation amount");
      return false;
    }

    if (totalLeaves === "") {
      errorAlert("Assign the leaves to the resource");
      return false;
    }

    if (totalWorkFromHome === "") {
      errorAlert("Assign the work from home to the resource");
      return false;
    }

    return true;
  };

  const handleOnChangeText = (value, fieldName) => {
    setUserInfo({ ...userInfo, [fieldName]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValidForm()) {
      setUserInfo({ ...userInfo, loading: true });
      // console.log("UserInfo", userInfo);
      try {
        if (!editingResource) {
          try {
            const response = await axios.post(`${API}/signup`, {
              company: auth.user._id,
              fullName,
              email,
              designation,
              compensation,
              joiningDate,
              status: "active",
              passwordRepeat,
              totalLeaves,
              totalWorkFromHome,
            });
            // console.log("Add new Resource Form Data", response.data);
            if (response.data) {
              setUserInfo({
                ...userInfo,
                fullName: "",
                email: "",
                designation: "",
                compensation: "",
                joiningDate: "",
                password: "",
                passwordRepeat: "",
                totalLeaves: "",
                totalWorkFromHome: "",
                loading: false,
              });
              dispatch(getAllEmployeesByAdminId(rowsPerPage, page));
              successAlert(response.data.message);
            }
          } catch (error) {
            // console.log("Error in adding resource", error);
            errorAlert(
              "Error in adding resource : " + error.response.data.message
            );
          }
        } else {
          try {
            // console.log("Resource editing data", userInfo);
            // const authToken = localStorage.getItem("authToken");

            const response = await axiosInstance.put(
              `${API}/employee/update/${editingResource.id}`,
              userInfo
            );

            if (response.data) {
              successAlert("Resource updated successfully", response);
              getAllEmployees();
              setUserInfo({
                ...userInfo,
                fullName: "",
                email: "",
                designation: "",
                compensation: "",
                joiningDate: "",
                status: "",
                totalLeaves: "",
                totalWorkFromHome: "",
                loading: false,
              });
            }
            dispatch(getAllEmployeesByAdminId(rowsPerPage, page));
          } catch (error) {
            errorAlert("Error updating resource details, try again...");
          }
        }
        closeModal();
        getAllEmployees();
      } catch (error) {
        console.error("Error:", error);
        errorAlert("Error : Something went wrong", error);
      }
    }
  };

  return (
    <div>
      <ToastContainer />

      <ReusableTable
        componentName={"Resources"}
        columns={columns}
        data={rows}
        onAdd={openModal}
        onEdit={openModal}
        onDelete={handleDeleteResource}
        loading={loading}
        totalCount={totalCountOfEmployees}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <CustomHeading
              variant="h5"
              align="center"
              text={editingResource ? "Edit Resource" : "Add Resource"}
            />

            <form>
              <div style={{ display: "flex", gap: "2rem" }}>
                <div style={{ width: "50%" }}>
                  <label>
                    Full Name
                    <input
                      type="text"
                      name="fullName"
                      placeholder="Full name"
                      value={fullName}
                      onChange={(event) =>
                        handleOnChangeText(event.target.value, "fullName")
                      }
                    />
                  </label>
                </div>
                <div style={{ width: "50%" }}>
                  <label>
                    Email
                    <input
                      type="email"
                      value={email}
                      name="email"
                      placeholder="Email address"
                      onChange={(event) =>
                        handleOnChangeText(event.target.value, "email")
                      }
                    />
                  </label>
                </div>
              </div>
              <div style={{ display: "flex", gap: "2rem" }}>
                <div style={{ width: "50%" }}>
                  <label>
                    Designation
                    <select
                      style={{ width: "100%", height: "30px" }}
                      value={designation}
                      name="designation"
                      onChange={(event) =>
                        handleOnChangeText(event.target.value, "designation")
                      }
                    >
                      <option value="">Select Designation</option>
                      {allDesignations.map((designation) => (
                        <option key={designation} value={designation}>
                          {designation}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div style={{ width: "50%" }}>
                  <label>
                    Compensation
                    <input
                      type="number"
                      name="compensation"
                      placeholder="Compensation amount"
                      value={compensation}
                      onChange={(event) =>
                        handleOnChangeText(event.target.value, "compensation")
                      }
                    />
                  </label>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <label>
                    Joining Date
                    <input
                      type="date"
                      name="joiningDate"
                      placeholder="Joining date"
                      value={joiningDate}
                      onChange={(event) =>
                        handleOnChangeText(event.target.value, "joiningDate")
                      }
                    />
                  </label>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                }}
              >
                <div style={{ width: "50%" }}>
                  <label>
                    Assign Leaves
                    <input
                      type="number"
                      name="totalLeaves"
                      placeholder="Assign leaves"
                      value={totalLeaves}
                      onChange={(event) =>
                        handleOnChangeText(event.target.value, "totalLeaves")
                      }
                    />
                  </label>
                </div>

                <div style={{ width: "50%" }}>
                  <label>
                    Assign Work From Home
                    <input
                      type="number"
                      name="totalWorkFromHome"
                      placeholder="Assign Work From Home"
                      value={totalWorkFromHome}
                      onChange={(event) =>
                        handleOnChangeText(
                          event.target.value,
                          "totalWorkFromHome"
                        )
                      }
                    />
                  </label>
                </div>
              </div>

              {editingResource && (
                <div style={{ width: "50%" }}>
                  <label>
                    <p>
                      <b>Status</b>
                    </p>
                    <select
                      style={{ width: "100%", padding: 4 }}
                      name="status"
                      value={status}
                      onChange={(event) =>
                        handleOnChangeText(event.target.value, "status")
                      }
                    >
                      <option value="active">Active</option>
                      <option value="inActive">Inactive</option>
                    </select>
                  </label>
                </div>
              )}

              {!editingResource && (
                <div style={{ display: "flex", gap: "2rem" }}>
                  <div style={{ width: "50%" }}>
                    <label>
                      Password
                      <input
                        type="password"
                        name="password"
                        placeholder="Enter password"
                        value={password}
                        onChange={(event) =>
                          handleOnChangeText(event.target.value, "password")
                        }
                      />
                    </label>
                  </div>
                  <div style={{ width: "50%" }}>
                    <label>
                      Repeat Password
                      <input
                        type="password"
                        name="passwordRepeat"
                        placeholder="Enter repeat password"
                        value={passwordRepeat}
                        onChange={(event) =>
                          handleOnChangeText(
                            event.target.value,
                            "passwordRepeat"
                          )
                        }
                      />
                    </label>
                  </div>
                </div>
              )}

              <div style={{ textAlign: "center" }}>
                <CustomButton
                  onClick={handleSubmit}
                  style={{ width: "20%", height: "35px" }}
                >
                  Submit
                </CustomButton>
                <CustomButton
                  variant="text"
                  style={{ width: "20%" }}
                  onClick={closeModal}
                >
                  Cancel
                </CustomButton>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowResources;
