import React from "react";
import CustomHeading from "./CustomHeading";

const NoDataFound = ({ message ="Oops! No Data Found" }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <img
          src="/NoDataFound.gif"
          // src={`${process.env.PUBLIC_URL}/NoDataFound.gif`}
          style={{
            height: 300,
            width: "100%",
          }}
          alt="No Data Found"
        />
      </div>
      <div>
        <CustomHeading variant="h6" text={message} />
      </div>
    </div>
  );
};

export default NoDataFound;
