import { combineReducers } from "redux";
import adminClientReducer from "./admin.client.reducer";
import adminEmployeesReducers from "./admin.employees.reducers";
import adminTimeReducer from "./admin.time.reducer";
import authReducer from "./auth.reducers";
import matterReducers from "./matter.reducers";
import timeReducers from "./time.reducers";
import writeoffReducer from "./admin.writeOff.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  matter: matterReducers,
  time: timeReducers,
  adminTime: adminTimeReducer,
  adminEmployees: adminEmployeesReducers,
  adminClient: adminClientReducer,
  writeoff: writeoffReducer,
});

export default rootReducer;
