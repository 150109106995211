import { useState, useEffect } from "react";
import axiosInstance from "../axios";
import { API, unBilledDesignations } from "../../utils/services";

export const useEmployeeOptions = () => {
  const [employeesData, setEmployeesData] = useState([]);
  const [allActiveEmployeesData, setAllActiveEmployeesData] = useState([]);

  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [employeeLoading, setEmployeeLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const rowsPerPage = 0;
        const page = 0;

        const res = await axiosInstance.get(`${API}/employee/getAll`, {
          params: {
            limit: rowsPerPage,
            page,
          },
        });

        const { employees } = res.data;

        //all -> active + billed + extra data -> Performance Report
        setEmployeesData(
          employees.filter(
            (emp) =>
              !unBilledDesignations.includes(emp.designation) &&
              emp.status === "active"
          )
        );

        setAllActiveEmployeesData(
          employees.filter((emp) => emp.status === "active")
        );

        const allEmployees = employees
          .filter((item) => item.status === "active")
          .map((employee) => ({
            value: employee._id,
            label: `${employee.fullName}`,
          }));

        //all -> active + billed + unbilled -> Ope
        setEmployees([{ value: "", label: "Select All" }, ...allEmployees]);

        const activeEmployees = employees
          .filter(
            (item) =>
              !unBilledDesignations.includes(item.designation) &&
              item.status === "active"
          )
          .map((employee) => ({
            value: employee._id,
            label: `${employee.fullName}`,
          }));

        //active + billed -> Select Resource
        setFilteredEmployees([
          { value: "", label: "Select All" },
          ...activeEmployees,
        ]);
      } catch (error) {
        setError(error);
      } finally {
        setEmployeeLoading(false);
      }
    };

    fetchEmployees();
  }, []);

  return {
    employees,
    employeesData,
    allActiveEmployeesData,
    filteredEmployees,
    employeeLoading,
    error,
  };
};
