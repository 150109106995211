import React from "react";
import { Container, Typography, Box, useMediaQuery } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import CustomButton from "./CustomButton";
import { useTheme } from "@mui/material/styles";

const NotFound = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: isSmallScreen ? 2 : 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f0f0f0",
          width: isSmallScreen ? "90%" : "50%",
          padding: isSmallScreen ? 4 : 10,
          borderRadius: 2,
          boxShadow: 1,
        }}
      >
        <Typography variant="h1" component="div" gutterBottom>
          404
        </Typography>
        <Typography variant="h4" component="div" gutterBottom paddingBottom={2}>
          Oops! Page Not Found
        </Typography>
        <CustomButton variant="contained" component={RouterLink} to="/">
          Go to Home
        </CustomButton>
      </Box>
    </Container>
  );
};

export default NotFound;
