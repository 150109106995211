import React from "react";
import { Button as MuiButton } from "@mui/material";

const CustomButton = ({
  children,
  variant = "contained",
  type = "button",
  fontSize = "13px",
  color = "white",
  ...props
}) => {
  const getStyles = (variant) => {
    const commonStyles = {
      fontWeight: "bold",
      height: "35px",
      textTransform: "none",
      transition: "transform 0.3s ease-in-out, background-color 0.3s ease",
      fontSize,
      width: {
        xs: "100%", // full width on extra small screens
        sm: "75%", // 75% width on small screens
        md: "50%", // 50% width on medium screens
        lg: "33%", // 33% width on large screens
        xl: "25%", // 25% width on extra large screens
      },
    };

    switch (variant) {
      case "text":
        return {
          ...commonStyles,
          color: "gray",
          fontWeight: "200",
          height: "16px",
          width: "100%",
        };
      case "outlined":
        return {
          ...commonStyles,
          color: "#372a1b",
          borderColor: "#372a1b",
          width: "100%",
          "&:hover": {
            backgroundColor: "rgba(55, 42, 27, 0.1)",
            borderColor: "#372a1b",
            transform: "scale(1.03)",
          },
        };
      case "contained":
      default:
        return {
          ...commonStyles,
          backgroundColor: "#372a1b",
          color: "white",
          "&:hover": {
            backgroundColor: "#372a1b",
            color: "white",
            transform: "scale(1.03)",
          },
        };
    }
  };

  return (
    <MuiButton
      {...props}
      type={type}
      variant={variant}
      sx={{
        ...getStyles(variant),
        ...(type === "submit" && {
          backgroundColor: "#372a1b !important",
          color: "white !important",
          marginTop: "5px",
          fontFamily: "Poppins",
        }),
      }}
    >
      {children}
    </MuiButton>
  );
};

export default CustomButton;
