import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  API,
  billedDesignations,
  errorAlert,
  formatLetterToNormalDate,
  successAlert,
} from "../../utils/services";
import Select from "react-select";
import "./EditMatter.css";
import CustomButton from "../../components/common/CustomButton";
import CustomHeading from "../../components/common/CustomHeading";
import { Download } from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import { Form } from "react-bootstrap";
import { Grid } from "@mui/material";
import { getAllEmployeesByAdminId } from "../../actions";

const Modal = ({ isOpen, onClose, entryData }) => {
  // Constants
  const auth = useSelector((state) => state.auth);
  const adminClient = useSelector((state) => state.adminClient);
  const adminEmployees = useSelector((state) => state.adminEmployees);
  const dispatch = useDispatch();

  const { employees } = adminEmployees;
  const { clientList } = adminClient;

  const clientNames = clientList.map((client) => ({
    value: client._id,
    label: `${client.name}`,
  }));

  // States
  const [formData, setFormData] = useState({
    admin: auth?.user?._id,
    entryId: "",
    clientName: "",
    matterName: "",
    currency: "",
    resourceSpecificPrice: [],
    pricePerHour: "",
    status: "",
    disableDate: "",
    matterOpenDate: "",
    matterAddress: "",
    engagementLetter: [],
    flatFeesAmount: "",
  });

  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);

  // State for designation prices
  const [designationPrices, setDesignationPrices] = useState({});

  // New resource input states
  const [newResource, setNewResource] = useState("");
  const [newResourcePrice, setNewResourcePrice] = useState("");

  // State for custom price input
  const [customPrice, setCustomPrice] = useState("");
  const [showPriceInput, setShowPriceInput] = useState(false);

  // State for shift mode
  const [shiftMode, setShiftMode] = useState("");

  useEffect(() => {
    setFormData({
      admin: auth?.user?._id,
      entryId: entryData.id || "",
      clientName: entryData.client || "",
      matterName: entryData.matter || "",
      currency: entryData.currency || "",
      pricePerHour:
        (typeof entryData.price === "string" && entryData.price) || "",
      resourceSpecificPrice:
        Array.isArray(entryData.price) && entryData.price.length > 0
          ? entryData.price
          : [],
      matterAddress: entryData.matterAddress || "",
      status: entryData.status || "",
      disableDate: formatLetterToNormalDate(entryData.disableDate) || "",
      matterOpenDate: formatLetterToNormalDate(entryData.matterOpenDate) || "",
      engagementLetter: entryData?.engagementLetter || [],
      flatFeesAmount: entryData.flatFeesAmount || "",
    });

    if (entryData.engagementLetter.length > 0) {
      setSelectedFile(entryData.engagementLetter);
    }
    dispatch(getAllEmployeesByAdminId());
  }, [entryData]);

  const handleInputChange = (e) => {
    if (e.target) {
      // Regular input field
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    } else {
      // Select component
      setFormData({
        ...formData,
        clientName: e.label,
      });
    }
  };

  const handleEmployeePriceChange = (e, index) => {
    const { value } = e.target;
    let updatedResourceSpecificPrice = [...formData.resourceSpecificPrice];
    const [employeeName] = updatedResourceSpecificPrice[index].split(":");

    // Directly update the price without filtering
    updatedResourceSpecificPrice[index] = `${employeeName.trim()}: ${value}`;

    // Update formData with the new resourceSpecificPrice array
    setFormData({
      ...formData,
      resourceSpecificPrice: updatedResourceSpecificPrice,
    });
  };

  const handleFileUpload = async (e) => {
    if (e.target.files.length > 0) {
      const files = e.target.files;
      const uploadPromises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("adminId", auth.user._id);

        uploadPromises.push(
          fetch(`${API}/uploadMatterEL`, {
            method: "POST",
            body: formData,
          })
        );
      }

      try {
        setUploading(true);
        const uploadResponses = await Promise.all(uploadPromises);
        const uploadData = await Promise.all(
          uploadResponses.map((res) => res.json())
        );

        uploadResponses.forEach((response) => {
          if (!response.ok) {
            console.error(
              `Error with status ${response.status} during file upload`
            );
          }
        });

        const uploadedFileUrls = uploadData.map((data) => data.url);
        setSelectedFile([...selectedFile, ...uploadedFileUrls]);

        e.target.value = "";
      } catch (e) {
        console.error(e);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleRemoveFile = async (index) => {
    const fileToRemove = selectedFile[index];

    try {
      // Send a request to the backend to delete the file
      const result = await axiosInstance({
        method: "delete",
        url: `${API}/deleteEngagementLetter`,
        data: { filePath: fileToRemove },
      });

      // console.log("Delete response:", result);

      const newSelectedFile = selectedFile.filter((_, i) => i !== index);
      setSelectedFile(newSelectedFile);

      // console.log("File deleted successfully from DigitalOcean Spaces.");
    } catch (error) {
      console.error("Error deleting file:", error);
      alert("Failed to delete file. Please try again.");
    }
  };

  const handleAddNewResource = () => {
    if (newResource && newResourcePrice) {
      // Create a new resource entry
      const newEntry = `${newResource.trim()}: ${newResourcePrice}`;

      // Add the new entry to the existing resourceSpecificPrice array
      setFormData((prevState) => ({
        ...prevState,
        resourceSpecificPrice: [...prevState.resourceSpecificPrice, newEntry],
      }));

      // Reset the new resource inputs
      setNewResource("");
      setNewResourcePrice("");
    } else {
      errorAlert("Please enter both resource name and price.");
    }
  };

  // Handle designation price change
  const handleDesignationPriceChange = (designation, price) => {
    setDesignationPrices((prevPrices) => ({
      ...prevPrices,
      [designation]: price,
    }));
  };

  // Apply designation prices to resources
  const applyDesignationPrices = () => {
    const newResourceSpecificPrices = employees
      .filter(
        (emp) =>
          emp.designation !== "Intern" &&
          emp.designation !== "Paralegal" &&
          emp.status !== "inActive"
      )
      .map((employee) => {
        const price = designationPrices[employee.designation] || 0;
        return `${employee.fullName}: ${price}`;
      });

    setFormData((prevState) => ({
      ...prevState,
      resourceSpecificPrice: newResourceSpecificPrices,
      pricePerHour: "", // Clear price per hour
    }));

    setShowPriceInput(false); // Hide the custom price input modal
    successAlert("Price applied based on designations.");
  };

  const handleShiftToResourceSpecific = () => {
    if (formData.pricePerHour) {
      setShiftMode("hourToResource");
      setShowPriceInput(true);
    } else {
      errorAlert("No price per hour to shift.");
    }
  };

  const handleShiftToPricePerHour = () => {
    if (formData.resourceSpecificPrice.length > 0) {
      setShiftMode("resourceToHour");
      setShowPriceInput(true);
    } else {
      errorAlert("No resource-specific prices to shift.");
    }
  };

  const applyCustomPriceToPricePerHour = () => {
    if (isNaN(customPrice) || customPrice <= 0) {
      errorAlert("Please enter a valid price.");
      return;
    }

    setFormData((prevState) => ({
      ...prevState,
      pricePerHour: customPrice,
      resourceSpecificPrice: [], // Clear the resource-specific prices
    }));

    setCustomPrice(""); // Reset custom price input
    setShowPriceInput(false);
    successAlert("Price successfully shifted to price per hour.");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Filter out empty prices from resourceSpecificPrice before submission
    const filteredResourceSpecificPrice = formData.resourceSpecificPrice.filter(
      (priceEntry) => {
        const [, price] = priceEntry.split(":");
        return price.trim() !== "";
      }
    );

    const form = {
      admin: auth?.user?._id,
      entryId: formData.entryId,
      clientName: formData.clientName,
      matterName: formData.matterName,
      currency: formData.currency,
      resourceSpecificPrice: filteredResourceSpecificPrice, // Use the filtered prices
      pricePerHour: formData.pricePerHour,
      status: formData.status,
      disableDate: formData.disableDate,
      matterOpenDate: formData.matterOpenDate,
      engagementLetter: selectedFile,
      matterAddress: formData.matterAddress,
      flatFeesAmount: formData.flatFeesAmount,
    };

    // console.log("Edit matter data going to backend", form);

    try {
      const response = await axiosInstance.put(
        `${API}/matter/updateMatter/${formData.entryId}`,
        form
      );
      // console.log("Updated Matter Data Response from server:", response.data);

      if (response.data) {
        successAlert("Matter updated successfully");
      }
      onClose();
    } catch (error) {
      console.error("Error updating matter details:", error);
      errorAlert("Error updating matter details, try again...");
    }
  };

  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <div>
          <CustomHeading variant="h5" align="center" text="Edit Matter" />
          <ToastContainer />
        </div>
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "100%",
              marginTop: 15,
            }}
          >
            <div style={{ width: "60%" }}>
              <label>
                <p>
                  <b>Client</b>
                </p>
              </label>
              <Select
                name="clientName"
                options={clientNames}
                value={clientNames.find(
                  (option) => option.label === formData.clientName
                )}
                onChange={(selectedOption) => handleInputChange(selectedOption)}
                isSearchable
                placeholder="Search Client"
              />
            </div>
            <div style={{ width: "60%" }}>
              <label>
                <p>
                  <b>Matter</b>
                </p>
              </label>
              <input
                type="text"
                name="matterName"
                value={formData.matterName}
                onChange={handleInputChange}
              />
            </div>
            <div style={{ width: "40%" }}>
              <label>
                <b>Currency</b>
              </label>
              <select
                type="text"
                name="currency"
                value={formData.currency}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              >
                <option>USD</option>
                <option>INR</option>
              </select>
            </div>
          </div>

          <div>
            <label>
              {formData?.pricePerHour !== ""
                ? "Price/Hour : "
                : "Resource Specific Price :"}
            </label>

            {formData?.pricePerHour && (
              <div>
                <input
                  type="text"
                  name="pricePerHour"
                  value={formData.pricePerHour}
                  onChange={handleInputChange}
                />
                <CustomButton
                  type="button"
                  onClick={handleShiftToResourceSpecific}
                  style={{ margin: "10px", width: "50%" }}
                >
                  Shift to Resource Specific
                </CustomButton>
              </div>
            )}

            {formData?.resourceSpecificPrice &&
              formData?.resourceSpecificPrice.map((employee, index) => {
                const [employeeName, employeePrice] = employee.split(":");
                return (
                  <div
                    key={index}
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "40%" }}>
                      <CustomHeading
                        fontSize="14px"
                        marginRight="10px"
                        style={{ marginRight: "10px" }}
                        text={employeeName.trim()}
                      />
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        type="text"
                        name={`employeePrice${index}`}
                        value={employeePrice}
                        onChange={(e) => {
                          handleEmployeePriceChange(e, index);
                        }}
                      />
                    </div>
                  </div>
                );
              })}

            {formData?.resourceSpecificPrice.length > 0 && (
              <CustomButton
                type="button"
                onClick={handleShiftToPricePerHour}
                style={{ marginTop: "10px" }}
              >
                Shift to Price Per Hour
              </CustomButton>
            )}
          </div>

          {/* Only show the new resource input fields if pricePerHour is empty */}
          {!formData.pricePerHour && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 20,
                width: "100%",
                marginTop: 15,
              }}
            >
              <div style={{ width: "50%" }}>
                <label>New Resource Name</label>
                <input
                  type="text"
                  value={newResource}
                  onChange={(e) => setNewResource(e.target.value)}
                  placeholder="Enter resource name"
                />
              </div>
              <div style={{ width: "50%" }}>
                <label>New Resource Price</label>
                <input
                  type="text"
                  value={newResourcePrice}
                  onChange={(e) => setNewResourcePrice(e.target.value)}
                  placeholder="Enter resource price"
                />
              </div>
              <div style={{ width: "50%" }}>
                <CustomButton
                  type="button"
                  onClick={handleAddNewResource}
                  style={{ width: "100%" }}
                >
                  Add Resource
                </CustomButton>
              </div>
            </div>
          )}

          {/* Custom price input modal */}
          {showPriceInput && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                padding: "20px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                zIndex: 1000,
              }}
            >
              {shiftMode === "hourToResource" ? (
                <>
                  <h3>Enter Prices for Designations</h3>
                  {billedDesignations.map((designation) => (
                    <div key={designation} style={{ marginBottom: "10px" }}>
                      <label>{designation}: </label>
                      <input
                        type="number"
                        value={designationPrices[designation] || ""}
                        onChange={(e) =>
                          handleDesignationPriceChange(
                            designation,
                            e.target.value
                          )
                        }
                        placeholder="Enter price"
                        style={{ width: "100%" }}
                      />
                    </div>
                  ))}
                  <CustomButton
                    type="button"
                    onClick={applyDesignationPrices}
                    style={{ marginRight: "10px", width: "60%" }}
                  >
                    Apply to Resource Specific
                  </CustomButton>
                </>
              ) : (
                <>
                  <h3>Enter Price Per Hour</h3>
                  <input
                    type="number"
                    value={customPrice}
                    onChange={(e) => setCustomPrice(e.target.value)}
                    placeholder="Enter price"
                    style={{ width: "100%", marginBottom: "10px" }}
                  />
                  <CustomButton
                    type="button"
                    onClick={applyCustomPriceToPricePerHour}
                    style={{ marginRight: "10px" }}
                  >
                    Apply to Price Per Hour
                  </CustomButton>
                </>
              )}
              <CustomButton
                type="button"
                variant="outlined"
                style={{ width: "35%" }}
                onClick={() => setShowPriceInput(false)}
              >
                Cancel
              </CustomButton>
            </div>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 20,
              width: "100%",
              marginTop: 15,
            }}
          >
            <div style={{ width: "50%" }}>
              <label>Matter Open Date</label>
              <input
                type="date"
                name="matterOpenDate"
                value={formData?.matterOpenDate}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div style={{ width: "50%" }}>
              <label>Flat Fees Amount (INR)</label>
              <input
                type="number"
                name="flatFeesAmount"
                value={formData.flatFeesAmount}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div style={{ width: "100%", display: "flex", gap: "20px" }}>
            <div style={{ width: "50%" }}>
              <label>Status</label>
              <select
                name="status"
                value={formData?.status}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              >
                <option value="enable">Enable</option>
                <option value="disable">Disable</option>
              </select>
            </div>

            <div style={{ width: "50%" }}>
              <label>Disable Date</label>
              <input
                type="date"
                name="disableDate"
                value={formData?.disableDate}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>

          <div style={{ width: "100%" }}>
            <label>Matter Address</label>
            <textarea
              name="matterAddress"
              value={formData?.matterAddress}
              onChange={handleInputChange}
              style={{ width: "100%" }}
            />
          </div>

          <div>
            <label>Engagement Letter</label>
            <input
              type="file"
              accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
              onChange={handleFileUpload}
              disabled={uploading}
            />

            {selectedFile.map((file, index) => (
              <div
                key={index}
                style={{
                  marginTop: "20px",
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <CustomButton variant="outlined" width="30%">
                  <Download style={{ marginRight: "8px" }} />
                  <a
                    href={file}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Existing Engagement Letter
                  </a>
                </CustomButton>
                <CustomButton
                  width="30%"
                  type="button"
                  onClick={() => handleRemoveFile(index)}
                >
                  Remove
                </CustomButton>
              </div>
            ))}
          </div>

          <div style={{ textAlign: "center" }}>
            <CustomButton
              type="submit"
              style={{ width: "20%", height: "35px" }}
            >
              Submit
            </CustomButton>
            <CustomButton
              variant="text"
              style={{ width: "20%" }}
              onClick={onClose}
            >
              Cancel
            </CustomButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;