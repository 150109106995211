import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  Box,
  CssBaseline,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  TablePagination,
  useMediaQuery,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getWorkHourByUserId } from "../../actions";
import CustomHeading from "../../components/common/CustomHeading";
import CustomButton from "../../components/common/CustomButton";
import formatDate, {
  API,
  ConfirmationAlert,
  errorAlert,
  formatMonthDate,
  successAlert,
} from "../../utils/services";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Check, ChevronLeft, ChevronRight, Delete } from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import SkeletonRows from "../../components/common/CustomSkeletonLoading";
import { useMattersOptions } from "../../helpers/hooks/useMattersOptions";
import NoDataFound from "../../components/common/NoDataFound";
import { HiSparkles } from "react-icons/hi2";

// Validation Schema
const validationSchema = Yup.object().shape({
  selectedDate: Yup.string().required("Date is required"),
  matter: Yup.string().required("Matter is required"),
  workDescription: Yup.string().required("Work Description is required"),
  workHour: Yup.string().required("Work Hour is required"),
  workMin: Yup.string().required("Work Minute is required"),
});

const AddTime = () => {
  const dispatch = useDispatch();
  const { filteredMatters } = useMattersOptions();
  const theme = useTheme();

  const auth = useSelector((state) => state.auth);
  const time = useSelector((state) => state.time);
  const { userWorkHour, workHourLoading, totalCount } = time;

  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [editingEntry, setEditingEntry] = useState(null);
  const [duplicatingEntry, setDuplicatingEntry] = useState(null);
  const [isTableFullWidth, setIsTableFullWidth] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [startSelectedDate, setStartSelectedDate] = useState("");
  const [endSelectedDate, setEndSelectedDate] = useState("");
  const [isShowingPending, setIsShowingPending] = useState(false); // false for Fulfilled, true for Pending
  const [lastSentTSDate, setLastSentTSDate] = useState(null);

  // Pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // AI suggestion state
  const [aiSuggestion, setAiSuggestion] = useState("");
  const [originalWorkDescription, setOriginalWorkDescription] = useState(""); // Store original work description separately
  const [isUsingAi, setIsUsingAi] = useState(false); // Correctly defining the isUsingAi state
  const [isAiSuggested, setIsAiSuggested] = useState(false); // Tracks if AI suggestion was displayed

  // Pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(
      getWorkHourByUserId(
        startSelectedDate,
        endSelectedDate,
        searchQuery,
        "",
        page,
        rowsPerPage
      )
    );
  }, [
    dispatch,
    auth.user._id,
    auth.user.company,
    page,
    rowsPerPage,
    startSelectedDate,
    endSelectedDate,
    searchQuery,
  ]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      selectedDate: formatDate(new Date()),
      matter: "",
      client: "",
      selectedMatterName: "",
      workDescription: "",
      comments: "",
      workHour: "0",
      workMin: "30",
    },
  });

  useEffect(() => {
    const entry = editingEntry || duplicatingEntry;
    if (entry) {
      setValue("selectedDate", formatDate(new Date(entry.workDate)));
      setValue("matter", entry.matter._id);
      setValue("selectedMatterName", entry.matter.name);
      setValue("workDescription", entry.workDescription);
      setOriginalWorkDescription(entry.workDescription); // Set original description
      setValue("comments", entry.comments);

      // Split workHour into hour and minute
      let [hour, min] = entry.workHour.split(".");

      // Ensure hour is set
      setValue("workHour", hour);

      // If min is undefined, set it to "0"; otherwise, check its length
      if (!min) {
        min = "00"; // Default to "00" if no minutes are specified
      } else if (min.length === 1) {
        // If the minute has only one digit, append "0"
        min = min + "0";
      }

      // Convert the corrected min to the nearest 6-minute increment
      const minuteValue = Math.floor((parseFloat(min) * 6) / 10);

      // Set the minute value in the form
      setValue("workMin", minuteValue);
    }
  }, [editingEntry, duplicatingEntry, setValue]);

  // Function to fetch AI suggestion
  const getAiSuggestion = async (workDescription, isDescriptive = false) => {
    try {
      const response = await axiosInstance.post(`${API}/ai/suggest`, {
        workDescription,
        isDescriptive, // Pass the descriptive flag to the backend
      });
      return response.data.aiSuggestedDescription;
    } catch (error) {
      console.error("Error fetching AI suggestion:", error);
      errorAlert("Failed to get AI suggestion. Please try again.");
      return "";
    }
  };

  // Normal submit handler
  const onSubmit = async (data) => {
    if (
      data.selectedDate &&
      data.matter &&
      data.workDescription &&
      data.workHour !== "00" &&
      data.workMin !== "00"
    ) {
      const timeData = {
        admin: auth?.user?.company,
        user: auth?.user?._id,
        workDate: data.selectedDate,
        matter: data.matter,
        workDescription: data.workDescription,
        comments: data.comments,
        workHour: `${data.workHour}.${data.workMin * (10 / 6)}`,
        category: "billable",
      };

      if (editingEntry) {
        // Update the entry
        try {
          const response = await axiosInstance.put(
            `${API}/workHour/update/${editingEntry._id}`,
            timeData
          );

          if (response.data) {
            successAlert("Time Updated Successfully!");
            fetchWorkHours();
            resetForm();
          }
        } catch (error) {
          console.error("Error updating time:", error);
          errorAlert("Error updating time");
        }
      } else {
        // Add or duplicate an entry
        try {
          const response = await axiosInstance.post(
            `${API}/workHour/createNew`,
            timeData
          );

          if (response.data) {
            successAlert("Great !! Time Added Successfully!");
            fetchWorkHours();
            resetForm();
          }
        } catch (error) {
          console.error("Error adding time:", error);
          errorAlert("Error adding time");
        }
      }
    } else {
      errorAlert("All Fields are Required!");
    }
  };

  // AI submit handler
  const onSubmitWithAi = async (data) => {
    if (
      data.selectedDate &&
      data.matter &&
      data.workDescription &&
      data.workHour !== "00" &&
      data.workMin !== "00"
    ) {
      const suggestion = await getAiSuggestion(data.workDescription, false);
      setAiSuggestion(suggestion);
      setIsUsingAi(true);
      setIsAiSuggested(true); // AI suggestion is now displayed
    } else {
      errorAlert("All Fields are Required!");
    }
  };

  // Submit with AI suggestion
  const onSubmitWithAiSuggestion = async (data) => {
    if (
      data.selectedDate &&
      data.matter &&
      aiSuggestion &&
      data.workHour !== "00" &&
      data.workMin !== "00"
    ) {
      const timeData = {
        admin: auth?.user?.company,
        user: auth?.user?._id,
        workDate: data.selectedDate,
        matter: data.matter,
        workDescription: aiSuggestion, // Use the AI suggestion
        comments: data.comments,
        workHour: `${data.workHour}.${data.workMin * (10 / 6)}`,
        category: "billable",
      };

      try {
        if (editingEntry) {
          // Update the existing entry with AI suggestion
          const response = await axiosInstance.put(
            `${API}/workHour/update/${editingEntry._id}`,
            timeData
          );

          if (response.data) {
            successAlert("Time Updated Successfully with AI Suggestion!");
            fetchWorkHours();
            resetForm();
          }
        } else {
          // Create a new entry with AI suggestion
          const response = await axiosInstance.post(
            `${API}/workHour/createNew`,
            timeData
          );

          if (response.data) {
            successAlert("Time Added Successfully with AI Suggestion!");
            fetchWorkHours();
            resetForm();
          }
        }
      } catch (error) {
        console.error(
          "Error adding or updating time with AI suggestion:",
          error
        );
        errorAlert("Error adding or updating time with AI suggestion");
      }
    } else {
      errorAlert("All Fields are Required!");
    }
  };

  // Reset form
  const resetForm = () => {
    reset();
    setEditingEntry(null);
    setDuplicatingEntry(null);
    setAiSuggestion("");
    setOriginalWorkDescription("");
    setIsUsingAi(false);
    setIsAiSuggested(false);
  };

  // DELETE handler
  const handleDelete = (id) => {
    toast(
      <ConfirmationAlert
        onDeleteOff={() => onDeleteWorkHour(id)}
        question={"Are you sure to delete this entry?"}
        answer={"Yes, Delete it"}
        icon={<Delete />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onDeleteWorkHour = async (entryId) => {
    await axiosInstance
      .delete(`${API}/workhour/delete/${entryId}`)
      .then((response) => {
        if (response.data) {
          successAlert("Successfully Deleted");
          fetchWorkHours();
        }
      })
      .catch((error) => {
        errorAlert("Deletion of entry failed", "Please try again...");
      });
  };

  const handleEdit = (entry) => {
    if (isTableFullWidth) {
      setIsTableFullWidth((prev) => !prev);
    }
    setEditingEntry(entry);
    setDuplicatingEntry(null);
  };

  const handleDuplicate = (entry) => {
    if (isTableFullWidth) {
      setIsTableFullWidth((prev) => !prev);
    }
    setDuplicatingEntry(entry);
    setEditingEntry(null);
  };

  const toggleFullWidth = () => {
    setIsTableFullWidth((prev) => !prev);
  };

  // Get filtered work hours based on the button selected
  const filteredWorkHours = userWorkHour.filter((entry) => {
    if (isShowingPending) {
      // Treat entries as pending if reviewed is false or the field doesn't exist
      return entry.reviewed === false;
    } else {
      // Show only fulfilled entries (reviewed is explicitly true)
      return entry.reviewed === true || entry.reviewed === undefined;
    }
  });

  const handleMatterChange = async (option) => {
    try {
      const response = await axiosInstance.get(
        `${API}/matter/getMatterByMatterId/${option.value}`
      );

      setLastSentTSDate(response.data?.matterDetails?.lastSentTSDate);

      setValue("matter", option.value);
      setValue("selectedMatterName", option.label.split("|")[0]);
      setValue("client", option.label.split("|")[1]);
      trigger("matter");
    } catch (error) {
      console.error("Error fetching matter details:", error);
    }
  };

  const pendingEntries = userWorkHour.filter(
    (entry) => entry.reviewed === false
  );

  // Approve handler function
  const handleApprove = async (entryId) => {
    try {
      const response = await axiosInstance.put(
        `${API}/workHour/update/${entryId}`,
        {
          reviewed: true,
        }
      );

      if (response.data) {
        successAlert("Time Approved Successfully!");
        fetchWorkHours(); // Refresh the work hours after approval
      }
    } catch (error) {
      console.error("Error approving time:", error);
      errorAlert("Error approving time");
    }
  };

  // Fetch work hours after any action
  const fetchWorkHours = () => {
    dispatch(
      getWorkHourByUserId(
        startSelectedDate,
        endSelectedDate,
        searchQuery,
        "",
        page,
        rowsPerPage
      )
    );
  };

  // Elaborate Further handler
  const elaborateFurtherWithAi = async () => {
    if (aiSuggestion) {
      const elaboratedSuggestion = await getAiSuggestion(aiSuggestion, true); // Pass true for descriptive prompt
      setAiSuggestion(elaboratedSuggestion);
      setIsUsingAi(true);
      setIsAiSuggested(true);
    }
  };

  return (
    <>
      <ToastContainer />
      <CssBaseline />

      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        {/* Left Form */}
        {!isTableFullWidth && (
          <Grid item xs={12} sm={12} md={5}>
            <CustomHeading
              text={
                editingEntry
                  ? "Edit Time"
                  : duplicatingEntry
                  ? "Duplicate Time"
                  : "Add Time"
              }
            />

            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <Grid container columnSpacing={2} marginTop={3} marginBottom={1}>
                <Grid item xs={12} sm={6} md={6}>
                  <label>Matter *</label>
                  <Controller
                    name="matter"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={filteredMatters}
                        value={filteredMatters.find(
                          (option) => option.value === field.value
                        )}
                        onChange={handleMatterChange}
                        isSearchable
                        placeholder="Search Matter"
                        className={` ${errors.matter ? "formInputError" : ""}`}
                        styles={{
                          control: (base) => ({
                            ...base,
                            borderColor: errors.matter
                              ? "red"
                              : base.borderColor,
                          }),
                        }}
                      />
                    )}
                  />
                  {errors.matter && (
                    <p className="errorMessage">{errors.matter.message}</p>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <label>Date *</label>
                  <input
                    type="date"
                    className={`formInput ${
                      errors.selectedDate ? `formInputError` : ""
                    }`}
                    {...register("selectedDate", { required: true })}
                    min={lastSentTSDate || ""}
                  />

                  {errors.selectedDate && (
                    <p className="errorMessage">
                      {errors.selectedDate.message}
                    </p>
                  )}
                </Grid>
              </Grid>

              <Grid container columnSpacing={2} marginBottom={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <label>Work Hour *</label>
                  <select className="formInput" {...register("workHour")}>
                    {Array.from({ length: 19 }, (_, i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                  {errors.workHour && (
                    <p className="errorMessage">{errors.workHour.message}</p>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <label>Work Minute *</label>
                  <select className="formInput" {...register("workMin")}>
                    {Array.from({ length: 10 }, (_, i) => (
                      <option key={i * 6} value={i * 6}>
                        {i * 6}
                      </option>
                    ))}
                  </select>
                  {errors.workMin && (
                    <p className="errorMessage">{errors.workMin.message}</p>
                  )}
                </Grid>
              </Grid>

              <Grid container marginBottom={2} flexDirection="column">
                <label>Work Description *</label>
                <textarea
                  className={`formInput ${
                    errors.workDescription ? "formInputError" : ""
                  }`}
                  style={{ height: "120px" }}
                  {...register("workDescription")}
                  value={watch("workDescription")}
                  onChange={(e) => {
                    setValue("workDescription", e.target.value);
                    setIsUsingAi(false);
                    setIsAiSuggested(false);
                  }}
                />
                {errors.workDescription && (
                  <p className="errorMessage">
                    {errors.workDescription.message}
                  </p>
                )}
              </Grid>

              {aiSuggestion && isAiSuggested && (
                <Grid container marginBottom={2} flexDirection="column">
                  <label>AI Suggested Description</label>
                  <textarea
                    className="formInput"
                    style={{ height: "120px", backgroundColor: "#f0f0f0" }}
                    value={aiSuggestion}
                    onChange={(e) => setAiSuggestion(e.target.value)} // Allow editing the AI suggestion
                  />
                </Grid>
              )}

              <Grid container marginBottom={1} flexDirection="column">
                <label>Comments</label>
                <textarea
                  className="formInput"
                  style={{ height: "60px" }}
                  {...register("comments")}
                />
              </Grid>

              {!aiSuggestion ? (
                <Grid
                  container
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="baseline"
                  gap={2}
                >
                  <CustomButton type="submit" style={{ width: "20%" }}>
                    {editingEntry
                      ? "Update"
                      : duplicatingEntry
                      ? "Duplicate"
                      : "Submit"}
                  </CustomButton>

                  <CustomButton
                    style={{ width: "35%" }}
                    onClick={handleSubmit(onSubmitWithAi)}
                  >
                    Submit with AI
                    <HiSparkles
                      style={{ marginLeft: "10px" }}
                      size={20}
                      color="orange"
                    />
                  </CustomButton>

                  <CustomButton
                    variant="text"
                    style={{ width: "30%" }}
                    onClick={resetForm}
                  >
                    Reset
                  </CustomButton>
                </Grid>
              ) : (
                <Grid
                  container
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="baseline"
                  gap={2}
                >
                  <CustomButton
                    style={{ width: "30%" }}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Submit Original
                  </CustomButton>

                  <CustomButton
                    style={{ width: "55%" }}
                    onClick={handleSubmit(onSubmitWithAiSuggestion)}
                  >
                    Submit with AI Suggestion
                    <HiSparkles
                      style={{ marginLeft: "10px" }}
                      size={20}
                      color="orange"
                    />
                  </CustomButton>

                  <CustomButton
                    style={{ width: "55%" }}
                    onClick={elaborateFurtherWithAi}
                  >
                    Elaborate Further with AI
                    <HiSparkles
                      style={{ marginLeft: "10px" }}
                      size={20}
                      color="orange"
                    />
                  </CustomButton>
                </Grid>
              )}
            </Box>
          </Grid>
        )}

        {/* Right container */}
        <Grid
          item
          xs={12}
          sm={12}
          md={isTableFullWidth ? 12 : 7}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Paper sx={{ width: isMediumScreen ? "80vw" : "100%" }}>
            {/* Buttons to toggle between Fulfilled and Pending */}
            <Grid container spacing={1} alignItems="center" padding="10px">
              <Grid item>
                <CustomButton
                  variant={isShowingPending ? "outlined" : "contained"}
                  onClick={() => setIsShowingPending(false)}
                >
                  Final
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton
                  style={{ width: "100%" }}
                  variant={!isShowingPending ? "outlined" : "contained"}
                  onClick={() => setIsShowingPending(true)}
                >
                  Maybe?{" "}
                  {pendingEntries.length > 0 && (
                    <span
                      style={{
                        padding: "0px 9px",
                        borderRadius: "50%",
                        backgroundColor: "green",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      {pendingEntries.length}
                    </span>
                  )}
                </CustomButton>
              </Grid>
            </Grid>

            {/* Search Bar */}
            <Grid container spacing={1} alignItems="baseline" padding="10px">
              <Grid item xs={12} sm={6} md={4}>
                <Select
                  options={filteredMatters}
                  value={filteredMatters.find(
                    (option) => option.value === searchQuery
                  )}
                  onChange={(option) => setSearchQuery(option.value)}
                  isSearchable
                  placeholder="Select Matter"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <input
                  type="date"
                  className="date"
                  value={startSelectedDate}
                  onChange={(e) => setStartSelectedDate(e.target.value)}
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <input
                  type="date"
                  className="date"
                  value={endSelectedDate}
                  onChange={(e) => setEndSelectedDate(e.target.value)}
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                />
              </Grid>
            </Grid>

            {/* Table */}
            <TableContainer sx={{ maxHeight: "66vh" }}>
              <Table aria-label="reusable table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={13}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <IconButton onClick={toggleFullWidth}>
                          {isTableFullWidth ? (
                            <ChevronRight />
                          ) : (
                            <ChevronLeft />
                          )}
                        </IconButton>
                        <Box flex="1" textAlign="center">
                          <CustomHeading
                            variant="subtitle1"
                            text="Recent Entries"
                            marginBottom="0px"
                          />
                        </Box>
                        <Box width="48px" />
                      </Box>
                    </TableCell>
                  </TableRow>

                  {/* columns row */}
                  <TableRow>
                    <TableCell id="tableHeadings">WorkDate</TableCell>
                    <TableCell id="tableHeadings">Matter</TableCell>
                    <TableCell id="tableHeadings">Narration</TableCell>
                    <TableCell id="tableHeadings">WorkHours</TableCell>
                    <TableCell id="tableHeadings">Edit</TableCell>
                    <TableCell id="tableHeadings">
                      {isShowingPending && "Approve"}
                    </TableCell>
                    <TableCell id="tableHeadings">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {workHourLoading ? (
                    <SkeletonRows rows={6} colSpan={11} />
                  ) : userWorkHour.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={11}>
                        <NoDataFound message="Oops! No Workhours Found" />
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredWorkHours.map((time, index) => (
                      <TableRow
                        key={time._id}
                        role="checkbox"
                        tabIndex={-1}
                        sx={{
                          backgroundColor: index % 2 ? "white" : "#eae4dd",
                        }}
                      >
                        <TableCell>{formatMonthDate(time.workDate)}</TableCell>
                        <TableCell>{time.matter?.name}</TableCell>
                        <TableCell>
                          {time?.workDescription?.slice(0, 50)}
                        </TableCell>
                        <TableCell className="table-cell">
                          {time.workHour
                            ? time.workHour +
                              (time.workHour > 1 ? " hrs" : " hr")
                            : time.amount?.$numberDecimal +
                              " " +
                              time.currency}{" "}
                        </TableCell>
                        <TableCell className="table-cell">
                          <CiEdit
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEdit(time)}
                          />
                        </TableCell>
                        <TableCell className="table-cell">
                          {
                            isShowingPending && (
                              <Check
                                style={{ cursor: "pointer", color: "green" }}
                                onClick={() => handleApprove(time._id)}
                              />
                            )
                            // : (
                            // <BiDuplicate
                            //   style={{ cursor: "pointer" }}
                            //   onClick={() => handleDuplicate(time)}
                            // />
                            // )
                          }
                        </TableCell>
                        <TableCell className="table-cell">
                          <RiDeleteBin6Line
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDelete(time._id)}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddTime;