import { useState, useEffect } from "react";
import axiosInstance from "../axios";
import { API } from "../../utils/services";

export const useMattersOptions = () => {
  const [matters, setMatters] = useState([]);
  const [filteredMatters, setFilteredMatters] = useState([]);
  const [matterLoading, setMatterLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMatters = async () => {
      try {
        const rowsPerPage = 0;
        const page = 0;

        const res = await axiosInstance.get(`${API}/matter/getAllMatters`, {
          params: {
            limit: rowsPerPage,
            page,
          },
        });

        const { matters } = res.data;

        setMatters(matters);

        const options = matters
          .filter((matter) => matter.status === "enable")
          .map((matter) => ({
            value: matter._id,
            label: `${matter.name} | ${matter.client.name}`,
          }));

        setFilteredMatters([{ value: "", label: "Select All" }, ...options]);
      } catch (error) {
        setError(error);
        // console.log("matter error", error);
      } finally {
        setMatterLoading(false);
      }
    };

    fetchMatters();
  }, []);

  return { matters, filteredMatters, matterLoading, error };
};
